import React from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";

const MenuExcelTemplate4 = ({
  menuDays,
  menuTimes,
  selectedClassGroup,
  weekInMonth,
  startDate,
  termDate,
}) => {
  const stringFirstDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2);
  const stringLastDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear();
  const filterTimesByIdTime = (data, id_time) => {
    return data.times.filter((time) => time.id_time === id_time);
  };
  const groupTimesByIdTime = (dataArray) => {
    // console.log(dataArray, "âấáa");

    const result = [];
    dataArray.forEach((data) => {
      const uniqueIdTimes = [
        ...new Set(data.times.map((time) => time.id_time)),
      ];
      // console.log(uniqueIdTimes, "âấáa");

      uniqueIdTimes.forEach((id_time) => {
        const filteredTimes = filterTimesByIdTime(data, id_time);
        result.push({ id_time, times: filteredTimes });
      });
    });
    // console.log(result, "result");

    return result;
  };

  const aggregateIngredentsByTime = (data) => {
    const groupedTimesMap = new Map();
    data.forEach((item) => {
      const { id_time, times } = item;
      if (!groupedTimesMap.has(id_time)) {
        groupedTimesMap.set(id_time, times);
      } else {
        const existingTimes = groupedTimesMap.get(id_time);
        groupedTimesMap.set(id_time, [...existingTimes, ...times]);
      }
    });
    const calculateAverage = (numbers) => {
      // console.log(numbers, "number");

      const validNumbers = numbers.filter(
        (number) => !isNaN(number) && number > 0
      );
      const sum = validNumbers.reduce((acc, number) => acc + number, 0);
      return validNumbers.length ? sum / validNumbers.length : 0;
    };

    const aggregatedData = [...groupedTimesMap.entries()].map(
      // check ở đây
      ([id_time, times]) => {
        const ingredentsMap = new Map();
        times.forEach((time) => {
          time.foods.forEach((food) => {
            food.groups
              ?.filter((group) => group.id == selectedClassGroup.id)
              ?.forEach((group) => {
                group.ingredents
                  ?.filter((e) => e.ingredent_type == 2)
                  ?.forEach((ingredent) => {
                    // merge id_time
                    const { id, ing_quantity } = ingredent;
                    const id_day = time.id_day;
                    if (!ingredentsMap.has(id)) {
                      ingredentsMap.set(id, [{ ingredent, id_time, id_day }]);
                    } else {
                      ingredentsMap
                        .get(id)
                        .push({ ingredent, id_time, id_day });
                    }
                  });
              });
          });
        });
        // merge id_day
        let resultMap = new Map();
        ingredentsMap.forEach((ingredients, id) => {
          let groupedByDay = {};
          ingredients.forEach((entry) => {
            let day = entry.id_day;
            if (!groupedByDay[day]) {
              groupedByDay[day] = {
                ...entry,
                ingredent: { ...entry.ingredent, ing_quantity: 0 },
              };
            }
            groupedByDay[day].ingredent.ing_quantity += parseFloat(
              entry.ingredent.ing_quantity
            );
          });

          resultMap.set(id, Object.values(groupedByDay));
        });

        const uniqueIngredents = [];
        resultMap.forEach((ingredentList, id) => {
          const sameIdTimeIngredents = ingredentList.filter(
            (item) => item.id_time == id_time
          );

          if (sameIdTimeIngredents.length == 1) {
            uniqueIngredents.push(sameIdTimeIngredents[0].ingredent);
          } else {
            const averageQuantity = calculateAverage(
              sameIdTimeIngredents.map((item) =>
                parseFloat(item.ingredent.ing_quantity)
              )
            );

            const [firstItem] = sameIdTimeIngredents;
            uniqueIngredents.push({
              ...firstItem.ingredent,
              ing_quantity: averageQuantity.toFixed(2).toString(),
            });
          }
        });

        return { id_time, ingredents: uniqueIngredents };
      }
    );

    return aggregatedData;
  };
  return (
    <Table bordered responsive id="menu-export-excel-4" className="d-none">
      <thead>
        <tr>
          {(() => {
            const arr = [];
            for (let i = 0; i <= menuDays.length; i++) {
              if (i === 0) {
                arr.push(<th key={i}>{selectedClassGroup.group_name}</th>);
                continue;
              }

              if (i === Math.floor(menuDays.length / 2) + 1) {
                arr.push(
                  <th key={i}>
                    Thực đơn Tháng {startDate.getMonth() + 1} (Tuần{" "}
                    {weekInMonth}) {stringFirstDate(startDate)} -{" "}
                    {stringLastDate(termDate)}
                  </th>
                );
                continue;
              }

              arr.push(<td key={i}>empty</td>);
            }
            return arr;
          })()}
        </tr>
        <tr>
          <th>Buổi</th>
          {menuDays.map((dayItem) => {
            return (
              <React.Fragment key={dayItem.id_day}>
                <th>{dayItem.day_name}</th>
              </React.Fragment>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {menuTimes.map((timeItem) => {
          const quantitativeTimes = [];
          let arrIngredent = [];
          menuDays.map((quantitativeItem) => {
            return quantitativeItem.times.map((timeObj) => {
              if (timeObj.id_time === timeItem.id_time) {
                quantitativeTimes.push(timeObj);
              }

              return timeObj;
            });
          });
          // Find Store by time

          const groupedDataMap = new Map();
          groupTimesByIdTime(menuDays)?.forEach((item) => {
            const { id_time, times } = item;
            if (!groupedDataMap.has(id_time)) {
              groupedDataMap.set(id_time, times);
            } else {
              const existingTimes = groupedDataMap?.get(id_time);
              groupedDataMap.set(id_time, [...existingTimes, ...times]);
            }
          });
          const IngredentbyTime = [...groupedDataMap.entries()].map(
            ([id_time, times]) => ({
              id_time,
              times,
            })
          );

          //
          let checkItemRows = [];

          quantitativeTimes.map((quantitativeTimeItem) => {
            let itemRow = [];

            const filteredFoodDatas = quantitativeTimeItem?.foods
              .map((foodData) => {
                const filteredGroups = foodData.groups.filter(
                  (group) => group.id === selectedClassGroup.id
                );
                return { ...foodData, groups: filteredGroups };
              })
              .filter((foodData) => foodData.groups.length > 0);

            filteredFoodDatas.map((foodItem, foodIndex) => {
              if (
                foodItem.groups.some(
                  (groupItem) =>
                    groupItem.id === selectedClassGroup.id &&
                    groupItem.ingredents.length > 0
                )
              ) {
                const ingredients = foodItem.groups
                  .find(
                    (groupItem) =>
                      groupItem.id === selectedClassGroup.id &&
                      groupItem.ingredents.length > 0
                  )
                  ?.ingredents?.filter((ingr) => ingr.ingredent_type == 1);

                itemRow.push({
                  id_day: quantitativeTimeItem.id_day,
                  id: foodItem.id,
                  name: foodItem.food_name,
                  keyFood: foodIndex,
                  countIngredient: ingredients.length,
                });
              }

              return foodItem;
            });

            checkItemRows.push({
              id: quantitativeTimeItem.id,
              items: itemRow,
            });

            return quantitativeTimeItem;
          });

          let listItemRows = [];
          let ingredients_Store = [];

          quantitativeTimes.map((quantitativeTimeItem) => {
            let itemRow = [];
            let countItem = 0;
            let startRowFood = {};
            let countIngredient = {};

            const filteredFoodDatas = quantitativeTimeItem?.foods
              .map((foodData) => {
                const filteredGroups = foodData.groups.filter(
                  (group) => group.id === selectedClassGroup.id
                );
                return { ...foodData, groups: filteredGroups };
              })
              .filter((foodData) => foodData.groups.length > 0);

            filteredFoodDatas.map((foodItem, foodIndex) => {
              if (
                foodItem.groups.some(
                  (groupItem) =>
                    groupItem.id === selectedClassGroup.id &&
                    groupItem.ingredents.length !== 0
                )
              ) {
                const group = foodItem.groups.find(
                  (groupItem) => groupItem.id === selectedClassGroup.id
                );

                const ingredients = foodItem.groups
                  .find(
                    (groupItem) =>
                      groupItem.id === selectedClassGroup.id &&
                      groupItem.ingredents.length > 0
                  )
                  ?.ingredents?.filter((ingr) => ingr.ingredent_type == 1);
                ingredients_Store = foodItem.groups
                  .find(
                    (groupItem) =>
                      groupItem.id === selectedClassGroup.id &&
                      groupItem.ingredents.length > 0
                  )
                  ?.ingredents?.filter((ingr) => ingr.ingredent_type == 2);
                let maxItemFoodWeek = 0;

                for (let i = 0; i <= foodIndex; i++) {
                  let maxCountIngredient = 1;

                  checkItemRows.forEach((row) => {
                    row.items.forEach((item) => {
                      if (
                        item.keyFood === i &&
                        item.countIngredient + 1 > maxCountIngredient
                      ) {
                        maxCountIngredient = item.countIngredient + 1;
                      }
                    });
                  });

                  maxItemFoodWeek += maxCountIngredient;
                }

                let maxItemFoodDay = 1;

                checkItemRows.forEach((row) => {
                  row.items.forEach((item) => {
                    if (
                      item.keyFood === foodIndex &&
                      item.countIngredient + 1 > maxItemFoodDay
                    ) {
                      maxItemFoodDay = item.countIngredient + 1;
                    }
                  });
                });

                countIngredient[foodIndex] = ingredients.length;
                startRowFood[foodIndex] = maxItemFoodWeek - maxItemFoodDay + 1; //Tiến tới 1 Vị trí

                countItem =
                  countIngredient[foodIndex] + startRowFood[foodIndex] >
                    countItem
                    ? countIngredient[foodIndex] + startRowFood[foodIndex]
                    : countItem;

                let num = 0;
                if (foodIndex > 0) {
                  num =
                    startRowFood[foodIndex] -
                    (countIngredient[foodIndex - 1] + 1) -
                    startRowFood[foodIndex - 1];
                }

                if (num > 0) {
                  for (let i = 0; i < num; i++) {
                    itemRow.push({
                      isEmpty: true,
                    });
                  }
                }

                itemRow.push({
                  id: foodItem.id,
                  name: foodItem.food_name,
                  quantity: 0,
                  unit: "",
                  isFood: true,
                  minItem: maxItemFoodWeek - maxItemFoodDay,
                  moreMeal: group.more_meal,
                  countIngredient: ingredients.length,
                });

                ingredients.map((ingredientItem) => {
                  itemRow.push({
                    id: ingredientItem.id,
                    name: ingredientItem.ingredent_name,
                    quantity: ingredientItem.ing_quantity,
                    unit: ingredientItem.unit_cook_name,
                    isFood: false,
                  });

                  return ingredientItem;
                });
              } else if (
                foodItem.groups.some(
                  (groupItem) => groupItem.id === selectedClassGroup.id
                )
              ) {
                const group = foodItem.groups.find(
                  (groupItem) => groupItem.id === selectedClassGroup.id
                );

                itemRow.push({
                  id: foodItem.id,
                  name: foodItem.food_name,
                  quantity: 0,
                  unit: "",
                  isFood: true,
                  minItem: 1,
                  moreMeal: group.more_meal,
                  countIngredient: 0,
                });
              }

              return foodItem;
            });

            listItemRows.push({
              id: quantitativeTimeItem.id,
              items: itemRow,
              countItem: countItem,
            });

            return quantitativeTimeItem;
          });

          const maxRowSpanLength =
            Math.max(...listItemRows.map((itemRow) => itemRow.countItem)) > 1
              ? Math.max(...listItemRows.map((itemRow) => itemRow.countItem))
              : 2;

          return (
            <React.Fragment key={timeItem.id_time}>
              <tr>
                <th>{timeItem.time_name}</th>

                {listItemRows?.map((listItemRowsItem, index) => {
                  if (listItemRowsItem.items[0]?.isFood) {
                    let foodName =
                      listItemRowsItem.items[0]?.name +
                      (listItemRowsItem.items[0]?.moreMeal > 0
                        ? ` (SL: ${listItemRowsItem.items[0]?.moreMeal})`
                        : "");

                    return (
                      <React.Fragment key={`food-name-first-${index}`}>
                        <th>{foodName}</th>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={`food-name-first-${index}`}>
                        <th>empty</th>
                      </React.Fragment>
                    );
                  }
                })}
              </tr>

              {(() => {
                const arr = [];
                for (let i = 1; i < maxRowSpanLength; i++) {
                  arr.push(
                    <tr key={i}>
                      <td>
                        HÀNG KHO: <br></br>
                        {aggregateIngredentsByTime(IngredentbyTime)
                          ?.filter((e) => e.id_time == timeItem.id_time)[0]
                          ?.ingredents?.map((store) => {
                            return (
                              <div>
                                {store.ingredent_name}
                                &nbsp;
                                <CurrencyFormat value={parseFloat(store.ing_quantity)} displayType={"text"} thousandSeparator={true} decimalScale={2} />
                                &nbsp;
                                {store.unit_cook_name}
                                <br></br>
                              </div>
                            );
                          })}
                      </td>

                      {listItemRows?.map((listItemRowsItem, index) => {
                        if (listItemRowsItem.items[i]?.isEmpty) {
                          return (
                            <React.Fragment key={listItemRowsItem.id}>
                              <td>empty</td>
                            </React.Fragment>
                          );
                        }

                        if (listItemRowsItem.items[i]?.isFood) {
                          const foodName =
                            listItemRowsItem.items[i]?.name +
                            (listItemRowsItem.items[i]?.moreMeal > 0
                              ? ` (SL: ${listItemRowsItem.items[i]?.moreMeal})`
                              : "");
                          return (
                            <React.Fragment key={listItemRowsItem.id}>
                              <th>{foodName}</th>
                            </React.Fragment>
                          );
                        }

                        return (
                          <React.Fragment key={listItemRowsItem.id}>
                            <td>
                              {listItemRowsItem.items[i]?.name && listItemRowsItem.items[i]?.quantity ? (
                                <>
                                  {listItemRowsItem.items[i]?.name}
                                  &nbsp;
                                  <CurrencyFormat
                                    value={parseFloat(listItemRowsItem.items[i]?.quantity)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                  />
                                  &nbsp;
                                  {listItemRowsItem.items[i]?.unit}
                                </>
                              ) : (
                                "empty"
                              )}
                            </td>
                          </React.Fragment>
                        );
                      })}
                    </tr>
                  );
                }

                return arr;
              })()}
            </React.Fragment>
          );
        })}
      </tbody>
    </Table>
  );
};

export default MenuExcelTemplate4;
