import { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ScheduleTable from "./ScheduleTable";
import ScheduleDaySession from "./ScheduleDaySession";
import swal from "sweetalert";
import moment from "moment/moment";
import * as XLSX from "xlsx-js-style";
import { Link } from "react-router-dom";
import Check from "../other/Check";
import api from "../../../helper/axiosInstance";
// import { MAX_WITH_A4_LANDSCAPE } from "../../../helper/constants";

const daySession = [
  { id: 1, name: "Sáng", nameEn: "morning" },
  { id: 2, name: "Trưa", nameEn: "noon" },
  { id: 3, name: "Chiều", nameEn: "afternoon" },
];

const schoolDays = [
  { id: 1, name: "Thứ 2", nameEn: "monday" },
  { id: 2, name: "Thứ 3", nameEn: "tuesday" },
  { id: 3, name: "Thứ 4", nameEn: "wednesday" },
  { id: 4, name: "Thứ 5", nameEn: "thursday" },
  { id: 5, name: "Thứ 6", nameEn: "friday" },
  { id: 6, name: "Thứ 7", nameEn: "saturday" },
  { id: 7, name: "Chủ nhật", nameEn: "sunday" },
];

const ScheduleIndex = () => {
  const { classId } = useParams();
  const navigate = useNavigate();

  const [classroom, setClassroom] = useState();
  const [scheduleDetail, setScheduleDetail] = useState();
  const [subjects, setSubjects] = useState([]);
  const [scheduleDaySessions, setScheduleDaySessions] = useState([]);
  const [idSession, setIdSession] = useState();
  const [idDay, setIdDay] = useState();

  const [isClassNotFound, setIsClassNotFound] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [weekInMonth, setWeekInMonth] = useState("");
  const [startOfWeek, setStartOfWeek] = useState(new Date());
  const [endOfWeek, setEndOfWeek] = useState(new Date());

  useEffect(() => {
    document.title = "Thời khóa biểu";
    onOpen();
  }, []);

  //#region API
  const getClassroom = async () => {
    try {
      const res = await api.get(`/classroom-show?id=${classId}`);
      if (res.status === 200) {
        const classroom = res.data.data.data;
        setClassroom(classroom);
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const getAllSubjects = async () => {
    await api
      .get(`/subject-shows`)
      .then((res) => {
        if (res.status === 200) {
          setSubjects(
            res.data.data.subjects.filter(
              (subject) => subject.type === "DEFAULT"
            )
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast(err.message, { type: "error", autoClose: 1000 });
      });
  };

  const getSchedule = async (startDate, endDate, copyToCurrent) => {
    setIsLoading(true);
    await api
      .get("/schedule-show", {
        params: {
          id_class: classId,
          start_of_week: startDate.getTime() / 1000,
          end_of_week: endDate.getTime() / 1000,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const schedules = res.data.data.schedules;
          if (copyToCurrent && schedules) {
            schedules.start_of_week = startOfWeek.getTime() / 1000;
            schedules.end_of_week = endOfWeek.getTime() / 1000;
            schedules.id_week = scheduleDetail?.id_week;
          }
          setScheduleDetail(schedules);
          if (copyToCurrent) {
            toast.success("Đã sao chép thành công", { autoClose: 1000 });
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (copyToCurrent) {
          toast.info("Không tìm thấy dữ liệu tuần trước", {
            autoClose: 1000,
          });
        } else {
          setScheduleDetail();
        }
        setIsLoading(false);
      });
  };

  const createSchedule = async (listSchedules) => {
    const toastId = toast.loading("Đang lưu...");
    setIsSending(true);
    await api({
      method: "post",
      url: "schedule-create",
      data: {
        id_class: classId,
        start_of_week: startOfWeek.getTime() / 1000,
        end_of_week: endOfWeek.getTime() / 1000,
        schedules: listSchedules,
      },
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        setIsSending(false);
        toast.update(toastId, {
          render: res.data.messages,
          type: "success",
          autoClose: 1000,
          isLoading: false,
        });
        getSchedule(startOfWeek, endOfWeek);
      })
      .catch((err) => {
        setIsSending(false);
        toast.update(toastId, {
          render: "Lưu thất bại",
          type: "error",
          autoClose: 1000,
          isLoading: false,
        });
      });
  };

  const updateSchedule = async (listSchedules, idWeek) => {
    const toastId = toast.loading("Đang lưu...");
    setIsSending(true);
    await api({
      method: "put",
      url: "schedule-update",
      data: {
        id: idWeek,
        id_class: classId,
        schedules: listSchedules,
      },
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        setIsSending(false);
        toast.update(toastId, {
          render: res.data.messages,
          type: "success",
          autoClose: 1000,
          isLoading: false,
        });
        getSchedule(startOfWeek, endOfWeek);
      })
      .catch((err) => {
        setIsSending(false);
        toast.update(toastId, {
          render: "Lưu thất bại",
          type: "error",
          autoClose: 1000,
          isLoading: false,
        });
      });
  };

  const deleteSchedule = async () => {
    await api
      .delete(`/schedule-delete?id=${scheduleDetail.id_week}`)
      .then((res) => {
        toast(res.data.messages, {
          type: "success",
          autoClose: 1000,
        });
        getSchedule(startOfWeek, endOfWeek);
      })
      .catch((err) => {
        toast("Xóa thất bại", {
          type: "error",
          autoClose: 1000,
        });
      });
  };
  //#endregion API

  //#region Logic
  const onOpen = async () => {
    const result = await getClassroom();
    if (result) {
      setIsClassNotFound(false);
      getAllSubjects();
      let initialWeek = setDatesOfWeek(new Date());
      setWeekInMonth((0 | (initialWeek[0].getDate() / 7)) + 1);
      getSchedule(initialWeek[0], initialWeek[6]);
      return;
    }
    setIsClassNotFound(true);
  };

  const datesOfWeek = (current) => {
    var week = [];
    // Starting Monday not Sunday
    current.setDate(current.getDate() - current.getDay() + 1);
    current.setHours(0, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
      current.setHours(0, 0, 0, 0);
    }
    return week;
  };

  const setDatesOfWeek = (theDate) => {
    const week = datesOfWeek(theDate);
    setStartOfWeek(week[0]);
    setEndOfWeek(week[week.length - 1]);
    return week;
  };

  const stringFirstDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2);

  const stringLastDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear();

  const handleShowModal = (
    scheduleDaySessionsSelected,
    idDaySelected,
    idSessionSelected
  ) => {
    setIdDay(idDaySelected);
    setIdSession(idSessionSelected);
    if (scheduleDaySessionsSelected) {
      setScheduleDaySessions(scheduleDaySessionsSelected);
    }
    setShowModal(true);
  };

  const handleCloseModal = (schedules) => {
    if (schedules) {
      const newScheduleDetail = { ...scheduleDetail };
      let day;
      switch (idDay) {
        case 1:
          day = "monday";
          break;
        case 2:
          day = "tuesday";
          break;
        case 3:
          day = "wednesday";
          break;
        case 4:
          day = "thursday";
          break;
        case 5:
          day = "friday";
          break;
        case 6:
          day = "saturday";
          break;
        case 7:
          day = "sunday";
          break;
      }

      switch (idSession) {
        case 1:
          newScheduleDetail[day] = {
            ...newScheduleDetail[day],
            morning: schedules,
          };
          break;
        case 2:
          newScheduleDetail[day] = {
            ...newScheduleDetail[day],
            noon: schedules,
          };
          break;
        case 3:
          newScheduleDetail[day] = {
            ...newScheduleDetail[day],
            afternoon: schedules,
          };
          break;
      }
      setScheduleDetail(newScheduleDetail);
    }

    setIdDay();
    setIdSession();
    setScheduleDaySessions([]);
    setShowModal(false);
  };

  const onSaveSchedule = () => {
    if (!scheduleDetail) {
      toast.warning("Chưa có thông tin thời khóa biểu", { autoClose: 1000 });
      return;
    }
    const listSchedules = [];
    schoolDays.forEach((day) => {
      if (scheduleDetail[day.nameEn]) {
        daySession.forEach((session) => {
          if (scheduleDetail[day.nameEn][session.nameEn]) {
            scheduleDetail[day.nameEn][session.nameEn].forEach((item) => {
              listSchedules.push({
                id_day: day.id, // required id day of school default setting
                id_session: session.id, // required, 1: morning, 2: noon, 3: afternoon
                id_subject: item.id_subject, // required, integer, type: DEFAULT
                start_at: item.timetable.start_at, // nullable, string lte 50
                end_at: item.timetable.end_at, // nullable, string lte 50
              });
            });
          }
        });
      }
    });
    if (scheduleDetail?.id_week) {
      updateSchedule(listSchedules, scheduleDetail?.id_week);
    } else {
      createSchedule(listSchedules);
    }
  };

  const onDeleteSchedule = () => {
    if (!scheduleDetail?.id_week) {
      return;
    }
    swal({
      title: "Bạn muốn xóa thời khóa biểu?",
      text: `Lớp ${classroom?.class_name} Tháng ${
        startOfWeek.getMonth() + 1
      } (Tuần ${weekInMonth}) ${stringFirstDate(
        startOfWeek
      )} - ${stringLastDate(endOfWeek)}`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        deleteSchedule();
      }
    });
  };

  const exportExcel = () => {
    const fileName = `Thời khóa biểu lớp ${classroom?.class_name} Tháng ${
      startOfWeek.getMonth() + 1
    } (Tuần ${weekInMonth}) ${stringFirstDate(startOfWeek)} -${stringLastDate(
      endOfWeek
    )}.xlsx`;
    const wb = XLSX.utils.book_new();
    const table1 = document.getElementById(`tb-tkb`);
    var ws = XLSX.utils.table_to_sheet(table1, { raw: true });
    const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H"];
    const rowNumber = [1, 2, 3, 4, 5];

    colAlpha.forEach((alpha) => {
      ws[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
        fill: { fgColor: { rgb: "F2F2F2" } },
      };
    });

    rowNumber.forEach((number) => {
      ws[`A${number}`].s = {
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
        fill: { fgColor: { rgb: "F2F2F2" } },
      };
    });

    const borderStyle = {
      font: {
        name: "Times New Roman",
        sz: 10,
      },
      alignment: {
        vertical: "top",
        wrapText: true,
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };

    colAlpha.forEach((alpha) => {
      if (alpha === "A") return;

      rowNumber.forEach((number) => {
        if (number < 3) {
          return;
        }
        if (ws[`${alpha}${number}`].t === "z") {
          ws[`${alpha}${number}`].t = "s";
        }
        ws[`${alpha}${number}`].s = borderStyle;
      });
    });

    ws[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 14,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
      border: {
        bottom: { style: "thin" },
      },
    };

    const MAX_WITH_A4_LANDSCAPE = 120;

    ws["!cols"] = [
      { wch: 10 },
      { wch: (MAX_WITH_A4_LANDSCAPE - 10) / 7 },
      { wch: (MAX_WITH_A4_LANDSCAPE - 10) / 7 },
      { wch: (MAX_WITH_A4_LANDSCAPE - 10) / 7 },
      { wch: (MAX_WITH_A4_LANDSCAPE - 10) / 7 },
      { wch: (MAX_WITH_A4_LANDSCAPE - 10) / 7 },
      { wch: (MAX_WITH_A4_LANDSCAPE - 10) / 7 },
      { wch: (MAX_WITH_A4_LANDSCAPE - 10) / 7 },
    ];

    ws["!rows"] = [{ hidden: false, hpt: 50 }, { hpt: 25 }];

    XLSX.utils.book_append_sheet(wb, ws, "Thời khóa biểu");
    XLSX.writeFile(wb, fileName);
  };
  //#endregion Logic

  const handleGoBack = () => {
    navigate(-1); // Quay lại trang trước đó
  };

  //#region Render
  return (
    <Container fluid className="px-3 mt-3">
      <Breadcrumb>
        <Breadcrumb.Item active>Học sinh</Breadcrumb.Item>
        <Breadcrumb.Item active>
          <Link className="text-muted" to={"/admin-v2/student-group2"}>
            Nhóm học sinh
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Thời khóa biểu</Breadcrumb.Item>
      </Breadcrumb>
      <Card className="mb-4">
        <Card.Header>
          <Card.Title>
            <Button
              className="me-2"
              variant="secondary"
              onClick={() => handleGoBack()}
              title="Quay lại"
            >
              <i className="fas fa-arrow-left"></i>
            </Button>
            {/* <div className=""> */}
            <i className="fa-solid fa-calendar-days"></i>
            &nbsp;Thời khóa biểu Lớp {classroom?.class_name}
            {/* </div> */}
          </Card.Title>

          {isClassNotFound ? (
            <div className="text-center">Không có thông tin lớp</div>
          ) : (
            <Card.Title>
              <div className="d-block d-md-flex  justify-content-between">
                <div className="d-block d-flex align-items-center">
                  Tháng {startOfWeek.getMonth() + 1} (Tuần {weekInMonth}){" "}
                  {stringFirstDate(startOfWeek)} - {stringLastDate(endOfWeek)}
                  {/* Select Week */}
                  <input
                    type="week"
                    name="week"
                    style={{
                      border: "none",
                      width: 39,
                      backgroundColor: "rgb(16,108,252)",
                      borderRadius: 5,
                    }}
                    className="ms-2"
                    onChange={(e) => {
                      const today = e.target.value
                        ? new Date(e.target.valueAsNumber)
                        : new Date();
                      const week = setDatesOfWeek(today);
                      setWeekInMonth((0 | (week[0].getDate() / 7)) + 1);
                      getSchedule(week[0], week[6]);
                    }}
                    disabled={isLoading}
                  />
                </div>

                <div className="d-flex">
                  {new Check().permission(["109"]) ? (
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={() => onDeleteSchedule()}
                      className="me-2"
                      disabled={
                        isLoading || isSending || !scheduleDetail?.id_week
                      }
                      title="Xóa thời khóa biểu"
                    >
                      <i className="fa-solid fa-trash" /> Xóa TKB
                    </Button>
                  ) : (
                    <></>
                  )}

                  {new Check().permission(["106"]) ? (
                    <Button
                      size="sm"
                      variant="success"
                      onClick={() => exportExcel()}
                      className="me-2"
                      disabled={
                        isLoading || isSending || !scheduleDetail?.id_week
                      }
                      title="Xuất excel thời khóa biểu"
                    >
                      <i className="fa-regular fa-cloud-arrow-down" /> Xuất TKB
                    </Button>
                  ) : (
                    <></>
                  )}

                  {new Check().permission(["107"]) ? (
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={() => {
                        const startDay = moment(startOfWeek)
                          .subtract(7, "day")
                          .toDate();
                        const endDay = moment(endOfWeek)
                          .subtract(7, "day")
                          .toDate();
                        console.log(
                          startOfWeek.getTime(),
                          startDay.getTime() / 1000,
                          endDay.getTime() / 1000
                        );
                        getSchedule(startDay, endDay, true);
                      }}
                      className="me-2"
                      disabled={isLoading || isSending}
                      title="Sao chép thời khóa biểu tuần"
                    >
                      <i className="fa-regular fa-clone"></i>Sao chép
                    </Button>
                  ) : (
                    <></>
                  )}

                  {new Check().permission(["108", "107"], "or") ? (
                    <Button
                      variant="success"
                      onClick={onSaveSchedule}
                      disabled={isLoading || !scheduleDetail || isSending}
                      title="Lưu thời khóa biểu"
                    >
                      <i className="fa-solid fa-check" /> Lưu TKB
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Card.Title>
          )}
        </Card.Header>

        {isClassNotFound ? null : (
          <Card.Body>
            <div className="text-end mb-2">
              <i className="text-muted">
                <strong>
                  <span className="text-danger">**</span>Lưu ý:
                </strong>{" "}
                Hãy chọn "Lưu thời khóa biểu" trước khi chuyển trang.
              </i>
            </div>

            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <ScheduleTable
                classroom={classroom}
                schedules={scheduleDetail}
                setSchedules={setScheduleDetail}
                showModal={handleShowModal}
                timeTitle={`Tháng ${
                  startOfWeek.getMonth() + 1
                } (Tuần ${weekInMonth}) ${stringFirstDate(startOfWeek)} -
                        ${stringLastDate(endOfWeek)}`}
              />
            )}
          </Card.Body>
        )}
      </Card>
      {showModal && (
        <ScheduleDaySession
          show={showModal}
          onHide={handleCloseModal}
          listSubjects={subjects}
          scheduleDaySessions={scheduleDaySessions}
          idSession={idSession}
          idDay={idDay}
        />
      )}
    </Container>
  );
  //#endregion Render
};

export default ScheduleIndex;
