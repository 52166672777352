import { toast } from "react-toastify";
import { message } from "../../../constants/message";
import { QUANTITIATIVE_ZONE } from "../../../constants";

const groupArrayByField = (arr, key) => {
  return [
    ...arr
      .reduce(
        (acc, o) => acc.set(o[key], (acc.get(o[key]) || []).concat(o)),
        new Map()
      )
      .values(),
  ];
};

const priceFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

const priceFormatterNoDecimal = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const dateFormatter = (date) => {
  return (
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear()
  );
};

const takeDecimalNumber = (num, n) => {
  let base = 10 ** n;
  let result = Math.round(num * base) / base;
  return result;
};

const getDate = (data) => {
  const date = new Date(data * 1000);
  return (
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear() +
    " " +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2)
  );
};

const getTime = (data) => {
  const date = new Date(data * 1000);
  return (
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2)
    //  +
    // ":" +
    // ("0" + date.getSeconds()).slice(-2)
  );
};

const toastErrorMessage = (error) => {
  let mess = "";

  switch (error.response?.status) {
    case 400:
      mess = message.errorBadRequest;
      break;
    case 500:
      mess = error.response.data.messages || message.errorInternalServer;
      break;
    default:
      mess = message.errorUnknown;
  }

  return toast(mess, { type: "error", autoClose: 1000 });
};

const toSlug = (str) => {
  str = str.toLowerCase(); // Chuyển hết sang chữ thường
  // xóa dấu
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
  str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
  str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
  return str;
};

const findeQuantitativeZone = (id_age) => {
  if (id_age >= 1 && id_age <= 3) {
    return QUANTITIATIVE_ZONE[0];
  } else if (id_age === 4) {
    return QUANTITIATIVE_ZONE[1];
  } else if (id_age >= 5) {
    return QUANTITIATIVE_ZONE[2];
  }
  return null;
};

const isInRange = (number, attribute, data) => {
  if (data?.hasOwnProperty(attribute)) {
    const min = data[attribute].MIN;
    const max = data[attribute].MAX;
    return number < min || number > max;
  }
  return false;
};

const isDecimal = (value) => {
  return value % 1 !== 0;
};

const getlastDayOfMonth = (year, month) => {
  var nextMonth = new Date(year, month + 1, 1);

  var lastDay = new Date(nextMonth.getTime() - 1);

  return new Date(lastDay.setHours(23, 59, 59));
};

const getNutritionalColorByRange = (number, attribute, data) => {
  if (data?.hasOwnProperty(attribute)) {
    const min = data[attribute].MIN;
    const max = data[attribute].MAX;

    if (number < min) {
      return "text-info";
    } else if (number > max) {
      return "text-danger";
    } else {
      return "text-success";
    }
  }
  return "";
};
const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
const extractAfterNumbers = (input) => {
  const startWords = [
    "một",
    "hai",
    "ba",
    "bốn",
    "năm",
    "sáu",
    "bảy",
    "tám",
    "chín",
    "mười",
  ];
  const regex = new RegExp(`\\b(${startWords.join("|")})\\b`, "i");

  const outputs = input.split(",").map((str) => {
    const match = str.match(regex);
    if (match) {
      const startIndex = str.indexOf(match[0]);
      return str.slice(startIndex);
    }
    return str;
  });

  return outputs.join(", ");
};
const soThanhChu = (so) => {
  const hang = ["", "nghìn", "triệu", "tỷ", "nghìn tỷ", "triệu tỷ", "tỷ tỷ"];

  if (so === 0) return "Không đồng";

  let chuoi = "";
  let i = 0;

  while (so > 0) {
    let donViHangNghin = so % 1000;

    // Chỉ thêm "không trăm" nếu không phải khối đầu tiên và khi cần thiết
    if (donViHangNghin > 0 || i === 0) {
      chuoi =
        soThanhChuHangNghin(donViHangNghin, i > 0) +
        (donViHangNghin > 0 ? " " + hang[i] : "") +
        " " +
        chuoi;
    }

    so = Math.floor(so / 1000);
    i++;
  }

  return chuoi.trim() + " đồng";
};

const soThanhChuHangNghin = (so, isMiddleBlock) => {
  const donVi = [
    "",
    "một",
    "hai",
    "ba",
    "bốn",
    "năm",
    "sáu",
    "bảy",
    "tám",
    "chín",
  ];
  const chuc = [
    "",
    "mười",
    "hai mươi",
    "ba mươi",
    "bốn mươi",
    "năm mươi",
    "sáu mươi",
    "bảy mươi",
    "tám mươi",
    "chín mươi",
  ];

  let chuoi = "";

  const hangTram = Math.floor(so / 100);
  const hangChuc = Math.floor((so % 100) / 10);
  const hangDonVi = so % 10;

  if (hangTram > 0) {
    chuoi += donVi[hangTram] + " trăm";
  } else if (isMiddleBlock) {
    chuoi += "không trăm";
  }

  if (hangChuc > 0) {
    chuoi += " " + chuc[hangChuc];
    if (hangDonVi > 0) {
      chuoi +=
        hangDonVi === 5 && hangChuc > 0 ? " lăm" : " " + donVi[hangDonVi];
    }
  } else if (hangDonVi > 0) {
    chuoi += " " + (hangDonVi === 5 ? "năm" : donVi[hangDonVi]);
  }

  return chuoi.trim();
};

const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = [...arr1].sort((a, b) => a - b);
  const sortedArr2 = [...arr2].sort((a, b) => a - b);

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};
const changeEmpty = (element) => {
  return element == "" ||
    element == null ||
    element == "null" ||
    element == undefined ||
    element == "undefined"
    ? "empty"
    : element;
};
const truncateString = (input) => {
  const maxLength = 31;
  if (input.length > maxLength) {
    return input.slice(0, 25) + "...";
  }
  return input;
};

const updateTimes = (arr, arr2) => {
  const updatedArray = arr.map((item, index) => {
    const updatedTimes = [...item.times];

    if (index < arr.length - 1) {
      const nextDayFirstTime = arr[index + 1].times[0];
      if (nextDayFirstTime) {
        updatedTimes.push({
          ...nextDayFirstTime,
          time_name: `${nextDayFirstTime.time_name} ${new Date(
            nextDayFirstTime?.menu_date * 1000
          )?.getDate()}/${
            new Date(nextDayFirstTime?.menu_date * 1000)?.getMonth() + 1
          }`,
        });
      }
    } else {
      updatedTimes.push(
        {
          ...arr2[0]?.times[0],
          time_name: `${arr2[0]?.times[0]?.time_name} ${new Date(
            arr2[0]?.times[0]?.menu_date * 1000
          )?.getDate()}/${
            new Date(arr2[0]?.times[0]?.menu_date * 1000)?.getMonth() + 1
          }`,
        } || {}
      );
    }

    return {
      ...item,
      times: updatedTimes,
    };
  });

  return updatedArray;
};
const toVietnamese = (input, currency = "đồng") => {
  const numbers = " hai ba bốn năm sáu bảy tám chín";
  const dict = {
    units: ("? một" + numbers).split(" "),
    tens: ("lẻ mười" + numbers).split(" "),
    hundreds: ("không một" + numbers).split(" "),
  };
  const digits = "x nghìn triệu tỉ nghìn".split(" ");
  const tram = "trăm";

  function tenth(block) {
    let [tens, unit] = [block[0], dict.units[block[1]]];
    const result = [dict.tens[tens]];
    if (tens > 0 && block[1] == 5) unit = "lăm";
    if (tens > 1) {
      result.push("mươi");
      if (block[1] == 1) unit = "mốt";
    }
    if (unit != "?") result.push(unit);
    return result.join(" ");
  }

  function blockOfThree(block) {
    switch (block.length) {
      case 1:
        return dict.units[block];
      case 2:
        return tenth(block);
      case 3:
        return [
          dict.hundreds[block[0]],
          tram,
          block.slice(1) !== "00" ? tenth(block.slice(1)) : "",
        ]
          .join(" ")
          .trim();
    }
    return "";
  }

  let str = parseInt(input).toString();
  if (!str || isNaN(str)) return "";

  const arr = [];
  for (let i = str.length; i > 0; i -= 3)
    arr.push(str.substring(Math.max(i - 3, 0), i));

  const result = [];
  let digitCounter = 0;
  for (let i = arr.length - 1; i >= 0; i--) {
    if (arr[i] == "000") {
      digitCounter++;
      if (i === 2 && digitCounter === 2) result.push(digits[i + 1]);
    } else {
      digitCounter = 0;
      result.push(blockOfThree(arr[i]));
      if (digits[i] && digits[i] !== "x") result.push(digits[i]);
    }
  }
  if (currency) result.push(currency);
  return result.join(" ").trim();
};

if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
  module.exports = toVietnamese;
} else if (typeof window !== "undefined") {
  window.toVietnamese = toVietnamese;
}
export {
  groupArrayByField,
  priceFormatter,
  dateFormatter,
  takeDecimalNumber,
  getDate,
  toastErrorMessage,
  toSlug,
  priceFormatterNoDecimal,
  getTime,
  findeQuantitativeZone,
  isInRange,
  isDecimal,
  getNutritionalColorByRange,
  getlastDayOfMonth,
  soThanhChu,
  capitalizeFirstLetter,
  arraysAreEqual,
  changeEmpty,
  extractAfterNumbers,
  truncateString,
  updateTimes,
  toVietnamese,
};
