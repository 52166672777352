import React from "react";
import { Form, Table } from "react-bootstrap";
import FormSettingDatePicker from "./FormSettingDatePicker";

const SampleBook = ({ formSetting, setFormSetting, times }) => {
  let show_item_single = null;
  const qty_merge =
    times.filter((itemTime) => itemTime.time_name_now !== null)?.length || 1;

  return (
    <div style={{ overflowX: "scroll" }}>
      <Table bordered style={{ minWidth: 999 }}>
        <thead>
          <tr>
            <td colSpan={2} className="align-middle">
              Địa điểm kiểm tra:
            </td>
            <td colSpan={2}>
              <Form.Control
                type="text"
                value={formSetting.book_check_address || ""}
                onChange={(e) =>
                  setFormSetting({
                    ...formSetting,
                    book_check_address: e.target.value,
                  })
                }
              />
            </td>
          </tr>
        </thead>

        <thead>
          <tr className="text-center">
            <td className="align-middle" style={{ minWidth: "8rem" }}>
              Buổi
            </td>
            <td className="align-middle">Bữa ăn (giờ ăn...)</td>
            <td className="align-middle">Dụng cụ chứa mẫu thức ăn lưu</td>
            <td className="align-middle">Nhiệt độ bảo quản mẫu (℃)</td>
            <td className="align-middle">
              Thời gian lấy mẫu <br />
              (giờ, ngày, tháng, năm)
            </td>
            <td className="align-middle">
              Thời gian hủy mẫu <br />
              (giờ, ngày, tháng, năm)
            </td>
            <td className="align-middle">
              Người lưu mẫu <br />
              (ký và ghi rõ họ tên)
            </td>
            <td className="align-middle">
              Người hủy mẫu <br />
              (ký và ghi rõ họ tên)
            </td>
          </tr>
          <tr className="text-center">
            <td className="align-middle"></td>
            <td className="align-middle">(3)</td>
            <td className="align-middle">(6)</td>
            <td className="align-middle">(7)</td>
            <td className="align-middle">(8)</td>
            <td className="align-middle">(9)</td>
            <td className="align-middle">(11)</td>
            <td className="align-middle">(12)</td>
          </tr>
        </thead>
        <tbody>
          {times.map((timeItem, timeIndex) => {
            if (show_item_single === null && timeItem.time_name_now !== null)
              show_item_single = timeIndex;

            return (
              <tr
                key={`time_${timeIndex}`}
                className={timeItem.time_name_now === null ? "d-none" : ""}
              >
                <td>{timeItem.time_name_now}</td>
                <td>
                  <FormSettingDatePicker
                    selected={
                      Number(
                        formSetting.book_3?.filter(
                          (e) => e.time == timeItem.id
                        )[0]?.val
                      ) || new Date()
                    }
                    onChange={(date) =>
                      setFormSetting({
                        ...formSetting,
                        book_3: formSetting.book_3?.map(
                          (timeObj, timeObjIndex) => {
                            if (timeItem.id === timeObjIndex + 1) {
                              return {
                                ...timeObj,
                                time: timeItem.id,
                                val: date.getTime(),
                              };
                            }

                            return timeObj;
                          }
                        ),
                      })
                    }
                  />
                </td>

                {timeIndex === show_item_single ? (
                  <>
                    <td
                      rowSpan={qty_merge}
                      className="text-center align-middle"
                    >
                      <Form.Control
                        type="text"
                        value={formSetting.book_6}
                        onChange={(e) =>
                          setFormSetting({
                            ...formSetting,
                            book_6: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td
                      rowSpan={qty_merge}
                      className="text-center align-middle"
                    >
                      <Form.Control
                        type="text"
                        value={formSetting.book_7}
                        onChange={(e) =>
                          setFormSetting({
                            ...formSetting,
                            book_7: e.target.value,
                          })
                        }
                      />
                    </td>
                  </>
                ) : (
                  ""
                )}

                <td>
                  <FormSettingDatePicker
                    selected={
                      Number(
                        formSetting.book_8?.filter(
                          (e) => e.time == timeItem.id
                        )[0]?.val
                      ) || new Date()
                    }
                    onChange={(date) =>
                      setFormSetting({
                        ...formSetting,
                        book_8: formSetting.book_8?.map(
                          (item, timeObjIndex) => {
                            if (timeItem.id === timeObjIndex + 1) {
                              return {
                                ...item,
                                time: timeItem.id,
                                val: date.getTime(),
                              };
                            }

                            return item;
                          }
                        ),
                      })
                    }
                  />
                </td>
                <td>
                  <FormSettingDatePicker
                    selected={
                      Number(formSetting.book_9[timeIndex]?.val) || new Date()
                    }
                    onChange={(date) =>
                      setFormSetting({
                        ...formSetting,
                        book_9: formSetting.book_9?.map(
                          (item, timeObjIndex) => {
                            if (timeItem.id === timeObjIndex + 1)
                              return {
                                ...item,
                                time: timeItem.id,
                                val: date.getTime(),
                              };

                            return item;
                          }
                        ),
                      })
                    }
                  />
                </td>

                {timeIndex === show_item_single ? (
                  <>
                    <td
                      rowSpan={qty_merge}
                      className="text-center align-middle"
                    >
                      <Form.Control
                        type="text"
                        value={formSetting.book_11}
                        onChange={(e) =>
                          setFormSetting({
                            ...formSetting,
                            book_11: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td
                      rowSpan={qty_merge}
                      className="text-center align-middle"
                    >
                      <Form.Control
                        type="text"
                        value={formSetting.book_12}
                        onChange={(e) =>
                          setFormSetting({
                            ...formSetting,
                            book_12: e.target.value,
                          })
                        }
                      />
                    </td>
                  </>
                ) : (
                  ""
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default SampleBook;
