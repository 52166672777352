import React from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";

const MenuExcelTemplate1 = ({ menuDays, menuTimes, selectedClassGroup, weekInMonth, startDate, termDate }) => {
	const stringFirstDate = (date) => ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2);
	const stringLastDate = (date) => ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();

	return (
		<Table bordered responsive id="menu-export-excel-1" className="d-none">
			<thead>
				<tr>
					{(() => {
						const arr = [];
						for (let i = 0; i < menuDays.length * 2; i++) {
							if (i === 0) {
								arr.push(<th key={i}>{selectedClassGroup.group_name}</th>);
								continue;
							}

							if (i === Math.floor((menuDays.length * 2) / 2) + 1) {
								arr.push(
									<th key={i}>
										Thực đơn Tháng {startDate.getMonth() + 1} (Tuần {weekInMonth}) {stringFirstDate(startDate)} - {stringLastDate(termDate)}
									</th>,
								);
								continue;
							}

							arr.push(<th key={i}>empty</th>);
						}
						return arr;
					})()}
				</tr>
				<tr>
					<th>Buổi</th>
					{menuDays.map((dayItem) => {
						return (
							<React.Fragment key={dayItem.id_day}>
								<th>{dayItem.day_name}</th>
								<th>empty</th>
							</React.Fragment>
						);
					})}
				</tr>
			</thead>

			<tbody>
				{menuTimes.map((timeItem) => {
					const quantitativeTimes = [];
					menuDays.map((quantitativeItem) => {
						return quantitativeItem.times.map((timeObj) => {
							if (timeObj.id_time === timeItem.id_time) {
								quantitativeTimes.push(timeObj);
							}

							return timeObj;
						});
					});

					const listItemRows = quantitativeTimes.map((quantitativeTimeItem) => {
						let itemRow = [];
						let rowSpanLength = 0;

						quantitativeTimeItem.foods.map((foodItem) => {
							if (foodItem.groups.some((groupItem) => groupItem.id === selectedClassGroup.id && groupItem.ingredents.length !== 0)) {
								const group = foodItem.groups.find((groupItem) => groupItem.id === selectedClassGroup.id)
								rowSpanLength += 1;
								rowSpanLength += foodItem.groups[0].ingredents.length;

								itemRow.push({
									isFood: true,
									id: foodItem.id,
									name: foodItem.food_name,
									quantity: 0,
									unit: "",
									moreMeal: group.more_meal
								});

								foodItem.groups
									.find((groupItem) => groupItem.id === selectedClassGroup.id && groupItem.ingredents.length > 0)
									.ingredents.map((ingredientItem) => {
										itemRow.push({
											isFood: false,
											id: ingredientItem.id,
											name: ingredientItem.ingredent_name,
											quantity: ingredientItem.ing_quantity,
											unit: ingredientItem.unit_cook_name,
										});

										return ingredientItem;
									});
							}

							return foodItem;
						});

						return {
							id: quantitativeTimeItem.id,
							items: itemRow,
							length: rowSpanLength !== 0 ? rowSpanLength : 1
						};
					});

					const maxRowSpanLength = Math.max(...listItemRows.map((itemRow) => itemRow.length));

					return (
						<React.Fragment key={timeItem.id_time}>
							<tr>
								<th>{timeItem.time_name}</th>
								{quantitativeTimes.map((quantitativeItem) => {
									const foods = quantitativeItem?.foods.filter(food => food.groups.some(group => group.id === selectedClassGroup.id));

									const group = foods[0]?.groups.find((groupItem) => groupItem.id === selectedClassGroup.id) || {};

									let foodName = foods[0]?.groups.some((groupItem) => groupItem.id === selectedClassGroup.id && groupItem.ingredents.length > 0)
										? foods[0]?.food_name
										: "empty";

									if (group.more_meal > 0) foodName = foodName + ` (SL: ${group.more_meal})`;

									return (
										<React.Fragment key={quantitativeItem.id}>
											<th>{foodName}</th>
											<th>empty</th>
										</React.Fragment>
									);
								})}
							</tr>

							{(() => {
								const arr = [];
								for (let i = 1; i < maxRowSpanLength; i++) {
									arr.push(
										<tr key={i}>
											<td>empty</td>
											{listItemRows?.map((listItemRowsItem) => {
												if (listItemRowsItem.items[i]?.isFood) {
													const foodName = listItemRowsItem.items[i]?.name + (listItemRowsItem.items[i]?.moreMeal > 0 ? ` (SL: ${listItemRowsItem.items[i]?.moreMeal})` : '');

													return (
														<React.Fragment key={listItemRowsItem.id}>
															<th>{foodName}</th>
															<th>empty</th>
														</React.Fragment>
													);
												}

												return (
													<React.Fragment key={listItemRowsItem.id}>
														<td>{listItemRowsItem.items[i]?.name || "empty"}</td>
														<td>
															{listItemRowsItem.items[i]?.quantity ? (
																<>
																	<CurrencyFormat
																		value={parseFloat(listItemRowsItem.items[i]?.quantity)}
																		displayType={"text"}
																		thousandSeparator={true}
																		decimalScale={2}
																	/>
																	&nbsp;
																	{listItemRowsItem.items[i]?.unit}
																</>
															) : (
																"empty"
															)}
														</td>
													</React.Fragment>
												);
											})}
										</tr>
									);
								}
								return arr;
							})()}
						</React.Fragment>
					);
				})}
			</tbody>
		</Table>
	);
};

export default MenuExcelTemplate1;
