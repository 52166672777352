import React from "react";

import "./assets/fontawesome-free-6.2.1-web/css/fontawesome.css";
import "./assets/fontawesome-free-6.2.1-web/css/solid.css";
// import "./assets/fontawesome-free-6.2.1-web/css/regular.css";
// import "./assets/fontawesome-free-6.2.1-web/css/brands.css";

import Router from "./routes/router";
import axios from "axios";
import Consent from "./components/admin2/cookie/Consent";

axios.defaults.baseURL = `${
  process.env.REACT_APP_BACKEND_URL || "http://localhost:5000/api/kfood/v1"
}/`;

axios.defaults.headers.post["Content-Type"] = "*/*";
axios.defaults.headers.post["Accept"] = "*/*";
axios.defaults.headers.post["Access-Control-Allow-Origin"] =
  process.env.REACT_APP_CLIENT_URL || "*";
axios.defaults.headers["Authorization"] = localStorage.getItem("access_token")
  ? "Bearer " + localStorage.getItem("access_token")
  : "";

export default function App() {
  const cookieAccepted = !!localStorage.getItem("cookie-consent");

  return (
    <>
      {!cookieAccepted && <Consent />}
      <Router />
    </>
  );
}
