import React from "react";
import { Table } from "react-bootstrap";

const DailyMenuTable = ({ times }) => {
  if (!times || times?.length === 0) {
    return <p>Không có dữ liệu thực đơn cho hôm nay.</p>;
  }

  return (
    <Table bordered className="mx-1 mt-2">
      <thead>
        <tr style={{ background: "rgba(54, 162, 235, 0.6)" }}>
          <th>Buổi</th>
          <th>Danh mục</th>
          <th>Món ăn</th>
        </tr>
      </thead>
      <tbody>
        {times?.map((time, timeIndex) =>
          time?.foods?.map((food, foodIndex) => (
            <tr key={`${timeIndex}-${foodIndex}`} className="align-middle">
              {foodIndex === 0 && (
                <td rowSpan={time?.foods?.length}>{time.time_name}</td>
              )}
              <td>{food.category_name}</td>
              <td>{food.food_name}</td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

export default DailyMenuTable;
