import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { groupArrayByField, priceFormatter } from "../../../utils/helpers";

const IngredientProcessingTable = ({ listVoucher }) => {
  let ingredientArray = [];

  listVoucher.map((voucherItem) => {
    ingredientArray = ingredientArray.concat(voucherItem.ingredents);
    return voucherItem;
  });

  const groupIngredientArray = groupArrayByField(
    ingredientArray,
    "id_ingredent"
  );

  let totalMarketMoney = 0;
  let totalStoreMoney = 0;

  return (
    <Table striped hover>
      <thead>
        <tr>
          <th className="text-center">STT</th>
          <th className="text-start">Tên nguyên liệu</th>
          <th className="text-start">Đơn vị tính</th>
          <th className="text-end">Số lượng</th>
          <th className="text-end">Tổng tiền</th>
        </tr>
      </thead>
      <tbody>
        {groupIngredientArray.filter(
          (groupIngredientItem) => groupIngredientItem[0].ingredent_type === 1
        ).length !== 0 ? (
          <>
            {groupIngredientArray
              .filter(
                (groupIngredientItem) =>
                  groupIngredientItem[0].ingredent_type === 1
              )
              .map((groupIngredientItem, groupIngredientIndex) => {
                let ingredientQuantity = 0;
                let ingredientPrice = 0;

                groupIngredientItem.map((ingredientItem) => {
                  ingredientQuantity += Number(ingredientItem.ing_quantity);
                  ingredientPrice += Number(
                    ingredientItem.ing_quantity * ingredientItem.ing_price
                  );
                  return ingredientItem;
                });

                ingredientPrice = Math.round(ingredientPrice);
                totalMarketMoney += ingredientPrice;

                return (
                  <tr key={groupIngredientItem[0].id_ingredent}>
                    <td className="text-center">{groupIngredientIndex + 1}</td>
                    <td className="text-start">
                      {groupIngredientItem[0].ingredent_name}
                    </td>
                    <td className="text-start">
                      {groupIngredientItem[0].unit_market_name}
                    </td>
                    <td className="text-end">
                      {priceFormatter.format(ingredientQuantity)}
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={ingredientPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                        isNumericString
                      />
                    </td>
                  </tr>
                );
              })}

            <tr>
              <th></th>
              <th className="text-center">TỔNG CỘNG</th>
              <th></th>
              <th></th>
              <th className="text-end">
                <CurrencyFormat
                  value={totalMarketMoney}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
          </>
        ) : (
          <tr>
            <td colSpan={5} className="text-center">
              Không có hàng chợ
            </td>
          </tr>
        )}

        {groupIngredientArray.filter(
          (groupIngredientItem) => groupIngredientItem[0].ingredent_type === 2
        ).length !== 0 ? (
          <>
            {groupIngredientArray
              .filter(
                (groupIngredientItem) =>
                  groupIngredientItem[0].ingredent_type === 2
              )
              .map((groupIngredientItem, groupIngredientIndex) => {
                let ingredientQuantity = 0;
                let ingredientPrice = 0;

                groupIngredientItem.map((ingredientItem) => {
                  ingredientQuantity += Number(ingredientItem.ing_quantity);
                  ingredientPrice += Number(
                    ingredientItem.ing_quantity * ingredientItem.ing_price
                  );
                  return ingredientItem;
                });

                ingredientPrice = Math.round(ingredientPrice);
                totalStoreMoney += ingredientPrice;

                return (
                  <tr key={groupIngredientItem[0].id_ingredent}>
                    <td className="text-center">{groupIngredientIndex + 1}</td>
                    <td className="text-start">
                      {groupIngredientItem[0].ingredent_name}
                    </td>
                    <td className="text-start">
                      {groupIngredientItem[0].unit_market_name}
                    </td>
                    <td className="text-end">
                      {priceFormatter.format(ingredientQuantity)}
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={ingredientPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                        isNumericString
                      />
                    </td>
                  </tr>
                );
              })}

            <tr>
              <th></th>
              <th className="text-center">TỔNG CỘNG</th>
              <th></th>
              <th></th>
              <th className="text-end">
                <CurrencyFormat
                  value={totalStoreMoney}
                  thousandSeparator={true}
                  allowNegative={false}
                  displayType="text"
                />
              </th>
            </tr>
          </>
        ) : (
          <tr>
            <td colSpan={5} className="text-center">
              Không có hàng kho
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default IngredientProcessingTable;
