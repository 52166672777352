import { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Container, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../helper/axiosInstance";

const ResultEvaluation = () => {
  const { idevaluation } = useParams();
  const navigate = useNavigate();
  const [evaluation, setEvaluation] = useState([]);
  const [resultEvaluation, setResultEvaluation] = useState([]);
  const [classrooms, setClassroom] = useState([]);

  useEffect(() => {
    document.title = "Kết quả đánh giá";
    getEvaluation(idevaluation);
    getResultEvaluation(idevaluation);
  }, []);
  const getEvaluation = (id) => {
    if (id !== "") {
      api
        .get(`/evaluation-period-show?id=${id}`)
        .then((res) => {
          getClassRoom(res.data.data?.start_date, res.data.data?.end_date);

          setEvaluation(res.data.data);
        })
        .catch((err) => {});
    }
  };
  const getResultEvaluation = (id) => {
    if (id !== "") {
      api
        .get(`/evaluation-period-result?id=${id}`)
        .then((res) => {
          setResultEvaluation(res.data.data);
        })
        .catch((err) => {});
    }
  };
  const getClassRoom = (start, end) => {
    api
      .get(`/classroom-shows?start_date=${parseInt(start)}&end_date=${end}`)
      .then((res) => {
        setClassroom(res.data.data.classrooms || []);
      })
      .catch((err) => {});
  };
  const getAverageScore = (teacherName, className, id_question) => {
    for (const teacher of resultEvaluation) {
      if (teacher.teacherName == teacherName) {
        for (const classAverage of teacher.classAverageScores) {
          if (classAverage.className == className) {
            for (const question of classAverage.questionScores) {
              if (question.id_question == id_question) {
                return question.averageScore;
              }
            }
          }
        }
      }
    }
    return null;
  };
  const getMaxAnswerScore = (questionContent) => {
    for (const question of evaluation.questions) {
      if (question.question_content == questionContent) {
        let maxScore = -Infinity;
        for (const answer of question.answers) {
          if (answer.answer_score > maxScore) {
            maxScore = answer.answer_score;
          }
        }
        return maxScore;
      }
    }

    return null;
  };
  const calculateTotalAnswerScore = () => {
    let totalMaxScore = 0;
    evaluation.questions?.map((item) => {
      totalMaxScore += getMaxAnswerScore(item.question_content);
    });
    return totalMaxScore;
  };
  const calculateTotalAverageScore = (className, teacherName) => {
    const teacher = resultEvaluation?.find(
      (teacher) => teacher.teacherName == teacherName
    );
    if (!teacher) {
      return 0;
    }

    const classInfo = teacher.classAverageScores.find(
      (classInfo) => classInfo.className == className
    );
    if (!classInfo) {
      return 0;
    }

    const totalAverageScore = classInfo.questionScores.reduce(
      (total, question) => total + question.averageScore,
      0
    );

    return totalAverageScore;
  };

  //#region Render
  return (
    <Container fluid className="px-3 mt-3">
      <Breadcrumb>
        <Breadcrumb.Item active>Nhân viên</Breadcrumb.Item>

        <Breadcrumb.Item active>Đánh giá</Breadcrumb.Item>
      </Breadcrumb>

      <Card className="mb-4">
        <Card.Header>
          <Card.Title>
            <i className="fas fa-list me-1" /> Kết quả -{" "}
            {evaluation.period_name}
            <Button
              variant="secondary"
              className="float-end"
              onClick={() => navigate("/admin-v2/evaluation")}
            >
              <i className="fas fa-arrow-left"></i> Quay lại
            </Button>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div style={{ overflow: "auto" }}>
            <Table bordered hover>
              <thead className="align-middle text-center">
                <tr>
                  <th rowSpan={2} style={{ minWidth: "200px" }}>
                    Câu hỏi
                  </th>
                  <th rowSpan={2}>Số điểm</th>
                  {classrooms?.map((item) => {
                    return (
                      <th colSpan={item.teachers?.length || 1}>
                        {item.class_name}
                      </th>
                    );
                  })}
                </tr>
                <tr>
                  {classrooms?.map((item) => {
                    return item.teachers?.map((teacher) => {
                      return (
                        <th style={{ minWidth: "150px" }}>
                          {teacher.teacher_name}
                        </th>
                      );
                    });
                  })}
                </tr>
              </thead>
              <tbody>
                {evaluation?.questions?.map((question) => {
                  return (
                    <tr>
                      <td>{question.question_content}</td>
                      <td className="text-center">
                        {getMaxAnswerScore(question.question_content)}
                      </td>
                      {classrooms?.map((item) => {
                        return item.teachers?.map((teacher) => {
                          return (
                            <td className="text-center">
                              {getAverageScore(
                                teacher.teacher_name,
                                item.class_name,
                                question.id
                              )}
                            </td>
                          );
                        });
                      })}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th>Tổng</th>
                  <th className="text-center">{calculateTotalAnswerScore()}</th>
                  {classrooms?.map((item) => {
                    return item.teachers?.map((teacher) => {
                      return (
                        <th className="text-center">
                          {calculateTotalAverageScore(
                            item.class_name,
                            teacher.teacher_name
                          )}
                        </th>
                      );
                    });
                  })}
                </tr>
              </tfoot>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ResultEvaluation;
