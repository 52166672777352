import React, { Component } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import moment from "moment/moment";
import { toast } from "react-toastify";
import api from "../../../helper/axiosInstance";
class AchievementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      other: [{ key: "", value: "", date: new Date() / 1000 }],
    };
  }
  handleChangeOther = (index, field, value) => {
    if (field === "date") {
      value = new Date(value) / 1000;
    }
    const { other } = this.state;
    const newList = [...other];
    newList[index][field] = value;

    if (
      index === other.length - 1 &&
      newList[index].key &&
      newList[index].date &&
      newList[index].value
    ) {
      newList.push({ key: "", value: "", date: new Date() / 1000 });
    }

    if (
      newList.length === 0 ||
      (newList.length === 1 && (newList[0].key || newList[0].value))
    ) {
      newList.push({ key: "", value: "", date: new Date() / 1000 });
    }

    this.setState({ other: newList });
  };

  handleRemove = (index) => {
    const { other } = this.state;
    const newList = [...other];
    newList.splice(index, 1);
    this.setState({ other: newList });
  };

  createAchievement = () => {
    let data = {
      id_student: this.props.studentChoice?.id, // required
      achievements: this.state.other
        ?.filter((e) => e.value != "" || e.key != "")
        .map((item) => ({
          achievement: item.key,
          description: item.value,
          achieved_date: parseInt(item.date),

          // [item.key]: item.value
        })),
    };

    api
      .post(`achievement-create`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Thêm thành công", { type: "success" });
        return this.props.onSubmit();
      })
      .catch((error) => {
        this.setState({ errors: error.response.data.messages });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.createAchievement();
  };
  submitFormById = (e) => {
    const form = document.getElementById("formAchievement");
    if (form) {
      this.handleSubmit(e);
      // form.submit();
    } else {
      console.error("Form không tồn tại");
    }
  };

  render() {
    return (
      <>
        <Modal show={this.props.show} onHide={this.props?.onHide} size={"lg"}>
          <Modal.Header closeButton>
            <Modal.Title>Thành tích</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.submitFormById} id="formAchievement">
            <Modal.Body>
              {this.state.other?.map((criteria, index) => (
                <Row key={index}>
                  <Col md={4}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="text"
                        name="key"
                        value={criteria.key}
                        required
                        onChange={(e) => {
                          delete this.state.errors[
                            `achievements[${index}].achievement`
                          ];
                          this.handleChangeOther(index, "key", e.target.value);
                        }}
                        placeholder="Nhập thành tích"
                      />
                      <Form.Label>Thành tích</Form.Label>
                      <span className="text-form-err">
                        {
                          this.state.errors[`achievements.${index}`]
                            ?.achievement
                        }
                      </span>
                      <span className="text-form-err">
                        {
                          this.state.errors[
                            `achievements[${index}].achievement`
                          ]
                        }
                      </span>
                    </Form.Floating>
                  </Col>
                  <Col md={4}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="text"
                        name="value"
                        value={criteria.value}
                        required
                        onChange={(e) => {
                          this.handleChangeOther(
                            index,
                            "value",
                            e.target.value
                          );
                          delete this.state.errors[
                            `achievements[${index}].description`
                          ];
                        }}
                        placeholder="Mô tả"
                      />
                      <Form.Label>Mô tả</Form.Label>

                      <span className="text-form-err">
                        {
                          this.state.errors[
                            `achievements[${index}].description`
                          ]
                        }
                      </span>
                    </Form.Floating>
                  </Col>
                  <Col md={3}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="date"
                        // required
                        name="date"
                        value={moment(new Date(criteria.date * 1000)).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={(e) => {
                          this.handleChangeOther(index, "date", e.target.value);

                          delete this.state.errors[
                            `achievements[${index}].achieved_date`
                          ];
                        }}
                      />
                      <Form.Label>Ngày đạt được</Form.Label>

                      <span className="text-form-err">
                        {
                          this.state.errors[
                            `achievements[${index}].achieved_date`
                          ]
                        }
                      </span>
                    </Form.Floating>
                  </Col>
                  <Col md={1} className="mb-3 d-flex align-items-center">
                    <Button
                      variant="outline-danger"
                      onClick={() => this.handleRemove(index)}
                      disabled={this.state.other.length < 2}
                    >
                      X
                    </Button>
                  </Col>
                </Row>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary me-2"
                onClick={() => this.props?.onHide()}
              >
                Đóng
              </Button>
              <Button
                variant="primary"
                // type="submit"
                onClick={this.submitFormById}
              >
                {this.props?.data?.id ? "Cập nhật" : "Lưu"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default AchievementModal;
