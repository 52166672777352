import * as XLSX from "xlsx-js-style";
import { toast } from "react-toastify";
import { dateFormatter, truncateString } from "../../utils/helpers";
const fontStyle = {
  name: "Times New Roman",
};

const borderStyle = {
  top: { style: "thin" },
  bottom: { style: "thin" },
  left: { style: "thin" },
  right: { style: "thin" },
};

const exportMartketList = (ingredientList) => {
  const table = document.getElementById("market-list-table");

  var ws = XLSX.utils.table_to_sheet(table);

  const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H"];
  const colAlpha_Sum = ["A", "C", "B", "D", "E", "F", "G", "H"];

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
  ];

  // Header
  colAlpha.map((alpha) => {
    ws[`${alpha}1`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    return alpha;
  });

  colAlpha.map((alpha) => {
    ws[`${alpha}2`].s = {
      font: {
        ...fontStyle,
        sz: 14,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    return alpha;
  });

  colAlpha.map((alpha) => {
    ws[`${alpha}3`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
    };

    return alpha;
  });
  // Header

  // Set Column Width
  var wscols = [
    { wch: 4 },
    { wch: 20 },
    { wch: 20 },
    { wch: 6 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
  ];

  ws["!cols"] = wscols;
  ws["!rows"] = [{ hpt: 20 }, { hpt: 35 }, { hpt: 40 }];

  // Format number Price, Unit, Will Money
  const numberFormat = "#,###,###,###";
  let index = 4;

  ingredientList
    .filter((item) => Number(item.ingredent_type) === 1)
    .map((item) => {
      let priceCol = `F${index}`;
      let unitCol = `E${index}`;
      let willMoneyCol = `G${index}`;

      colAlpha.map((alpha) => {
        if (ws[`${alpha}${index}`].v === "empty") {
          ws[`${alpha}${index}`].v = " ";
        }

        ws[`${alpha}${index}`].s = {
          font: fontStyle,
          border: borderStyle,
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: alpha === "A" ? "center" : false,
          },
        };

        return alpha;
      });

      ws[priceCol.toString()].z = numberFormat;
      delete ws[priceCol.toString()].w;
      XLSX.utils.format_cell(ws[priceCol.toString()]);

      ws[unitCol.toString()].z = "#,###,###,##0.00";
      delete ws[unitCol.toString()].w;
      XLSX.utils.format_cell(ws[unitCol.toString()]);

      ws[willMoneyCol.toString()].z = numberFormat;
      delete ws[willMoneyCol.toString()].w;
      XLSX.utils.format_cell(ws[willMoneyCol.toString()]);

      index += 1;

      return item;
    });

  if (
    ingredientList.filter((item) => Number(item.ingredent_type) === 1)
      .length !== 0
  ) {
    ws[`G${index}`].z = numberFormat;
    delete ws[`H${index}`].w;
    XLSX.utils.format_cell(ws[`H${index}`]);

    colAlpha_Sum.map((alpha) => {
      ws[`${alpha}${index}`].s = {
        font: fontStyle,
        border: borderStyle,
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });

    ws[`F${index}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`G${index}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    index++;
  } else {
    colAlpha.map((alpha) => {
      ws[`${alpha}${index}`].s = {
        font: fontStyle,
        border: borderStyle,
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });
    merge.push({ s: { r: index - 1, c: 0 }, e: { r: index - 1, c: 7 } });

    index++;
  }

  ingredientList
    .filter((item) => Number(item.ingredent_type) === 2)
    .map((item) => {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${index}`].v === "empty") {
          ws[`${alpha}${index}`].v = " ";
        }

        ws[`${alpha}${index}`].s = {
          font: fontStyle,
          border: borderStyle,
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: alpha === "A" ? "center" : false,
          },
        };

        return alpha;
      });

      let amountCol = `E${index}`;
      let priceCol = `F${index}`;
      let willMoneyCol = `G${index}`;

      ws[priceCol.toString()].z = numberFormat;
      delete ws[priceCol.toString()].w;
      XLSX.utils.format_cell(ws[priceCol.toString()]);

      ws[amountCol.toString()].z = "#,###,###,##0.00";
      delete ws[amountCol.toString()].w;
      XLSX.utils.format_cell(ws[amountCol.toString()]);

      ws[willMoneyCol.toString()].z = numberFormat;
      delete ws[willMoneyCol.toString()].w;
      XLSX.utils.format_cell(ws[willMoneyCol.toString()]);

      index += 1;

      return item;
    });

  if (
    ingredientList.filter((item) => Number(item.ingredent_type) === 2)
      .length !== 0
  ) {
    ws[`G${index}`].z = numberFormat;
    delete ws[`G${index}`].w;
    XLSX.utils.format_cell(ws[`G${index}`]);

    colAlpha_Sum.map((alpha) => {
      ws[`${alpha}${index}`].s = {
        font: fontStyle,
        border: borderStyle,
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });

    ws[`F${index}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`G${index}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };
  } else {
    colAlpha.map((alpha) => {
      ws[`${alpha}${index}`].s = {
        font: fontStyle,
        border: borderStyle,
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });

    merge.push({ s: { r: index - 1, c: 0 }, e: { r: index - 1, c: 7 } });
  }

  ws["!merges"] = merge;

  return ws;
};
const createWorksheet = (supplier) => {
  if (!supplier) return null;

  const table = document.getElementById(
    `supplier-${supplier.supplier_name}-${supplier.id}`
  );
  const ws = XLSX.utils.table_to_sheet(table, { raw: true });

  // Set column widths
  ws["!cols"] = [
    { wch: 5 },
    { wch: 5 },
    { wch: 20 },
    { wch: 8 },
    { wch: 10 },
    { wch: 11 },
    { wch: 10 },
    { wch: 12 },
    { wch: 9 },
  ];

  // Set merged cells
  ws["!merges"] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 8 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } },
    { s: { r: 1, c: 5 }, e: { r: 1, c: 8 } },
    { s: { r: 2, c: 0 }, e: { r: 2, c: 4 } },
    { s: { r: 2, c: 5 }, e: { r: 2, c: 8 } },
    { s: { r: 3, c: 0 }, e: { r: 3, c: 4 } },
    { s: { r: 3, c: 5 }, e: { r: 3, c: 8 } },
    { s: { r: 4, c: 0 }, e: { r: 4, c: 8 } },
  ];

  const range = XLSX.utils.decode_range(ws["!ref"]);
  const startRow = 6;
  const endRow = range.e.r;

  // Helper to apply style
  const applyStyle = (cell, style) => {
    if (!cell.s) cell.s = {};
    cell.s = { ...cell.s, ...style };
  };

  // Set styles for each cell
  for (let row = 0; row <= endRow; row++) {
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellAddr = XLSX.utils.encode_cell({ r: row, c: col });
      const cell = ws[cellAddr] || {};

      if (cell.v === "empty") cell.v = "";

      if (row < 5) {
        applyStyle(cell, {
          font: {
            name: "Times New Roman",
            bold: row === 0,
            sz: row === 0 ? 13 : 11,
          },
          alignment: { horizontal: row === 0 ? "center" : "left" },
        });
      } else if (row === 5) {
        applyStyle(cell, {
          font: { name: "Times New Roman", bold: true, sz: 11 },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
          alignment: { horizontal: "center" },
        });
      } else if (row >= startRow && row <= endRow - 3) {
        applyStyle(cell, {
          font: { name: "Times New Roman" },
          alignment: {
            horizontal: col > 4 ? "right" : "left",
            wrapText: true,
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        });
      }
      ws[cellAddr] = cell;
    }
  }

  // Add specific styles for footer rows
  ws[`A${endRow - 1}`].s = {
    font: { name: "Times New Roman", sz: 11 },
    alignment: { horizontal: "right" },
  };
  ws[`A${endRow}`].s = {
    font: { name: "Times New Roman", sz: 11, italic: true },
  };
  ws[`H${endRow - 1}`].s = {
    font: { name: "Times New Roman", sz: 11, bold: true },
    alignment: { horizontal: "right" },
  };
  ws[`A${endRow + 1}`].s = {
    font: { name: "Times New Roman", sz: 11, bold: true },
    alignment: { horizontal: "center" },
  };
  ws[`F${endRow + 1}`].s = {
    font: { name: "Times New Roman", sz: 11, bold: true },
    alignment: { horizontal: "center" },
  };

  ws["!merges"].push(
    { s: { r: endRow, c: 0 }, e: { r: endRow, c: 3 } },
    { s: { r: endRow, c: 5 }, e: { r: endRow, c: 8 } },
    { s: { r: endRow - 1, c: 0 }, e: { r: endRow - 1, c: 8 } },
    { s: { r: endRow - 2, c: 0 }, e: { r: endRow - 2, c: 6 } }
  );

  return ws;
};

const exportMartketListSheet = (suppliers, voucher, selectedSuppliers) => {
  try {
    const wb = XLSX.utils.book_new();
    suppliers.forEach((supplier) => {
      if (
        supplier?.ingredients?.length > 0 &&
        (!selectedSuppliers?.length ||
          selectedSuppliers?.some((e) => +e.id === +supplier?.id))
      ) {
        const ws = createWorksheet(supplier);
        XLSX.utils.book_append_sheet(
          wb,
          ws,
          truncateString(supplier.supplier_name)
        );
      }
    });

    if (!wb.SheetNames.length) {
      throw new Error("Không có dữ liệu");
    }

    XLSX.writeFile(
      wb,
      `Phiếu đi chợ (${dateFormatter(new Date(voucher.menu_date * 1000))}).xlsx`
    );
  } catch (error) {
    toast.error(error.message || "Hệ thống xảy ra lỗi");
  }
};

export { exportMartketList, exportMartketListSheet };
