import * as XLSX from "xlsx-js-style";

const fontStyle = {
  name: "Times New Roman",
  sz: 9,
};

const borderStyle = {
  top: { style: "thin" },
  bottom: { style: "thin" },
  left: { style: "thin" },
  right: { style: "thin" },
};

const exportReportRegisterMeal = (data, groupChoice) => {
  const table = document.getElementById(`export-report-register-meal`);
  var ws = XLSX.utils.table_to_sheet(table, { raw: true });

  const colAlpha = ["A", "B", "C"];
  const transformData = (data, idGroup) => {
    const tableData = [];

    data.forEach((dayItem) => {
      const day = dayItem.day;
      const dayFoodMap = {};

      dayItem.times.forEach((timeItem) => {
        timeItem.foods.forEach((foodItem) => {
          const foodName = foodItem.food_name;

          const relevantGroups =
            idGroup === 0
              ? foodItem.groups
              : foodItem.groups.filter((group) => group.id === idGroup);

          if (relevantGroups.length === 0) return;

          const totalPortions = relevantGroups.reduce(
            (sum, group) => sum + group.number_of_portions,
            0
          );

          if (dayFoodMap[foodName]) {
            dayFoodMap[foodName].total_portions += totalPortions;
          } else {
            dayFoodMap[foodName] = {
              day: day,
              food_name: foodName,
              total_portions: totalPortions,
            };
          }
        });
      });

      tableData.push(...Object.values(dayFoodMap));
    });

    return tableData;
  };

  const tableData = transformData(data, groupChoice.id);

  let startRow = 1;
  for (let i = startRow; i < tableData?.length + 1 + startRow; i++) {
    colAlpha.map((alpha) => {
      ws[`${alpha}${i}`].s = {
        font: {
          ...fontStyle,
          sz: i === 1 ? 12 : 10,
          bold: i === 1 || alpha === "A",
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: alpha === "C" && i > 1 ? "right" : "left",
        },
        border: borderStyle,
      };
      return alpha;
    });
  }

  const cols = [{ wch: 10 }, { wch: 20 }, { wch: 8 }];

  ws["!cols"] = cols;

  ws["!rows"] = [{ hpt: 20 }];

  const merge = [];
  let count = 1;

  tableData.forEach((row, index) => {
    if (index === 0 || row.day !== tableData[index - 1]?.day) {
      if (index > 0) {
        merge.push({ s: { r: count + 1, c: 0 }, e: { r: index, c: 0 } });
      }
      count = index;
    }
  });
  if (tableData.length > 0) {
    merge.push({
      s: { r: count + 1, c: 0 },
      e: { r: tableData.length, c: 0 },
    });
  }

  ws["!merges"] = merge;

  return ws;
};

export { exportReportRegisterMeal };
