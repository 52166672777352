import React from "react";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "../../../helper/axiosInstance";
import Check from "../other/Check";

const TableRegister = ({
  listQuantitatives,
  listDays,
  listTimes,
  setMenus,
  choiceClass,
  isCallRegister,
  settingsMealRegistration,
}) => {
  const settingTimesId = settingsMealRegistration?.timesId || [];
  const settingCategoriesId = settingsMealRegistration?.categoriesId || [];

  const unApprove = (choiceClass, dayItem) => {
    let data = {
      id_class: [choiceClass.value], // required, array
      id_menu: dayItem.id_menu, // required, integer
      id_day: dayItem?.id_day, // required, integer
      status: "pending", // required, is int: pending, approved, rejected
    };

    api
      .put(`update-status-registration-list-of-meals-by-class-and-day`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Thành công", { type: "success" });
        isCallRegister(true);
      })
      .catch((error) => {
        toast(`Lỗi `, {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const handleUpdateQuantity = (event, id_time, foodId, id_day) => {
    const newQuantity = parseInt(event.target.value || 0);

    const updatedQuantitatives = listQuantitatives?.map((quantitativeItem) => {
      if (quantitativeItem.id_day === id_day) {
        return {
          ...quantitativeItem,
          menu_has_times: quantitativeItem?.menu_has_times?.map((timeItem) => {
            if (timeItem.id_time === id_time) {
              let foods = timeItem?.foods || [];

              let totalQuantity = 0;

              const food = foods?.find((food) => food.id === foodId);

              let active_category_id = "";

              foods?.map((foodItem, foodIndex) => {
                const foodsByCategory = timeItem?.foods?.filter(
                  (food) => food.id_category === foodItem.id_category
                );

                const checkTimeConstraint = settingTimesId.includes(
                  foodItem.id_time
                );

                const checkCategoryConstraint = settingCategoriesId.includes(
                  foodItem.id_category
                );

                if (
                  checkTimeConstraint &&
                  checkCategoryConstraint &&
                  foodItem.id === foodId &&
                  (foodItem.active_category_id === "" ||
                    foodItem.active_category_id === foodItem.id_category)
                ) {
                  active_category_id = foodItem.id_category;

                  if (foodsByCategory.length === 1) totalQuantity = newQuantity;
                  else {
                    foodsByCategory.map((food) => {
                      totalQuantity +=
                        food.id === foodId
                          ? newQuantity
                          : parseInt(food?.quantity || 0);
                    });
                  }
                } else if (foodItem.active_category_id !== "") {
                  active_category_id = foodItem.active_category_id;
                }

                // foods.map((food) => {
                //   totalQuantity += parseInt(food?.quantity || 0);
                //   // food.id === foodId
                //   //   ? newQuantity
                //   //   : parseInt(food?.quantity || 0);
                // });
              });

              return {
                ...timeItem,
                foods: foods?.map((foodItem, foodIndex) => {
                  const foodsByCategory = timeItem?.foods?.filter(
                    (food) => food.id_category === foodItem.id_category
                  );

                  if (food.id_category === active_category_id) {
                    const checkTimeConstraint = settingTimesId.includes(
                      foodItem.id_time
                    );

                    const checkCategoryConstraint =
                      settingCategoriesId.includes(foodItem.id_category);

                    if (
                      checkTimeConstraint &&
                      checkCategoryConstraint &&
                      foodItem.id !== foodId
                    ) {
                      return {
                        ...foodItem,
                        totalQuantity: totalQuantity,
                        active_category_id: active_category_id,
                      };
                    }

                    if (checkTimeConstraint && !checkCategoryConstraint) {
                      return {
                        ...foodItem,
                        quantity: totalQuantity,
                        totalQuantity: totalQuantity,
                        active_category_id: active_category_id,
                      };
                    }
                  }

                  if (foodItem.id === foodId) {
                    return {
                      ...foodItem,
                      quantity: newQuantity,
                      totalQuantity:
                        food.id_category === active_category_id
                          ? totalQuantity
                          : foodItem.totalQuantity,
                      active_category_id: active_category_id,
                    };
                  }

                  return foodItem;
                  // //
                  // return {
                  //   ...foodItem,
                  //   // quantity: totalQuantity,
                  //   totalQuantity: totalQuantity,
                  //   // active_category_id: active_category_id,
                  // };
                }),
              };
            }
            return timeItem;
          }),
        };
      }
      return quantitativeItem;
    });
    setMenus(updatedQuantitatives);
  };

  const renderQuantitativeRows = (quantitativeTimes, categoryTimes) => {
    const result = listQuantitatives.map((listQuantitativesItem) => {
      const found = quantitativeTimes.find(
        (quantitativeTimesItem) =>
          quantitativeTimesItem.id_menu_day === listQuantitativesItem.id
      );
      return found ? found : { food: [] };
    });

    return result.map((quantitativeTimeItem) => {
      let itemRow = [];
      let rowSpanLength = 0;

      quantitativeTimeItem?.foods?.forEach((foodItem) => {
        if (foodItem.id_category === categoryTimes.id_category) {
          rowSpanLength += 1;

          itemRow.push({
            isFood: true,
            id: foodItem.id,
            name: foodItem.food_name,
            id_time: foodItem.id_time,
            id_day: foodItem.id_day,
            quantity: foodItem.quantity || 0,
            totalQuantity: foodItem.totalQuantity || 0,
            totalCategory: foodItem.totalCategory,
            active_category_id: foodItem.active_category_id,
            status: foodItem.status,
          });
        }
      });

      return {
        id: quantitativeTimeItem.id,
        items: itemRow,
        length: rowSpanLength !== 0 ? rowSpanLength : 1,
      };
    });
  };

  const renderCategoryRows = (categoryTimes, timeItem) => {
    return categoryTimes.map((categoryTimeItem, categoryTimeItemIndex) => {
      const arr = [];
      for (
        let i = categoryTimeItemIndex === 0 ? 1 : 0;
        i < categoryTimeItem.cateLength;
        i++
      ) {
        const numRowSpanCategory = categoryTimeItem?.cateLength;

        const checkCategoryConstraint = settingCategoriesId.includes(
          categoryTimeItem.id_category
        );

        const checkTimeConstraint = settingTimesId.includes(timeItem.id_time);

        arr.push(
          <tr key={i}>
            {i === 0 ? (
              <th
                rowSpan={numRowSpanCategory}
                // style={{
                //   backgroundColor:
                //     (checkTimeConstraint && checkCategoryConstraint) ||
                //     !checkTimeConstraint
                //       ? "#e0f0e3"
                //       : "#ffdb99",
                // }}
              >
                {categoryTimeItem.category_name}
              </th>
            ) : (
              ""
            )}
            {categoryTimeItem?.timeQuantitative?.map((rowItem) => {
              if (rowItem.items[i]?.isFood) {
                const foodName = rowItem.items[i]?.name;
                const quantity = rowItem.items[i]?.quantity;

                const totalQtyByTimes = parseInt(
                  rowItem.items[i]?.totalQuantity || 0
                );

                const totalQtyByCategory = rowItem.items?.reduce(
                  (total, record) => total + parseInt(record.quantity),
                  0
                );

                return (
                  <React.Fragment key={rowItem.id}>
                    <td>{foodName}</td>
                    <td className="text-center">
                      <input
                        type="number"
                        value={quantity > 0 ? parseInt(quantity) : ""}
                        className={`text-end`}
                        style={{
                          width: "56px",
                          border:
                            rowItem.items[i]?.totalQuantity ===
                              totalQtyByCategory ||
                            !checkTimeConstraint ||
                            !checkCategoryConstraint
                              ? "1px solid #d2d2d2"
                              : "1px solid #dc3545",
                        }}
                        min={0}
                        disabled={
                          rowItem.items[i]?.status === "approved" ||
                          (checkTimeConstraint && !checkCategoryConstraint)
                        }
                        required
                        onChange={(e) => {
                          handleUpdateQuantity(
                            e,
                            rowItem.items[i].id_time,
                            rowItem.items[i].id,
                            rowItem.items[i].id_day
                          );
                        }}
                      />
                    </td>
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={rowItem.id}>
                  <td>{rowItem.items[i]?.name || ""}</td>
                  <td></td>
                </React.Fragment>
              );
            })}
          </tr>
        );
      }
      return arr;
    });
  };

  return (
    <Table bordered responsive id="menu-export-excel-2">
      <thead>
        <tr className="text-center ">
          <th className="bg-success text-white">Buổi</th>
          <th className="bg-success text-white">Danh mục</th>
          {listDays.map((dayItem) => (
            <React.Fragment key={dayItem.id_day}>
              <th colSpan={2} className="text-nowrap bg-success text-white">
                {dayItem.day_of_week?.day_name}
                {new Check().permission([165]) ? (
                  <Button
                    variant="outline-danger mb-1 ms-2"
                    size="sm"
                    title="Hủy duyệt đăng ký"
                    hidden={dayItem.status !== "approved"}
                    onClick={() => {
                      unApprove(choiceClass, dayItem);
                    }}
                  >
                    X
                  </Button>
                ) : (
                  <></>
                )}
              </th>
            </React.Fragment>
          ))}
        </tr>
      </thead>

      <tbody className="align-middle">
        {listTimes.map((timeItem) => {
          const quantitativeTimes = [];
          let categoryTimes = [];

          listQuantitatives?.forEach((quantitativeItem) => {
            quantitativeItem?.menu_has_times?.forEach((timeObj) => {
              if (timeObj.id_time === timeItem.id_time) {
                quantitativeTimes.push(timeObj);
                const categoryTimesToAdd =
                  timeObj.foods.length > 0
                    ? timeObj.foods.map((foodItem) => ({
                        id_category: foodItem.id_category,
                        category_name: foodItem?.category.category_name,
                      }))
                    : [{ id_category: "", category_name: "" }];
                categoryTimes = categoryTimes.concat(categoryTimesToAdd);
              }
            });
          });

          categoryTimes = [
            ...new Map(
              categoryTimes.map((item) => [item["id_category"], item])
            ).values(),
          ];

          let dataCate =
            categoryTimes.filter((ca) => ca.id_category !== "").length > 0
              ? categoryTimes.filter((ca) => ca.id_category !== "")
              : categoryTimes;

          categoryTimes = dataCate.map((categoryItem) => {
            const listItemRows = renderQuantitativeRows(
              quantitativeTimes,
              categoryItem
            );
            const maxRowSpanLength = Math.max(
              ...listItemRows.map((itemRow) => itemRow.length)
            );
            return {
              ...categoryItem,
              timeQuantitative: listItemRows.map((itemRow) => ({
                ...itemRow,
                length: maxRowSpanLength,
              })),
              cateLength: maxRowSpanLength,
            };
          });

          const numRowSpanTime = categoryTimes.reduce(
            (total, category) => total + category.cateLength,
            0
          );

          const numRowSpanCategory = categoryTimes[0]?.cateLength;

          const checkCategoryConstraint = settingCategoriesId.includes(
            categoryTimes[0].id_category
          );

          const checkTimeConstraint = settingTimesId.includes(timeItem.id_time);

          return (
            <React.Fragment key={timeItem.id_time}>
              <tr>
                <th
                  rowSpan={numRowSpanTime}
                  style={{
                    backgroundColor: checkTimeConstraint
                      ? "#7fbf7f"
                      : "#a8eae8",
                  }}
                >
                  {timeItem.time_name}
                </th>
                <th
                  rowSpan={numRowSpanCategory}
                  // style={{
                  //   backgroundColor:
                  //     (checkTimeConstraint && checkCategoryConstraint) ||
                  //     !checkTimeConstraint
                  //       ? "#e0f0e3"
                  //       : "#ffdb99",
                  // }}
                >
                  {categoryTimes[0]?.category_name}
                </th>

                {categoryTimes[0]?.timeQuantitative?.map((rowItem) => {
                  const totalQtyByCategory = rowItem.items?.reduce(
                    (total, record) => total + parseInt(record.quantity),
                    0
                  );

                  const uniqueCategories = new Set(
                    listQuantitatives
                      ?.find((day) => day.id_day === rowItem.items[0]?.id_day)
                      ?.menu_has_times.find(
                        (time) => time.id_time === rowItem.items[0].id_time
                      )
                      ?.foods?.map((food) => food.id_category)
                  );

                  const countCategory = uniqueCategories.size;

                  const checkTimeConstraint = settingTimesId.includes(
                    timeItem.id_time
                  );

                  return (
                    <React.Fragment key={rowItem.id}>
                      <td>{rowItem.items[0]?.name}</td>
                      <td className="text-center">
                        {rowItem.items[0]?.name ? (
                          <input
                            type="number"
                            value={
                              rowItem.items[0]?.quantity > 0
                                ? parseInt(rowItem.items[0]?.quantity)
                                : ""
                            }
                            className="text-end"
                            style={{
                              width: "56px",
                              border:
                                parseInt(rowItem.items[0]?.totalQuantity) ===
                                  parseInt(totalQtyByCategory) ||
                                !checkTimeConstraint ||
                                !checkCategoryConstraint ||
                                countCategory === 1
                                  ? "1px solid #d2d2d2"
                                  : "1px solid #dc3545",
                            }}
                            min={0}
                            disabled={
                              rowItem.items[0]?.status === "approved" ||
                              (checkTimeConstraint && !checkCategoryConstraint)
                            }
                            required
                            onChange={(e) =>
                              handleUpdateQuantity(
                                e,
                                rowItem.items[0].id_time,
                                rowItem.items[0].id,
                                rowItem.items[0]?.id_day
                              )
                            }
                          />
                        ) : (
                          ""
                        )}
                      </td>
                    </React.Fragment>
                  );
                })}
              </tr>

              {renderCategoryRows(categoryTimes, timeItem)}
            </React.Fragment>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TableRegister;
