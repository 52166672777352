import React, { Component } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Table,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx-js-style";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import Select from "react-select";
import { error } from "jquery";
import { touchRippleClasses } from "@mui/material";
class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolInfo: {},
      classifies: [],
      cutMeals: [],
      cutMealDetail: [],
      cutMealDetailShow: [],
      classifyShow: 1,
      listStaff: [],
      listAbsent: [],
      listcutStudent: [],
      toDay: new Date(),
      listSetting: [],
      setting: [],
      showCutMealDetail: false,
      listcut: [],
      list_: [],
      listStudent: [],
      studentss: [],
      startDate: new Date(),
      endDate: new Date(),
      show: false,
      start_: new Date(),
      end_: new Date(),
      listClass: [],
      listClassSelect: [],
      chociceClass: {
        value: 0,
        label: "Tất cả lớp",
      },
      tongAll: 0,
      tongdihoc: 0,
      tongcoan: 0,
      tongkhongan: 0,
      totalnghi: 0,
      totalkhongphep: 0,
      totalcophep: 0,
      tongsang: 0,
      tongchieu: 0,
      tongtrua: 0,
      count: [],
      startMonth: new Date(),
      listDate: [],
      listDay: [],
      listStudentByCLass: [],
      checkload: true,
      listmeal: [],
      listHoliday: [],
    };
  }

  async componentDidMount() {
    document.title = "Báo cáo chi tiết sĩ số";
    this.getDetailschool();
    this.getClassifiesData();
    this.getStudent();
    this.getClassRoom(new Date(), new Date());
    this.getSetting(this.state.classifyShow);
    this.getDetailHoliday();
    this.showDate(new Date());

    const start = new Date();
    start.setHours(0, 0, 0, 0);

    const end = new Date();
    end.setHours(23, 59, 59, 0);

    this.setState({
      startDate: start,
      endDate: end,
    });
  }

  getDetailschool = () => {
    axios.get(`/detailSchool`).then((res) => {
      if (res.data.status === true) {
        this.setState({
          schoolInfo: res.data.data[0],
        });
      }
    });
  };
  getDetailHoliday = () => {
    axios.get(`/viewHolidaysBySchoolYear`).then((res) => {
      if (res.data.status === true) {
        this.setState({
          listHoliday: res.data.data,
        });
      }
    });
  };

  getSetting = (classifyShow) => {
    axios.get(`/viewSetting?id_classify=${classifyShow}`).then((res) => {
      res.data.data.time.map((e) => {
        e.count = 0;
      });

      this.setState({
        listSetting: res.data.data.time.filter((e) => e.is_active == 1),
      });
      this.setState({
        setting: res.data.data.day.filter((e) => e.is_active == 1),
      });
    });
  };
  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  customStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: "150px",
      marginRight: "10px",
      // marginLeft: '-100%'
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "4px",
      // marginLeft: '-100%'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "blue" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };
  getDate_ = (data) => {
    let date = new Date(data);
    let result = `${date?.getDate()}/${
      date?.getMonth() + 1
    }/${date?.getFullYear()}`;
    return result;
  };
  getDate = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };
  getTong = () => {
    var tong = 0;
    var tongOneDay = 0;
    this.state.listSetting.map((item) =>
      item.is_active == 1
        ? (tongOneDay = tongOneDay + parseInt(item.poetry))
        : null
    );
    this.state.listStudent.map((student_, index) => {
      student_.absent.map((absent_, index1) => {
        if (absent_.type_meal == 1 && absent_.is_term != 0)
          tong = tong + absent_.price_return;
        else {
          if (absent_.is_term != 0) {
            absent_.detail.map(
              (item) => (tong = parseInt(tong) + parseInt(item.price_return))
              // tong = parseInt(tong) + parseInt(this.state.listSetting.filter(e => e.time_name == item.time_name)[0]?.poetry)
            );
          }
        }
      });
    });
    return tong;
  };
  getClassRoom = (start, end) => {
    var arr = [];
    var arr1 = [];

    arr.push({ value: 0, label: "Tất cả lớp" });
    axios
      .get(`/viewClassRoom?start=${this.changeEndMonth(end) / 1000}`)
      .then((res) => {
        // console.log(res.data.data, )
        for (const obj1 of res.data.data)
          for (const obj2 of this.state.listClassSelect) {
            if (obj1.id == obj2.value) {
              // console.log(obj2.value,obj1.id,obj1.count_student,obj2.label )
              arr.push({
                value: obj2.value,
                count: obj1.count_student,
                label: obj2.label,
                total: obj2.total,
                student: obj2.student,
              });
            }

            this.setState({ listClassSelect: arr });
          }
      });
  };
  getListMealstudent(class_, start, end) {
    this.setState({ checkload: true });
    let start_ = start.setHours(0, 0, 0) / 1000;
    let end_ = end.setHours(23, 59, 59) / 1000;
    // this.setState({ checkload: true })
    axios
      .post(
        `/viewMeal?id_classify=1&start_date=${parseInt(
          this.changeStartMonth(this.state.startMonth) / 1000
        )}&end_date=${parseInt(
          this.changeEndMonth(this.state.startMonth) / 1000
        )}`
      )
      .then((res) => {
        this.setState({ checkload: false });
        if (res.data.status) {
          const dateFilter = res.data.data.filter(
            (absentDay) =>
              start_ <= absentDay.meal_day &&
              absentDay.meal_day <= end_ &&
              absentDay.is_status == 1
          );
          const nghinguyenngay =
            dateFilter.length > 0
              ? dateFilter.filter((e) => e.type_meal == 1)
              : [];
          const nghibuoi =
            dateFilter.length > 0
              ? dateFilter.filter((e) => e.type_meal == 2)
              : [];
          const hskhongan =
            this.state.studentss.length > 0
              ? this.state.studentss.filter((e) => e.is_meal == 1)
              : [];
          let listClass = [];
          let count1 = [];
          var tongDs = 0;
          var tongDihoc = 0;
          var tongCoan = 0;
          var tongKhongan = 0;
          var tongNghi = 0;
          var tongKhongphep = 0;
          var tongCophep = 0;
          let betweenDay = parseInt((end - start) / (24 * 60 * 60 * 1000) + 1);
          class_.map((e) => {
            var count_student = e.count_student;
            let day_default = Number(start.getDay());
            var dihoc = 0;
            var coan = 0;
            var khongan = 0;
            const arr = [];
            var nghi = nghinguyenngay.filter(
              (el) => el.class_name == e.class_name
            ).length;
            var khongphep = nghinguyenngay.filter(
              (el) => el.class_name == e.class_name && el.is_term == 0
            ).length;
            var cophep = nghinguyenngay.filter(
              (el) => el.class_name == e.class_name && el.is_term == 1
            ).length;
            for (var i = 0; i < betweenDay; i++) {
              let checkday = this.state.setting.filter(
                (z) => z.stt_day == day_default
              );
              if (checkday.length > 0) {
                khongan += hskhongan.filter(
                  (el) => el.class_name == e.class_name
                ).length;
                const listMealByBuoi = nghibuoi.filter(
                  (el) => el.class_name == e.class_name
                );
                dihoc += count_student;
                this.state.listSetting.map((time) => {
                  let moreDay = 0;
                  let sumDay = 0;
                  listMealByBuoi.map((meal) => {
                    moreDay += meal.detail.filter(
                      (buoi_) => time.time_name == buoi_.time_name
                    ).length;
                  });
                  nghibuoi.map((meal) => {
                    sumDay += meal.detail.filter(
                      (buoi_) => time.time_name == buoi_.time_name
                    ).length;
                  });

                  arr.push({ time_name: time.time_name, count: moreDay });
                  count1.push({ time_name: time.time_name, count: sumDay });
                });
              }
              day_default = day_default + 1 > 7 ? 1 : day_default + 1;
            }
            dihoc = dihoc - nghi;
            coan = dihoc - khongan;

            tongDs += count_student;
            tongKhongan += khongan;
            tongDihoc += dihoc;
            tongCoan += coan;
            tongNghi += nghi;
            tongKhongphep += khongphep;
            tongCophep += cophep;

            let data = {
              id: e.id,
              class_name: e.class_name,
              user_name: e.user_name,
              total: count_student,
              dihoc: dihoc,
              coan: coan,
              khongan: khongan,
              buoi: arr,
              tongnghi: nghi,
              khongphep: khongphep,
              cophep: cophep,
            };
            listClass.push(data);
          });

          this.setState({
            tongAll: tongDs,
            tongkhongan: tongKhongan,
            tongdihoc: tongDihoc,
            tongcoan: tongCoan,
            totalnghi: tongNghi,
            totalkhongphep: tongKhongphep,
            totalcophep: tongCophep,
            listStudent: listClass,
            count: count1,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCutMealsData = (classifyId) => {
    axios
      .post(
        `/viewMeal?id_classify=${classifyId}&start_date=${parseInt(
          this.changeStartMonth(this.state.startMonth) / 1000
        )}&end_date=${parseInt(
          this.changeEndMonth(this.state.startMonth) / 1000
        )}`
      )
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            cutMeals: res.data.data.filter(
              (e) => e.meal_day <= new Date().setHours(23, 59, 59) / 1000
            ),
          });
        } else {
          this.setState({
            cutMeals: [],
          });
        }
      });
  };
  getClassifiesData = () => {
    axios.get(`/viewClassify`).then((res) => {
      if (res.data.status === true) {
        this.setState({
          classifies: res.data.data,
          classifyShow: res.data.data[0].id,
        });
        this.getCutMealsData(res.data.data[0].id);
      }
    });
  };
  removeDuplicates(arr) {
    const uniqueItems = new Set();
    const result = [];

    for (const item of arr) {
      const itemAsString = JSON.stringify(item);
      if (!uniqueItems.has(itemAsString)) {
        uniqueItems.add(itemAsString);
        result.push(item);
      }
    }

    return result;
  }

  // getAllClass(){
  //     axios.get(`/viewClassRoom?start=${this.changeEndMonth(end) / 1000}`).then(res => {

  //     })
  // }
  getStudent() {
    var arrClass = [];

    arrClass.push({ value: 0, label: "Tất cả lớp" });
    axios
      .get("/viewStudent")
      .then((res) => {
        if (res.data.status) {
          this.setState({ studentss: res.data.data });
          res.data.data
            .sort(function (a, b) {
              if (a.id_class <= b.id_class) return -1;
              if (a.id_class > b.id_class) return 1;
              return 0;
            })
            .map((e) =>
              arrClass.push({
                value: e.id_class,
                label: e.class_name,
                total: res.data.data.filter(
                  (class_) => class_.class_name == e.class_name
                ),
                student: res.data.data.filter(
                  (class_) => class_.id_class == e.id_class
                ),
              })
            );
          this.setState({
            listClassSelect: this.removeDuplicates(arrClass),
            chociceClass: this.removeDuplicates(arrClass)[0],
          });
          this.getListMealstudent(
            this.removeDuplicates(arrClass),
            this.state.startDate,
            this.state.endDate
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getAllStudent = () => {
    var tong = 0;
    this.state.listClassSelect.map((item) =>
      item.value > 0 ? (tong += item.total.length) : 0
    );
    return tong;
  };
  getStudentClassInMonth = (id_class, month) => {
    // axios.post(`/viewChilClassRoom?id_class=${id}&start=${this.changeStartMonth(start) / 1000}&end=${this.changeEndMonth(end) / 1000}`).then((res) => {

    var date_ = new Date(month.getFullYear(), month.getMonth(), 1);
    var date_1 =
      this.changeStartMonth(new Date(date_.setHours(0, 0, 0))) / 1000;
    var date_2 =
      this.changeEndMonth(new Date(date_.setHours(23, 59, 59))) / 1000;
    var arrClass = this.state.listClassSelect
      ?.filter((e) => (e.value > 0 ? e.value == id_class : ""))[0]
      .student?.filter((class_) => class_.process[0]?.start_at <= date_1);
    return arrClass.filter(
      (e) => e.process[0].end_at == null || e.process[0]?.end_at + 50 >= date_2
    );
  };
  getStudentInclassOneDay = (id_class, date) => {
    var date_ = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth(),
      date
    );
    var date_1 = new Date(date_.setHours(0, 0, 0)) / 1000;
    var date_2 = new Date(date_.setHours(23, 59, 59)) / 1000;
    var arrClass = this.state.listClassSelect
      ?.filter((e) => e.value == id_class)[0]
      .student?.filter((class_) =>
        // class_.process.length == 1 ?
        //     class_.process[class_.process.length - 1]?.start_at - 5000 <= date_1

        //     && (class_.process[class_.process.length - 1]?.end_at != null ? class_.process[class_.process.length - 1]?.end_at >= date_2 : true)
        //     :
        //     (class_.process[0]?.start_at - 5000 <= date_1

        //         && (class_.process[0]?.end_at != null ? class_.process[0]?.end_at >= date_2 : true)
        //     )
        //     ||
        //     (class_.process[class_.process.length - 1]?.start_at - 5000 <= date_1

        //         && (class_.process[class_.process.length - 1]?.end_at != null ?
        //             class_.process[class_.process.length - 1]?.end_at >= date_2 : true)
        //     )
        //     || (class_.process[1]?.start_at - 5000 <= date_1

        //         && (class_.process[1]?.end_at != null ? class_.process[1]?.end_at >= date_2 : true)
        //     )
        //     ||
        //     (class_.process[2]?.start_at - 5000 <= date_1

        //         && (class_.process[2]?.end_at != null ? class_.process[2]?.end_at >= date_2 : true)
        //     )
        this.checkProcess(date_1, date_2, class_.process)
      );
    return arrClass;
    // .filter(e => e.process[0].end_at == null )
    // || (e.process[0].end_at != null ? e.process[0]?.end_at >= date_2 : true))
  };

  checkProcess = (start, end, process) => {
    let isProcessValid = false;

    for (var i = 0; i < process.length; i++) {
      if (process[i].end_at != null) {
        if (process[i].start_at <= start && process[i].end_at >= end) {
          isProcessValid = true;
          break;
        }
      } else {
        if (process[i].start_at <= start) {
          isProcessValid = true;
          break;
        }
      }
    }

    if (isProcessValid) {
      return true;
    } else {
      return false;
    }
  };

  getSumStudentByClassOneDay = (date) => {
    var tong = 0;
    this.state.listClassSelect.map((item) =>
      item.value > 0
        ? (tong +=
            Number(this.getStudentInclassOneDay(item.value, date).length) -
            Number(this.countStudentDateByClass(item.label, date)))
        : ""
    );
    return tong;
  };
  getStudentbyClass = (id, start, end) => {
    // axios.post(`/viewChilClassRoom?id_class=${id}&start=${this.changeEndMonth(end) / 1000}`).then((res) => {

    axios
      .post(
        `/viewChilClassRoom?id_class=${id}&start=${
          this.changeStartMonth(start) / 1000
        }&end=${this.changeEndMonth(end) / 1000}`
      )
      .then((res) => {
        this.setState({ listStudentByCLass: res.data.data });
      });
  };

  getDateTime = (data) => {
    let dateTime = new Date(data * 1000);
    return `${dateTime.getDate()}/${
      dateTime.getMonth() + 1
    }/${dateTime.getFullYear()} ${dateTime.getHours()}:${dateTime.getMinutes()}:${dateTime.getSeconds()}`;
  };
  formatNumber(num) {
    if (num != null && num != "")
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  Emaxlement = (array) => {
    let max = array[0];
    let max_index = 0;
    for (let i = 0; i < array.length; ++i) {
      if (max < array[i]) {
        max = array[i];
        max_index = i;
      }
    }
    return max;
  };
  changeStartMonth = (date) => {
    return new Date(new Date(date.setDate(1)).setHours(0, 0, 0));
  };
  changeEndMonth = (date) => {
    return new Date(
      new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59)
    );
  };
  changeTime = (update) => {
    this.setState({ start_: update[0] });
    this.setState({ end_: update[1] });
    this.setState({ show: update[1] == null });
    this.getListMealstudent(
      this.state.listClassSelect,
      update[0],
      update[1] == null ? new Date(update[0].setHours(23, 59, 59)) : update[1]
    );
    this.setState({ startDate: update[0], endDate: update[1] });
  };
  showDate = (month) => {
    var viewDate = [];
    var viewDay = [];
    var lastDayOfLastMonth = new Date(
      month.getFullYear(),
      month.getMonth() + 1,
      0
    );
    for (let i = 1; i <= lastDayOfLastMonth.getDate(); i++) {
      viewDate.push({ value: i, label: i });
      viewDay.push({
        value: this.changeDateToDay(i),
        label: this.changeDateToDay(i),
      });
    }
    this.setState({ listDay: viewDay });

    this.setState({ listDate: viewDate });
  };
  changeDateToDay = (date) => {
    var data_ = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth(),
      date
    );
    var daysOfWeek = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];

    var rs = daysOfWeek[data_.getDay()];

    return rs;
  };
  countStudentDateByClass = (class_name, date) => {
    var total = 0;
    var date_ = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth(),
      date
    );
    var date_1 = new Date(date_.setHours(0, 0, 0)) / 1000;
    var date_2 = new Date(date_.setHours(23, 59, 59)) / 1000;

    total = this.state.cutMeals.filter(
      (data_) =>
        data_.class_name == class_name &&
        data_.meal_day >= date_1 - 1 &&
        data_.meal_day <= date_2 &&
        data_.type_meal == 1 &&
        data_.is_status == 1
    ).length;

    return total;
  };

  countStudentDateByClassDatailColor = (id_student, date, process) => {
    var total = [];
    var date_ = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth(),
      date
    );
    var date_1 = parseInt(new Date(date_.setHours(0, 0, 0)) / 1000);
    var date_2 = new Date(date_.setHours(23, 59, 59)) / 1000;

    total = this.state.cutMeals.filter(
      (data_) =>
        data_.id_student == id_student &&
        data_.meal_day >= date_1 &&
        data_.meal_day <= date_2 &&
        data_.type_meal == 1 &&
        data_.is_status == 1
    );
    var kp = total.filter((e) => e.is_term == 0)?.length;
    var cp = total.filter((e) => e.is_term == 1)?.length;
    var rs = "";
    if (this.CheckHoliday(date)) {
      rs = "#ff9a0a";
    } else {
      if (total.length > 0) {
        if (kp > 0) rs = "#FEA1A1";
        if (cp > 0) rs = "#a7c957";
      } else rs = "#FFF";
    }

    if (
      date_.setHours(0, 0, 0) - new Date().setHours(0, 0, 0) >
      0
      // start - 50 >= date_1
      // this.checkProcess(date_1, date_2, process)
    ) {
      rs = "#FFF";
    }
    if (
      // start - 50 >= date_1
      this.checkProcessByClass(date_1, date_2, process)
    ) {
      rs = "#FFF";
    }
    // if (end != null) {
    //     if (end <= date_2) {
    //         rs = "#FFF"
    //     }
    // }
    return rs;
  };

  countStudentDateByClassDatail = (id_student, process, date) => {
    var total = [];
    var date_ = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth(),
      date
    );
    var date_1 = new Date(date_.setHours(0, 0, 0)) / 1000;
    var date_2 = new Date(date_.setHours(23, 59, 59)) / 1000;
    total = this.state.cutMeals.filter(
      (data_) =>
        data_.id_student == id_student &&
        data_.meal_day >= date_1 &&
        data_.meal_day <= date_2 &&
        data_.type_meal == 1 &&
        data_.is_status == 1
    );
    var kp = total.filter((e) => e.is_term == 0)?.length;
    var cp = total.filter((e) => e.is_term == 1)?.length;
    var rs = "";
    if (this.CheckHoliday(date)) {
      rs = "N";
    } else {
      if (total.length > 0) {
        if (kp > 0) rs = "K";
        if (cp > 0) rs = "P";
      } else rs = "X";
    }

    if (
      date_.setHours(0, 0, 0) - new Date().setHours(0, 0, 0) >
      0
      // ||

      // start - 50 >= date_1
      // this.checkProcessByClass(date_1, date_2, process)
    ) {
      rs = "-";
    }

    // if (this.checkProcessByClass(date_1, date_2, process)) {
    //     rs = "-"
    // }
    if (!this.isInRange(date_ / 1000 + 5, process)) {
      rs = "-";
    }
    return rs;
  };
  isInRange(x, intervals) {
    for (let i = 0; i < intervals.length; i++) {
      let start = intervals[i].start_at;
      let end = intervals[i].end_at;

      if (end === null) {
        if (x >= start) {
          return true;
        }
      } else {
        if (x >= start && x <= end) {
          return true;
        }
      }
    }

    return false;
  }

  checkProcessByClass = (start, end, process) => {
    let isProcessValid = false;

    for (var i = 0; i < process.length; i++) {
      if (process[i].end_at != null) {
        if (process.length == 1) {
          if (process[i].end_at <= end || process[i].start_at >= start) {
            isProcessValid = true;
            break;
          }
        } else {
          if (process[i].end_at >= end && process[i].start_at <= start) {
            isProcessValid = false;
            break;
          }
        }
      } else {
        if (process[i].start_at >= start) {
          isProcessValid = true;
          break;
        }
      }
    }

    if (isProcessValid) {
      return true;
    } else {
      return false;
    }
  };
  getlastDayOfMonth = (year, month) => {
    var nextMonth = new Date(year, month + 1, 1);

    var lastDay = new Date(nextMonth.getTime() - 1);

    return new Date(lastDay.setHours(23, 59, 59));
  };
  countnghihoc = (id_student) => {
    var total = [];
    var date_1 = new Date(this.state.startMonth.setHours(0, 0, 0));
    date_1 = parseInt(new Date(date_1.setDate(1)) / 1000);
    var date_2 =
      this.getlastDayOfMonth(
        this.state.startMonth.getFullYear(),
        this.state.startMonth.getMonth()
      ) / 1000;
    total = this.state.cutMeals.filter((data_) =>
      !this.CheckHoliday(new Date(data_.meal_day * 1000).getDate())
        ? data_.id_student == id_student &&
          data_.meal_day >= date_1 &&
          data_.meal_day <= date_2 &&
          data_.type_meal == 1 &&
          data_.is_status == 1
        : null
    );
    var kp = total.filter((e) => e.is_term == 0)?.length;
    var cp = total.filter((e) => e.is_term == 1)?.length;

    return total.length;
  };
  countClassMonth = (id_class) => {
    var total = [];
    var date_1 = new Date(this.state.startMonth.setHours(0, 0, 0));
    date_1 = new Date(date_1.setDate(1)) / 1000;
    var date_2 =
      this.getlastDayOfMonth(
        this.state.startMonth.getFullYear(),
        this.state.startMonth.getMonth()
      ) / 1000;
    total = this.state.cutMeals.filter(
      (data_) =>
        data_.id_class == id_class &&
        data_.meal_day >= date_1 &&
        data_.meal_day <= date_2 &&
        data_.type_meal == 1 &&
        data_.is_status == 1
    );
    var kp = total.filter((e) => e.is_term == 0)?.length;
    var cp = total.filter((e) => e.is_term == 1)?.length;

    return total.length;
  };
  countClassMonthKP = (id_class) => {
    var total = [];
    var date_1 = new Date(this.state.startMonth.setHours(0, 0, 0));
    date_1 = parseInt(new Date(date_1.setDate(1)) / 1000);
    var date_2 =
      this.getlastDayOfMonth(
        this.state.startMonth.getFullYear(),
        this.state.startMonth.getMonth()
      ) / 1000;
    total = this.state.cutMeals.filter((data_) =>
      !this.CheckHoliday(new Date(data_.meal_day * 1000).getDate())
        ? data_.id_class == id_class &&
          data_.meal_day >= date_1 &&
          data_.meal_day <= date_2 &&
          data_.type_meal == 1 &&
          data_.is_status == 1
        : null
    );
    var kp = total.filter((e) => e.is_term == 0)?.length;
    var cp = total.filter((e) => e.is_term == 1)?.length;
    return kp;
  };
  countClassMonthCP = (id_class) => {
    var total = [];
    var date_1 = new Date(this.state.startMonth.setHours(0, 0, 0));
    date_1 = parseInt(new Date(date_1.setDate(1)) / 1000);
    var date_2 =
      this.getlastDayOfMonth(
        this.state.startMonth.getFullYear(),
        this.state.startMonth.getMonth()
      ) / 1000;
    total = this.state.cutMeals.filter(
      (data_) =>
        !this.CheckHoliday(new Date(data_.meal_day * 1000).getDate())
          ? data_.id_class == id_class &&
            data_.meal_day >= date_1 &&
            data_.meal_day <= date_2 &&
            data_.type_meal == 1 &&
            data_.is_status == 1
          : null
      // console.log(data_.meal_day,date_1)
    );
    var kp = total.filter((e) => e.is_term == 0)?.length;
    var cp = total.filter((e) => e.is_term == 1)?.length;

    return cp;
  };
  countkhongphep = (id_student) => {
    var total = [];
    var date_1 = new Date(this.state.startMonth.setHours(0, 0, 0));
    date_1 = parseInt(new Date(date_1.setDate(1)) / 1000);
    var date_2 =
      this.getlastDayOfMonth(
        this.state.startMonth.getFullYear(),
        this.state.startMonth.getMonth()
      ) / 1000;
    total = this.state.cutMeals.filter((data_) =>
      !this.CheckHoliday(new Date(data_.meal_day * 1000).getDate())
        ? data_.id_student == id_student &&
          data_.meal_day >= date_1 &&
          data_.meal_day <= date_2 &&
          data_.type_meal == 1 &&
          data_.is_status == 1
        : null
    );
    var kp = total.filter((e) => e.is_term == 0)?.length;
    var cp = total.filter((e) => e.is_term == 1)?.length;

    return kp;
  };

  countStudentDate = (date) => {
    var total = 0;
    var date_ = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth(),
      date
    );
    var date_1 = parseInt(new Date(date_.setHours(0, 0, 0)) / 1000);
    var date_2 = new Date(date_.setHours(23, 59, 59)) / 1000;

    total = this.state.cutMeals.filter(
      (data_) =>
        data_.meal_day >= date_1 &&
        data_.meal_day <= date_2 &&
        data_.type_meal == 1 &&
        data_.is_status == 1
    ).length;

    return total;
  };
  countStudentDetail = (count, date) => {
    var total = 0;
    var date_ = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth(),
      date
    );
    var date_1 = parseInt(new Date(date_.setHours(0, 0, 0)) / 1000);
    var date_2 = new Date(date_.setHours(23, 59, 59)) / 1000;

    total = this.state.cutMeals.filter(
      (data_) =>
        data_.meal_day >= date_1 &&
        data_.meal_day <= date_2 &&
        data_.type_meal == 1 &&
        data_.is_status == 1 &&
        data_.id_class == this.state.chociceClass.value
    ).length;
    var rs = "";

    var tamnghi = this.state.listStudentByCLass.filter(
      (e) => this.checkProcess(date_1, date_2, e.process)
      // e?.process?.start_at - 50 >= date_1
      // || (e?.process?.end_at != null ? e?.process?.end_at - 50 >= date_2 : true)
      //  && (e.end_at ? e.end_at - 50 <= date_2 : true)
    ).length;
    // console.log(tamnghi)
    //
    // if (date_.setHours(0, 0, 0) - (new Date()).setHours(0, 0, 0) > 0 || start - 50 >= date_1) {
    //     rs = "-"
    // }
    // if (end != null) {
    //     if (end <= date_2) {
    //         rs = "-"
    //     }
    // }
    //
    if (
      date_.setHours(0, 0, 0) - new Date().setHours(0, 0, 0) > 0 ||
      // || start >=date_1 hee

      this.state.listStudentByCLass.filter(
        (e) => this.checkProcessBy_Class(date_1, date_2, e.process)
        // e?.process?.start_at - 50 <= date_1
        // && (e?.process?.end_at != null ? e?.process?.end_at - 50 <= date_2 : true)
      ).length == 0
      //  || this.state.listStudentByCLass.filter(e=>

      //     e.end_at <=date_2).length==0
    ) {
      rs = "-";
    } else {
      rs =
        this.state.listStudentByCLass.filter(
          (e) => this.checkProcessBy_Class(date_1, date_2, e.process)
          // e?.process?.start_at - 50 <= date_1
          // && (e?.process?.end_at != null ? e?.process?.end_at - 50 >= date_2 : true)
          // && (e.end_at ? e.end_at - 50 <= date_2 : true
        ).length - total;
    }

    return rs;
  };
  checkProcessBy_Class = (start, end, process) => {
    let isProcessValid = false;

    for (var i = 0; i < process.length; i++) {
      if (process[i].end_at != null) {
        if (process[i].start_at <= start && process[i].end_at >= end) {
          isProcessValid = true;
          break;
        }
      } else {
        if (process[i].start_at <= start) {
          isProcessValid = true;
          break;
        }
      }
    }

    if (isProcessValid) {
      return true;
    } else {
      return false;
    }
  };
  CheckHoliday = (day) => {
    let array = this.state.listHoliday;
    var date_ =
      new Date(
        this.state.startMonth.getFullYear(),
        this.state.startMonth.getMonth(),
        day
      ) /
        1000 +
      1;
    // console.log(date_)
    for (let i = 0; i < array.length; i++) {
      if (date_ >= array[i].start_date && date_ <= array[i].end_date) {
        return true;
      }
    }
    return false;
  };

  countStudentDetailKP = (date) => {
    var total = 0;
    var date_ = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth(),
      date
    );
    var date_1 = parseInt(new Date(date_.setHours(0, 0, 0)) / 1000);
    var date_2 = new Date(date_.setHours(23, 59, 59)) / 1000;

    total = this.state.cutMeals.filter((data_) =>
      !this.CheckHoliday(date)
        ? data_.meal_day >= date_1 &&
          data_.meal_day <= date_2 &&
          data_.type_meal == 1 &&
          data_.is_status == 1 &&
          data_.id_class == this.state.chociceClass.value &&
          data_.is_term == 0
        : null
    ).length;

    return total;
  };
  countStudentDetailCP = (date) => {
    var total = 0;
    var date_ = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth(),
      date
    );
    var date_1 = parseInt(new Date(date_.setHours(0, 0, 0)) / 1000);
    var date_2 = new Date(date_.setHours(23, 59, 59)) / 1000;

    total = this.state.cutMeals.filter(
      (data_) =>
        data_.meal_day >= date_1 &&
        data_.meal_day <= date_2 &&
        data_.type_meal == 1 &&
        data_.is_status == 1 &&
        data_.id_class == this.state.chociceClass.value &&
        data_.is_term == 1
    ).length;

    return total;
  };
  countHienDien = (count) => {
    let tong = 0;
    this.state.listDate?.map((item) => {
      // console.log(Number((count- this.countStudentDetail(count, item.label) )) ? Number((count- this.countStudentDetail(count, item.label) )) :0)
      // tong+=(Number((count- this.countStudentDetail(count, item.label) )) ? Number((count- this.countStudentDetail(count, item.label) )) :0)
      tong += this.state.setting.filter(
        (setting_) =>
          setting_.stt_day ==
          new Date(
            this.state.startMonth.getFullYear(),
            this.state.startMonth.getMonth(),
            item.label
          ).getDay()
      )?.[0]
        ? this.CheckHoliday(item.label)
          ? 0
          : Number(this.countStudentDetail(count, item.label))
          ? Number(this.countStudentDetail(count, item.label))
          : 0
        : // Number(count) - (this.countStudentDetail(count, item.label) == '-' ? count : this.countStudentDetail(count, item.label))
          0;
    });
    return tong;
  };
  Emaxlement = (array) => {
    let max = array[0];
    let max_index = 0;
    for (let i = 0; i < array.length; ++i) {
      if (max < array[i]) {
        max = array[i];
        max_index = i;
      }
    }
    return max;
  };

  // Export Excel
  xport = () => {
    const wb = XLSX.utils.book_new();

    if (this.state.chociceClass.value > 0) {
      XLSX.utils.book_append_sheet(
        wb,
        this.classDetailReport(),
        `Báo cáo hiện diện tháng ${this.state.startMonth.getMonth() + 1}`
      );
    } else {
      XLSX.utils.book_append_sheet(
        wb,
        this.summaryReport(),
        `Báo cáo hiện diện tháng ${this.state.startMonth.getMonth() + 1}`
      );
    }

    XLSX.writeFile(
      wb,
      `Báo cáo hiện diện tháng ${this.state.startMonth.getMonth() + 1}.xlsx`
    );
  };

  summaryReport = () => {
    const table = document.getElementById("CutUserTable");
    var ws = XLSX.utils.table_to_sheet(table);

    const colAlpha = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
    ];

    const colAlphaHeader = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
    ];

    const colAlphaFooter1 = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
    ];
    const colAlphaFooter2 = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
    ];

    // Style header
    colAlpha.slice(0, this.state.listDate?.length + 3).map((alpha) => {
      ws[`${alpha}1`].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    colAlpha.slice(0, this.state.listDate?.length + 3).map((alpha) => {
      ws[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          italic: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    colAlpha.slice(0, this.state.listDate?.length + 3).map((alpha) => {
      ws[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          sz: 16,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    colAlphaHeader.slice(0, this.state.listDate?.length + 3).map((alpha) => {
      ws[`${alpha}4`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
      return alpha;
    });

    colAlphaHeader.slice(0, this.state.listDate?.length + 3).map((alpha) => {
      ws[`${alpha}5`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
      return alpha;
    });
    // Style header

    // Style body
    let classIndex = 6;

    this.state.listClassSelect
      .filter((e) => e.value > 0)
      .map((_, index) => {
        colAlpha
          .slice(0, this.state.listDate?.length + 3)
          .map((alpha, indx) => {
            if (
              indx < 3 ||
              this.state.setting.filter(
                (setting_) =>
                  setting_.stt_day ==
                  new Date(
                    this.state.startMonth.getFullYear(),
                    this.state.startMonth.getMonth(),
                    this.state.listDate[indx - 3]?.value
                  ).getDay()
              )?.[0]
            ) {
              ws[`${alpha}${classIndex}`].s = {
                font: {
                  name: "Times New Roman",
                },
                alignment: {
                  vertical: "center",
                  horizontal: "center",
                },
                border: {
                  top: { style: "thin" },
                  bottom: { style: "thin" },
                  left: { style: "thin" },
                  right: { style: "thin" },
                },
              };
            } else {
              ws[`${alpha}${classIndex}`].s = {
                fill: {
                  patternType: "solid",
                  fgColor: {
                    theme: 8,
                    tint: 0.3999755851924192,
                    rgb: "EEEEEE",
                  },
                  bgColor: { indexed: 64 },
                },
                font: {
                  name: "Times New Roman",
                  bold: true,
                },
                alignment: {
                  vertical: "center",
                  horizontal: "center",
                },
                border: {
                  top: { style: "thin" },
                  bottom: { style: "thin" },
                  left: { style: "thin" },
                  right: { style: "thin" },
                },
              };
            }
          });

        classIndex++;

        return index;
      });
    // Style body

    // Style footer
    colAlphaFooter1
      .slice(0, this.state.listDate?.length + 3)
      .map((alpha, index) => {
        if (
          index < 3 ||
          this.state.setting.filter(
            (setting_) =>
              setting_.stt_day ==
              new Date(
                this.state.startMonth.getFullYear(),
                this.state.startMonth.getMonth(),
                this.state.listDate[index - 3]?.value
              ).getDay()
          )?.[0]
        ) {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "DDB892" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        } else {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "EEEEEE" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        }
      });

    colAlphaFooter2
      .slice(0, this.state.listDate?.length + 3)
      .map((alpha, index) => {
        if (
          index < 3 ||
          this.state.setting.filter(
            (setting_) =>
              setting_.stt_day ==
              new Date(
                this.state.startMonth.getFullYear(),
                this.state.startMonth.getMonth(),
                this.state.listDate[index - 3]?.value
              ).getDay()
          )?.[0]
        ) {
          ws[`${alpha}${classIndex + 1}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "DDB892" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        } else {
          ws[`${alpha}${classIndex + 1}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "EEEEEE" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        }

        return alpha;
      });
    // Style footer

    ws["!rows"] = [{}, { hpt: 20 }, { hpt: 40 }];

    const merge = [
      { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
      { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
      { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },

      { s: { r: 0, c: 0 }, e: { r: 0, c: this.state.listDate.length + 2 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: this.state.listDate.length + 2 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: this.state.listDate.length + 2 } },
      { s: { r: classIndex - 1, c: 0 }, e: { r: classIndex - 1, c: 1 } },
      { s: { r: classIndex, c: 0 }, e: { r: classIndex, c: 2 } },
    ];
    ws["!merges"] = merge;

    return ws;
  };

  classDetailReport = () => {
    const table = document.getElementById("CutUserTable");
    var ws = XLSX.utils.table_to_sheet(table);

    const colAlpha = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
      "AI",
      "AJ",
      "AK",
    ];

    const colAlphaHeader = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
      "AI",
      "AJ",
      "AK",
    ];

    const colAlphaFooter1 = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
      "AI",
      "AJ",
      "AK",
    ];
    const colAlphaFooter2 = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
      "AI",
      "AJ",
      "AK",
    ];

    // Style header
    colAlpha.slice(0, this.state.listDate?.length + 3).map((alpha) => {
      ws[`${alpha}1`].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    colAlpha.slice(0, this.state.listDate?.length + 6).map((alpha) => {
      ws[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          italic: true,
          sz: 12,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    colAlpha.slice(0, this.state.listDate?.length + 6).map((alpha) => {
      ws[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          sz: 16,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    colAlphaHeader
      .slice(0, this.state.listDate?.length + 6)
      .map((alpha, index) => {
        if (
          colAlpha.slice(0, this.state.listDate?.length + 6).length - 4 <
          index
        ) {
          ws[`${alpha}4`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "C9CBFF" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
              textRotation: 90,
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }

        ws[`${alpha}4`].s = {
          font: {
            name: "Times New Roman",
            bold: true,
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };
        return alpha;
      });

    colAlphaHeader.slice(0, this.state.listDate?.length + 6).map((alpha) => {
      ws[`${alpha}5`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
      return alpha;
    });
    // Style header

    // Style body
    let classIndex = 6;

    this.state.listStudentByCLass.map((_, index) => {
      colAlpha.slice(0, this.state.listDate?.length + 6).map((alpha, indx) => {
        if (
          colAlpha.slice(0, this.state.listDate?.length + 6).length - 4 <
          indx
        ) {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "C9CBFF" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }
        if (ws[`${alpha}${classIndex}`].v === "N") {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "ff9a0a" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }
        if (ws[`${alpha}${classIndex}`].v === "P") {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "A7C957" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }

        if (ws[`${alpha}${classIndex}`].v === "K") {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "FEA1A1" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }

        ws[`${alpha}${classIndex}`].s = {
          font: {
            name: "Times New Roman",
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        if (ws[`${alpha}${classIndex}`].v === "empty") {
          ws[`${alpha}${classIndex}`].v = " ";

          if (alpha === "C") {
            ws[`${alpha}${classIndex}`].s = {
              font: {
                name: "Times New Roman",
              },
              alignment: {
                vertical: "center",
                horizontal: "center",
              },
              border: {
                top: { style: "thin" },
                bottom: { style: "thin" },
                left: { style: "thin" },
                right: { style: "thin" },
              },
            };
          } else {
            ws[`${alpha}${classIndex}`].s = {
              fill: {
                patternType: "solid",
                fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "EEEEEE" },
                bgColor: { indexed: 64 },
              },
              font: {
                name: "Times New Roman",
              },
              alignment: {
                vertical: "center",
                horizontal: "center",
              },
              border: {
                top: { style: "thin" },
                bottom: { style: "thin" },
                left: { style: "thin" },
                right: { style: "thin" },
              },
            };
          }
        }

        return alpha;
      });

      classIndex++;

      return index;
    });
    // Style body

    // Style footer
    colAlphaFooter1
      .slice(0, this.state.listDate?.length + 6)
      .map((alpha, index) => {
        if (
          colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            3 ===
          index
        ) {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "FAE392" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }

        if (
          index < 3 ||
          this.state.setting.filter(
            (setting_) =>
              setting_.stt_day ==
              new Date(
                this.state.startMonth.getFullYear(),
                this.state.startMonth.getMonth(),
                this.state.listDate[index - 3]?.value
              ).getDay()
          )?.[0]
        ) {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "DDB892" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        } else {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "EEEEEE" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        }

        return alpha;
      });

    colAlphaFooter2
      .slice(0, this.state.listDate?.length + 6)
      .map((alpha, index) => {
        if (
          colAlphaFooter2.slice(0, this.state.listDate?.length + 6).length -
            3 ===
          index
        ) {
          ws[`${alpha}${classIndex + 1}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "FAE392" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }

        if (
          index < 3 ||
          this.state.setting.filter(
            (setting_) =>
              setting_.stt_day ==
              new Date(
                this.state.startMonth.getFullYear(),
                this.state.startMonth.getMonth(),
                this.state.listDate[index - 3]?.value
              ).getDay()
          )?.[0]
        ) {
          ws[`${alpha}${classIndex + 1}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "DDB892" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        } else {
          ws[`${alpha}${classIndex + 1}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "EEEEEE" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        }
        return alpha;
      });

    colAlphaFooter2
      .slice(0, this.state.listDate?.length + 6)
      .map((alpha, index) => {
        if (
          colAlphaFooter2.slice(0, this.state.listDate?.length + 6).length -
            3 ===
          index
        ) {
          ws[`${alpha}${classIndex + 2}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "FAE392" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }

        if (
          index < 3 ||
          this.state.setting.filter(
            (setting_) =>
              setting_.stt_day ==
              new Date(
                this.state.startMonth.getFullYear(),
                this.state.startMonth.getMonth(),
                this.state.listDate[index - 3]?.value
              ).getDay()
          )?.[0]
        ) {
          ws[`${alpha}${classIndex + 2}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "DDB892" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        } else {
          ws[`${alpha}${classIndex + 2}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "EEEEEE" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        }

        return alpha;
      });

    colAlphaFooter2.slice(0, this.state.listDate?.length + 6).map((alpha) => {
      if (ws[`${alpha}${classIndex + 3}`].v === "empty") {
        ws[`${alpha}${classIndex + 3}`].v = " ";
      }

      ws[`${alpha}${classIndex + 3}`].s = {
        font: {
          name: "Times New Roman",
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });

    colAlphaFooter2.slice(0, this.state.listDate?.length + 6).map((alpha) => {
      if (ws[`${alpha}${classIndex + 4}`].v === "empty") {
        ws[`${alpha}${classIndex + 4}`].v = " ";
      }

      ws[`${alpha}${classIndex + 4}`].s = {
        font: {
          name: "Times New Roman",
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });
    // Style footer

    ws["!cols"] = [{ wch: 10 }, { wch: 20 }];
    ws["!rows"] = [{}, { hpt: 20 }, { hpt: 40 }, { hpt: 30 }, { hpt: 30 }];

    const merge = [
      { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
      { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
      { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },

      {
        s: { r: 3, c: this.state.listDate.length + 5 },
        e: { r: 4, c: this.state.listDate.length + 5 },
      },
      {
        s: { r: 3, c: this.state.listDate.length + 4 },
        e: { r: 4, c: this.state.listDate.length + 4 },
      },
      {
        s: { r: 3, c: this.state.listDate.length + 3 },
        e: { r: 4, c: this.state.listDate.length + 3 },
      },

      { s: { r: 0, c: 0 }, e: { r: 0, c: this.state.listDate.length + 5 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: this.state.listDate.length + 5 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: this.state.listDate.length + 5 } },
      { s: { r: classIndex - 1, c: 0 }, e: { r: classIndex - 1, c: 1 } },
      {
        s: {
          r: classIndex - 1,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            3,
        },
        e: {
          r: classIndex - 1,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            1,
        },
      },
      { s: { r: classIndex, c: 0 }, e: { r: classIndex, c: 2 } },
      {
        s: {
          r: classIndex,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            3,
        },
        e: {
          r: classIndex,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            1,
        },
      },
      { s: { r: classIndex + 1, c: 0 }, e: { r: classIndex + 1, c: 2 } },
      {
        s: {
          r: classIndex + 1,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            3,
        },
        e: {
          r: classIndex + 1,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            1,
        },
      },

      { s: { r: classIndex + 2, c: 0 }, e: { r: classIndex + 2, c: 4 } },
      {
        s: {
          r: classIndex + 2,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            10,
        },
        e: {
          r: classIndex + 2,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            1,
        },
      },

      { s: { r: classIndex + 3, c: 0 }, e: { r: classIndex + 3, c: 4 } },
      {
        s: {
          r: classIndex + 3,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            10,
        },
        e: {
          r: classIndex + 3,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            1,
        },
      },
    ];
    ws["!merges"] = merge;

    return ws;
  };

  handleExport = () => {
    swal({
      title: `Xác nhận`,
      text: `Xuất danh sách báo cáo hiện diện tháng ${
        this.state.startMonth.getMonth() + 1
      } `,
      icon: "info",
      buttons: ["Đóng", "Export"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        this.xport();
      }
    });
  };
  // Export Excel

  renderCutMealListTable() {
    return (
      <>
        {this.state.checkload ? (
          this.loading()
        ) : (
          <div style={{ overflow: "auto" }}>
            <Table bordered style={{ borderColor: "#000" }}>
              <thead>
                <tr>
                  <th
                    className="text-left "
                    colSpan={
                      7 +
                      new Date(
                        this.state.startMonth.getFullYear(),
                        this.state.startMonth.getMonth() + 1,
                        0
                      ).getDate()
                    }
                  >
                    <p
                      className=""
                      style={{
                        fontSize: "12px",
                        fontStyle: "italic",
                        margin: "0",
                        fontWeight: "400",
                      }}
                    >
                      {this.state.chociceClass?.value > 0
                        ? "Chú thích: X: đi học, P: có phép, K: không phép (xin trễ), N: nghỉ lễ"
                        : "Chú thích:"}
                    </p>
                  </th>
                </tr>
                <tr>
                  <th
                    className="text-center text-uppercase "
                    colSpan={
                      7 +
                      new Date(
                        this.state.startMonth.getFullYear(),
                        this.state.startMonth.getMonth() + 1,
                        0
                      ).getDate()
                    }
                  >
                    {this.state.chociceClass?.value > 0
                      ? `Bảng hiện diện lớp ${this.state.chociceClass.label} `
                      : ` Báo cáo tổng hợp hiện diện theo lớp `}{" "}
                    Tháng {this.state.startMonth.getMonth() + 1} năm{" "}
                    {this.state.startMonth.getFullYear()}
                  </th>
                </tr>

                <tr>
                  <th rowSpan="2" className="align-middle text-center">
                    STT
                  </th>
                  <th rowSpan="2" className="align-middle text-center">
                    {this.state.chociceClass.value > 0 ? "Họ và tên" : "Lớp"}
                  </th>
                  <th rowSpan="2" className=" align-middle text-center">
                    {this.state.chociceClass.value > 0 ? "Nữ" : "Sĩ số"}
                  </th>

                  {this.state.listDate?.map((item) => {
                    return (
                      <th
                        className="align-middle text-center"
                        style={{
                          backgroundColor: this.state.setting.filter(
                            (setting_) =>
                              setting_.stt_day ==
                              new Date(
                                this.state.startMonth.getFullYear(),
                                this.state.startMonth.getMonth(),
                                item.label
                              ).getDay()
                          )?.[0]
                            ? "white"
                            : "#EEEEEE",
                        }}
                      >
                        {item.label}
                      </th>
                    );
                  })}

                  {this.state.chociceClass.value > 0 ? (
                    <>
                      <th
                        rowSpan="2"
                        style={{
                          transform: "rotate(180deg)",
                          writingMode: "vertical-rl",
                          backgroundColor: "#C9CBFF",
                        }}
                      >
                        Tổng nghỉ học
                      </th>
                      <th
                        rowSpan="2"
                        style={{
                          transform: "rotate(180deg)",
                          writingMode: "vertical-rl",
                          backgroundColor: "#C9CBFF",
                        }}
                      >
                        Tổng không phép
                      </th>
                      <th
                        rowSpan="2"
                        style={{
                          transform: "rotate(180deg)",
                          writingMode: "vertical-rl",
                          backgroundColor: "#C9CBFF",
                        }}
                      >
                        Tổng có phép
                      </th>
                    </>
                  ) : (
                    ""
                  )}
                </tr>

                <tr>
                  {this.state.listDate?.map((item) => {
                    return (
                      <th
                        className="align-middle text-center"
                        style={{
                          backgroundColor: this.state.setting.filter(
                            (setting_) =>
                              setting_.stt_day ==
                              new Date(
                                this.state.startMonth.getFullYear(),
                                this.state.startMonth.getMonth(),
                                item.label
                              ).getDay()
                          )?.[0]
                            ? "white"
                            : "#EEEEEE",
                        }}
                      >
                        {this.changeDateToDay(item.label)}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {this.state.chociceClass.value > 0 ? (
                <tbody>
                  {this.state.listStudentByCLass.map((student, index) => (
                    <>
                      <tr>
                        <td> {index + 1} </td>
                        <td> {student.student_name} </td>
                        <td className=" align-middle text-center">
                          {student.gender_name == "Nữ" ? "X" : ""}
                        </td>
                        {this.state.listDate?.map((item) => {
                          return (
                            <td
                              className="align-middle text-center"
                              style={{
                                backgroundColor: this.state.setting.filter(
                                  (setting_) =>
                                    setting_.stt_day ==
                                    new Date(
                                      this.state.startMonth.getFullYear(),
                                      this.state.startMonth.getMonth(),
                                      item.label
                                    ).getDay()
                                )?.[0]
                                  ? // 'red'
                                    this.countStudentDateByClassDatailColor(
                                      student.id,
                                      item.label,
                                      student.process
                                    )
                                  : "#EEEEEE",
                              }}
                            >
                              {this.state.setting.filter(
                                (setting_) =>
                                  setting_.stt_day ==
                                  new Date(
                                    this.state.startMonth.getFullYear(),
                                    this.state.startMonth.getMonth(),
                                    item.label
                                  ).getDay()
                              )?.[0]
                                ? // this.CheckHoliday(item.label)?'NL':
                                  this.countStudentDateByClassDatail(
                                    student.id,
                                    // student.process?.start_at, student.process?.end_at,
                                    student.process,
                                    item.label
                                  )
                                : ""}
                            </td>
                          );
                        })}
                        <td style={{ backgroundColor: "#C9CBFF" }}>
                          {this.countnghihoc(student.id)}
                        </td>
                        <td style={{ backgroundColor: "#C9CBFF" }}>
                          {this.countkhongphep(student.id)}
                        </td>
                        <td style={{ backgroundColor: "#C9CBFF" }}>
                          {this.countnghihoc(student.id) -
                            this.countkhongphep(student.id)}
                        </td>
                      </tr>
                    </>
                  ))}

                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th colSpan="2"> Hiện diện</th>
                    <th>
                      {
                        this.state.listStudentByCLass.filter(
                          (e) => e.gender_name == "Nữ"
                        ).length
                      }
                    </th>
                    {this.state.listDate?.map((item) => {
                      return (
                        <th
                          className="align-middle text-center"
                          style={{
                            backgroundColor: this.state.setting.filter(
                              (setting_) =>
                                setting_.stt_day ==
                                new Date(
                                  this.state.startMonth.getFullYear(),
                                  this.state.startMonth.getMonth(),
                                  item.label
                                ).getDay()
                            )?.[0]
                              ? "#ddb892"
                              : "#EEEEEE",
                          }}
                        >
                          {this.state.setting.filter(
                            (setting_) =>
                              setting_.stt_day ==
                              new Date(
                                this.state.startMonth.getFullYear(),
                                this.state.startMonth.getMonth(),
                                item.label
                              ).getDay()
                          )?.[0]
                            ? this.CheckHoliday(item.label)
                              ? 0
                              : // this.state.listStudentByCLass.length -
                              this.countStudentDetail(
                                  this.state.listStudentByCLass.length,
                                  item.label
                                ) == "-"
                              ? 0
                              : this.countStudentDetail(
                                  this.state.listStudentByCLass.length,
                                  item.label
                                )
                            : 0}
                        </th>
                      );
                    })}
                    <th style={{ backgroundColor: "#FAE392" }} colSpan={3}>
                      {
                        //  this.countHienDien(this.state.listStudentByCLass?.length)
                        //
                        this.countHienDien(
                          this.state.listStudentByCLass?.length
                        )
                        //  >0 ?this.countHienDien(this.state.listStudentByCLass?.length).split('-')[0] :0
                      }
                    </th>
                  </tr>

                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th colSpan="3"> Vắng không phép</th>

                    {this.state.listDate?.map((item) => {
                      return (
                        <th
                          className="align-middle text-center"
                          style={{
                            backgroundColor: this.state.setting.filter(
                              (setting_) =>
                                setting_.stt_day ==
                                new Date(
                                  this.state.startMonth.getFullYear(),
                                  this.state.startMonth.getMonth(),
                                  item.label
                                ).getDay()
                            )?.[0]
                              ? "#ddb892"
                              : "#EEEEEE",
                          }}
                        >
                          {" "}
                          {this.countStudentDetailKP(item.label)}{" "}
                        </th>
                      );
                    })}
                    <th style={{ backgroundColor: "#FAE392" }} colSpan={3}>
                      {this.countClassMonthKP(this.state.chociceClass.value)}
                    </th>
                  </tr>
                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th colSpan="3"> Vắng có phép</th>

                    {this.state.listDate?.map((item) => {
                      return (
                        <th
                          className="align-middle text-center"
                          style={{
                            backgroundColor: this.state.setting.filter(
                              (setting_) =>
                                setting_.stt_day ==
                                new Date(
                                  this.state.startMonth.getFullYear(),
                                  this.state.startMonth.getMonth(),
                                  item.label
                                ).getDay()
                            )?.[0]
                              ? "#ddb892"
                              : "#EEEEEE",
                          }}
                        >
                          {" "}
                          {this.CheckHoliday(item.label)
                            ? 0
                            : this.countStudentDetailCP(item.label)}{" "}
                        </th>
                      );
                    })}
                    <th style={{ backgroundColor: "#FAE392" }} colSpan={3}>
                      {this.countClassMonthCP(this.state.chociceClass.value)}
                    </th>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {this.state.listClassSelect.map((student, index) =>
                    student.value > 0 ? (
                      <tr>
                        <td className="align-middle text-center"> {index} </td>
                        <td className="align-middle text-center">
                          {" "}
                          {student.label}{" "}
                        </td>
                        <td className="align-middle text-center">
                          {student.total.length}
                        </td>
                        {/* <td>{console.log(student)}
                                                                {student.count}
                                                            </td> */}
                        {/* <td>
                                                                {this.getStudentClassInMonth(student.value, this.state.startMonth)}
                                                            </td> */}
                        {this.state.listDate?.map((item) => (
                          <td
                            className="align-middle text-center"
                            style={{
                              backgroundColor: this.state.setting.filter(
                                (setting_) =>
                                  setting_.stt_day ==
                                  new Date(
                                    this.state.startMonth.getFullYear(),
                                    this.state.startMonth.getMonth(),
                                    item.label
                                  ).getDay()
                              )?.[0]
                                ? "white"
                                : "#EEEEEE",
                            }}
                          >
                            {this.state.setting.filter(
                              (setting_) =>
                                setting_.stt_day ==
                                new Date(
                                  this.state.startMonth.getFullYear(),
                                  this.state.startMonth.getMonth(),
                                  item.label
                                ).getDay()
                            )?.[0]
                              ? new Date(
                                  this.state.startMonth.getFullYear(),
                                  this.state.startMonth.getMonth(),
                                  item.label
                                ).setHours(0, 0, 0) -
                                  new Date().setHours(0, 0, 0) >
                                0
                                ? "-"
                                : this.CheckHoliday(item.label)
                                ? 0
                                : this.getStudentInclassOneDay(
                                    student.value,
                                    item.label
                                  ).length -
                                  this.countStudentDateByClass(
                                    student.label,
                                    item.label
                                  )
                              : 0}
                          </td>
                        ))}
                      </tr>
                    ) : null
                  )}
                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th colSpan="2"> Hiện diện</th>
                    <th>{this.getAllStudent()}</th>
                    {this.state.listDate?.map((item) => {
                      return (
                        <th
                          className="align-middle text-center"
                          style={{
                            backgroundColor: this.state.setting.filter(
                              (setting_) =>
                                setting_.stt_day ==
                                new Date(
                                  this.state.startMonth.getFullYear(),
                                  this.state.startMonth.getMonth(),
                                  item.label
                                ).getDay()
                            )?.[0]
                              ? "#ddb892"
                              : "#EEEEEE",
                          }}
                        >
                          {this.state.setting.filter(
                            (setting_) =>
                              setting_.stt_day ==
                              new Date(
                                this.state.startMonth.getFullYear(),
                                this.state.startMonth.getMonth(),
                                item.label
                              ).getDay()
                          )?.[0]
                            ? new Date(
                                this.state.startMonth.getFullYear(),
                                this.state.startMonth.getMonth(),
                                item.label
                              ).setHours(0, 0, 0) -
                                new Date().setHours(0, 0, 0) >
                              0
                              ? "0"
                              : this.CheckHoliday(item.label)
                              ? 0
                              : // this.state.tongAll -
                                // this.countStudentDate(item.label)

                                this.getSumStudentByClassOneDay(item.label)
                            : 0}
                        </th>
                      );
                    })}
                  </tr>
                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th colSpan="3"> Vắng</th>

                    {this.state.listDate?.map((item) => {
                      return (
                        <th
                          className="align-middle text-center"
                          style={{
                            backgroundColor: this.state.setting.filter(
                              (setting_) =>
                                setting_.stt_day ==
                                new Date(
                                  this.state.startMonth.getFullYear(),
                                  this.state.startMonth.getMonth(),
                                  item.label
                                ).getDay()
                            )?.[0]
                              ? "#ddb892"
                              : "#EEEEEE",
                          }}
                        >
                          {" "}
                          {this.CheckHoliday(item.label)
                            ? 0
                            : this.countStudentDate(item.label)}{" "}
                        </th>
                      );
                    })}
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
        )}
      </>
    );
  }

  renderCutMealListTableExport() {
    return (
      <>
        {this.state.checkload ? (
          ""
        ) : (
          <div className="d-none">
            <Table bordered id="CutUserTable" style={{ borderColor: "#000" }}>
              <thead>
                <tr>
                  <th className="text-center">
                    {this.state.schoolInfo.school_name}
                  </th>
                  <th>empty</th>
                  <th>empty</th>

                  {this.state.chociceClass.value > 0 ? (
                    <>
                      <th>empty</th>
                      <th>empty</th>
                      <th>empty</th>
                    </>
                  ) : (
                    ""
                  )}

                  {this.state.listDate?.map((_, index) => {
                    return <th key={index}>empty</th>;
                  })}
                </tr>
                <tr>
                  <th className="text-left">
                    <p
                      className=""
                      style={{
                        fontSize: "12px",
                        fontStyle: "italic",
                        margin: "0",
                        fontWeight: "400",
                      }}
                    >
                      {this.state.chociceClass?.value > 0
                        ? "Chú thích: X: đi học, P: có phép, K: không phép (xin trễ), -: chưa diễn ra, N: nghỉ lễ"
                        : "Chú thích: -: chưa diễn ra"}
                    </p>
                  </th>
                  <th>empty</th>
                  <th>empty</th>

                  {this.state.chociceClass.value > 0 ? (
                    <>
                      <th>empty</th>
                      <th>empty</th>
                      <th>empty</th>
                    </>
                  ) : (
                    ""
                  )}

                  {this.state.listDate?.map((_, index) => {
                    return <th key={index}>empty</th>;
                  })}
                </tr>

                <tr>
                  <th className="text-center text-uppercase">
                    {this.state.chociceClass?.value > 0
                      ? `Bảng hiện diện lớp ${this.state.chociceClass.label} `
                      : ` Báo cáo tổng hợp hiện diện theo lớp `}{" "}
                    Tháng {this.state.startMonth.getMonth() + 1} năm{" "}
                    {this.state.startMonth.getFullYear()}
                  </th>

                  <th>empty</th>
                  <th>empty</th>

                  {this.state.chociceClass.value > 0 ? (
                    <>
                      <th>empty</th>
                      <th>empty</th>
                      <th>empty</th>
                    </>
                  ) : (
                    ""
                  )}

                  {this.state.listDate?.map((_, index) => {
                    return <th key={index}>empty</th>;
                  })}
                </tr>

                <tr>
                  <th className="align-middle text-center">STT</th>
                  <th className="align-middle text-center">
                    {this.state.chociceClass.value > 0 ? "Họ và tên" : "Lớp"}
                  </th>
                  <th className=" align-middle text-center">
                    {this.state.chociceClass.value > 0 ? "Nữ" : "Sĩ số"}
                  </th>

                  {this.state.listDate?.map((item) => {
                    return (
                      <th
                        className="align-middle text-center"
                        style={{
                          backgroundColor: this.state.setting.filter(
                            (setting_) =>
                              setting_.stt_day ==
                              new Date(
                                this.state.startMonth.getFullYear(),
                                this.state.startMonth.getMonth(),
                                item.label
                              ).getDay()
                          )?.[0]
                            ? "white"
                            : "#EEEEEE",
                        }}
                      >
                        {item.label}
                      </th>
                    );
                  })}

                  {this.state.chociceClass.value > 0 ? (
                    <>
                      <th
                        style={{
                          transform: "rotate(180deg)",
                          writingMode: "vertical-rl",
                          backgroundColor: "#C9CBFF",
                        }}
                      >
                        Tổng nghỉ học
                      </th>
                      <th
                        style={{
                          transform: "rotate(180deg)",
                          writingMode: "vertical-rl",
                          backgroundColor: "#C9CBFF",
                        }}
                      >
                        Tổng không phép
                      </th>
                      <th
                        style={{
                          transform: "rotate(180deg)",
                          writingMode: "vertical-rl",
                          backgroundColor: "#C9CBFF",
                        }}
                      >
                        Tổng có phép
                      </th>
                    </>
                  ) : (
                    ""
                  )}
                </tr>

                <tr>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  {this.state.listDate?.map((item) => {
                    return (
                      <th
                        className="align-middle text-center"
                        style={{
                          backgroundColor: this.state.setting.filter(
                            (setting_) =>
                              setting_.stt_day ==
                              new Date(
                                this.state.startMonth.getFullYear(),
                                this.state.startMonth.getMonth(),
                                item.label
                              ).getDay()
                          )?.[0]
                            ? "white"
                            : "#EEEEEE",
                        }}
                      >
                        {this.changeDateToDay(item.label)}
                      </th>
                    );
                  })}

                  {this.state.chociceClass.value > 0 ? (
                    <>
                      <th>empty</th>
                      <th>empty</th>
                      <th>empty</th>
                    </>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>

              {this.state.chociceClass.value > 0 ? (
                <tbody>
                  {this.state.listStudentByCLass.map((student, index) => {
                    return (
                      <tr>
                        <td> {index + 1} </td>
                        <td> {student.student_name} </td>
                        <td className=" align-middle text-center">
                          {student.gender_name == "Nữ" ? "X" : "empty"}
                        </td>
                        {this.state.listDate?.map((item) => {
                          return (
                            <td
                              className="align-middle text-center"
                              style={{
                                backgroundColor: this.state.setting.filter(
                                  (setting_) =>
                                    setting_.stt_day ==
                                    new Date(
                                      this.state.startMonth.getFullYear(),
                                      this.state.startMonth.getMonth(),
                                      item.label
                                    ).getDay()
                                )?.[0]
                                  ? this.countStudentDateByClassDatailColor(
                                      student.id,
                                      item.label,
                                      student.process
                                    )
                                  : "#EEEEEE",
                              }}
                            >
                              {this.state.setting.filter(
                                (setting_) =>
                                  setting_.stt_day ==
                                  new Date(
                                    this.state.startMonth.getFullYear(),
                                    this.state.startMonth.getMonth(),
                                    item.label
                                  ).getDay()
                              )?.[0]
                                ? // this.CheckHoliday(item.label)?'NL':
                                  this.countStudentDateByClassDatail(
                                    student.id,
                                    student.process,
                                    item.label
                                  )
                                : "empty"}
                            </td>
                          );
                        })}
                        <td style={{ backgroundColor: "#C9CBFF" }}>
                          {this.countnghihoc(student.id)}
                        </td>
                        <td style={{ backgroundColor: "#C9CBFF" }}>
                          {this.countkhongphep(student.id)}
                        </td>
                        <td style={{ backgroundColor: "#C9CBFF" }}>
                          {this.countnghihoc(student.id) -
                            this.countkhongphep(student.id)}
                        </td>
                      </tr>
                    );
                  })}

                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th> Hiện diện</th>
                    <th>empty</th>
                    <th>
                      {
                        this.state.listStudentByCLass.filter(
                          (e) => e.gender_name == "Nữ"
                        ).length
                      }
                    </th>
                    {this.state.listDate?.map((item) => {
                      return (
                        <th
                          className="align-middle text-center"
                          style={{
                            backgroundColor: this.state.setting.filter(
                              (setting_) =>
                                setting_.stt_day ==
                                new Date(
                                  this.state.startMonth.getFullYear(),
                                  this.state.startMonth.getMonth(),
                                  item.label
                                ).getDay()
                            )?.[0]
                              ? "#ddb892"
                              : "#EEEEEE",
                          }}
                        >
                          {this.state.setting.filter(
                            (setting_) =>
                              setting_.stt_day ==
                              new Date(
                                this.state.startMonth.getFullYear(),
                                this.state.startMonth.getMonth(),
                                item.label
                              ).getDay()
                          )?.[0]
                            ? this.CheckHoliday(item.label)
                              ? 0
                              : // this.state.listStudentByCLass.length -
                              this.countStudentDetail(
                                  this.state.listStudentByCLass.length,
                                  item.label
                                ) == "-"
                              ? 0
                              : this.countStudentDetail(
                                  this.state.listStudentByCLass.length,
                                  item.label
                                )
                            : 0}
                        </th>
                      );
                    })}
                    <th style={{ backgroundColor: "#FAE392" }}>
                      {
                        this.countHienDien(
                          this.state.listStudentByCLass?.length
                        )
                        // >0 ?this.countHienDien(this.state.listStudentByCLass?.length).split('-')[0] :0

                        // this.countHienDien(this.state.listStudentByCLass?.length)?.split('-')[0]
                      }
                    </th>
                    <th>empty</th>
                    <th>empty</th>
                  </tr>
                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th> Vắng không phép</th>
                    <th>empty</th>
                    <th>empty</th>

                    {this.state.listDate?.map((item) => {
                      return (
                        <th
                          className="align-middle text-center"
                          style={{
                            backgroundColor: this.state.setting.filter(
                              (setting_) =>
                                setting_.stt_day ==
                                new Date(
                                  this.state.startMonth.getFullYear(),
                                  this.state.startMonth.getMonth(),
                                  item.label
                                ).getDay()
                            )?.[0]
                              ? "#ddb892"
                              : "#EEEEEE",
                          }}
                        >
                          {" "}
                          {this.countStudentDetailKP(item.label)}{" "}
                        </th>
                      );
                    })}
                    <th style={{ backgroundColor: "#FAE392" }}>
                      {this.countClassMonthKP(this.state.chociceClass.value)}
                    </th>
                    <th>empty</th>
                    <th>empty</th>
                  </tr>
                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th> Vắng có phép</th>
                    <th>empty</th>
                    <th>empty</th>

                    {this.state.listDate?.map((item) => {
                      return (
                        <th
                          className="align-middle text-center"
                          style={{
                            backgroundColor: this.state.setting.filter(
                              (setting_) =>
                                setting_.stt_day ==
                                new Date(
                                  this.state.startMonth.getFullYear(),
                                  this.state.startMonth.getMonth(),
                                  item.label
                                ).getDay()
                            )?.[0]
                              ? "#ddb892"
                              : "#EEEEEE",
                          }}
                        >
                          {" "}
                          {this.CheckHoliday(item.label)
                            ? 0
                            : this.countStudentDetailCP(item.label)}{" "}
                        </th>
                      );
                    })}
                    <th style={{ backgroundColor: "#FAE392" }}>
                      {this.countClassMonthCP(this.state.chociceClass.value)}
                    </th>
                    <th>empty</th>
                    <th>empty</th>
                  </tr>

                  <tr>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>

                    {this.state.listDate
                      ?.slice(9, this.state.listDate?.length)
                      .map((_, index) => {
                        return <th key={index}>empty</th>;
                      })}

                    <th>
                      Ngày {this.state.listDate?.length} tháng{" "}
                      {this.state.startMonth.getMonth() + 1} năm{" "}
                      {this.state.startMonth.getFullYear()}
                    </th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                  </tr>

                  <tr>
                    <th>Phụ trách bộ phận</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>

                    {this.state.listDate
                      ?.slice(9, this.state.listDate?.length)
                      .map((_, index) => {
                        return <th key={index}>empty</th>;
                      })}

                    <th>Giáo viên</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {this.state.listClassSelect.map((student, index) => {
                    if (student.value > 0)
                      return (
                        <tr>
                          <td> {index} </td>
                          <td> {student.label} </td>
                          <td>{student.total.length}</td>
                          {this.state.listDate?.map((item) => {
                            return (
                              <td
                                className="align-middle text-center"
                                style={{
                                  backgroundColor: this.state.setting.filter(
                                    (setting_) =>
                                      setting_.stt_day ==
                                      new Date(
                                        this.state.startMonth.getFullYear(),
                                        this.state.startMonth.getMonth(),
                                        item.label
                                      ).getDay()
                                  )?.[0]
                                    ? "white"
                                    : "#EEEEEE",
                                }}
                              >
                                {this.state.setting.filter(
                                  (setting_) =>
                                    setting_.stt_day ==
                                    new Date(
                                      this.state.startMonth.getFullYear(),
                                      this.state.startMonth.getMonth(),
                                      item.label
                                    ).getDay()
                                )?.[0]
                                  ? new Date(
                                      this.state.startMonth.getFullYear(),
                                      this.state.startMonth.getMonth(),
                                      item.label
                                    ).setHours(0, 0, 0) -
                                      new Date().setHours(0, 0, 0) >
                                    0
                                    ? "-"
                                    : this.CheckHoliday(item.label)
                                    ? 0
                                    : this.getStudentInclassOneDay(
                                        student.value,
                                        item.label
                                      ).length -
                                      // - this.getStudentInclass(student.value, item.label) : 0}
                                      this.countStudentDateByClass(
                                        student.label,
                                        item.label
                                      )
                                  : 0}
                                {/* student.total - this.countStudentDateByClass(student.class_name, item.label) : 0} */}
                              </td>
                            );
                          })}
                        </tr>
                      );
                  })}

                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th> Hiện diện</th>
                    <th>empty</th>

                    {/* <th>{this.state.tongAll}</th> */}
                    <th>{this.getAllStudent()}</th>
                    {this.state.listDate?.map((item) => {
                      return (
                        <th
                          className="align-middle text-center"
                          style={{
                            backgroundColor: this.state.setting.filter(
                              (setting_) =>
                                setting_.stt_day ==
                                new Date(
                                  this.state.startMonth.getFullYear(),
                                  this.state.startMonth.getMonth(),
                                  item.label
                                ).getDay()
                            )?.[0]
                              ? "#ddb892"
                              : "#EEEEEE",
                          }}
                        >
                          {this.state.setting.filter(
                            (setting_) =>
                              setting_.stt_day ==
                              new Date(
                                this.state.startMonth.getFullYear(),
                                this.state.startMonth.getMonth(),
                                item.label
                              ).getDay()
                          )?.[0]
                            ? new Date(
                                this.state.startMonth.getFullYear(),
                                this.state.startMonth.getMonth(),
                                item.label
                              ).setHours(0, 0, 0) -
                                new Date().setHours(0, 0, 0) >
                              0
                              ? "0"
                              : // this.state.tongAll -
                              // this.countStudentDate(item.label) : 0}
                              this.CheckHoliday(item.label)
                              ? 0
                              : this.getSumStudentByClassOneDay(item.label)
                            : 0}
                        </th>
                      );
                    })}
                  </tr>
                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th>Vắng</th>
                    <th>empty</th>
                    <th>empty</th>

                    {this.state.listDate?.map((item) => {
                      return (
                        <th
                          className="align-middle text-center"
                          style={{
                            backgroundColor: this.state.setting.filter(
                              (setting_) =>
                                setting_.stt_day ==
                                new Date(
                                  this.state.startMonth.getFullYear(),
                                  this.state.startMonth.getMonth(),
                                  item.label
                                ).getDay()
                            )?.[0]
                              ? "#ddb892"
                              : "#EEEEEE",
                          }}
                        >
                          {" "}
                          {this.CheckHoliday(item.label)
                            ? 0
                            : this.countStudentDate(item.label)}{" "}
                        </th>
                      );
                    })}
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
        )}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid>
          <Breadcrumb>
            <Breadcrumb.Item active>Báo cáo</Breadcrumb.Item>
            <Breadcrumb.Item active>Báo cáo chi tiết sĩ số</Breadcrumb.Item>
          </Breadcrumb>
          <Card>
            <Card.Header>
              <Card.Title
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                className="d-lg-flex"
              >
                <div>
                  <i className="fas fa-list me-1"></i>
                  <span style={{ padding: "0 10px", fontSize: "1.13rem" }}>
                    Báo cáo hiện diện tháng{" "}
                    {this.state.startMonth.getMonth() + 1} /
                    {this.state.startMonth.getFullYear()}{" "}
                  </span>

                  {/* {this.state.startDate ? (this.getDate_(this.state.startDate) + " - " + this.getDate_(this.state.endDate)) : ""} {" "} */}
                  {/* -  {this.state.endDate ? this.getDate_(this.state.endDate) : "--"}{" "} */}

                  {/* <Button
                                    onClick={() => { this.setState({ show: true }) }}>
                                    < i className="fa-solid fa-calendar-days"> </i> </Button> */}

                  {/* <DatePicker
                                    selected={this.state.startMonth}
                                    onChange={(date) => {
                                        console.log(date)
                                        this.setState({ startMonth: date })
                                        this.showDate(date)
                                    }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    locale="vi"
                                    styles={this.customStyles}
                                /> */}
                </div>

                <div className="d-flex">
                  <input
                    type="month"
                    name="month"
                    style={{
                      border: "none",
                      width: 39,
                      backgroundColor: "rgb(16,108,252)",
                      borderRadius: 5,
                    }}
                    className="me-2 md-mb-2"
                    onChange={(e) => {
                      const [year, month] = e.target.value
                        ? e.target.value.split("-")
                        : [new Date().getFullYear(), new Date().getMonth() + 1];
                      this.setState(
                        { startMonth: new Date(year, month - 1) },
                        () => {
                          // this.getStudent()

                          this.getCutMealsData(1);
                        }
                      );
                      this.showDate(new Date(year, month - 1));

                      {
                        this.state.chociceClass.value > 0
                          ? this.getStudentbyClass(
                              this.state.chociceClass.value,
                              new Date(year, month - 1),
                              new Date(year, month - 1)
                            )
                          : this.getClassRoom(
                              new Date(year, month - 1),
                              new Date(year, month - 1)
                            );
                      }
                    }}
                    // autoComplete="off"
                  />
                  <Select
                    options={this.state.listClassSelect}
                    defaultValue={this.state.listClassSelect[0]}
                    value={this.state.chociceClass}
                    onChange={(choice) => {
                      this.setState({ chociceClass: choice });
                      this.getStudentbyClass(
                        choice.value,
                        this.state.startMonth,
                        this.state.startMonth
                      );
                      // this.getStudentbyClass(choice.value, this.state.startDate, this.state.endDate)
                    }}
                    styles={this.customStyles}
                  ></Select>
                  <Button
                    variant="success"
                    size="sm"
                    style={{
                      whiteSpace: "nowrap",
                      float: "right",
                      padding: "2px",
                    }}
                    onClick={() => this.handleExport()}
                  >
                    Xuất file <i className="fa-solid fa-file-export ms-1"></i>
                  </Button>
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {this.renderCutMealListTable()}

              {this.renderCutMealListTableExport()}
            </Card.Body>
          </Card>
        </Container>
      </>
    );
  }
}

export default Report;
