import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../layouts/frontend/Navbar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Home() {
  const history = useNavigate();

  useEffect(() => {
    document.title = "Trang chủ";

    //Redirect libk
    if (localStorage.getItem("access_token")) {
      toast("Đăng nhập thành công.", { type: "success", autoClose: 1000 });

      if (localStorage.getItem("version") === "2.0")
        return history("/admin-v2");
      else return history("/admin");
    } else {
      return history("/login");
    }
  }, [history]);

  return (
    <div>
      <Navbar />
    </div>
  );
}

export default Home;
