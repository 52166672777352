import React from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";

const MenuExcelTemplate3 = ({ menuDays, menuTimes, selectedClassGroup, weekInMonth, startDate, termDate }) => {
	const stringFirstDate = (date) => ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2);
	const stringLastDate = (date) => ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();

	return (
		<Table bordered responsive id="menu-export-excel-3" className="d-none">
			<thead>
				<tr>
					{(() => {
						const arr = [];
						for (let i = 0; i < menuDays.length * 2; i++) {
							if (i === 0) {
								arr.push(<th key={i}>{selectedClassGroup.group_name}</th>);
								continue;
							}

							if (i === Math.floor((menuDays.length * 2) / 2) + 1) {
								arr.push(
									<th key={i}>
										Thực đơn Tháng {startDate.getMonth() + 1} (Tuần {weekInMonth}) {stringFirstDate(startDate)} - {stringLastDate(termDate)}
									</th>,
								);
								continue;
							}

							arr.push(<td key={i}>empty</td>);
						}
						return arr;
					})()}
				</tr>
				<tr>
					<th>Buổi</th>
					{menuDays.map((dayItem) => {
						return (
							<React.Fragment key={dayItem.id_day}>
								<th>{dayItem.day_name}</th>
								<th>empty</th>
							</React.Fragment>
						);
					})}
				</tr>
			</thead>

			<tbody>
				{menuTimes.map((timeItem) => {
					const quantitativeTimes = [];

					menuDays.map((quantitativeItem) => {
						return quantitativeItem.times.map((timeObj) => {
							if (timeObj.id_time === timeItem.id_time) {
								quantitativeTimes.push(timeObj);
							}

							return timeObj;
						});
					});

					let checkItemRows = [];

					quantitativeTimes.map((quantitativeTimeItem) => {
						let itemRow = [];

						const filteredFoodDatas = quantitativeTimeItem?.foods.map(foodData => {
							const filteredGroups = foodData.groups.filter(group => group.id === selectedClassGroup.id);
							return { ...foodData, groups: filteredGroups };
						}).filter(foodData => foodData.groups.length > 0);

						filteredFoodDatas.map((foodItem, foodIndex) => {
							if (foodItem.groups.some((groupItem) => groupItem.id === selectedClassGroup.id && groupItem.ingredents.length > 0)) {
								const ingredients = foodItem.groups.find((groupItem) => groupItem.id === selectedClassGroup.id && groupItem.ingredents.length > 0).ingredents;

								itemRow.push({
									id_day: quantitativeTimeItem.id_day,
									id: foodItem.id,
									name: foodItem.food_name,
									keyFood: foodIndex,
									countIngredient: ingredients.length,
								});
							}

							return foodItem;
						});

						checkItemRows.push({
							id: quantitativeTimeItem.id,
							items: itemRow,
						});

						return quantitativeTimeItem;
					});

					let listItemRows = [];

					quantitativeTimes.map((quantitativeTimeItem) => {
						let itemRow = [];
						let countItem = 0;
						let startRowFood = {};
						let countIngredient = {};

						const filteredFoodDatas = quantitativeTimeItem?.foods.map(foodData => {
							const filteredGroups = foodData.groups.filter(group => group.id === selectedClassGroup.id);
							return { ...foodData, groups: filteredGroups };
						}).filter(foodData => foodData.groups.length > 0);

						filteredFoodDatas.map((foodItem, foodIndex) => {
							if (foodItem.groups.some((groupItem) => groupItem.id === selectedClassGroup.id && groupItem.ingredents.length !== 0)) {
								const group = foodItem.groups.find((groupItem) => groupItem.id === selectedClassGroup.id);

								const ingredients = foodItem.groups.find((groupItem) => groupItem.id === selectedClassGroup.id && groupItem.ingredents.length > 0).ingredents;

								let maxItemFoodWeek = 0;

								for (let i = 0; i <= foodIndex; i++) {
									let maxCountIngredient = 1;

									checkItemRows.forEach(row => {
										row.items.forEach(item => {
											if (item.keyFood === i && (item.countIngredient + 1) > maxCountIngredient) {
												maxCountIngredient = (item.countIngredient + 1);
											}
										});
									});

									maxItemFoodWeek += maxCountIngredient;
								}

								let maxItemFoodDay = 1;

								checkItemRows.forEach(row => {
									row.items.forEach(item => {
										if (item.keyFood === foodIndex && (item.countIngredient + 1) > maxItemFoodDay) {
											maxItemFoodDay = (item.countIngredient + 1);
										}
									});
								});

								countIngredient[foodIndex] = ingredients.length;
								startRowFood[foodIndex] = maxItemFoodWeek - maxItemFoodDay + 1; //Tiến tới 1 Vị trí

								countItem = (countIngredient[foodIndex] + startRowFood[foodIndex]) > countItem
									? countIngredient[foodIndex] + startRowFood[foodIndex]
									: countItem;

								let num = 0;
								if (foodIndex > 0) {
									num = startRowFood[foodIndex] - (countIngredient[foodIndex - 1] + 1) - startRowFood[foodIndex - 1];
								}

								if (num > 0) {
									for (let i = 0; i < num; i++) {
										itemRow.push({
											isEmpty: true,
										});
									}
								}

								itemRow.push({
									id: foodItem.id,
									name: foodItem.food_name,
									quantity: 0,
									unit: "",
									isFood: true,
									minItem: maxItemFoodWeek - maxItemFoodDay,
									moreMeal: group.more_meal,
									countIngredient: ingredients.length,
								});

								ingredients.map((ingredientItem) => {
									itemRow.push({
										id: ingredientItem.id,
										name: ingredientItem.ingredent_name,
										quantity: ingredientItem.ing_quantity,
										unit: ingredientItem.unit_cook_name,
										isFood: false,
									});

									return ingredientItem;
								});
							} else if (foodItem.groups.some((groupItem) => groupItem.id === selectedClassGroup.id)) {
								const group = foodItem.groups.find((groupItem) => groupItem.id === selectedClassGroup.id);

								itemRow.push({
									id: foodItem.id,
									name: foodItem.food_name,
									quantity: 0,
									unit: "",
									isFood: true,
									minItem: 1,
									moreMeal: group.more_meal,
									countIngredient: 0,
								});
							}

							return foodItem;
						});

						listItemRows.push({
							id: quantitativeTimeItem.id,
							items: itemRow,
							// length: rowSpanLength !== 0 ? rowSpanLength : 1,
							countItem: countItem
						});

						return quantitativeTimeItem;
					});

					const maxRowSpanLength = Math.max(...listItemRows.map((itemRow) => itemRow.countItem));

					return (
						<React.Fragment key={timeItem.id_time}>
							<tr>
								<th>{timeItem.time_name}</th>

								{listItemRows?.map((listItemRowsItem, index) => {
									if (listItemRowsItem.items[0]?.isFood) {
										let foodName = listItemRowsItem.items[0]?.name + (listItemRowsItem.items[0]?.moreMeal > 0 ? ` (SL: ${listItemRowsItem.items[0]?.moreMeal})` : '');

										return (
											<React.Fragment key={`food-name-first-${index}`}>
												<th>{foodName}</th>
												<td>empty</td>
											</React.Fragment>
										);
									} else {
										return (
											<React.Fragment key={`food-name-first-${index}`}>
												<th>empty</th>
												<td>empty</td>
											</React.Fragment>
										);
									}
								})}
							</tr>

							{(() => {
								const arr = [];
								for (let i = 1; i < maxRowSpanLength; i++) {
									arr.push(
										<tr key={i}>
											<td>empty </td>

											{listItemRows?.map((listItemRowsItem, index) => {
												if (listItemRowsItem.items[i]?.isEmpty) {
													return (
														<React.Fragment key={listItemRowsItem.id}>
															<td>empty</td>
															<td>empty</td>
														</React.Fragment>
													);
												}

												if (listItemRowsItem.items[i]?.isFood) {
													const foodName = listItemRowsItem.items[i]?.name + (listItemRowsItem.items[i]?.moreMeal > 0 ? ` (SL: ${listItemRowsItem.items[i]?.moreMeal})` : '');
													return (
														<React.Fragment key={listItemRowsItem.id}>
															<th>{foodName}</th>
															<td>empty</td>
														</React.Fragment>
													);
												}

												return (
													<React.Fragment key={listItemRowsItem.id}>
														<td>{listItemRowsItem.items[i]?.name || "empty"}</td>
														<td>
															{listItemRowsItem.items[i]?.quantity ? (
																<>
																	<CurrencyFormat
																		value={parseFloat(listItemRowsItem.items[i]?.quantity)}
																		displayType={"text"}
																		thousandSeparator={true}
																		decimalScale={2}
																	/>
																	&nbsp;
																	{listItemRowsItem.items[i]?.unit}
																</>
															) : (
																"empty"
															)}
														</td>
													</React.Fragment>
												);
											})}
										</tr>,
									);
								}

								return arr;
							})()}
						</React.Fragment>
					);
				})
				}
			</tbody>
		</Table>
	);
};

export default MenuExcelTemplate3;
