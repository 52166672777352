import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Button,
  Card,
  Col,
  Form,
  Row,
  InputGroup,
  Container,
  Table,
} from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import api from "../../../helper/axiosInstance";

const hour_ = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

const minute_ = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
];

const moneyForm = [
  {
    id: 1,
    label: "Tiền thu phụ huynh",
    name: "price_meal",
  },
  {
    id: 2,
    label: "Tiền hoàn trả nghỉ học",
    name: "price_return",
  },
  {
    id: 3,
    label: "Tiền chi thực phẩm",
    name: "fee_meal",
  },
  {
    id: 4,
    label: "Chi phí khác",
    name: "fee_other",
  },
];

const errorTextStyle = {
  fontSize: 13,
  color: "red",
  marginTop: 6,
};

const CreateSetting = () => {
  const navigate = useNavigate();

  const [days, setDays] = useState([]);
  const [times, setTimes] = useState([]);
  // const [settings, setSettings] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupSetting, setGroupSetting] = useState([]);
  const [errorMessage, setErrorMessage] = useState({
    turn_latch: "",
    turn_today: "",

    setting_date_start: "",
    setting_date_end: "",

    price_meal: "",
    price_return: "",

    fee_meal: "",
    fee_other: "",

    times: "",
    days: "",
  });

  const [settingForm, setSettingForm] = useState({
    turn_latch: false,
    turn_today: false,
    hour_latch: "0",
    minute_latch: "0",
    setting_date_start: new Date().setHours(0, 0, 0, 0) / 1000,
    setting_date_end: new Date().setHours(0, 0, 0, 0) / 1000,
    price_meal: 0,
    price_return: 0,
    fee_meal: 0,
    fee_other: 0,
    times: [],
    days: [],
    meal_refund: 0,
    tuition_refund: 0,
    holiday_refund: 0,
  });

  useEffect(() => {
    getDays();
    getTimes();
    getGroups();
  }, []);

  const getGroups = async () => {
    await api
      .get("/group-show")
      .then((res) => {
        if (res.data.errCode === 0) {
          setGroups(res.data.groups);
          let data = [];
          res.data.groups?.map((item) => {
            data.push({
              id: item.id,
              price_meal: 0,
              price_return: 0,
              fee_meal: 0,
              fee_other: 0,
            });

            return item;
          });

          setGroupSetting(data);
        }
      })
      .catch((error) => {});
  };
  const getDays = async () => {
    await api
      .get("/getDayOfWeeks")
      .then((res) => {
        if (res.data.errCode === 0) {
          setDays(res.data.days);
        }
      })
      .catch((error) => {});
  };

  const getTimes = async () => {
    await api
      .get("/getTimeOfDays")
      .then((res) => {
        if (res.data.errCode === 0) {
          setTimes(res.data.times);
        }
      })
      .catch((error) => {});
  };

  const createNewSetting = async () => {
    if (settingForm.setting_date_end < settingForm.setting_date_start) {
      toast("Ngày hiệu lực không được lớn hơn ngày kết thúc", {
        type: "error",
        autoClose: 1500,
      });
      return;
    }

    let bodyFormData = new FormData();
    bodyFormData.append("turn_latch", settingForm.turn_latch ? 1 : 0);
    bodyFormData.append("turn_today", settingForm.turn_today ? 1 : 0);
    bodyFormData.append(
      "time_latch",
      `${settingForm.hour_latch}:${settingForm.minute_latch}`
    );
    bodyFormData.append("setting_date_start", settingForm.setting_date_start);
    bodyFormData.append("setting_date_end", settingForm.setting_date_end);
    bodyFormData.append("groups", JSON.stringify(groupSetting));
    bodyFormData.append("times", JSON.stringify(settingForm.times));
    bodyFormData.append("days", JSON.stringify(settingForm.days));
    bodyFormData.append("meal_refund", settingForm.meal_refund || 0);
    bodyFormData.append("tuition_refund", settingForm.tuition_refund || 0);
    bodyFormData.append("holiday_refund", settingForm.holiday_refund || 0);

    setErrorMessage({
      turn_latch: "",
      turn_today: "",

      setting_date_start: "",
      setting_date_end: "",

      price_meal: "",
      price_return: "",

      fee_meal: "",
      fee_other: "",

      times: "",
      days: "",
    });

    await api({
      method: "post",
      url: "setting-create",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });

          navigate("/admin-v2/mainsetting");
        } else {
          setErrorMessage(res.data.message);

          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        setErrorMessage(err.response.data?.messages);
      });
  };
  // API

  return (
    <Container fluid className="px-3 mt-3">
      <Card className="mb-4">
        <Card.Header>
          <Card.Title>
            <i className="fa-solid fa-gears"></i>Thêm mới cài đặt
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row className="mb-2">
            <Card.Title>Thời gian chốt cắt suất</Card.Title>

            <Col xs={12} md={4}>
              <Form.Check
                type="switch"
                label="Kích hoạt"
                className="mb-2"
                checked={settingForm.turn_latch}
                value={settingForm.turn_latch}
                onChange={(e) => {
                  setSettingForm({
                    ...settingForm,
                    turn_latch: e.target.checked,
                  });
                }}
              />

              <Form.Check
                type="switch"
                label="Chốt trong ngày"
                className="mb-2"
                checked={settingForm.turn_today}
                value={settingForm.turn_today}
                onChange={(e) => {
                  setSettingForm({
                    ...settingForm,
                    turn_today: e.target.checked,
                  });
                }}
              />
            </Col>

            <Col xs={6} md={2} className="mb-3">
              <Form.Floating>
                <Form.Select
                  name="time_hour"
                  required
                  value={settingForm.hour_latch}
                  onChange={(e) => {
                    setSettingForm({
                      ...settingForm,
                      hour_latch: e.target.value,
                    });
                  }}
                >
                  {hour_.map((e) => {
                    return (
                      <option key={e} value={e}>
                        {e}
                      </option>
                    );
                  })}
                </Form.Select>
                <label>Giờ</label>
              </Form.Floating>
            </Col>

            <Col xs={6} md={2} className="mb-3">
              <Form.Floating>
                <Form.Select
                  name="time_minute"
                  required
                  value={settingForm.minute_latch}
                  onChange={(e) => {
                    setSettingForm({
                      ...settingForm,
                      minute_latch: e.target.value,
                    });
                  }}
                >
                  {minute_.map((e) => {
                    return (
                      <option key={e} value={e}>
                        {e}
                      </option>
                    );
                  })}
                </Form.Select>
                <label>Phút</label>
              </Form.Floating>
            </Col>

            <Col xs={6} md={2} className="mb-3">
              <Form.Floating>
                <Form.Control
                  type="date"
                  required
                  value={
                    settingForm.setting_date_start !== ""
                      ? moment(settingForm.setting_date_start * 1000).format(
                          "yyyy-MM-DD"
                        )
                      : ""
                  }
                  onChange={(e) => {
                    const dateValue =
                      new Date(e.target.valueAsNumber).setHours(0) / 1000;
                    const startDate = dateValue || "";

                    setSettingForm({
                      ...settingForm,
                      setting_date_start: startDate,
                    });

                    setErrorMessage({
                      ...errorMessage,
                      setting_date_start: "",
                    });
                  }}
                  className={
                    errorMessage.setting_date_start ? "is-invalid" : ""
                  }
                />
                <label>
                  Ngày hiệu lực<i className="text-danger">*</i>
                </label>
              </Form.Floating>

              {errorMessage.setting_date_start && (
                <p style={errorTextStyle}>{errorMessage.setting_date_start}</p>
              )}
            </Col>

            <Col xs={6} md={2} className="mb-3">
              <Form.Floating>
                <Form.Control
                  type="date"
                  required
                  value={
                    settingForm.setting_date_end !== ""
                      ? moment(settingForm.setting_date_end * 1000).format(
                          "yyyy-MM-DD"
                        )
                      : ""
                  }
                  onChange={(e) => {
                    const dateValue =
                      new Date(e.target.valueAsNumber).setHours(23, 59, 59) /
                      1000;
                    const endDate = dateValue || "";

                    setSettingForm({
                      ...settingForm,
                      setting_date_end: endDate,
                    });

                    setErrorMessage({
                      ...errorMessage,
                      setting_date_end: "",
                    });
                  }}
                  className={errorMessage.setting_date_end ? "is-invalid" : ""}
                />
                <label>
                  Ngày kết thúc<i className="text-danger">*</i>
                </label>
              </Form.Floating>

              {errorMessage.setting_date_end && (
                <p style={errorTextStyle}>{errorMessage.setting_date_end}</p>
              )}
            </Col>
          </Row>

          <Row className="mb-2">
            <Col>
              <Card.Title>Thu - Chi trong ngày</Card.Title>
              <Table responsive bordered hover>
                <thead>
                  <tr className="bg-light">
                    <th style={{ minWidth: "7rem" }}>Nhóm </th>
                    <th className="text-center" style={{ minWidth: "7rem" }}>
                      Tiền thu phụ huynh<span className="text-danger">*</span>
                    </th>
                    <th className="text-center" style={{ minWidth: "7rem" }}>
                      Tiền hoàn trả nghỉ học
                      <span className="text-danger">*</span>
                    </th>
                    <th className="text-center" style={{ minWidth: "7rem" }}>
                      Tiền chi thực phẩm<span className="text-danger">*</span>
                    </th>
                    <th className="text-center" style={{ minWidth: "7rem" }}>
                      Chi phí khác<span className="text-danger">*</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {groups?.map((e, i) => {
                    return (
                      <tr key={`group_1_${i}`}>
                        <th className="align-middle">{e.group_name}</th>
                        {moneyForm.map((formItem) => {
                          return (
                            <td key={formItem.id} className="mb-3">
                              <CurrencyFormat
                                className="form-control text-end"
                                thousandSeparator={true}
                                suffix=" ₫"
                                displayType="input"
                                allowNegative={false}
                                required
                                value={
                                  groupSetting?.filter(
                                    (group) => group.id === e.id
                                  )[0]?.[formItem.name] ?? 0
                                }
                                onValueChange={(values) => {
                                  let existsInGroup = groupSetting.some(
                                    (item) => item.id === e.id
                                  );

                                  if (!existsInGroup) {
                                    groupSetting.push({
                                      id: e.id,
                                      price_meal: 0,
                                      price_return: 0,
                                      fee_meal: 0,
                                      fee_other: 0,
                                    });

                                    setGroupSetting(groupSetting);
                                  }

                                  const groupSettingData = groupSetting?.map(
                                    (gr) => {
                                      if (gr.id === e.id) {
                                        const valueInput = values.value || 0;

                                        let price_meal = parseInt(
                                          formItem.name === "price_meal"
                                            ? valueInput
                                            : gr?.price_meal || 0
                                        );

                                        if (
                                          valueInput > price_meal &&
                                          formItem.name !== price_meal
                                        ) {
                                          toast(
                                            `Không được lớn hơn Tiền thu phụ huynh.`,
                                            { type: "error" }
                                          );

                                          let price_return =
                                            formItem.name === "price_return"
                                              ? price_meal
                                              : gr?.price_return || 0;

                                          return {
                                            ...gr,
                                            status: false,
                                          };
                                        } else {
                                          let price_return =
                                            formItem.name === "price_return"
                                              ? valueInput
                                              : gr?.price_return || 0;

                                          let fee_meal =
                                            formItem.name === "fee_meal"
                                              ? valueInput
                                              : gr?.fee_meal || 0;

                                          let fee_other = parseInt(
                                            formItem.name === "fee_other"
                                              ? valueInput
                                              : gr?.fee_other || 0
                                          );

                                          if (formItem.name === "price_meal") {
                                            fee_meal = price_meal - fee_other;

                                            fee_meal =
                                              fee_meal > 0
                                                ? fee_meal
                                                : price_meal;

                                            fee_other = price_meal - fee_meal;

                                            price_return = price_meal;
                                          } else if (
                                            formItem.name === "fee_meal"
                                          ) {
                                            fee_other = price_meal - fee_meal;
                                          } else if (
                                            formItem.name === "fee_other"
                                          ) {
                                            fee_meal = price_meal - fee_other;
                                          }

                                          return {
                                            ...gr,
                                            price_meal,
                                            price_return,
                                            fee_meal,
                                            fee_other,
                                          };
                                        }
                                      }
                                      return gr;
                                    }
                                  );
                                  if (
                                    !groupSettingData.some(
                                      (item) => item.status === false
                                    )
                                  ) {
                                    setGroupSetting(groupSettingData);
                                  }
                                }}
                              />

                              {errorMessage[formItem.name] && (
                                <p style={errorTextStyle}>
                                  {errorMessage[formItem.name]}
                                </p>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className="mb-2">
            <Card.Title>
              Buổi ăn<i className="text-danger">*</i>
            </Card.Title>

            {times.map((timeItem, timeIndex) => {
              const timeChonseIndex = settingForm?.times?.findIndex(
                (day) => day.id === timeItem.id
              );

              return (
                <Col
                  key={`time-key${timeIndex}`}
                  xs={6}
                  sm={4}
                  md={4}
                  xl={"auto"}
                  className="mb-2"
                >
                  <InputGroup>
                    <InputGroup.Checkbox
                      checked={settingForm.times.find(
                        (day) => day.id === timeItem.id
                      )}
                      value={settingForm.times.find(
                        (day) => day.id === timeItem.id
                      )}
                      onChange={(e) => {
                        if (e.target.checked)
                          setSettingForm({
                            ...settingForm,
                            times: [...settingForm.times, timeItem],
                          });
                        else
                          setSettingForm({
                            ...settingForm,
                            times: settingForm.times.filter(
                              (day) => day.id !== timeItem.id
                            ),
                          });

                        setErrorMessage({ ...errorMessage, times: "" });
                      }}
                    />

                    <Form.Control
                      value={
                        settingForm.times.find((day) => day.id === timeItem.id)
                          ?.time_name || timeItem?.time_name
                      }
                      onChange={(e) => {
                        setSettingForm({
                          ...settingForm,
                          times: settingForm.times.map((day) => {
                            if (day.id === timeItem.id) {
                              return {
                                ...day,
                                time_name: e.target.value,
                              };
                            }

                            return day;
                          }),
                        });

                        setErrorMessage({
                          ...errorMessage,
                          [`times[${timeChonseIndex}].time_name`]: "",
                        });
                      }}
                      className={
                        errorMessage[`times[${timeChonseIndex}].time_name`]
                          ? "is-invalid"
                          : ""
                      }
                      disabled={
                        settingForm.times.some((day) => day.id === timeItem.id)
                          ? false
                          : true
                      }
                    />
                  </InputGroup>
                  <p style={errorTextStyle}>
                    {errorMessage[`times[${timeChonseIndex}].time_name`]}
                  </p>
                </Col>
              );
            })}

            {errorMessage.times && (
              <p style={errorTextStyle}>{errorMessage.times}</p>
            )}
          </Row>

          <Row className="mb-4 justify-content-start">
            <Card.Title>
              Ngày học<i className="text-danger">*</i>
            </Card.Title>

            {days.map((dayItem) => {
              return (
                <Col key={dayItem.id} xs={6} sm={4} md={"auto"}>
                  <Form.Check
                    type="checkbox"
                    label={dayItem.day_name}
                    className="me-2"
                    checked={settingForm.days.find(
                      (day) => day.id === dayItem.id
                    )}
                    value={settingForm.days.find(
                      (day) => day.id === dayItem.id
                    )}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSettingForm({
                          ...settingForm,
                          days: [...settingForm.days, dayItem],
                        });
                      } else {
                        setSettingForm({
                          ...settingForm,
                          days: settingForm.days.filter(
                            (day) => day.id !== dayItem.id
                          ),
                        });
                      }

                      setErrorMessage({ ...errorMessage, days: "" });
                    }}
                  />
                </Col>
              );
            })}

            {errorMessage.days && (
              <p style={errorTextStyle}>{errorMessage.days}</p>
            )}
          </Row>

          <Row className="mb-2">
            <Card.Title>
              Cài đặt phiếu tính tiền<i className="text-danger">*</i>
            </Card.Title>
            <Col xs={6} md={3}>
              <Form.Check
                type="switch"
                label="Hoàn tiền ăn tháng trước"
                checked={settingForm.meal_refund}
                value={settingForm.meal_refund}
                onChange={(e) =>
                  setSettingForm({
                    ...settingForm,
                    meal_refund: e.target.checked ? 1 : 0,
                  })
                }
              />
            </Col>

            <Col xs={6} md={3}>
              <Form.Check
                type="switch"
                label="Thu tiền học thêm"
                checked={settingForm.tuition_refund === 1 ? true : false}
                value={settingForm.tuition_refund}
                onChange={(e) =>
                  setSettingForm({
                    ...settingForm,
                    tuition_refund: e.target.checked ? 1 : 0,
                  })
                }
              />
            </Col>

            <Col xs={6} md={3}>
              <Form.Check
                type="switch"
                label="Hoàn tiền nghỉ lễ"
                checked={settingForm.holiday_refund === 1 ? true : false}
                value={settingForm.holiday_refund}
                onChange={(e) =>
                  setSettingForm({
                    ...settingForm,
                    holiday_refund: e.target.checked ? 1 : 0,
                  })
                }
              />
            </Col>
          </Row>
        </Card.Body>

        <Card.Footer className="text-end">
          <Button
            variant="success"
            onClick={() => createNewSetting()}
            className="px-3 ms-3 float-end"
          >
            <i className="fas fa-check"></i> Lưu
          </Button>

          <Button
            variant="secondary"
            onClick={() => navigate("/admin-v2/mainsetting")}
            className="px-3 float-end"
          >
            <i className="fas fa-arrow-left"></i> Quay lại
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default CreateSetting;
