import React, { Component } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../other/Check";
import swal from "sweetalert";
import api from "../../../helper/axiosInstance";

class RoleEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permissions: this.props?.permissions || [],
      dataInput: {
        id: "",
        is_rank: "",
        role_name: "",
        ids_permission: [],
      },
      errMessages: {},
    };
  }

  componentDidMount() {
    this.getRole();
  }

  getRole = async () => {
    await api
      .get("role-show", {
        params: { id: this.props.roleId },
      })
      .then((res) => {
        const role = res.data.data;
        const dataInput = {
          id: role.id,
          is_rank: role.is_rank,
          role_name: role.role_name,
          ids_permission: role.permissions.map((item) => item.id),
        };
        console.log(role);
        this.setState({ dataInput: dataInput });
      })
      .catch((error) => {});
  };

  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataInput };
    dataInput[field] = value;
    this.setState({
      dataInput: dataInput,
      errMessages: { ...this.state.errMessages, [field]: "" },
    });
  };

  hanldeCheck = (e) => {
    const { value, checked } = e.target;

    let dataInput = { ...this.state.dataInput };

    dataInput.ids_permission = [...dataInput.ids_permission, Number(value)];

    if (!checked) {
      dataInput.ids_permission = dataInput.ids_permission.filter(
        (item) => item !== Number(value)
      );
    }

    const permissions = this.state.permissions;
    const permission_parent = permissions.find((permission) =>
      permission.children.some((child) => child.id === Number(value))
    );
    const isCheckAllChildren = permission_parent.children.every((item) =>
      dataInput.ids_permission.includes(item.id)
    );
    if (isCheckAllChildren)
      dataInput.ids_permission = [
        ...dataInput.ids_permission,
        permission_parent.id,
      ];
    else
      dataInput.ids_permission = dataInput.ids_permission.filter(
        (item) => item !== permission_parent.id
      );

    this.setState({ dataInput: dataInput });
  };

  handleSelectGroup = (e) => {
    const { value, checked } = e.target;
    let dataInput = { ...this.state.dataInput };

    const permissions = this.state.permissions;

    let idChildArr = [
      ...permissions
        .find((item) => Number(item.id) == Number(value))
        ?.children.map((itemChild) => itemChild.id),
      Number(value),
    ];

    if (idChildArr.length > 0) {
      const ids_permission = [
        ...new Set([...dataInput.ids_permission, ...idChildArr]),
      ];

      dataInput.ids_permission = ids_permission;
      this.setState({ dataInput: dataInput });

      if (!checked) {
        idChildArr.map((id, i) => {
          dataInput.ids_permission = dataInput.ids_permission.filter(
            (item) => item !== id
          );

          this.setState({ dataInput: dataInput });

          return id;
        });
      }
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    swal({
      title: "Bạn chắc muốn cập nhật?",
      text: `**Lưu ý: Những người dùng có chức vụ "${this.state.dataInput.role_name}" sẽ bị đăng xuất.`,
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        const role = this.state.dataInput;
        const data = {
          role_name: role.role_name,
          id: this.props.roleId,
          ids_permission: [...new Set(role.ids_permission)],
        };

        await api
          .put("role-update", data)
          .then((res) => {
            toast(`${res.data.messages}`, { type: "success" });
            this.props.onSubmit();
          })
          .catch((error) => {
            this.setState({ errMessages: error.response.data?.messages });
          });
      }
    });
  };

  handleHide = () => {
    return this.props.onHide();
  };

  renderPermission = () => {
    return (
      <>
        <Modal.Title>Quyền truy cập</Modal.Title>
        <span className="text-danger">
          {this.state.errMessages?.ids_permission}
        </span>

        <Row style={{ maxHeight: "468px", overflow: "auto" }}>
          {this.state.permissions.length > 0
            ? this.state.permissions.map((item, index) => {
                let perChild = [];
                let idPerChild = [];
                const checkedParent =
                  this.state.dataInput.ids_permission.includes(item.id);

                if (item.children.length > 0) {
                  let n = 0;
                  item.children.map((itemChild, i) => {
                    idPerChild.push(String(itemChild.id));

                    const checkedChild =
                      this.state.dataInput.ids_permission.includes(
                        itemChild.id
                      );

                    n = checkedChild ? n + 1 : n;

                    perChild.push(
                      <Form.Check
                        key={"per-child-" + itemChild.id}
                        className="ms-4"
                        type="checkbox"
                        label={itemChild.description}
                        name="permission"
                        value={itemChild.id}
                        onChange={this.hanldeCheck}
                        checked={checkedChild}
                      />
                    );

                    return itemChild;
                  });
                }

                return (
                  <Col md={2} className="mt-1" key={"per-parent-" + item.id}>
                    <Form.Check
                      type="checkbox"
                      label={item.description}
                      value={item.id}
                      onChange={this.handleSelectGroup}
                      checked={checkedParent}
                      className="fw-bold"
                    />

                    {perChild}
                  </Col>
                );
              })
            : null}
        </Row>
      </>
    );
  };

  render() {
    const { onHide, ...other } = { ...this.props };
    return (
      <>
        <Modal
          show={other.show}
          onHide={onHide}
          size="xl"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Chi tiết chức vụ</Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col md={4}>
                  <Form.Floating className="mb-1">
                    <Form.Control
                      type="text"
                      name="role_name"
                      defaultValue={this.state.dataInput?.role_name || ""}
                      placeholder="Tên chức vụ"
                      onChange={this.handleInput}
                      className={
                        this.state.errMessages?.role_name ? "is-invalid" : ""
                      }
                    />
                    <Form.Label>
                      Tên chức vụ<i className="text-danger">*</i>
                    </Form.Label>
                  </Form.Floating>
                  <span className="text-danger">
                    {this.state.errMessages?.role_name}
                  </span>
                </Col>
              </Row>
              <hr></hr>
              {this.renderPermission()}
            </Modal.Body>

            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddIngredent"
                onClick={this.handleHide}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              {new Check().permission(["63", "64"], "or") &&
              this.state.dataInput?.is_rank !== 1 ? (
                <Button
                  size="sm"
                  variant="success"
                  type="submit"
                  disabled={!new Check().permission(["63", "64"])}
                >
                  <i className="fa-solid fa-check" />
                  Cập nhật
                </Button>
              ) : (
                <></>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default RoleEditModal;
