import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Modal, Button, Form, Table, Row, Col } from "react-bootstrap";
import AddChildUnit from "../unit/UnitAdd";
import SupplierFormModal2 from "../Supplier/SupplierFormModal";
import CurrencyFormat from "react-currency-format";
import Check from "../../admin/other/Check";
import api from "../../../helper/axiosInstance";

class IngredentModalFromBank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childUnitModalShow: false,
      modalSuppliershow: false,
      dataInput: {
        price: 0,
        id_supplier: "",
      },
      data: this.props.data,
      suppliers: [],
      err: {},
    };
  }

  componentDidMount() {
    this.getSuppliersData();
  }
  getSuppliersData = () => {
    api
      .post(`/viewSupplier?limit=${100}`)
      .then((res) => {
        if (res.data.status === true) {
          const arrayUniqueByKey = [
            ...new Map(
              res.data.data.map((item) => [item["id"], item])
            ).values(),
          ];

          this.setState({ suppliers: arrayUniqueByKey });
        }
      })
      .catch((err) => {});
  };
  dataSuppliers() {
    if (this.state.suppliers.length > 0) {
      return this.state.suppliers.map((item, i) => {
        return (
          <option
            key={item.id}
            value={item.id}
            supplier_name={item.supplier_name}
            address={item.supplier_address}
          >
            {" "}
            {item.supplier_name}{" "}
          </option>
        );
      });
    }
  }
  handleHide = () => {
    return this.props.onHide();
  };

  addIngredent() {
    let data = {
      ingredent_code_arr: JSON.stringify(
        this.state.data?.map((e) => e.ingredent_code)
      ),
      ing_price_expect: this.state.dataInput?.price,
      id_supplier: this.state.dataInput?.id_supplier,
    };
    api({
      method: "post",
      url: "ingredent-create-from-bank",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data.errCode === 0) {
          this.setState({ bntAdd: false });

          toast("Thêm thành công", { type: "success" });

          this.setState({
            dataInput: {},
          });
          this.handleHide();
        } else {
          this.setState({ bntAdd: false });
          this.setState({ err: res.data.message });
          // toast("Thêm thất bại", { type: "error" });
        }
      })
      .catch((err) => this.setState({ bntAdd: false }));
  }
  render() {
    return (
      <>
        <Modal
          {...this.props}
          size="lg"
          backdrop="static"
          keyboard={false}
          id="addIngredentModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa-solid fa-carrot"></i> Thêm nguyên liệu
            </Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  <Col>
                    <br></br>
                    <Form.Floating>
                      <CurrencyFormat
                        className="form-control mb-3 text-end"
                        name="price"
                        thousandSeparator={true}
                        placeholder="Giá nhập"
                        required
                        value={this.state.dataInput.price || 0}
                        onValueChange={(values) => {
                          const { value } = values;
                          let dataInput = { ...this.state.dataInput };
                          dataInput.price = value;
                          this.setState({ dataInput: dataInput });
                          if (this.state.err) delete this.state.err["price"];
                        }}
                      />
                      <Form.Label>Giá tạm thời (VND)</Form.Label>
                      <span className="text-form-err">
                        {this.state.err?.price}
                      </span>
                    </Form.Floating>
                  </Col>
                </Col>
                <Col>
                  {new Check().permission(["24"]) ? (
                    <Form.Group className="text-end">
                      <Link
                        className="fst-italic"
                        onClick={() =>
                          this.setState({ modalSuppliershow: true })
                        }
                      >
                        {" "}
                        +Thêm nhà cung cấp
                      </Link>
                    </Form.Group>
                  ) : null}
                  <Form.Floating className="mb-3">
                    <Form.Select
                      value={this.state.dataInput.id_supplier}
                      onChange={(e) => {
                        let dataInput = { ...this.state.dataInput };
                        dataInput.id_supplier = e.target.value;
                        this.setState({ dataInput: dataInput });
                        if (this.state.err)
                          delete this.state.err["id_supplier"];
                      }}
                      name="id_supplier"
                      // required
                    >
                      <option value="">--Chọn--</option>
                      {this.dataSuppliers()}
                    </Form.Select>
                    <Form.Label>
                      Nhà cung cấp<i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err?.id_supplier}
                    </span>
                  </Form.Floating>
                </Col>
              </Row>
              <Table size="lg" bordered>
                <thead>
                  <tr className="align-middle text-center">
                    <th>STT</th>
                    <th>Mã nguyên liệu</th>
                    <th>Tên nguyên liệu</th>
                    <th>Phương thức</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data?.length > 0 ? (
                    this.state.data?.map((ingredent, index) => {
                      return (
                        <React.Fragment key={ingredent.ingredent_code}>
                          <tr className="align-middle">
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">
                              {ingredent.ingredent_code}
                            </td>
                            <td>{ingredent.ingredent_name}</td>

                            <td className="text-center">
                              <Button
                                size="sm"
                                variant="outline-danger"
                                type="button"
                                onClick={() => {
                                  this.setState({
                                    data: this.state.data?.filter(
                                      (e) =>
                                        e.ingredent_code !==
                                        ingredent.ingredent_code
                                    ),
                                  });
                                }}
                                title="Xóa"
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            </td>
                          </tr>

                          {this.state.err[`ingredent_code_arr.${index}`] && (
                            <tr
                              style={{
                                fontSize: 13,
                                color: "red",
                                marginTop: 6,
                              }}
                            >
                              <td colSpan={6}>
                                {this.state.err[`ingredent_code_arr.${index}`]}
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <p className="text-center">Không có dữ liệu...</p>
                  )}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={this.handleHide}>
                <i className="fa-solid fa-times"></i> Đóng
              </Button>
              <Button
                size="sm"
                variant="success"
                onClick={() => this.addIngredent()}
                disabled={this.state.data?.length < 1}
              >
                <i className="fa-solid fa-check"></i> Lưu
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        {this.state.childUnitModalShow ? (
          <AddChildUnit
            show={this.state.childUnitModalShow}
            onHide={this.handleChildModalClose}
            onSubmit={this.handleChildModalClose}
            units={this.state.unitOptions}
          />
        ) : (
          ""
        )}

        {this.state.modalSuppliershow ? (
          <SupplierFormModal2
            show={this.state.modalSuppliershow}
            onHide={() => this.setState({ modalSuppliershow: false })}
            modal={{
              title: "Thêm nhà cung cấp",
              button: (
                <>
                  <i className="fa-solid fa-check" /> Lưu
                </>
              ),
            }}
            data={{
              id: "",
              supplier_name: "",
              supplier_phone: "",
              supplier_address: "",
              supplier_author: "",
              supplier_shipper: "",
            }}
            submit={() => {
              this.setState({ modalSuppliershow: false });
              this.getSuppliersData();
            }}
          />
        ) : null}
      </>
    );
  }
}

export default IngredentModalFromBank;
