import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx-js-style";
import DatePicker from "react-datepicker";
import { vi } from "date-fns/locale";
import { startOfWeek, endOfWeek } from "date-fns";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import swal from "sweetalert";
import QuantitativeKitchenTable from "./QuantitativeKitchenTable";
import { dateFormatter } from "../../utils/helpers";
import {
  exportQuantitativeKitchen,
  exportWeekQuantitativeKitchen,
} from "./exportFunctions";
import WeekQuantitativeKitchenTable from "./WeekQuantitativeKitchenTable";
import Check from "../../other/Check";
import api from "../../../../helper/axiosInstance";

const CustomInput = ({ value, onClick }) => (
  <Button variant="primary" onClick={onClick}>
    <i className="fa-solid fa-calendar" />
  </Button>
);

const QuantitativeKitchen = () => {
  const [school, setSchool] = useState({});

  const [listQuantitatives, setListQuantitatives] = useState([]);
  const [quantitative, setQuantitative] = useState({
    menu_date: new Date().getTime() / 1000,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [classGroups, setClassGroups] = useState([]);
  const [selectedClassGroup, setSelectedClassGroups] = useState({});

  const [listDays, setListDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState({});
  const [listTimes, setListTimes] = useState([]);

  const [weekInMonth, setWeekInMonth] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [groupExcel, setGroupExcel] = useState([]);

  useEffect(() => {
    document.title = "Định lượng cho bếp";

    let initialWeek = setDatesOfWeek(new Date());
    setWeekInMonth((0 | (initialWeek[0].getDate() / 7)) + 1);

    let todayIs = new Date().getDay();
    if (todayIs === 0) {
      todayIs = 7;
    }

    getListQuantitatives(initialWeek[0], initialWeek[6], todayIs);
    getListClassGroups();
    getDetailschool();
  }, []);

  //#region  API
  const getListQuantitatives = async (startDate, endDate, dayId) => {
    setIsLoading(true);
    await api
      .get(`menu-show`, {
        params: {
          start_at: startDate.getTime() / 1000,
          term_at: endDate.getTime() / 1000,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setGroupExcel(res.data.groups);
          setListDays(
            res.data.days.map((dayItem) => {
              return {
                id_day: dayItem.id_day,
                day_name: dayItem.day_name,
                date: new Date(dayItem.menu_date * 1000),
              };
            })
          );
          setListTimes(res.data.times);

          setSelectedDay(
            res.data.days.find(
              (listQuantitativesItem) => listQuantitativesItem.id_day === dayId
            )
              ? res.data.days.find(
                  (listQuantitativesItem) =>
                    listQuantitativesItem.id_day === dayId
                )?.id_day
              : res.data.days[0].id_day
          );
          setListQuantitatives(res.data.days);
          setQuantitative(
            res.data.days.find(
              (listQuantitativesItem) => listQuantitativesItem.id_day === dayId
            )
              ? res.data.days.find(
                  (listQuantitativesItem) =>
                    listQuantitativesItem.id_day === dayId
                )
              : res.data.days[0]
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const getListClassGroups = async () => {
    await api
      .get(`/group-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setClassGroups(res.data.groups);
          setSelectedClassGroups(res.data.groups[0]);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const getDetailschool = async () => {
    await api
      .get(`/detailSchool`)
      .then((res) => {
        if (res.data.status === true) {
          setSchool(res.data.data[0]);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };
  // #endregion

  //#region Logic
  const exportQuantitativeExcel = () => {
    const wb = XLSX.utils.book_new();

    let ingredientQuantity = 0;

    quantitative.times?.map((timeItem) => {
      let timeQuantityIngredient = 0;
      if (timeItem.foods.length !== 0) {
        timeItem.foods?.map((foodItem) => {
          if (foodItem.groups[0].ingredents.length === 0) {
            timeQuantityIngredient++;

            return foodItem;
          }

          return foodItem.groups[0]?.ingredents?.map((ingredientItem) => {
            timeQuantityIngredient++;
            return ingredientItem;
          });
        });
      } else {
        timeQuantityIngredient = 1;
      }

      ingredientQuantity += timeQuantityIngredient;
      return timeItem;
    });

    const ws = exportQuantitativeKitchen(
      quantitative,
      ingredientQuantity,
      groupExcel
    );

    XLSX.utils.book_append_sheet(wb, ws, "Định lượng cho bếp");
    XLSX.writeFile(
      wb,
      `Định lượng cho bếp (${dateFormatter(
        new Date(quantitative.menu_date * 1000)
      )}).xlsx`
    );
  };

  const exportWeekQuantitativeExcel = () => {
    const wb = XLSX.utils.book_new();

    const ws = exportWeekQuantitativeKitchen(
      listDays.length,
      listTimes,
      listQuantitatives,
      selectedClassGroup
    );

    XLSX.utils.book_append_sheet(wb, ws, "Định lượng cho bếp");

    XLSX.writeFile(
      wb,
      `Định lượng cho bếp (${dateFormatter(startDate)} - ${dateFormatter(
        endDate
      )}).xlsx`
    );

    setModalShow(false);
  };

  const handleXportQuantitativeKitchen = () => {
    swal({
      title: `Bạn muốn xuất file định lượng cho bếp`,
      text: `Ngày ${dateFormatter(new Date(quantitative.menu_date * 1000))}`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        exportQuantitativeExcel();
      }
    });
  };

  const handleXportWeekQuantitativeKitchen = () => {
    exportWeekQuantitativeExcel();
  };

  const datesOfWeek = (current) => {
    var week = [];
    // Starting Monday not Sunday
    current.setDate(current.getDate() - current.getDay() + 1);
    current.setHours(0, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
      current.setHours(0, 0, 0, 0);
    }
    return week;
  };

  const setDatesOfWeek = (theDate) => {
    const week = datesOfWeek(theDate);

    setStartDate(week[0]);
    setEndDate(week[week.length - 1]);
    return week;
  };
  // #endregion

  // Render
  const RenderOption = () => {
    return (
      <section className="mb-2">
        <Row>
          {listDays.map((dayItem) => {
            return (
              <Col key={dayItem.id_day} md="auto" xs={4} className="d-flex">
                <Form.Check
                  type="radio"
                  value={dayItem.id_day}
                  checked={selectedDay === dayItem.id_day}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedDay(Number(e.target.value));
                      setQuantitative(
                        listQuantitatives.find(
                          (quantitativeItem) =>
                            quantitativeItem.id_day === Number(e.target.value)
                        )
                      );
                    }
                  }}
                  name="day"
                  className="me-1"
                />
                {dayItem.day_name}
              </Col>
            );
          })}
        </Row>
      </section>
    );
  };

  const exportStore = (id) => {
    let data = {
      id_menu_day: id,
    };

    api({
      method: "post",
      url: "store-export-kitchen",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
        } else {
          toast(res.data.message, { type: "error", autoClose: 1000 });
        }
      })
      .catch((error) => {
        toast(error.response.data.messages, {
          type: "error",
          autoClose: 1000,
        });
      });
  };
  return (
    <Container fluid id="quantitative-kitchen">
      <Breadcrumb>
        <Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
        <Breadcrumb.Item active>Định lượng cho bếp</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block d-md-flex align-items-center">
              <Card.Title className="me-2">
                Định lượng cho bếp{" "}
                {dateFormatter(new Date(quantitative.menu_date * 1000))}
              </Card.Title>

              {/* Select Week */}
              <div
                className="me-2 d-flex align-items-center mt-2 mt-md-0"
                style={{ zIndex: "1021" }}
              >
                <DatePicker
                  disabled={isLoading}
                  selected={
                    listDays?.length > 0
                      ? new Date(listDays[0]?.date)
                      : new Date()
                  }
                  startDate={
                    listDays?.length > 0
                      ? new Date(listDays[0]?.date)
                      : new Date()
                  }
                  endDate={
                    listDays?.length > 0
                      ? new Date(listDays[listDays.length - 1]?.date)
                      : new Date()
                  }
                  onChange={(date) => {
                    const startDateGMT = startOfWeek(date, { weekStartsOn: 1 });
                    const endDateGMT = startOfWeek(
                      endOfWeek(date, { weekStartsOn: 1 })
                    );
                    setWeekInMonth(
                      (0 | (new Date(startDateGMT).getDate() / 7)) + 1
                    );
                    setDatesOfWeek(new Date(startDateGMT));
                    getListQuantitatives(
                      new Date(startDateGMT),
                      new Date(endDateGMT),
                      selectedDay
                    );
                  }}
                  showWeekNumbers
                  dateFormat="yyyy-MM-dd"
                  calendarClassName="custom-calendar"
                  customInput={<CustomInput />}
                  locale={vi}
                />
              </div>
            </div>

            <div className="d-flex mt-2 mt-md-0">
              <Button
                size="sm"
                variant="success"
                onClick={() => setModalShow(true)}
                className="me-2"
                disabled={isLoading}
              >
                <i className="fa-solid fa-file-export" /> Xuất file tuần
              </Button>

              <Button
                size="sm"
                variant="success"
                onClick={() => handleXportQuantitativeKitchen()}
                className="me-2"
                disabled={isLoading}
              >
                <i className="fa-solid fa-file-export" /> Xuất file ngày
              </Button>

              {new Check().permission(["48"]) ? (
                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => {
                    swal({
                      title: `Bạn muốn xuất kho cho bếp`,
                      text: `Ngày ${dateFormatter(
                        new Date(quantitative.menu_date * 1000)
                      )}`,
                      icon: "info",
                      buttons: ["Đóng", "Xuất"],
                      successMode: true,
                    }).then((ok) => {
                      if (ok) {
                        exportStore(
                          listQuantitatives.find(
                            (quantitativeItem) =>
                              quantitativeItem.id_day === Number(selectedDay)
                          )?.id
                        );
                      }
                    });
                  }}
                  disabled={isLoading}
                >
                  <i className="fa-solid fa-arrow-up-from-water-pump" /> Xuất
                  kho cho bếp
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Card.Header>

        <Card.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Đang tải...</span>
              </Spinner>
            </div>
          ) : (
            <Row>
              {RenderOption()}

              <QuantitativeKitchenTable quantitative={quantitative} />

              {/* {modalShow ? ( */}
              <WeekQuantitativeKitchenTable
                school={school}
                weekInMonth={weekInMonth}
                startDate={startDate}
                endDate={endDate}
                listQuantitatives={listQuantitatives}
                listDays={listDays}
                listTimes={listTimes}
                classGroups={classGroups}
                selectedClassGroup={selectedClassGroup}
                setSelectedClassGroups={setSelectedClassGroups}
                onHide={() => setModalShow(false)}
                show={modalShow}
                handleExport={handleXportWeekQuantitativeKitchen}
              />
              {/* ) : (
								""
							)} */}
            </Row>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default QuantitativeKitchen;
