import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { Modal, Form, Button, Row, Col, Table } from "react-bootstrap";
import { convertTimeToTimestamp } from "../../../../helper/times";
import api from "../../../../helper/axiosInstance";

const dummySchedule = {
  id_day: 0,
  start_at: "",
  end_at: "",
  error: "",
};

const StudyGroupAdd = ({ show, onHide, listTeachers, listSubjects }) => {
  const [studyGroupDetail, setStudyGroupDetail] = useState({
    code: "",
    name: "",
    id_teacher: "",
    id_subject: "",
    start_date: "",
    end_date: "",
    lesson_time: "",
    lesson_number: "",
    is_status: 1,
  });

  const [errorMessage, setErrorMessage] = useState({
    code: "",
    name: "",
    id_teacher: "",
    id_subject: "",
    start_date: "",
    end_date: "",
    lesson_time: "",
    lesson_number: "",
    timetables: [],
  });

  const [schedules, setSchedules] = useState([dummySchedule]);
  const [schoolDayOptions, setSchoolDayOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getShoolDayOptions();
  }, []);

  //#region API
  const getShoolDayOptions = async () => {
    setIsLoading(true);
    await api
      .get(`/getDayOfWeeks`)
      .then((res) => {
        setIsLoading(false);
        if (res.data.errCode === 0) {
          setSchoolDayOptions(res.data.days);
        } else {
          toast(res.data.message, { type: "error" });
        }

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const createNewStudyGroup = async () => {
    setErrorMessage({
      code: "",
      name: "",
      id_teacher: "",
      id_subject: "",
      start_date: "",
      end_date: "",
      lesson_time: "",
      lesson_number: "",
    });
    setIsLoading(true);

    await api({
      method: "post",
      url: "study-group-create",
      data: {
        ...studyGroupDetail,
        start_date: new Date(studyGroupDetail.start_date) / 1000,
        end_date: new Date(studyGroupDetail.end_date) / 1000,
        lesson_time:
          studyGroupDetail.lesson_time?.length > 0
            ? studyGroupDetail.lesson_time
            : null,
        lesson_number:
          studyGroupDetail.lesson_number?.length > 0
            ? studyGroupDetail.lesson_number
            : null,
        timetables: schedules.filter((schedule) => schedule.id_day !== 0),
      },
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        setIsLoading(false);
        toast(res.data.messages, { type: "success" });
        onHide(true);
      })
      .catch((err) => {
        setIsLoading(false);
        toast("Thêm thất bại", { type: "error" });

        setErrorMessage(err.response.data.messages);
      });
  };
  //#endregion API

  //#region Logic
  const checkInput = () => {
    let hasError = false;
    const errorMessage = {
      code: "",
      name: "",
      id_teacher: "",
      id_subject: "",
      start_date: "",
      end_date: "",
      lesson_time: "",
      lesson_number: "",
    };

    // Thông tin cơ bản
    if (studyGroupDetail.code.length === 0) {
      hasError = true;
      errorMessage.code = "Không được để trống";
    }
    if (studyGroupDetail.code.length > 100) {
      hasError = true;
      errorMessage.code = "Không quá 100 ký tự";
    }

    if (studyGroupDetail.name.length === 0) {
      hasError = true;
      errorMessage.name = "Không được để trống";
    }
    if (studyGroupDetail.name.length > 100) {
      hasError = true;
      errorMessage.name = "Không quá 100 ký tự";
    }

    if (studyGroupDetail.id_teacher.length === 0) {
      hasError = true;
      errorMessage.id_teacher = "Không được để trống";
    }

    if (studyGroupDetail.id_subject.length === 0) {
      hasError = true;
      errorMessage.id_subject = "Không được để trống";
    }

    if (studyGroupDetail.start_date.length === 0) {
      hasError = true;
      errorMessage.start_date = "Không được để trống";
    }

    if (studyGroupDetail.end_date.length === 0) {
      hasError = true;
      errorMessage.end_date = "Không được để trống";
    }
    if (
      Date.parse(studyGroupDetail.end_date) <
      Date.parse(studyGroupDetail.start_date)
    ) {
      hasError = true;
      errorMessage.end_date = "Không trước ngày bắt đầu";
    }

    const numLessonTime = Number(studyGroupDetail.lesson_time);
    if (
      numLessonTime < 0 ||
      numLessonTime > 10000 ||
      isNaN(numLessonTime) ||
      !Number.isInteger(numLessonTime)
    ) {
      hasError = true;
      errorMessage.lesson_time =
        "Vui lòng nhập số nguyên trong khoảng 0 đến 10000";
    }

    const numLessonNumber = Number(studyGroupDetail.lesson_number);
    if (
      numLessonNumber < 0 ||
      numLessonNumber > 10000 ||
      isNaN(numLessonNumber) ||
      !Number.isInteger(numLessonNumber)
    ) {
      hasError = true;
      errorMessage.lesson_number =
        "Vui lòng nhập số nguyên trong khoảng 0 đến 10000";
    }

    // Lịch học
    const schedulesValidated = schedules.map((schedule, index) => {
      // Không chọn ngày học thì bỏ qua
      if (schedule.id_day === 0) {
        return {
          ...schedule,
          error: "",
        };
      }
      // Nhập thiếu
      if (!(schedule.start_at || schedule.end_at)) {
        hasError = true;
        return {
          ...schedule,
          error: "Giờ bắt đầu, giờ kết thúc không được để trống",
        };
      }
      // Giờ bắt đầu trước giờ kết thúc
      const startTime = convertTimeToTimestamp(schedule.start_at);
      const endTime = convertTimeToTimestamp(schedule.end_at);

      if (startTime > endTime) {
        hasError = true;
        return {
          ...schedule,
          error: "Giờ kết thúc không thể trước giờ bắt đầu",
        };
      }
      // Giờ học bị trùng
      let i;
      for (i = 0; i < index; i++) {
        if (schedule.id_day != schedules[i].id_day) {
          continue;
        }
        const endTimeI = Date.parse(`01/01/2024 ${schedules[i].end_at}`);
        if (startTime < endTimeI) {
          return {
            ...schedule,
            error: `Giờ học bị trùng với STT ${i + 1}`,
          };
        }
      }
      // Không có lỗi
      return {
        ...schedule,
        error: "",
      };
    });

    setSchedules(schedulesValidated);
    if (hasError) {
      setErrorMessage(errorMessage);
    } else {
      createNewStudyGroup();
    }
  };

  const removeSchedule = (index) => {
    const newSchedules = [...schedules];
    newSchedules.splice(index, 1);
    setSchedules(newSchedules);
  };
  //#endregion Logic

  //#region Render
  const RenderScheduleTable = () => {
    return (
      <div style={{ overflowX: "scroll" }}>
        <Table size="lg" bordered hover>
          <thead className="bg-light">
            <tr className="align-middle text-center">
              <th>STT</th>
              <th>Ngày học</th>
              <th>Giờ bắt đầu</th>
              <th>Giờ kết thúc</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{RenderSchedules()}</tbody>
        </Table>
      </div>
    );
  };

  const RenderSchedules = () => {
    return schedules?.map((scheduleItem, index) => {
      return (
        <>
          <tr key={index} className="align-middle text-center">
            <td>{index + 1}</td>
            <td>
              <Select
                options={schoolDayOptions}
                className="text-start"
                getOptionLabel={(option) => option.day_name}
                getOptionValue={(option) => option.id}
                placeholder={<>--Chọn--</>}
                value={
                  schoolDayOptions.find(
                    (shoolDay) => shoolDay.id === scheduleItem.id_day
                  ) || "--Chọn--"
                }
                onChange={(choice) => {
                  let newSchedules = schedules.map((item, i) => {
                    if (i === index) {
                      return {
                        ...scheduleItem,
                        id_day: choice.id,
                      };
                    }
                    return item;
                  });

                  if (scheduleItem.id_day === 0) {
                    newSchedules.push(dummySchedule);
                  }

                  setSchedules(newSchedules);
                }}
                menuPortalTarget={document.querySelector("body")}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                    minWidth: "150px",
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    minWidth: "150px",
                  }),
                }}
              />
            </td>
            <td>
              <Form.Control
                type="time"
                className="text-end"
                value={scheduleItem.start_at}
                onChange={(e) => {
                  let newSchedules = schedules.map((item, i) => {
                    if (i === index) {
                      return {
                        ...scheduleItem,
                        start_at: e.target.value,
                      };
                    }
                    return item;
                  });

                  setSchedules(newSchedules);

                  setErrorMessage({
                    ...errorMessage,
                    [`timetables[${index}].start_at`]: "",
                  });
                }}
                placeholder="Giờ bắt đầu"
                disabled={scheduleItem.id_day === 0}
              />

              <i className="text-danger">
                {errorMessage[`timetables[${index}].start_at`]}
              </i>
            </td>
            <td>
              <Form.Control
                type="time"
                className="text-end"
                value={scheduleItem.end_at}
                onChange={(e) => {
                  let newSchedules = schedules.map((item, i) => {
                    if (i === index) {
                      return {
                        ...scheduleItem,
                        end_at: e.target.value,
                      };
                    }
                    return item;
                  });

                  setSchedules(newSchedules);
                  setErrorMessage({
                    ...errorMessage,
                    [`timetables[${index}].end_at`]: "",
                  });
                }}
                placeholder="Giờ kết thúc"
                disabled={scheduleItem.id_day === 0}
              />
              <i className="text-danger">
                {errorMessage[`timetables[${index}].end_at`]}
              </i>
            </td>
            <td>
              <Button
                variant="outline-danger"
                size="sm"
                type="button"
                onClick={() => removeSchedule(index)}
                disabled={scheduleItem.id_day === 0}
                title="Xóa"
              >
                <i className="fa-solid fa-times"></i>
              </Button>
            </td>
          </tr>

          {scheduleItem.error && (
            <tr>
              <td colSpan={5}>
                <i className="text-danger"> {scheduleItem.error}</i>
              </td>
            </tr>
          )}
        </>
      );
    });
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide()}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Thêm nhóm môn học</Modal.Title>
      </Modal.Header>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <Modal.Body>
          <Row>
            <Col md={4}>
              {/* Ma lop */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  value={studyGroupDetail.code}
                  onChange={(e) => {
                    setStudyGroupDetail({
                      ...studyGroupDetail,
                      code: e.target.value,
                    });
                    setErrorMessage({ ...errorMessage, code: "" });
                  }}
                  placeholder="Mã nhóm học tập"
                  required
                />
                <Form.Label>
                  Mã nhóm
                  <i className="text-danger">*</i>
                </Form.Label>

                {errorMessage.code && (
                  <i className="text-danger">{errorMessage.code}</i>
                )}
              </Form.Floating>
              {/* Ten lop */}
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  value={studyGroupDetail.name}
                  onChange={(e) => {
                    setStudyGroupDetail({
                      ...studyGroupDetail,
                      name: e.target.value,
                    });
                    setErrorMessage({ ...errorMessage, name: "" });
                  }}
                  placeholder="Tên nhóm học tập"
                  required
                />
                <Form.Label>
                  Tên nhóm
                  <i className="text-danger">*</i>
                </Form.Label>

                {errorMessage.name && (
                  <i className="text-danger">{errorMessage.name}</i>
                )}
              </Form.Floating>
              {/* Giao vien */}
              <Form.Floating className="mb-3">
                <Form.Select
                  value={studyGroupDetail.id_teacher}
                  onChange={(e) => {
                    setStudyGroupDetail({
                      ...studyGroupDetail,
                      id_teacher: e.target.value,
                    });
                    setErrorMessage({ ...errorMessage, id_teacher: "" });
                  }}
                  required
                >
                  <option value="">--Chọn--</option>
                  {listTeachers.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.user_name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Label>
                  Giáo viên<i className="text-danger">*</i>
                </Form.Label>

                {errorMessage.id_teacher && (
                  <i className="text-danger">{errorMessage.id_teacher}</i>
                )}
              </Form.Floating>
              {/* Mon hoc */}
              <Form.Floating className="mb-3">
                <Form.Select
                  value={studyGroupDetail.id_subject}
                  onChange={(e) => {
                    setStudyGroupDetail({
                      ...studyGroupDetail,
                      id_subject: e.target.value,
                    });
                    setErrorMessage({ ...errorMessage, id_subject: "" });
                  }}
                  required
                >
                  <option value="">--Chọn--</option>
                  {listSubjects.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.subject_name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Label>
                  Môn học<i className="text-danger">*</i>
                </Form.Label>

                {errorMessage.id_subject && (
                  <i className="text-danger">{errorMessage.id_subject}</i>
                )}
              </Form.Floating>
              {/* Thoi gian */}
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="date"
                      className="text-end"
                      value={studyGroupDetail.start_date}
                      onChange={(e) => {
                        setStudyGroupDetail({
                          ...studyGroupDetail,
                          start_date: e.target.value,
                        });
                        setErrorMessage({ ...errorMessage, start_date: "" });
                      }}
                      placeholder="Ngày bắt đầu"
                      required
                    />
                    <Form.Label>
                      Ngày bắt đầu
                      <i className="text-danger">*</i>
                    </Form.Label>

                    {errorMessage.start_date && (
                      <i className="text-danger">{errorMessage.start_date}</i>
                    )}
                  </Form.Floating>
                </Col>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="date"
                      className="text-end"
                      value={studyGroupDetail.end_date}
                      onChange={(e) => {
                        setStudyGroupDetail({
                          ...studyGroupDetail,
                          end_date: e.target.value,
                        });
                        setErrorMessage({ ...errorMessage, end_date: "" });
                      }}
                      placeholder="Ngày kết thúc"
                      required
                    />
                    <Form.Label>
                      Ngày kết thúc
                      <i className="text-danger">*</i>
                    </Form.Label>

                    {errorMessage.end_date && (
                      <i className="text-danger">{errorMessage.end_date}</i>
                    )}
                  </Form.Floating>
                </Col>
              </Row>
              {/* So bai hoc */}

              <Row>
                <Col>
                  <Form.Floating>
                    <Form.Control
                      type="number"
                      className="text-end"
                      value={studyGroupDetail.lesson_number}
                      onChange={(e) => {
                        setStudyGroupDetail({
                          ...studyGroupDetail,
                          lesson_number: e.target.value,
                        });
                        setErrorMessage({ ...errorMessage, lesson_number: "" });
                      }}
                      placeholder="Số buổi học"
                    />
                    <Form.Label>Số buổi học</Form.Label>

                    {errorMessage.lesson_number && (
                      <i className="text-danger">
                        {errorMessage.lesson_number}
                      </i>
                    )}
                  </Form.Floating>
                </Col>

                <Col>
                  <Form.Floating>
                    <Form.Control
                      type="number"
                      className="text-end"
                      value={studyGroupDetail.lesson_time}
                      onChange={(e) => {
                        setStudyGroupDetail({
                          ...studyGroupDetail,
                          lesson_time: e.target.value,
                        });
                        setErrorMessage({ ...errorMessage, lesson_time: "" });
                      }}
                      placeholder="Thời gian / Buổi (phút)"
                    />
                    <Form.Label>Thời gian / Buổi (phút)</Form.Label>
                    {errorMessage.lesson_time && (
                      <i className="text-danger">{errorMessage.lesson_time}</i>
                    )}
                  </Form.Floating>
                </Col>
              </Row>
            </Col>

            <Col md={8}>
              <RenderScheduleTable />
            </Col>
          </Row>
        </Modal.Body>
      )}

      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onHide()}>
          <i className="fa-solid fa-times"></i> Đóng
        </Button>

        <Button
          size="sm"
          type="submit"
          variant="success"
          onClick={() => checkInput()}
          disabled={isLoading}
        >
          <i className="fa-solid fa-check"></i> Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
  //#endregion Render
};

export default StudyGroupAdd;
