import { Component } from "react";

class Check extends Component {
    permission(data, type = 'and') {

        if (localStorage.getItem('role_rank') === '1') { return true; }

        const permission = localStorage.getItem('permission') ? localStorage.getItem('permission').split(',') : [];


        if (data.length === 0) {
            return false;
        } else {
            let result = false;
            let errCode = 0;

            data.map((item) => {
                item = item.toString();

                if (permission.length > 0) {
                    switch (type) {
                        case 'or':
                            if (permission.indexOf(item) > -1) {
                                result = true;
                            }
                            break;

                        case 'and':
                            if (permission.indexOf(item) > -1) {
                                result = true;
                            } else {
                                errCode++;
                                result = false;
                            }
                            break;
                        default:
                            errCode++;
                            result = false;
                    }
                }

                return item;
            });

            return result && errCode === 0 ? true : false;
        }
    }
}

export default Check;