import { useEffect, useState } from "react";
import { Breadcrumb, Container, Tab, Tabs } from "react-bootstrap";
import NutritionDayReport from "./nutritionReport/NutritionDayReport";
import NutritionWeekReport from "./nutritionReport/NutritionWeekReport";
import NutritionMonthReport from "./nutritionReport/NutritionMonthReport";
import api from "../../../../helper/axiosInstance";

const NutritionEnergy = () => {
  const [classGroups, setClassGroups] = useState([]);
  useEffect(() => {
    document.title = "Năng lượng dinh dưỡng";
    getListClassGroups();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const getListClassGroups = async () => {
    await api
      .get(`/group-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setClassGroups(res.data.groups);
        } else {
        }
      })
      .catch((error) => {});
  };
  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
        <Breadcrumb.Item active>Năng lượng dinh dưỡng</Breadcrumb.Item>
      </Breadcrumb>

      <Tabs defaultActiveKey={1} className="mb-3">
        <Tab eventKey={1} title="Dưỡng chất 1 trẻ">
          <NutritionDayReport />
        </Tab>
        <Tab eventKey={2} title="Dinh dưỡng tuần">
          <NutritionWeekReport />
        </Tab>
        <Tab eventKey={3} title="Dinh dưỡng tháng">
          {classGroups?.length > 0 ? (
            <NutritionMonthReport groups={classGroups} />
          ) : null}
          {/* <NutritionMonthReport groups={classGroups} /> */}
        </Tab>
      </Tabs>
    </Container>
  );
};

export default NutritionEnergy;
