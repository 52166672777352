import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Form,
} from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import Check from "../other/Check";
import VaccinationModal from "./VaccinationModal";
import { dateFormatter, getDate, getTime } from "../utils/helpers";
import VaccinationEditModal from "./VaccinationEditModal";
import { INJECTIONS } from "../../../constants";
import api from "../../../helper/axiosInstance";

class VaccinationIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkload: true,
      filterText: "",
      studentsSearch: [],
      startDate: new Date(),
      endDate: new Date(),
      typeModal: "",
      vaccination: [],
      showVaccinationModal: false,
      isTableSelectable: true,
      students: [],
      disease: [],
      itemSelect: {},
    };
  }

  componentDidMount() {
    document.title = "Lịch tiêm ngừa";
    // this.getStudents();
    this.getSchoolYears();
    this.getDisease();
    this.setState(
      {
        startDate: new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)),
        endDate: this.lastDayy(
          new Date().getFullYear(),
          Number(new Date().getMonth()) + 1
        ),
      },
      () => this.getvaccination()
    );
  }
  getSchoolYears = async () => {
    await api
      .get("/school-year-shows")
      .then((res) => {
        if (res.status === 200) {
          const schoolYears = res.data.data?.schoolYears || [];
          const currentTime = Math.floor(Date.now() / 1000);

          const filteredData = schoolYears.filter(
            (item) =>
              item.start_date <= currentTime && item.end_date >= currentTime
          );

          const yearId = filteredData.length > 0 ? filteredData[0].id : "";
          this.getStudents(yearId);
        }
      })
      .catch((err) => {});
  };
  getvaccination = () => {
    api.get(`/vaccination-shows`).then((res) => {
      this.setState({ checkload: false, isTableSelectable: true });
      this.setState({ vaccination: res.data.data }, () =>
        this.filterStudent("")
      );
    });
  };

  getStudents = async (id) => {
    await api
      .get(`/student-shows?id_year=${id || ""}`)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            students: res.data.data.students?.map((e) => ({
              ...e,
              label: e.student_name,
              value: e.id,
            })),
          });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };
  getDisease = async () => {
    await api
      .get(`/disease-type-shows`)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            disease: res.data.data?.map((e) => ({
              ...e,
              label: e.name,
              value: e.id,
            })),
          });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  toSlug(str) {
    if (str !== "undefined") {
      str = str.toLowerCase(); // Chuyển hết sang chữ thường
      str = str
        .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
      str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
      str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    }

    return str;
  }
  filterStudent = (text) => {
    this.setState({ isTableSelectable: false });
    this.setState({ filterText: text });
    const filteredStudents = this.state.vaccination?.filter((student) =>
      this.toSlug(student?.student?.student_name)?.includes(
        this.toSlug(text?.trim())
      )
    );
    this.setState({
      studentsSearch: filteredStudents,
      filterText: text,
    });
  };

  destroyVaccination = async (id) => {
    swal({
      title: `Bạn chắc muốn xóa lịch tiêm ngừa?`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        await api
          .delete(`/vaccination-delete?id=${id}`)
          .then((res) => {
            toast(res.data.messages, { type: "success" });
            this.getvaccination();
          })
          .catch((err) => {
            toast(err.message, { type: "error" });
          });
      }
    });
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  lastDayy = (year, month) => {
    const nextMonth = new Date(year, month, 1);
    nextMonth.setDate(nextMonth.getDate() - 1);
    return new Date(nextMonth.setHours(23, 59, 59));
  };

  dataTable() {
    const columns = [
      {
        name: "Tên học sinh",
        selector: (row) => row.student?.student_name,
        sortable: true,
        wrap: true,
      },
      {
        name: "Tên vắc xin",
        selector: (row) => row.vaccine_name,
        sortable: true,
        wrap: true,
      },
      {
        name: "Mũi tiêm",
        selector: (row) => row.sequence,
        sortable: true,
        wrap: true,
      },
      {
        name: "Ngày tiêm",
        selector: (row) => parseInt(row.vaccination_date),
        sortable: true,
        cell: (row) => (
          <div>{dateFormatter(new Date(row.vaccination_date * 1000))}</div>
        ),
        wrap: true,
        center: true,
      },

      {
        name: "",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    if (this.state.studentsSearch?.length > 0) {
      this.state.studentsSearch?.map((item, i) => {
        data.push({
          ...item,
          setting: (
            <>
              {new Check().permission(["62"]) ? (
                <Button
                  size="sm"
                  variant="warning me-2"
                  type="button"
                  onClick={() => {
                    this.setState({
                      showVaccinationModal: true,
                      itemSelect: item,
                    });
                  }}
                  title="Chi tiết"
                >
                  <i className="fa-solid fa-pencil"></i>
                </Button>
              ) : null}

              {/* {new Check().permission(["65"]) ? ( */}
              <Button
                size="sm"
                variant="danger"
                type="button"
                onClick={() => this.destroyVaccination(item.id)}
                title="Xóa"
              >
                <i className="fas fa-trash"></i>
              </Button>
              {/* ) : null} */}
            </>
          ),
        });

        return item;
      });
    }

    return <DataTables data={data} columns={columns} />;
  }

  renderRoleList() {
    const modalClose = () => {
      this.setState({ addModal: false });
    };

    return (
      <>
        <Card.Header className="px-2">
          <div className="d-flex align-items-center justify-content-between">
            <Card.Title className="mt-1">
              <i className="fas fa-list me-1"></i> Lịch tiêm ngừa
            </Card.Title>
            {new Check().permission([87]) ? (
              <Button
                type="button"
                variant="success"
                size="sm"
                style={{ float: "right" }}
                onClick={() => this.setState({ addModal: true })}
              >
                <i className="fa-solid fa-plus"></i> Thêm
              </Button>
            ) : null}
          </div>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col md={3}>
              <Form.Control
                className="mb-3"
                style={{ height: 39 }}
                type="text"
                placeholder="Nhập tên học sinh"
                value={this.state.filterText}
                onChange={(e) => {
                  this.filterStudent(e.target.value);
                }}
              />
            </Col>
          </Row>

          {this.state.checkload ? this.loading() : this.dataTable()}
        </Card.Body>

        {this.state.addModal ? (
          <>
            <VaccinationModal
              show={this.state.addModal}
              onHide={modalClose}
              students={this.state.students}
              disease={this.state.disease}
              submit={() => {
                this.setState({ addModal: false });
                this.getvaccination();
              }}
              type={this.state.typeModal}
            />
          </>
        ) : null}

        {this.state.showVaccinationModal ? (
          <>
            <VaccinationEditModal
              show={this.state.showVaccinationModal}
              onHide={() => this.setState({ showVaccinationModal: false })}
              type={"TM"}
              students={this.state.students}
              disease={this.state.disease}
              data={{
                ...this.state.itemSelect,
                sequence: INJECTIONS?.filter(
                  (e) => e.label == this.state?.itemSelect?.sequence
                )[0]?.value,
              }}
              submit={() => {
                this.setState({ showVaccinationModal: false });
                this.getvaccination();
              }}
            />
          </>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 ">
            <Breadcrumb.Item active>Học sinh</Breadcrumb.Item>
            <Breadcrumb.Item active>Lịch sử tiêm ngừa</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default VaccinationIndex;
