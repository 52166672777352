import React, { Component } from "react";
import {
  Card,
  Container,
  Breadcrumb,
  Table,
  Button,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import * as XLSX from "xlsx-js-style";
import moment from "moment";
import api from "../../../../helper/axiosInstance";

class FinanceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listVoucher: [],
      listmealbyclass: [],

      listStudent: [],

      takeMoney: 0,

      showModal: false,
      isLoading: false,

      datesInMonth: [],
      currentTime: new Date(),
    };
  }

  async componentDidMount() {
    document.title = "Báo cáo thu chi";

    const date = new Date();

    const listDate = this.getDaysInMonth(date.getMonth(), date.getFullYear());

    this.getListVoucher(listDate[0], listDate[listDate.length - 1]);
    this.getListMealByClassData(listDate[0], listDate[listDate.length - 1]);
    this.getStudentData();
    this.getlistsetting();

    this.setState({
      datesInMonth: listDate,
      currentTime: new Date(),
    });
  }

  //#region Fetch API
  getListVoucher = (startDate, endDate) => {
    api
      .get(
        `/viewVoucher?type_voucher=1&start_at=${
          startDate.getTime() / 1000
        }&term_at=${endDate.getTime() / 1000}`
      )
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            listVoucher: res.data.data.filter(
              (item) => Number(item.id_classify) === 1
            ),
          });
        }
      });
  };

  getlistsetting = () => {
    api
      .get(`/viewSetting?id_classify=1`)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            takeMoney: Number(res.data.data.main[0].price_proceed),
          });
        }
      })
      .catch((error) => {});
  };

  getListMealByClassData(startDate, endDate) {
    const start = startDate.setHours(0, 0, 0, 0) / 1000;
    const end = endDate.setHours(23, 59, 59, 0) / 1000;

    this.setState({
      isLoading: true,
    });

    api
      .post(
        `/viewMeal?id_classify=1&start_date=${parseInt(start)}&end_date=${end}`
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({
            listmealbyclass: res.data.data.filter(
              (mealCut) =>
                start <= mealCut.meal_day &&
                mealCut.meal_day <= end &&
                Number(mealCut.type_meal) === 1 &&
                Number(mealCut.is_status) === 1
            ),
          });
        }

        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {});
  }

  getStudentData() {
    api
      .get("/viewStudent")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            listStudent: res.data.data.filter(
              (student) => student.id_class !== 0 && student.is_meal !== 1
            ),
          });
        }
      })
      .catch((error) => {});
  }
  //#endregion

  //#region Handle Logic
  xport = () => {
    const wb = XLSX.utils.book_new();

    const ws = this.xportFinanceReport();

    XLSX.utils.book_append_sheet(wb, ws, "Báo cáo thu chi");

    XLSX.writeFile(wb, "Báo cáo thu chi.xlsx");
  };

  xportFinanceReport = () => {
    const table = document.getElementById("finance-report-table");

    var ws = XLSX.utils.table_to_sheet(table, { raw: true });

    const colAlpha = ["A", "B", "C", "D", "E"];

    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }];
    ws["!merges"] = merge;

    colAlpha.map((alpha) => {
      ws[`${alpha}1`].s = {
        font: {
          sz: 16,
          name: "Times New Roman",
          bold: true,
        },
        // border: {
        //     top: { style: "thin" },
        //     bottom: { style: "thin" },
        //     left: { style: "thin" },
        //     right: { style: "thin" },
        // },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });

    colAlpha.map((alpha) => {
      ws[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: true,
        },
      };

      return alpha;
    });

    // Set Column Width
    var wscols = [
      { wch: 10 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
    ];

    ws["!cols"] = wscols;
    ws["!rows"] = [{ hpt: 40 }];

    // Format number Price, Unit, Will Money
    const numberFormat = "#,###,###,###";

    for (let i = 0; i < this.state.listVoucher.length; i++) {
      ws[`A${i + 3}`].s = {
        font: {
          name: "Times New Roman",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      ws[`B${i + 3}`].s = {
        font: {
          name: "Times New Roman",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
        alignment: {
          vertical: "center",
          horizontal: "right",
        },
      };

      ws[`C${i + 3}`].s = {
        font: {
          name: "Times New Roman",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
        alignment: {
          vertical: "center",
          horizontal: "right",
        },
      };

      ws[`D${i + 3}`].s = {
        font: {
          name: "Times New Roman",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
        alignment: {
          vertical: "center",
          horizontal: "right",
        },
      };

      ws[`E${i + 3}`].s = {
        font: {
          name: "Times New Roman",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
        alignment: {
          vertical: "center",
          horizontal: "right",
        },
      };

      ws[`B${i + 3}`].z = numberFormat;
      delete ws[`B${i + 3}`].w;
      XLSX.utils.format_cell(ws[`B${i + 3}`]);

      ws[`C${i + 3}`].z = numberFormat;
      delete ws[`C${i + 3}`].w;
      XLSX.utils.format_cell(ws[`C${i + 3}`]);

      ws[`D${i + 3}`].z = numberFormat;
      delete ws[`D${i + 3}`].w;
      XLSX.utils.format_cell(ws[`D${i + 3}`]);

      ws[`E${i + 3}`].z = numberFormat;
      delete ws[`E${i + 3}`].w;
      XLSX.utils.format_cell(ws[`E${i + 3}`]);
    }

    ws[`A${3 + this.state.listVoucher.length}`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[`B${3 + this.state.listVoucher.length}`].v = " ";

    ws[`B${3 + this.state.listVoucher.length}`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[`C${3 + this.state.listVoucher.length}`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`D${3 + this.state.listVoucher.length}`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`E${3 + this.state.listVoucher.length}`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    ws[`C${3 + this.state.listVoucher.length}`].z = numberFormat;
    delete ws[`C${3 + this.state.listVoucher.length}`].w;
    XLSX.utils.format_cell(ws[`C${3 + this.state.listVoucher.length}`]);

    ws[`D${3 + this.state.listVoucher.length}`].z = numberFormat;
    delete ws[`D${3 + this.state.listVoucher.length}`].w;
    XLSX.utils.format_cell(ws[`D${3 + this.state.listVoucher.length}`]);

    ws[`E${3 + this.state.listVoucher.length}`].z = numberFormat;
    delete ws[`E${3 + this.state.listVoucher.length}`].w;
    XLSX.utils.format_cell(ws[`E${3 + this.state.listVoucher.length}`]);

    return ws;
  };

  getDaysInMonth = (month, year) => {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };
  //#endregion

  // Render
  RenderFinanceTable() {
    let financeReport = [];

    this.state.datesInMonth.map((date) => {
      const start = date.setHours(0, 0, 0, 0) / 1000;
      const end = date.setHours(23, 59, 59, 0) / 1000;

      const mealInDate = [];

      this.state.listmealbyclass.map((mealcut) => {
        if (start <= mealcut.meal_day && mealcut.meal_day <= end) {
          mealInDate.push(mealcut);
        }

        return mealcut;
      });

      financeReport.push({
        date: date,
        mealCut: mealInDate,
        voucher: this.state.listVoucher.filter(
          (voucher) => Number(voucher.market_date) === start
        )[0] || {
          ingredent: [],
        },
      });

      return date;
    });

    let totalTake = 0;
    let totalCost = 0;
    let totalDifference = 0;

    return (
      <Modal
        show={this.state.showModal}
        size="xl"
        onHide={() => {
          this.setState({
            showModal: false,
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Chi tiết báo cáo thu chi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-3">
            <div></div>
            <Button
              variant="success"
              size="sm"
              onClick={() => {
                this.xport();
              }}
            >
              Xuất file <i className="fa-solid fa-file-export"></i>
            </Button>
          </div>

          <div style={{ overflowX: "scroll" }}>
            <Table>
              <thead className="text-end align-middle">
                <tr className="text-center d-none">
                  <th colSpan={5}>
                    Báo cáo thu chi Tháng{" "}
                    {this.state.currentTime.getMonth() + 1} Năm{" "}
                    {this.state.currentTime.getFullYear()}
                  </th>
                </tr>
                <tr>
                  <th className="text-center">Ngày</th>
                  <th>Tổng suất ăn</th>
                  <th>Tổng thu</th>
                  <th>Tổng chi</th>
                  <th>Chênh lệch</th>
                </tr>
              </thead>
              <tbody>
                {financeReport.reverse()?.map((finance, index) => {
                  let totalPerson = this.state.listStudent.length;
                  let pauseStudent = 0;

                  this.state.listStudent.map((student) => {
                    let isStillStudy = false;

                    student.process.map((period) => {
                      if (period.end_at !== null) {
                        if (
                          period.start_at <= finance.date.getTime() / 1000 &&
                          finance.date.getTime() / 1000 <= period.end_at
                        ) {
                          isStillStudy = true;
                        }
                      } else {
                        if (period.start_at <= finance.date.getTime() / 1000) {
                          isStillStudy = true;
                        }
                      }
                      return period;
                    });

                    if (!isStillStudy) {
                      pauseStudent++;
                    }

                    return student;
                  });

                  totalPerson -= pauseStudent;
                  let takeMoneyInDay = this.state.takeMoney * totalPerson;

                  totalPerson -= finance.mealCut.filter(
                    (mealCut) => Number(mealCut.type_meal) === 1
                  ).length;

                  let returnMoney = 0;

                  finance.mealCut.map((meal) => {
                    if (Number(meal.is_term) === 1) {
                      if (Number(meal.type_meal) === 1) {
                        returnMoney += Number(meal.price_return);
                      } else {
                        meal.detail.map((detailItem) => {
                          returnMoney += Number(detailItem.price_return);

                          return detailItem;
                        });
                      }
                    }
                    return meal;
                  });

                  let costMoneyInDay = 0;

                  finance.voucher.ingredent?.map((ingredient) => {
                    costMoneyInDay +=
                      Number(ingredient.ingredent_price) *
                      Number(ingredient.count).toFixed(2);
                    return ingredient;
                  });

                  if (costMoneyInDay === 0) {
                    return "";
                  }

                  takeMoneyInDay -= returnMoney;

                  totalTake += takeMoneyInDay;
                  totalCost += costMoneyInDay;
                  totalDifference += takeMoneyInDay - costMoneyInDay;

                  return (
                    <tr key={index} className="text-end align-middle">
                      <td className="text-center">
                        {moment(finance.date).format("DD/MM/YYYY")}
                      </td>
                      <td>
                        <CurrencyFormat
                          value={totalPerson}
                          thousandSeparator={true}
                          allowNegative={true}
                          displayType="text"
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={takeMoneyInDay.toFixed(0)}
                          thousandSeparator={true}
                          allowNegative={true}
                          displayType="text"
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={costMoneyInDay.toFixed(0)}
                          thousandSeparator={true}
                          allowNegative={true}
                          displayType="text"
                        />
                      </td>
                      <td
                        style={
                          takeMoneyInDay - costMoneyInDay < 0
                            ? { color: "red" }
                            : {}
                        }
                      >
                        <CurrencyFormat
                          value={(takeMoneyInDay - costMoneyInDay).toFixed(0)}
                          thousandSeparator={true}
                          allowNegative={true}
                          displayType="text"
                        />
                      </td>
                    </tr>
                  );
                })}

                <tr className="text-end align-middle">
                  <th className="text-center">TỔNG</th>
                  <th></th>
                  <th>
                    <CurrencyFormat
                      value={totalTake.toFixed(0)}
                      thousandSeparator={true}
                      allowNegative={true}
                      displayType="text"
                    />
                  </th>
                  <th>
                    <CurrencyFormat
                      value={totalCost.toFixed(0)}
                      thousandSeparator={true}
                      allowNegative={true}
                      displayType="text"
                    />
                  </th>
                  <th style={totalDifference < 0 ? { color: "red" } : {}}>
                    <CurrencyFormat
                      value={totalDifference.toFixed(0)}
                      thousandSeparator={true}
                      allowNegative={true}
                      displayType="text"
                    />
                  </th>
                </tr>
              </tbody>
            </Table>
          </div>

          <Table id="finance-report-table" className="d-none">
            <thead className="text-end align-middle">
              <tr className="text-center d-none">
                <th>
                  Báo cáo thu chi Tháng {this.state.currentTime.getMonth() + 1}{" "}
                  Năm {this.state.currentTime.getFullYear()}
                </th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th className="text-center">Ngày</th>
                <th>Tổng suất ăn</th>
                <th>Tổng thu</th>
                <th>Tổng chi</th>
                <th>Chênh lệch</th>
              </tr>
            </thead>
            <tbody>
              {financeReport?.map((finance, index) => {
                let totalPerson = this.state.listStudent.length;

                let pauseStudent = 0;

                this.state.listStudent.map((student) => {
                  let isStillStudy = false;

                  student.process.map((period) => {
                    if (period.end_at !== null) {
                      if (
                        period.start_at <= finance.date.getTime() / 1000 &&
                        finance.date.getTime() / 1000 <= period.end_at
                      ) {
                        isStillStudy = true;
                      }
                    } else {
                      if (period.start_at <= finance.date.getTime() / 1000) {
                        isStillStudy = true;
                      }
                    }
                    return period;
                  });

                  if (!isStillStudy) {
                    pauseStudent++;
                  }

                  return student;
                });

                totalPerson -= pauseStudent;
                let takeMoneyInDay = this.state.takeMoney * totalPerson;
                totalPerson -= finance.mealCut.filter(
                  (mealCut) => Number(mealCut.type_meal) === 1
                ).length;

                let returnMoney = 0;

                finance.mealCut.map((meal) => {
                  if (Number(meal.is_term) === 1) {
                    if (Number(meal.type_meal) === 1) {
                      returnMoney += Number(meal.price_return);
                    } else {
                      meal.detail.map((detailItem) => {
                        returnMoney += Number(detailItem.price_return);

                        return detailItem;
                      });
                    }
                  }
                  return meal;
                });

                let costMoneyInDay = 0;

                finance.voucher.ingredent?.map((ingredient) => {
                  costMoneyInDay +=
                    Number(ingredient.ingredent_price) *
                    Number(ingredient.count).toFixed(2);
                  return ingredient;
                });

                if (costMoneyInDay === 0) {
                  return "";
                }
                takeMoneyInDay -= returnMoney;

                return (
                  <tr key={index} className="text-end align-middle">
                    <td className="text-center">
                      {moment(finance.date).format("DD/MM/YYYY")}
                    </td>
                    <td>
                      <CurrencyFormat
                        value={totalPerson}
                        thousandSeparator={true}
                        allowNegative={true}
                        displayType="text"
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={takeMoneyInDay.toFixed(0)}
                        thousandSeparator={true}
                        allowNegative={true}
                        displayType="text"
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={costMoneyInDay.toFixed(0)}
                        thousandSeparator={true}
                        allowNegative={true}
                        displayType="text"
                      />
                    </td>
                    <td
                      style={
                        takeMoneyInDay - costMoneyInDay < 0
                          ? { color: "red" }
                          : {}
                      }
                    >
                      <CurrencyFormat
                        value={(takeMoneyInDay - costMoneyInDay).toFixed(0)}
                        thousandSeparator={true}
                        allowNegative={true}
                        displayType="text"
                      />
                    </td>
                  </tr>
                );
              })}

              <tr className="text-end align-middle">
                <th className="text-center">TỔNG</th>
                <th>empty</th>
                <th>
                  <CurrencyFormat
                    value={totalTake.toFixed(0)}
                    thousandSeparator={true}
                    allowNegative={true}
                    displayType="text"
                  />
                </th>
                <th>
                  <CurrencyFormat
                    value={totalCost.toFixed(0)}
                    thousandSeparator={true}
                    allowNegative={true}
                    displayType="text"
                  />
                </th>
                <th style={totalDifference < 0 ? { color: "red" } : {}}>
                  <CurrencyFormat
                    value={totalDifference.toFixed(0)}
                    thousandSeparator={true}
                    allowNegative={true}
                    displayType="text"
                  />
                </th>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    let financeReport = [];

    this.state.datesInMonth.map((date) => {
      const start = date.setHours(0, 0, 0, 0) / 1000;
      const end = date.setHours(23, 59, 59, 0) / 1000;

      const mealInDate = [];

      this.state.listmealbyclass.map((mealcut) => {
        if (start <= mealcut.meal_day && mealcut.meal_day <= end) {
          mealInDate.push(mealcut);
        }

        return mealcut;
      });

      financeReport.push({
        date: date,
        mealCut: mealInDate,
        voucher: this.state.listVoucher.filter(
          (voucher) => Number(voucher.market_date) === start
        )[0] || {
          ingredent: [],
        },
      });

      return date;
    });

    let totalTake = 0;
    let totalCost = 0;

    financeReport?.map((finance) => {
      let totalPerson = this.state.listStudent.length;

      let pauseStudent = 0;

      this.state.listStudent.map((student) => {
        let isStillStudy = false;

        student.process.map((period) => {
          if (period.end_at !== null) {
            if (
              period.start_at <= finance.date.getTime() / 1000 &&
              finance.date.getTime() / 1000 <= period.end_at
            ) {
              isStillStudy = true;
            }
          } else {
            if (period.start_at <= finance.date.getTime() / 1000) {
              isStillStudy = true;
            }
          }

          return period;
        });

        if (!isStillStudy) {
          pauseStudent++;
        }

        return student;
      });

      totalPerson -= pauseStudent;
      let takeMoneyInDay = this.state.takeMoney * totalPerson;
      totalPerson -= finance.mealCut.filter(
        (mealCut) => Number(mealCut.type_meal) === 1
      ).length;

      let returnMoney = 0;

      finance.mealCut.map((meal) => {
        if (Number(meal.is_term) === 1) {
          if (Number(meal.type_meal) === 1) {
            returnMoney += Number(meal.price_return);
          } else {
            meal.detail.map((detailItem) => {
              returnMoney += Number(detailItem.price_return);

              return detailItem;
            });
          }
        }
        return meal;
      });

      let costMoneyInDay = 0;

      finance.voucher.ingredent?.map((ingredient) => {
        costMoneyInDay +=
          Number(ingredient.ingredent_price) *
          Number(ingredient.count).toFixed(2);
        return ingredient;
      });

      if (costMoneyInDay === 0) {
        return "";
      }
      takeMoneyInDay -= returnMoney;

      totalTake += takeMoneyInDay;
      totalCost += costMoneyInDay;

      return finance;
    });

    return (
      <Container fluid>
        <Breadcrumb>
          <Breadcrumb.Item active>Báo cáo</Breadcrumb.Item>
          <Breadcrumb.Item active>Báo cáo thu chi</Breadcrumb.Item>
        </Breadcrumb>

        <Card>
          <Card.Header className="d-block d-sm-flex mb-3 mb-sm-0">
            <Card.Title className="me-3">
              <i className="fas fa-list me-1"></i> Báo cáo thu chi Tháng{" "}
              {this.state.currentTime.getMonth() + 1} Năm{" "}
              {this.state.currentTime.getFullYear()}
            </Card.Title>
            <div className="d-flex align-items-center">
              <input
                type="month"
                name="month"
                style={{
                  border: "none",
                  width: 38,
                  backgroundColor: "rgb(16,108,252)",
                  borderRadius: 5,
                }}
                onChange={(e) => {
                  const listDate = this.getDaysInMonth(
                    e.target.valueAsDate.getMonth(),
                    e.target.valueAsDate.getFullYear()
                  );

                  this.getListVoucher(
                    listDate[0],
                    listDate[listDate.length - 1]
                  );
                  this.getListMealByClassData(
                    listDate[0],
                    listDate[listDate.length - 1]
                  );

                  this.setState({
                    datesInMonth: listDate,
                    currentTime: e.target.valueAsDate,
                  });
                }}
              />
            </div>
          </Card.Header>
          <Card.Body>
            {this.state.isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <div
                  className="spinner-border text-primary"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Đang tải báo cáo thu chi...</span>
                </div>
              </div>
            ) : (
              <Row>
                <Col xs={12} md={3}>
                  <Card.Title>
                    Tháng {this.state.currentTime.getMonth() + 1} Năm{" "}
                    {this.state.currentTime.getFullYear()}
                  </Card.Title>
                </Col>
                <Col xs={6} md={3} className="mt-3 mt-md-0">
                  <Card.Title>Tổng thu trong tháng:</Card.Title>
                  <CurrencyFormat
                    value={totalTake.toFixed(0)}
                    thousandSeparator={true}
                    allowNegative={true}
                    displayType="text"
                    suffix="đ"
                  />
                </Col>
                <Col xs={6} md={3} className="mt-3 mt-md-0">
                  <Card.Title>Tổng chi trong tháng:</Card.Title>
                  <CurrencyFormat
                    value={totalCost.toFixed(0)}
                    thousandSeparator={true}
                    allowNegative={true}
                    displayType="text"
                    suffix="đ"
                  />
                </Col>
                <Col xs={12} md={3} className="mt-3 mt-md-0">
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      this.setState({
                        showModal: true,
                      });
                    }}
                  >
                    Chi tiết
                  </Button>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>

        {this.RenderFinanceTable()}
      </Container>
    );
  }
}

export default FinanceReport;
