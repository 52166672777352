import { useState, useRef } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import Select from "react-select";

const daySession = [
  { id: 1, name: "Sáng", nameEn: "morning" },
  { id: 2, name: "Trưa", nameEn: "noon" },
  { id: 3, name: "Chiều", nameEn: "afternoon" },
];

const schoolDays = [
  { id: 1, name: "Thứ 2", nameEn: "monday" },
  { id: 2, name: "Thứ 3", nameEn: "tuesday" },
  { id: 3, name: "Thứ 4", nameEn: "wednesday" },
  { id: 4, name: "Thứ 5", nameEn: "thursday" },
  { id: 5, name: "Thứ 6", nameEn: "friday" },
  { id: 6, name: "Thứ 7", nameEn: "saturday" },
  { id: 7, name: "Chủ nhật", nameEn: "sunday" },
];

const ScheduleDaySession = ({
  show,
  onHide,
  listSubjects,
  scheduleDaySessions,
  idSession,
  idDay,
}) => {
  const dummySchedule = {
    id: 0,
    id_session: idSession,
    id_subject: 0,
    subjects: {
      id: 0,
      subject_code: "",
      subject_name: "",
    },
    timetable: { id_day: idDay, start_at: "", end_at: "" },
  };

  const [schedules, setSchedules] = useState([
    ...scheduleDaySessions,
    dummySchedule,
  ]);

  //#region Render
  const RenderScheduleTable = () => {
    return (
      <div style={{ overflowX: "scroll" }}>
        <Table size="lg" bordered hover>
          <thead>
            <tr className="align-middle text-center">
              <th>STT</th>
              <th style={{ width: "220px" }}>
                Môn - Hoạt động<i className="text-danger">*</i>
              </th>
              <th>Giờ bắt đầu</th>
              <th>Giờ kết thúc</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{RenderSchedules()}</tbody>
        </Table>
      </div>
    );
  };

  const [tempTime, setTempTime] = useState('');

  const RenderSchedules = () => {
    return schedules?.map((scheduleItem, index) => {
      return (
        <tr key={index} className="align-middle text-center">
          <td>{index + 1}</td>
          <td>
            <Select
              options={listSubjects}
              className="text-start"
              isSearchable={true}
              getOptionLabel={(option) => option.subject_name}
              getOptionValue={(option) => option.id}
              placeholder={<>--Chọn--</>}
              value={
                listSubjects.find(
                  (subject) => subject.id === scheduleItem.id_subject
                ) || "Chọn hoạt động/môn học"
              }
              onChange={(choice) => {
                let newSchedules = schedules.map((item, i) => {
                  if (i === index) {
                    return {
                      ...scheduleItem,
                      id_subject: choice.id,
                      subjects: choice,
                    };
                  }
                  return item;
                });

                if (scheduleItem.id_subject === 0) {
                  newSchedules.push(dummySchedule);
                }

                setSchedules(newSchedules);
              }}
              menuPortalTarget={document.querySelector("body")}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
            />
          </td>
          <td>
            <Form.Control
              type="time"
              className="text-end"
              value={scheduleItem.timetable.start_at}
              onChange={(e) => {
                let newSchedules = schedules.map((item, i) => {
                  if (i === index) {
                    return {
                      ...scheduleItem,
                      timetable: {
                        ...scheduleItem.timetable,
                        start_at: e.target.value,
                      },
                    };
                  }
                  return item;
                });

                setSchedules(newSchedules);
              }}
              placeholder="Giờ bắt đầu"
              required
              disabled={scheduleItem.id_subject === 0}
            />
          </td>
          <td>
            <Form.Control
              type="time"
              className="text-end"
              value={scheduleItem.timetable.end_at}
              onChange={(e) => {
                let newSchedules = schedules.map((item, i) => {
                  if (i === index) {
                    return {
                      ...scheduleItem,
                      timetable: {
                        ...scheduleItem.timetable,
                        end_at: e.target.value,
                      },
                    };
                  }
                  return item;
                });

                setSchedules(newSchedules);
              }}
              placeholder="Giờ kết thúc"
              required
              disabled={scheduleItem.id_subject === 0}
            />
          </td>
          <td>
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => {
                const newSchedules = [...schedules];
                newSchedules.splice(index, 1);
                setSchedules(newSchedules);
              }}
              disabled={scheduleItem.id_subject === 0}
            >
              <i className="fa-solid fa-times" />
            </Button>
          </td>
        </tr>
      );
    });
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide()}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa fa-graduation-cap me-2"></i>
          {`${daySession.find((item) => item.id === idSession).name} ${schoolDays.find((item) => item.id === idDay).name
            }`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RenderScheduleTable />
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onHide()}>
          <i className="fa-solid fa-times"></i> Hủy
        </Button>
        <Button
          size="sm"
          variant="success"
          onClick={() =>
            onHide(schedules.filter((item) => item.id_subject !== 0))
          }
        >
          <i className="fa-solid fa-check"></i> Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
  //#endregion Render
};
export default ScheduleDaySession;
