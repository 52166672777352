import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Form,
} from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import { Link } from "react-router-dom";

import Check from "../other/Check";
import EvaluationModal from "./EvaluationModal";
import { dateFormatter } from "../utils/helpers";
import api from "../../../helper/axiosInstance";

class EvaluationIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      modalRoleShow: false,

      role: {},
      selectedRows: [],
      checkload: true,
      editModal: false,
      filterText: "",
      studentsSearch: [],
      startDate: new Date(),
      endDate: new Date(),
      typeModal: "",

      //
      evaluation: [],
    };
  }

  componentDidMount() {
    document.title = "Đánh giá";
    this.setState({
      startDate: new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)),
      endDate: this.lastDayy(
        new Date().getFullYear(),
        Number(new Date().getMonth()) + 1
      ),
    });

    this.getevaluation();
  }

  getevaluation = () => {
    api
      .get(`/evaluation-period-shows`)
      .then((res) => {
        this.setState({ checkload: false });
        this.setState({ evaluation: res.data.data?.docs }, () =>
          this.filterStudent("")
        );
      })
      .catch((err) => {});
  };

  toSlug(str) {
    if (str !== "undefined") {
      str = str.toLowerCase(); // Chuyển hết sang chữ thường
      str = str
        .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
      str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
      str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    }

    return str;
  }
  filterStudent = (text) => {
    this.setState({ filterText: text });
    const filteredStudents = this.state.evaluation?.filter((student) =>
      this.toSlug(student?.period_name)?.includes(this.toSlug(text?.trim()))
    );
    this.setState({
      studentsSearch: filteredStudents,
      filterText: text,
    });
  };
  getRole = (id) => {
    if (id !== "") {
      api
        .get(`/evaluation-period-show?id=${id}`)
        .then((res) => {
          this.setState({
            role: res.data.data,
          });

          this.setState({ editModal: true, typeModal: "CN" });
        })
        .catch((err) => {});
    }
  };
  copyItem = (item) => {
    this.setState({
      role: item,
    });

    this.setState({ editModal: true, typeModal: "TM" });
  };

  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa kỳ đánh giá?",

      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .delete(`/evaluation-period-delete?id=${item.id}`)
            .then((res) => {
              toast(res.data.messages, { type: "success", autoClose: 1000 });
              this.getevaluation();
            })
            .catch((err) => {
              toast(err.response.data.data.messages || "Lỗi", {
                type: "error",
                autoClose: 1000,
              });
            });
        }
      })
      .catch((err) => {});
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  lastDayy = (year, month) => {
    const nextMonth = new Date(year, month, 1);
    nextMonth.setDate(nextMonth.getDate() - 1);
    return new Date(nextMonth.setHours(23, 59, 59));
  };
  dataTable() {
    const columns = [
      {
        name: "Tên kỳ",
        selector: (row) => row.period_name,
        sortable: true,
        wrap: true,
      },
      {
        name: "Thời gian bắt đầu",
        selector: (row) => Number(row.start_date),
        sortable: true,
        cell: (row) => (
          <div>{dateFormatter(new Date(row.start_date * 1000))}</div>
        ),
        center: true,
      },
      // id: "updated_at",
      // name: "Cập nhật",
      // selector: (row) => parseInt(row.updated_at),
      // sortable: true,
      // cell: (row) => new FormatData().date(row.updated_at),
      {
        name: "Thời gian kết thúc",
        selector: (row) => Number(row.end_date),
        sortable: true,
        center: true,
        cell: (row) => (
          <div>{dateFormatter(new Date(row.end_date * 1000))}</div>
        ),
      },

      {
        name: "Trạng thái",
        selector: (row) => row.is_status,
        cell: (row) =>
          Number(row.is_status) === 1 ? (
            <div className="text-success">Đang diễn ra</div>
          ) : Number(row.is_status) === 0 ? (
            <div className="text-danger">Đang khóa</div>
          ) : (
            <div className="text-secondary">Bản nháp</div>
          ),
        sortable: true,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
        wrap: true,
        minWidth: "200px",
        // style: { display: "contents" },
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items });
    };

    if (this.state.studentsSearch?.length > 0) {
      this.state.studentsSearch.map((item, i) => {
        data.push({
          ...item,
          setting: (
            <>
              {new Check().permission(["111"]) ? (
                <>
                  <Link to={`/admin-v2/evaluation/${item.id}`}>
                    <Button
                      size="sm"
                      className="me-2"
                      type="button"
                      disabled={item.pay_status}
                      onClick={() => this.getRole(item.id)}
                      title="Kết quả đánh giá"
                    >
                      <i className="fa-solid fa-ranking-star"></i>
                    </Button>
                  </Link>

                  <Button
                    size="sm"
                    variant="warning me-2"
                    type="button"
                    disabled={item.pay_status}
                    onClick={() => this.getRole(item.id)}
                    title="Chi tiết"
                  >
                    <i className="fa-solid fa-pencil"></i>
                  </Button>
                </>
              ) : (
                <></>
              )}

              {new Check().permission(["112"]) ? (
                <Button
                  size="sm"
                  variant="info me-2"
                  type="button"
                  onClick={() => this.copyItem(item)}
                  title="Sao chép"
                >
                  <i className="fa-solid fa-copy"></i>
                </Button>
              ) : null}

              {new Check().permission(["114"]) ? (
                <Button
                  size="sm"
                  variant="danger"
                  type="button"
                  onClick={() => this.handleDelete(item)}
                  disabled={item.pay_status}
                  title="Xóa"
                >
                  <i className="fas fa-trash"></i>
                </Button>
              ) : null}
            </>
          ),
        });

        return item;
      });
    }

    return (
      <DataTables data={data} columns={columns} selectedRows={selectedRows} />
    );
  }

  refreshRole = () => {
    this.setState({
      role: {},
    });
  };

  renderRoleList() {
    const modalClose = () => {
      this.setState({ modalRoleShow: false, editModal: false });
      this.refreshRole();
    };

    const modalSubmit = () => {
      this.setState({ modalRoleShow: false, editModal: false });
      this.refreshRole();
      this.getevaluation();
    };

    return (
      <>
        <Card.Header>
          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách đánh giá
          </Card.Title>
        </Card.Header>

        <Card.Body>
          <Row className="justify-content-between">
            <Col lg={3} md={4}>
              <Form.Control
                className="mb-3"
                style={{ height: 39 }}
                type="text"
                placeholder="Nhập tên kỳ đánh giá"
                value={this.state.filterText}
                onChange={(e) => {
                  this.filterStudent(e.target.value);
                }}
              />
            </Col>

            <Col lg={3} md={4}>
              {new Check().permission(["112"]) ? (
                <Button
                  type="button"
                  variant="success mb-2"
                  size="sm"
                  style={{ float: "right" }}
                  onClick={() =>
                    this.setState({ editModal: true, typeModal: "TM" })
                  }
                  title="Thêm mẫu đánh giá"
                >
                  <i className="fa-solid fa-plus"></i> Thêm
                </Button>
              ) : null}
            </Col>
          </Row>

          {this.state.checkload ? this.loading() : this.dataTable()}
        </Card.Body>

        {this.state.editModal ? (
          <>
            <EvaluationModal
              show={this.state.editModal}
              onHide={modalClose}
              modal={{
                title: "Thêm kỳ đánh giá",
                button: (
                  <>
                    <i className="fa-solid fa-check" /> Lưu
                  </>
                ),
              }}
              type={this.state.typeModal}
              datarole={this.state.role}
              submit={modalSubmit}
            />
          </>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 ">
            <Breadcrumb.Item active>Nhân viên</Breadcrumb.Item>
            <Breadcrumb.Item active>Đánh giá</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default EvaluationIndex;
