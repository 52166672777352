import React from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
function TableReportRegisterMeal({ data, groupChoice }) {
  const transformData = (data, idGroup) => {
    const tableData = [];

    data.forEach((dayItem) => {
      const day = dayItem.day;
      const dayFoodMap = {};

      dayItem.times.forEach((timeItem) => {
        timeItem.foods.forEach((foodItem) => {
          const foodName = foodItem.food_name;

          const relevantGroups =
            idGroup === 0
              ? foodItem.groups
              : foodItem.groups.filter((group) => group.id === idGroup);

          if (relevantGroups.length === 0) return;

          const totalPortions = relevantGroups.reduce(
            (sum, group) => sum + group.number_of_portions,
            0
          );

          if (dayFoodMap[foodName]) {
            dayFoodMap[foodName].total_portions += totalPortions;
          } else {
            dayFoodMap[foodName] = {
              day: day,
              food_name: foodName,
              total_portions: totalPortions,
            };
          }
        });
      });

      tableData.push(...Object.values(dayFoodMap));
    });

    return tableData;
  };

  const tableData = transformData(data, groupChoice.id);

  return (
    <div className="table-responsive-600">
      {}
      {tableData?.length > 0 ? (
        <>
          <Table bordered>
            <thead>
              <tr className="align-middle text-center">
                <th>Thời gian</th>
                <th>Tên món</th>
                <th>Số lượng</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => {
                const showDay =
                  index === 0 || row.day !== tableData[index - 1].day;
                const dayRowCount = tableData.filter(
                  (item) => item.day === row.day
                ).length;

                return (
                  <tr key={`${row.day}-${index}`} className="align-middle ">
                    {showDay && <th rowSpan={dayRowCount}>{row.day}</th>}
                    <td>{row.food_name}</td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={parseFloat(row.total_portions) || 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={0}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <p className="text-center">Không có dữ liệu...</p>
      )}
      <Table bordered id="export-report-register-meal" className="d-none">
        <thead>
          <tr className="align-middle text-center">
            <th>Thời gian</th>
            <th>Tên món</th>
            <th>Số lượng</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => {
            // const showDay =
            //   index === 0 || row.day !== tableData[index - 1].day;
            // const dayRowCount = tableData.filter(
            //   (item) => item.day === row.day
            // ).length;

            return (
              <tr key={`${row.day}-${index}`} className="align-middle ">
                {/* {showDay && <th rowSpan={dayRowCount}>{row.day}</th>} */}
                <th>{row.day}</th>
                <td>{row.food_name}</td>
                <td className="text-end">
                  <CurrencyFormat
                    value={parseFloat(row.total_portions) || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default TableReportRegisterMeal;
