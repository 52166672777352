import * as XLSX from "xlsx-js-style";

const fontStyle = {
	name: "Times New Roman",
	sz: 9,
};

const borderStyle = {
	top: { style: "thin" },
	bottom: { style: "thin" },
	left: { style: "thin" },
	right: { style: "thin" },
};

const exportQuantitativeKitchen = (quantitative, ingredientQuantity, classGroups) => {
	const table = document.getElementById(`quantitative-kitchen-table`);
	var ws = XLSX.utils.table_to_sheet(table, { raw: true });

	const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ",];

	//Format header
	ws[`A1`].s = {
		font: {
			...fontStyle,
			bold: true,
			sz: 10,
		},
		alignment: {
			vertical: "center",
			horizontal: "center",
		},
	};

	colAlpha.slice(0, quantitative.costPerDays?.length * 3 + 6).map((alpha) => {
		ws[`${alpha}2`].s = {
			font: {
				...fontStyle,
				bold: true,
			},
			alignment: {
				wrapText: true,
				vertical: "center",
				horizontal: "center",
			},
			border: borderStyle,
		};

		return alpha;
	});

	colAlpha.slice(0, quantitative.costPerDays?.length * 3 + 6).map((alpha) => {
		ws[`${alpha}3`].s = {
			font: {
				...fontStyle,
				bold: true,
				sz: 10,
			},
			alignment: {
				wrapText: true,
				vertical: "center",
				horizontal: "center",
			},
			border: borderStyle,
		};

		return alpha;
	});

	for (let i = 4; i < ingredientQuantity + 4; i++) {
		colAlpha.slice(0, quantitative.costPerDays?.length * 3 + 6).map((alpha) => {
			if (ws[`${alpha}${i}`].v === "empty") {
				ws[`${alpha}${i}`].v = " ";
			}

			ws[`${alpha}${i}`].s = {
				font: {
					...fontStyle,
					sz: 10,
				},
				alignment: {
					wrapText: true,
					vertical: "center",
					horizontal: alpha === "A" || alpha === "B" || alpha === "C" || alpha === "D" ? "left" : "right",
				},
				border: borderStyle,
			};
			return alpha;
		});
	}

	const cols = [{ wch: 8 }, { wch: 15 }, { wch: 15 }, { wch: 8 }];

	colAlpha.slice(3, quantitative.costPerDays?.length * 3 + 3).map((alpha) => {
		cols.push({ wch: 21 / quantitative.costPerDays.length });

		return alpha;
	});

	cols.push({ wch: 9.5 });
	cols.push({ wch: 9.5 });

	ws["!cols"] = cols;

	ws["!rows"] = [{ hpt: 30 }, { hpt: 40 }, { hpt: 40 }];

	const merge = [
		{ s: { r: 0, c: 0 }, e: { r: 0, c: quantitative.costPerDays.length * 3 + 5 } },

		{ s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
		{ s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
		{ s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
		{ s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },

		{ s: { r: 1, c: 4 }, e: { r: 1, c: 4 + quantitative.costPerDays.length - 1 } },
		{ s: { r: 1, c: 4 + quantitative.costPerDays.length }, e: { r: 1, c: 4 + quantitative.costPerDays.length * 2 - 1 } },
		{ s: { r: 1, c: 4 + quantitative.costPerDays.length * 2 }, e: { r: 1, c: 4 + quantitative.costPerDays.length * 3 - 1 } },

		{ s: { r: 1, c: quantitative.costPerDays.length * 3 + 4 }, e: { r: 2, c: quantitative.costPerDays.length * 3 + 4 } },
		{ s: { r: 1, c: quantitative.costPerDays.length * 3 + 5 }, e: { r: 2, c: quantitative.costPerDays.length * 3 + 5 } },
	];

	let timeIndex = 3;
	let foodIndex = 3;

	quantitative.times.map((timeItem) => {
		let timeQuantityIngredient = 0;
		if (timeItem.foods.length == 0) {
			timeQuantityIngredient++

		}
		timeItem.foods?.map((foodItem) => {
			if (foodItem.groups[0].ingredents.length === 0) {
				timeQuantityIngredient++;

				return foodItem;
			}

			return foodItem.groups[0]?.ingredents?.map((ingredientItem) => {
				timeQuantityIngredient++;
				return ingredientItem;
			});
		});

		// Merge meal
		merge.push({
			s: { r: timeIndex, c: 0 },
			e: { r: timeIndex + timeQuantityIngredient - 1, c: 0 }
		});

		ws[`A${timeIndex + 1}`].s = {
			font: {
				...fontStyle,
				bold: true,
			},
			alignment: {
				wrapText: true,
				vertical: "center",
				horizontal: "center",
			},
			border: borderStyle,
		};


		timeIndex += timeQuantityIngredient;
		if (timeItem.foods.length == 0) {
			foodIndex++
			// return timeItem;
		}

		timeItem.foods.map((foodItem) => {
			if (foodItem.groups[0].ingredents.length === 0) {
				timeQuantityIngredient++;

				merge.push({
					s: { r: foodIndex, c: 1 },
					e: { r: foodIndex, c: 1 }
				});


				foodIndex += 1;
				return foodItem;
			}

			//Merge Food name
			merge.push({
				s: { r: foodIndex, c: 1 },
				e: { r: foodIndex + foodItem.groups[0].ingredents.length - 1, c: 1 }
			});

			//Format Food name
			ws[`B${foodIndex + 1}`].s = {
				font: {
					...fontStyle,
					bold: true,
				},
				alignment: {
					wrapText: true,
					vertical: "center",
					horizontal: "left"
				},
				border: borderStyle,
			};

			foodIndex += foodItem.groups[0].ingredents.length;
			return foodItem;
		});

		return timeItem;
	});

	ws["!merges"] = merge;

	return ws;
};

const exportWeekQuantitativeKitchen = (dayLength, listTimes, listQuantitatives, selectedClassGroup) => {
	const table = document.getElementById(`week-quantitative-kitchen-table`);
	var ws = XLSX.utils.table_to_sheet(table, { raw: true });

	const initColAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W"];
	const colAlpha = initColAlpha.slice(0, dayLength * 3 + 2);

	// Header
	ws[`A1`].s = {
		font: {
			...fontStyle,
			bold: true,
		},
		alignment: {
			vertical: "center",
			horizontal: "center",
			wrapText: 'true'
		},
	};

	ws[`${colAlpha[5]}1`].s = {
		font: {
			...fontStyle,
			bold: true,
		},
		alignment: {
			vertical: "center",
			horizontal: "center",
			wrapText: true,
		},
	};

	// Số lượng trẻ
	for (let i = 1; i <= dayLength; i++) {
		ws[`${colAlpha[i * 3 - 1]}2`].s = {
			font: {
				...fontStyle,
				bold: true
			},
			alignment: {
				vertical: "center",
				horizontal: "right",
				wrapText: true
			},
		};
	}

	ws[`${colAlpha[dayLength * 3 - 1]}1`].s = {
		font: {
			...fontStyle,
			bold: true
		},
		alignment: {
			vertical: "center",
			horizontal: "right",
			wrapText: true
		},
	};
	ws[`A2`].s = {
		font: {
			...fontStyle,
			// bold: true,
		},
		alignment: {
			vertical: "center",
			horizontal: "center",
			wrapText: 'true'
		},
	};

	const merge = [
		{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } },
		{ s: { r: 0, c: 5 }, e: { r: 0, c: dayLength * 3 - 2 } },
		{ s: { r: 0, c: dayLength * 3 - 1 }, e: { r: 0, c: dayLength * 3 + 1 } },

		{ s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
		// { s: { r: 1, c: 3 }, e: { r: 1, c: dayLength * 3 - 2 } },
		// { s: { r: 1, c: dayLength * 3 - 1 }, e: { r: 1, c: dayLength * 3 + 1 } },
	];

	// Số lượng trẻ
	for (let i = 1; i <= dayLength; i++) {
		merge.push(
			{ s: { r: 1, c: i * 3 - 1 }, e: { r: 1, c: i * 3 + 1 } },
		)
	}

	colAlpha.map((alpha) => {
		if (ws[`${alpha}3`].v === "empty") {
			ws[`${alpha}3`].v = " ";
		}

		ws[`${alpha}3`].s = {
			font: {
				...fontStyle,
				bold: true,
			},
			alignment: {
				vertical: "center",
				horizontal: "center",
			},
			border: borderStyle,
		};

		return alpha;
	});

	for (let i = dayLength * 3 + 2; i > 3; i -= 3) {
		// theo thứ
		merge.push({ s: { r: 2, c: i - 3 }, e: { r: 2, c: i - 1 } });
	}

	// Body
	let totalLength = 4;
	let totalLengthTmp = 4;

	const moneyFormat = "#,###,###,###";

	listTimes.map((timeItem) => {
		const quantitativeTimes = [];
		let categoryTimes = [];
		listQuantitatives.map((quantitativeItem) => {
			return quantitativeItem.times.map((timeObj) => {
				if (timeObj.id_time === timeItem.id_time) {
					quantitativeTimes.push(timeObj);
					let filteredFoods = timeObj.foods
						.filter((foodItem) =>
							foodItem.groups.some(
								(groupItem) => groupItem.id === selectedClassGroup.id && groupItem.ingredents.length !== 0,
							),
						);

					let categoryTimesToAdd = [];

					if (filteredFoods.length > 0) {
						categoryTimesToAdd = filteredFoods.map((foodItem) => {
							return {
								id_category: foodItem.id_category,
								id_category_bank: foodItem.id_category_bank,
								category_name: foodItem.category_name,
								count_meal: quantitativeItem?.costPerDays,
								more_meal: foodItem?.groups?.filter(meal => meal.id == selectedClassGroup?.id)[0]?.more_meal ?? 0
							};
						});
					} else {
						categoryTimesToAdd.push({
							id_category: '',
							id_category_bank: '',
							category_name: 'empty',
							count_meal: [],
							more_meal: 0
						});
					}

					categoryTimes = categoryTimes.concat(categoryTimesToAdd);
				}

				return timeObj;
			});
		});
		categoryTimes = [...new Map(categoryTimes.map((item) => [item["id_category"], item])).values()].sort(function (a, b) {
			return (
				(a.id_category_bank === null) - (b.id_category_bank === null) ||
				+(a.id_category_bank > b.id_category_bank) ||
				-(a.id_category_bank < b.id_category_bank)
			);
		});
		let totalCateLength = 0;
		let dataCate = categoryTimes?.filter(ca => ca.id_category != '').length > 0 ? categoryTimes?.filter(ca => ca.id_category != '') : categoryTimes

		categoryTimes = dataCate.map((categoryItem) => {

			const listItemRows = quantitativeTimes.map((quantitativeTimeItem) => {
				let rowSpanLength = 0;

				quantitativeTimeItem.foods.filter((foodItem) => foodItem.id_category === categoryItem.id_category)
					.map((foodItem) => {
						if (foodItem.groups.some((groupItem) => groupItem.id === selectedClassGroup.id && groupItem.ingredents.length !== 0)) {
							rowSpanLength += 1;
							rowSpanLength += foodItem.groups[0].ingredents.length;

						}

						return foodItem;
					});
				return rowSpanLength !== 0 ? rowSpanLength : 1;

			});

			const maxRowSpanLength = Math.max(...listItemRows.map((itemRow) => itemRow));

			// Theo danh mục
			merge.push({ s: { r: totalLengthTmp - 1, c: 1 }, e: { r: totalLengthTmp + maxRowSpanLength - 2, c: 1 } });

			totalLengthTmp += maxRowSpanLength;
			totalCateLength += maxRowSpanLength;

			return categoryItem;
		});

		merge.push({
			s: { r: totalLength - 1, c: 0 },
			e: { r: totalLength + totalCateLength - 2, c: 0 }
		});

		totalLength += totalCateLength;
		return timeItem;
	});


	for (let i = 4; i < totalLength; i++) {
		colAlpha.map((alpha, alphaIndex) => {
			if (ws[`${alpha}${i}`].v === "empty") {
				ws[`${alpha}${i}`].v = " ";
			}

			if ((alphaIndex - 1) % 3 === 0) {
				ws[`${alpha}${i}`].z = moneyFormat;
				delete ws[`${alpha}${i}`].w;
				XLSX.utils.format_cell(ws[`${alpha}${i}`]);
			}

			ws[`${alpha}${i}`].s = {
				font: fontStyle,
				alignment: {
					wrapText: true,
					vertical: "center",
					horizontal: (alphaIndex + 1) % 3 === 0 ? "start" : "center",
				},
				border: borderStyle,
			};
			return alpha;
		});

		ws[`A${i}`].s = {
			font: {
				...fontStyle,
				bold: true,
			},
			alignment: {
				wrapText: true,
				vertical: "center",
				horizontal: "center",
			},
			border: borderStyle,
		};

		ws[`B${i}`].s = {
			font: {
				...fontStyle,
				bold: true,
			},
			alignment: {
				wrapText: true,
				vertical: "center",
				horizontal: "center",
			},
			border: borderStyle,
		};
	}

	let totalLengthClone = 4;

	listTimes.map((timeItem) => {
		const quantitativeTimes = [];
		let categoryTimes = [];

		listQuantitatives.map((quantitativeItem) => {
			return quantitativeItem.times.map((timeObj) => {

				if (timeObj.id_time === timeItem.id_time) {
					quantitativeTimes.push(timeObj);
					categoryTimes = categoryTimes.concat(
						timeObj.foods.filter((foodItem) => foodItem.groups.some(
							(groupItem) => groupItem.id === selectedClassGroup.id && groupItem.ingredents.length !== 0))
							.map((foodItem) => {

								return {
									id_category: foodItem.id_category,
									id_category_bank: foodItem.id_category_bank,
									category_name: foodItem.category_name,
								};
							}),
					);

				}

				return timeObj;
			});
		});



		categoryTimes = [...new Map(categoryTimes.map((item) => [item["id_category"], item])).values()].sort(function (a, b) {
			return (
				(a.id_category_bank === null) - (b.id_category_bank === null) ||
				+(a.id_category_bank > b.id_category_bank) ||
				-(a.id_category_bank < b.id_category_bank)
			);
		});

		let totalCateLength = 0;
		let dataCate = categoryTimes?.filter(ca => ca.id_category != '').length > 0 ? categoryTimes?.filter(ca => ca.id_category != '') : categoryTimes

		categoryTimes = dataCate.map((categoryItem) => {
			const listItemRows = quantitativeTimes.map((quantitativeTimeItem, quantitativeTimeItemIndex) => {
				let rowSpanLength = 0;

				quantitativeTimeItem.foods
					.filter((foodItem) => foodItem.id_category === categoryItem.id_category)
					.map((foodItem) => {
						if (foodItem.groups.some((groupItem) => groupItem.id === selectedClassGroup.id && groupItem.ingredents.length !== 0)) {
							// theo món
							merge.push({
								s: { r: totalCateLength + totalLengthClone + rowSpanLength - 1, c: quantitativeTimeItemIndex * 3 + 2 },
								e: { r: totalCateLength + totalLengthClone + rowSpanLength - 1, c: quantitativeTimeItemIndex * 3 + 4 },
							});

							ws[`${colAlpha[quantitativeTimeItemIndex * 3 + 2]}${totalCateLength + totalLengthClone + rowSpanLength}`].s = {
								font: {
									...fontStyle,
									bold: true,
								},
								alignment: {
									wrapText: true,
									vertical: "center",
									horizontal: "center",
								},
							};

							rowSpanLength += 1;
							rowSpanLength += foodItem.groups[0].ingredents.length;
						}

						return foodItem;
					});

				return rowSpanLength !== 0 ? rowSpanLength : 1;
			});

			const maxRowSpanLength = Math.max(...listItemRows.map((itemRow) => itemRow));
			totalCateLength += maxRowSpanLength;


			return categoryItem;
		});
		totalLengthClone += totalCateLength;

		return timeItem;
	});

	// Footer
	ws[`A${extractNumber(findCellByValue(ws, "Người lập"))}`].s = {
		font: {
			...fontStyle,
			bold: true,
		},
		alignment: {
			vertical: "center",
			horizontal: "center",
		},
	};
	ws[`${findCellByValue(ws, "Hiệu phó bán trú").replace(/\d/g, '')}${extractNumber(findCellByValue(ws, "Hiệu phó bán trú"))}`].s = {
		font: {
			...fontStyle,
			bold: true,
		},
		alignment: {
			vertical: "center",
			horizontal: "center",
		},
	};

	merge.push({ s: { r: extractNumber(findCellByValue(ws, "Hiệu phó bán trú")) - 1, c: 0 }, e: { r: extractNumber(findCellByValue(ws, "Hiệu phó bán trú")) - 1, c: Math.ceil((dayLength * 3 + 2) / 2) } });
	merge.push({ s: { r: extractNumber(findCellByValue(ws, "Hiệu phó bán trú")) - 1, c: Math.ceil((dayLength * 3 + 2) / 2) + 1 }, e: { r: extractNumber(findCellByValue(ws, "Hiệu phó bán trú")) - 1, c: dayLength * 3 + 1 } });

	const cols = [{ wch: 6 }, { wch: 6 }];

	let n = 114 / dayLength;
	for (let i = 2; i < dayLength * 3 + 2; i += 3) {
		cols.push({ wch: 0.48 * n });
		cols.push({ wch: 0.26 * n });
		cols.push({ wch: 0.26 * n });
	}

	ws["!cols"] = cols;

	ws["!rows"] = [{ hpt: 40 }, { hpt: '' }, { hpt: '' }];
	for (let i = 4; i < totalLength; i++)
		ws["!rows"].push({ hpt: 15 * 2 })
	ws["!merges"] = merge;

	ws['!margins'] = { left: 0.25, right: 0.25, top: 0.75, bottom: 0.75, header: 0.3, footer: 0.3 };
	ws['!pageSetup'] = {
		orientation: "landscape",
		paperSize: 9, // A4 paper
		scale: 100,
		fitToPage: false,
		fitToWidth: 1,
		fitToHeight: 0
	};

	return ws;

};

const findCellByValue = (data, targetValue) => {
	for (const cellRef in data) {
		if (
			data.hasOwnProperty(cellRef) &&
			data[cellRef].t === "s" &&
			data[cellRef].v == targetValue
		) {
			return cellRef;
		}
	}
	return null; // Return null if the target value is not found
};

const extractNumber = (inputString) => {
	const numberPart = inputString.replace(/[^\d]/g, "");
	return parseInt(numberPart, 10);
};

export { exportQuantitativeKitchen, exportWeekQuantitativeKitchen };
