import React from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import FormSettingDatePicker from "./FormSettingDatePicker";

const Book1 = ({ formSetting, setFormSetting }) => {
  return (
    <React.Fragment>
      <Row>
        <Col md={8}>
          <Table bordered>
            <tbody>
              <tr>
                <td className="align-middle">Người kiểm tra:</td>
                <td>
                  <Form.Control
                    type="text"
                    value={formSetting.step1_checker || ""}
                    onChange={(e) =>
                      setFormSetting({
                        ...formSetting,
                        step1_checker: e.target.value,
                      })
                    }
                  />
                </td>
                <td className="align-middle" colSpan={2}>
                  <Form.Check
                    type="switch"
                    checked={
                      formSetting.step1_show_inventory === 1 ? true : false
                    }
                    onChange={(e) =>
                      setFormSetting({
                        ...formSetting,
                        step1_show_inventory: e.target.checked ? 1 : 0,
                      })
                    }
                    label="Hiển thị hàng kho"
                  />
                </td>
              </tr>

              <tr>
                <td className="align-middle">Địa điểm kiểm tra:</td>
                <td colSpan={3}>
                  <Form.Control
                    type="text"
                    value={formSetting.step1_check_address || ""}
                    onChange={(e) =>
                      setFormSetting({
                        ...formSetting,
                        step1_check_address: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </Table>

          <Table bordered>
            <thead>
              <tr>
                <th colSpan={2}>
                  I. Thực phẩm tươi sống, đông lạnh: thịt, cá, rau củ, quả....
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="text-center w-50">
                  Thời gian nhập (ngày, giờ)
                  <br />
                  (3)
                </td>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <td className="w-50 text-center">
                  <FormSettingDatePicker
                    selected={
                      formSetting.step1_m1_3 || new Date().getTime() / 1000
                    }
                    onChange={(date) =>
                      setFormSetting({
                        ...formSetting,
                        step1_m1_3: date.getTime(),
                      })
                    }
                  />
                </td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </Table>

          <Table bordered>
            <thead>
              <tr>
                <th colSpan={2}>
                  II. Thực phẩm khô và thực phẩm bao gói sẵn, phụ gia thực phẩm
                </th>
              </tr>
            </thead>

            <tbody>
              <tr className="text-center">
                <td className="align-middle">
                  Thời gian nhập (ngày, giờ)
                  <br />
                  (5)
                </td>
                <td className="align-middle">
                  Điều kiện bảo quản (thường, lạnh...)
                  <br />
                  (11)
                </td>
              </tr>

              <tr>
                <td className="w-50 text-center">
                  <FormSettingDatePicker
                    selected={
                      formSetting.step1_m2_5 || new Date().getTime() / 1000
                    }
                    onChange={(date) =>
                      setFormSetting({
                        ...formSetting,
                        step1_m2_5: date.getTime(),
                      })
                    }
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </td>
                <td className="w-50">
                  <Form.Control
                    type="text"
                    value={formSetting.step1_m2_11 || ""}
                    onChange={(e) =>
                      setFormSetting({
                        ...formSetting,
                        step1_m2_11: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Book1;
