import React, { Component } from "react";
import {Container, Breadcrumb, Tabs, Tab } from "react-bootstrap";
import FinanceDayBook from "./FinanceDayBook";
import FinanceMonthBook from "./FinanceMonthBook";

class FinanceBook extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	async componentDidMount() {
		document.title = 'Sổ tài chính công khai';
	}

    render(){
        return(
            <Container fluid >
                <Breadcrumb>
                    <Breadcrumb.Item active>Báo cáo</Breadcrumb.Item>
                    <Breadcrumb.Item active>Sổ tài chính</Breadcrumb.Item>
                </Breadcrumb>

                <Tabs defaultActiveKey={1} className="mb-3">
                    <Tab eventKey={1} title="Sổ theo ngày">
                        <FinanceDayBook />
                    </Tab>
                    <Tab eventKey={2} title="Sổ theo tháng">
                        <FinanceMonthBook />
                    </Tab>
                </Tabs>
			</Container>
        )
    }
}

export default FinanceBook;