import React from "react";
import { Form, Table } from "react-bootstrap";
import FormSettingDatePicker from "./FormSettingDatePicker";

const Book3 = ({ formSetting, setFormSetting, times }) => {
  let show_item_single = null;
  const qty_merge =
    times.filter((itemTime) => itemTime.time_name_now !== null)?.length || 1;

  return (
    <div style={{ overflowX: "scroll" }}>
      <Table bordered style={{ minWidth: 999 }}>
        <thead>
          <tr>
            <td colSpan={2} className="align-middle">
              Người kiểm tra:
            </td>
            <td>
              <Form.Control
                type="text"
                value={formSetting.step3_checker || ""}
                onChange={(e) =>
                  setFormSetting({
                    ...formSetting,
                    step3_checker: e.target.value,
                  })
                }
              />
            </td>
          </tr>

          <tr>
            <td colSpan={2} className="align-middle">
              Địa điểm kiểm tra:
            </td>
            <td colSpan={2}>
              <Form.Control
                type="text"
                value={formSetting.step3_check_address || ""}
                onChange={(e) =>
                  setFormSetting({
                    ...formSetting,
                    step3_check_address: e.target.value,
                  })
                }
              />
            </td>
          </tr>
        </thead>

        <thead>
          <tr className="text-center">
            <td className="align-middle" style={{ minWidth: "8rem" }}>
              Buổi
            </td>
            <td className="align-middle">Ca/bữa ăn (Bữa ăn, giờ ăn...)</td>
            <td className="align-middle">
              Thời gian chia món ăn xong (ngày, giờ)
            </td>
            <td className="align-middle">Thời gian bắt đầu ăn (ngày, giờ)</td>
            <td className="align-middle">
              Dụng cụ chia, chứa đựng, che đậy, bảo quản thức ăn
            </td>
          </tr>
          <tr className="text-center">
            <td className="align-middle"></td>
            <td className="align-middle">(2)</td>
            <td className="align-middle">(5)</td>
            <td className="align-middle">(6)</td>
            <td className="align-middle">(7)</td>
          </tr>
        </thead>
        <tbody>
          {times.map((timeItem, timeIndex) => {
            if (show_item_single === null && timeItem.time_name_now !== null)
              show_item_single = timeIndex;

            return (
              <tr
                key={timeItem.id}
                className={timeItem.time_name_now === null ? "d-none" : ""}
              >
                <td>{timeItem.time_name_now}</td>
                <td className="text-center">
                  <FormSettingDatePicker
                    selected={
                      Number(
                        formSetting.step3_2?.filter(
                          (e) => e.time == timeItem.id
                        )[0]?.val
                      ) || new Date()
                    }
                    onChange={(date) =>
                      setFormSetting({
                        ...formSetting,
                        step3_2: formSetting.step3_2?.map(
                          (timeObj, timeObjIndex) => {
                            if (parseInt(timeItem.id) === timeObjIndex + 1) {
                              return {
                                ...timeObj,
                                time: timeItem.id,
                                val: date.getTime(),
                              };
                            }

                            return timeObj;
                          }
                        ),
                      })
                    }
                  />
                </td>
                <td className="text-center">
                  <FormSettingDatePicker
                    selected={
                      Number(
                        formSetting.step3_5?.filter(
                          (e) => e.time == timeItem.id
                        )[0]?.val
                      ) || new Date()
                    }
                    onChange={(date) =>
                      setFormSetting({
                        ...formSetting,
                        step3_5: formSetting.step3_5?.map(
                          (timeObj, timeObjIndex) => {
                            if (parseInt(timeItem.id) === timeObjIndex + 1) {
                              return {
                                ...timeObj,
                                time: timeItem.id,
                                val: date.getTime(),
                              };
                            }

                            return timeObj;
                          }
                        ),
                      })
                    }
                  />
                </td>
                <td className="text-center">
                  <FormSettingDatePicker
                    selected={
                      Number(
                        formSetting.step3_6?.filter(
                          (e) => e.time == timeItem.id
                        )[0]?.val
                      ) || new Date()
                    }
                    onChange={(date) =>
                      setFormSetting({
                        ...formSetting,
                        step3_6: formSetting.step3_6?.map(
                          (timeObj, timeObjIndex) => {
                            if (parseInt(timeItem.id) === timeObjIndex + 1) {
                              return {
                                ...timeObj,
                                time: timeItem.id,
                                val: date.getTime(),
                              };
                            }

                            return timeObj;
                          }
                        ),
                      })
                    }
                  />
                </td>

                {show_item_single === timeIndex ? (
                  <td rowSpan={qty_merge} className="text-center align-middle">
                    <Form.Control
                      type="text"
                      value={formSetting.step3_7}
                      onChange={(e) =>
                        setFormSetting({
                          ...formSetting,
                          step3_7: e.target.value,
                        })
                      }
                    />
                  </td>
                ) : (
                  ""
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default Book3;
