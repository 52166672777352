import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/admin/assets/img/logo_kafood_4.png";

const Navbar = () => {
  if (localStorage.getItem("access_token")) {
    window.location.href = "/admin-v2";
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary shadow sticky-top">
      <div className="container">
        <Link className="navbar-brand ps-3" to="/login">
          <img src={Logo} style={{ width: 69, height: 17 }} alt="Kafood" />
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
