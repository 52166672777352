import React, { Component } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Modal,
  Table,
  Form,
  Col,
  Row,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../../other/Check";
import DatePicker from "react-datepicker";
import DataTables from "../../datatable/DataTables";
import swal from "sweetalert";
import api from "../../../../helper/axiosInstance";
import DatePickerCustom from "../../../uiElements/DatePickerCustom";
import { vi } from "date-fns/locale";

class ListmealIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cutMeals: [],
      cutMealDetail: [],
      cutMealDetailSearch: [],
      searchtext: "",
      classifyShow: 1,
      toDay: new Date(),
      listClass: [],
      showCutMealDetail: false,
      checkloading: true,
      startDate: new Date().setHours(0, 0, 0),
      endDate: new Date().setHours(23, 59, 59),
      startDateFilterClass: new Date().setHours(0, 0, 0),
      endDateFilterClass: new Date().setHours(23, 59, 59),
      idClass: {},
      classReport: [],
      id_class: "",
      pendingConfirm: false,
      pendingDestroy: false,
    };
  }

  async componentDidMount() {
    document.title = "Suất ăn";
    this.setState({
      startDateFilterClass: new Date(
        new Date(new Date().setDate(1)).setHours(0, 0, 0)
      ),
      endDateFilterClass: this.lastDayy(
        new Date().getFullYear(),
        new Date().getMonth() + 1
      ),
    });
    this.getClassRoom(
      new Date(new Date().setHours(0, 0, 0)),
      new Date(new Date().setHours(23, 59, 59))
    );
  }

  lastDayy = (year, month) => {
    const nextMonth = new Date(year, month, 1);
    nextMonth.setDate(nextMonth.getDate() - 1);
    return new Date(nextMonth.setHours(23, 59, 59));
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  searchText = (text, data) => {
    if (text != "")
      this.setState({
        searchtext: text,
        cutMealDetailSearch: data?.filter((e) =>
          this.toSlug(e.student_name).includes(this.toSlug(text?.trim() || ""))
        ),
      });
    else {
      this.setState({
        searchtext: text,
        cutMealDetailSearch: data,
      });
    }
  };

  getDataMeal = (start, end) => {
    api
      .get(
        `day-off-shows?&start_date=${parseInt(
          start / 1000
        )}&end_date=${parseInt(end / 1000)}
       `
      )
      .then((res) => {
        this.setState({ cutMeals: res.data.data });
      })
      .catch((err) => {});
  };

  delCut = (id) => {
    swal({
      title: `Bạn chắc muốn xóa cắt xuất?`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    })
      .then(async (ok) => {
        if (ok) {
          this.setState({ pendingDestroy: true });
          await api.delete(`/day-off-destroy?id=${id}`).then((res) => {
            this.setState({
              cutMealDetailSearch: this.state.cutMealDetailSearch.filter(
                (e) => e.id != id
              ),
              pendingDestroy: false,
            });

            toast(res.data.messages, { type: "success" });
          });
        }
      })
      .catch((e) => {
        this.setState({ pendingDestroy: false });
        toast(e.message, { type: "error" });
      });
  };

  getClassRoom = (start, end) => {
    this.setState({ checkloading: true });
    api
      .get(
        `/classroom-shows?start_date=${parseInt(
          start / 1000
        )}&end_date=${parseInt(end / 1000)}`
      )
      .then((res) => {
        this.setState({
          checkloading: false,
        });
        this.getlistCutAll(res.data.data?.classrooms, start, end);
      })
      .catch((err) => this.setState({ checkloading: true }));
  };

  getlistCutAll = (dataClass, start, end) => {
    api
      .get(
        `/report-class?start_date=${parseInt(start / 1000)}&end_date=${parseInt(
          end / 1000
        )}`
      )
      .then((res) => {
        let arr = [];
        for (const obj1 of dataClass)
          arr.push({
            ...obj1,
            countStudent:
              res.data.data[0]?.class?.filter((e) => e.id_class == obj1.id)[0]
                ?.countStudent || 0,
          });

        this.setState({ listClass: arr });
        this.getDataMeal(start, end);
      });
  };

  getCutMealDetailData = (idClass, start, end) => {
    api
      .get(
        `day-off-shows?id_class=${idClass}&start_date=${parseInt(
          start / 1000
        )}&end_date=${end / 1000}`
      )
      .then((res) => {
        this.setState({
          cutMealDetail: res.data.data,
          cutMealDetailSearch: res.data.data,
        });
        this.searchText(this.state.searchtext, res.data.data);
      })
      .catch((err) => {});
  };

  handleApprove = (id) => {
    let link = `/changeMoreStatus?${
      this.state.classifyShow.toString() === "1"
        ? `id_class=${id}`
        : `id_person=${id}`
    }`;
    api.post(link).then((res) => {
      if (res.data.status === true) {
        toast(res.data.data.mess, { type: "success", autoClose: 1000 });
      } else {
        toast(res.data.data.mess, { type: "error", autoClose: 1000 });
      }
    });
  };
  handleApproveToday = () => {
    let link = `/day-off-confirm-all`;
    api
      .put(link)
      .then((res) => {
        toast(res.data.messages, { type: "success", autoClose: 1000 });
      })
      .catch((err) => toast("Xảy ra lỗi", { type: "error", autoClose: 1000 }));
  };

  confirmChangeStatus = (id_meal, is_status, is_valid, id_class) => {
    if (is_valid == 0 && is_status == 0) {
      swal({
        title: "Chọn trạng thái duyệt cắt xuất.",
        icon: "warning",
        buttons: {
          cancel: "Đóng",
          catch: {
            text: "Báo đúng",
            value: "true",
          },
          defeat: {
            text: "Báo trễ",
            value: "false",
            dangerMode: true,
          },
        },
      }).then((value) => {
        switch (value) {
          case "false":
            return this.handleCanelMeal(
              id_meal,
              !is_status,
              (is_valid = null),
              id_class
            );
          case "true":
            return this.handleCanelMeal(
              id_meal,
              !is_status,
              (is_valid = 2),
              id_class
            );

          default:
            break;
        }
      });
    } else {
      this.handleCanelMeal(id_meal, !is_status, (is_valid = null), id_class);
    }
  };

  handleCanelMeal = async (id, is_status, is_valid, id_class) => {
    let data = { id: id, is_status: is_status ? 1 : 0, is_valid: is_valid };
    this.setState({ pendingConfirm: true });

    await api
      .put(`/day-off-confirm`, data)
      .then((res) => {
        this.setState({ pendingConfirm: false });

        toast(res.data.messages, { type: "success", autoClose: 1000 });
        this.getCutMealDetailData(
          id_class,
          this.state.startDateFilterClass,
          this.state.endDateFilterClass
        );
      })
      .catch((e) => {
        this.setState({ pendingConfirm: false });
        toast(e.message, { type: "error" });
      });
  };

  dataTable() {
    const columns = [
      {
        name: "Tên lớp",
        selector: (row) => row.class_name,
        sortable: true,
      },
      {
        name: "Giáo viên",
        selector: (row) => row.teacher_name,
        sortable: true,
        style: {
          div: {
            overflow: "inherit !important ",
            width: "200px",
          },
        },
      },

      {
        name: "SL đi học",
        selector: (row) => row.count_student,
        sortable: true,
        center: true,
      },
      {
        name: "SL đã duyệt",
        selector: (row) => row.daduyet,
        sortable: true,
        center: true,
      },
      {
        name: "Tổng chưa duyệt",
        selector: (row) => row.chuaduyet,
        sortable: true,
        center: true,
        style: {
          div: {
            fontWeight: "bold",
          },
        },
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.listClass.length > 0) {
      this.state.listClass.map((item, i) => {
        data.push({
          class_name: item.class_name,
          teacher_name: item.teacher_name,
          count_student:
            item.countStudent -
            (this.state.cutMeals.length > 0
              ? this.state.cutMeals.filter(
                  (e) =>
                    e.class_name == item.class_name &&
                    e.is_status == 1 &&
                    e.is_day_off == 1
                ).length
              : 0),
          daduyet:
            this.state.cutMeals.length > 0
              ? this.state.cutMeals.filter(
                  (e) => e.class_name == item.class_name && e.is_status == 1
                ).length
              : 0,
          chuaduyet:
            this.state.cutMeals.length > 0
              ? this.state.cutMeals.filter(
                  (e) => e.class_name == item.class_name && e.is_status != 1
                ).length
              : 0,
          setting: (
            <>
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  this.getCutMealDetailData(
                    item.id,
                    this.state.startDateFilterClass,
                    this.state.endDateFilterClass
                  );
                  this.setState({
                    showCutMealDetail: true,
                    id_class: item,
                  });
                }}
              >
                <i
                  style={{ width: 14 }}
                  className="d-block d-sm-none fa-solid fa-info"
                />{" "}
                <div className="d-none d-sm-block">Chi tiết</div>
              </Button>
            </>
          ),
        });

        return item;
      });
    }

    const totalStudentdihoc = data.reduce(
      (total, record) => total + record.count_student,
      0
    );
    const totaldaduyet = data.reduce(
      (total, record) => total + record.daduyet,
      0
    );
    const totalchuaduyet = data.reduce(
      (total, record) => total + record.chuaduyet,
      0
    );

    const totalRow = {
      id: "total-student",
      name: "Tổng",
      count_student: totalStudentdihoc,
      teacher_name: "Tổng",
      daduyet: totaldaduyet,
      chuaduyet: totalchuaduyet,
    };

    const conditionalRowStyles = [
      {
        when: (row) => row.name === "Tổng",
        style: {
          borderTop: "1px solid #000000",
          position: "absolute",
          bottom: "0",
        },
      },
    ];

    const customStyles = {
      table: {
        style: {
          paddingBottom: "38px",
        },
      },
    };

    const temporaryData = [...data, totalRow];
    return (
      <>
        <DataTables
          data={temporaryData}
          columns={columns}
          selectedRows={selectedRows}
          conditionalRowStyles={conditionalRowStyles}
          customStyles={customStyles}
        />
      </>
    );
  }

  renderCutMealListTable() {
    var totalPresent = 0;
    var totalUnapprove = 0;
    var totalApprove = 0;
    var total = 0;
    {
      this.state.cutMeals.map((item) => {
        {
          totalApprove += item.is_status == 1;
          total += item.is_status == 1 && item.is_day_off == 1;
        }
      });
      this.state.cutMeals.map((e) => {
        if (e.is_status === 0) {
          totalUnapprove++;
        }
      });
    }

    return (
      <>
        {this.state.listClass?.length > 0 ? (
          this.dataTable()
        ) : (
          <p className="text-center">Không có dữ liệu. . .</p>
        )}
      </>
    );
  }

  renderDetailCutMealList() {
    return (
      <>
        <Modal
          size="xl"
          show={this.state.showCutMealDetail}
          onHide={() => {
            const date = new Date(this.state.startDate);

            const startDateFilterClass = new Date(
              new Date(date.setDate(1)).setHours(0, 0, 0)
            );

            const endDateFilterClass = this.lastDayy(
              date.getFullYear(),
              date.getMonth() + 1
            );

            this.setState({
              showCutMealDetail: false,
              cutMealDetail: [],
              searchtext: "",
              startDateFilterClass,
              endDateFilterClass,
            });

            this.getDataMeal(this.state.startDate, this.state.endDate);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Chi tiết cắt suất {this.state.id_class?.class_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={3} sm={12} className="mb-1">
                <Form.Control
                  placeholder="Nhập tên học sinh"
                  onChange={(e) => {
                    this.searchText(e.target.value, this.state.cutMealDetail);
                  }}
                  value={this.state.searchtext}
                />
              </Col>

              <Col md={2} xs={6} className="mb-1" style={{ zIndex: 2 }}>
                <DatePicker
                  locale={vi}
                  selected={this.state.startDateFilterClass}
                  onChange={(date) => {
                    if (date) {
                      const startDate = new Date(date.setHours(0, 0, 0));

                      this.setState({ startDateFilterClass: startDate });

                      this.getCutMealDetailData(
                        this.state.id_class?.id,
                        startDate,
                        this.state.endDateFilterClass
                      );
                    }
                  }}
                  selectsStart
                  className="form-control"
                  maxDate={this.state.endDateFilterClass}
                  dateFormat="dd/MM/yyyy"
                />
              </Col>

              <Col md={2} xs={6} className="mb-1" style={{ zIndex: 2 }}>
                <DatePicker
                  locale={vi}
                  selected={this.state.endDateFilterClass}
                  className="form-control"
                  onChange={(date) => {
                    if (date) {
                      const endDate = new Date(date.setHours(23, 59, 59));
                      this.setState({ endDateFilterClass: endDate });

                      this.getCutMealDetailData(
                        this.state.id_class?.id,
                        this.state.startDateFilterClass,
                        endDate
                      );
                    }
                  }}
                  selectsEnd
                  dateFormat="dd/MM/yyyy"
                  minDate={this.state.startDateFilterClass}
                />
              </Col>
            </Row>

            {this.state.cutMealDetail.length === 0 ? (
              <div className="text-center">Không có dữ liệu</div>
            ) : (
              <>
                <Table
                  responsive
                  striped
                  hover
                  style={{ position: "relative" }}
                >
                  <thead style={{ position: "sticky", top: 0 }}>
                    <tr style={{ whiteSpace: "nowrap" }}>
                      <th style={{ width: "140px" }}>Tên HS</th>

                      <th className="text-center" style={{ width: "80px" }}>
                        Ngày nghỉ
                      </th>
                      <th
                        className="text-center"
                        style={{
                          width: "160px",
                        }}
                      >
                        Loại
                      </th>
                      <th
                        className="text-center"
                        style={{
                          width: "140px",
                        }}
                      >
                        Lí do
                      </th>
                      <th className="text-center" style={{ width: "120px" }}>
                        Ngày báo
                      </th>
                      <th className="text-center" style={{ width: "90px" }}>
                        Duyệt
                      </th>
                      <th className="text-center" style={{ width: "130px" }}>
                        Người duyệt
                      </th>
                      <th className="text-center" style={{ width: "150px" }}>
                        Ngày duyệt
                      </th>
                      <th
                        className="text-center"
                        style={{
                          width: "110px",
                        }}
                      >
                        Phương thức
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.cutMealDetailSearch.map((mealDetail) => (
                      <tr key={mealDetail.id}>
                        <td style={{ width: "150px" }}>
                          {mealDetail.student_name}
                        </td>

                        <td className="text-center" style={{ width: "80px" }}>
                          {" "}
                          {this.getDate(1, mealDetail.date_off)}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            width: "200px",
                          }}
                        >
                          {mealDetail.is_day_off == 1 ? "Xin nghỉ" : "Cắt suất"}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            width: "140px",
                          }}
                        >
                          <span
                            style={{
                              width: "140px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "block",
                            }}
                            title={mealDetail.notes}
                          >
                            {mealDetail.is_status == 1 &&
                            mealDetail.is_valid == 2
                              ? `${
                                  mealDetail.notes || ""
                                } (Báo trễ, duyệt bởi ${mealDetail.updater})`
                              : mealDetail.notes}
                          </span>
                        </td>
                        <td className="text-center" style={{ width: "150px" }}>
                          {this.getTime(mealDetail.created_at)}
                        </td>

                        <td className="text-center" style={{ width: "80px" }}>
                          <div
                            className={
                              !mealDetail.is_valid
                                ? "text-danger"
                                : mealDetail.is_status
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {!mealDetail.is_valid &&
                            mealDetail.is_status == "1" ? (
                              "Báo trễ"
                            ) : mealDetail.is_status == "1" ? (
                              <i
                                className="fa-sharp fa-solid fa-check fa-lg"
                                title="Đã duyệt"
                                style={{ color: "#00c203" }}
                              ></i>
                            ) : null}
                          </div>
                        </td>
                        <td className="text-center" style={{ width: "150px" }}>
                          {mealDetail.is_status
                            ? !mealDetail.is_valid
                              ? ""
                              : mealDetail.updater
                            : ""}
                        </td>
                        <td className="text-center" style={{ width: "150px" }}>
                          {mealDetail.is_status
                            ? !mealDetail.is_valid
                              ? ""
                              : this.getTime(mealDetail.updated_at)
                            : " "}
                        </td>

                        <td className="text-end">
                          {new Check().permission(["74", "75"], "or") ? (
                            <>
                              {new Check().permission(["74"]) ? (
                                <Button
                                  variant={
                                    !mealDetail.is_term &&
                                    mealDetail.is_status == "1"
                                      ? "secondary me-2"
                                      : !mealDetail.is_status
                                      ? "success me-2"
                                      : "secondary me-2"
                                  }
                                  size="sm"
                                  onClick={() =>
                                    this.confirmChangeStatus(
                                      mealDetail.id,
                                      mealDetail.is_status,
                                      mealDetail.is_valid,
                                      mealDetail.id_class
                                    )
                                  }
                                  title={
                                    !mealDetail.is_term &&
                                    mealDetail.is_status == "1"
                                      ? "Hủy"
                                      : !mealDetail.is_status
                                      ? "Duyệt"
                                      : "Hủy"
                                  }
                                  disabled={this.state.pendingConfirm}
                                >
                                  {!mealDetail.is_term &&
                                  mealDetail.is_status == "1" ? (
                                    <>Hủy</>
                                  ) : !mealDetail.is_status ? (
                                    <>Duyệt</>
                                  ) : (
                                    <>Hủy</>
                                  )}
                                </Button>
                              ) : null}

                              {new Check().permission(["75"]) ? (
                                <Button
                                  variant="danger"
                                  size="sm"
                                  title="Xóa cắt suất"
                                  onClick={() => this.delCut(mealDetail.id)}
                                  hidden={
                                    Number(localStorage.getItem("role_rank")) >=
                                    4
                                  }
                                  disabled={this.state.pendingDestroy}
                                >
                                  <i className="fas fa-trash"></i>
                                </Button>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }

  toSlug(str) {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường

    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ

    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt

    return str;
  }

  getDate = (type, data) => {
    let date = new Date(data * 1000);
    let result = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;

    if (type == 2) result = `${date.getMonth() + 1}/${date.getFullYear()}`;
    if (type == 3) result = `${date.getFullYear()}`;
    if (type == 4) result = `${date.getDate()}/${date.getFullYear()}`;

    return result;
  };

  getTime = (data) => {
    let date = new Date(data * 1000);
    let result =
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear() +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2) +
      ":" +
      ("0" + date.getSeconds()).slice(-2);
    return result;
  };

  getDate_ = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}`;
  };

  changeTime = (date) => {
    this.setState({ show: false });
    const startDate = new Date(date.setHours(0, 0, 0));
    const endDate = new Date(date.setHours(23, 59, 59));

    this.getClassRoom(startDate, endDate);
    const startDateFilterClass = new Date(
      new Date(date.setDate(1)).setHours(0, 0, 0)
    );

    const endDateFilterClass = this.lastDayy(
      date.getFullYear(),
      date.getMonth() + 1
    );

    this.setState({
      startDate,
      endDate,
      startDateFilterClass,
      endDateFilterClass,
    });
  };

  render() {
    return (
      <>
        <Container fluid>
          <Breadcrumb>
            <Breadcrumb.Item active>Suất ăn</Breadcrumb.Item>
            <Breadcrumb.Item active>Danh sách cắt suất ăn</Breadcrumb.Item>
          </Breadcrumb>
          <Card>
            <Card.Header>
              <Card.Title className="mb-0">
                <i className="fa-solid fa-list-check"></i> Danh sách cắt suất ăn{" "}
                {this.getDate(1, this.state.startDate / 1000)} {this.get}
                <Button
                  size="sm"
                  onClick={() => {
                    this.setState({ show: true });
                  }}
                  className="mb-2 mb-md-0"
                >
                  <i className="fa-solid fa-calendar-days"> </i>
                </Button>
                {new Check().permission(["74"]) ? (
                  <div className="float-end d-flex align-items-center">
                    <h6 className="text-muted mb-0 me-2">
                      <span className="text-danger">**</span>
                      Lưu ý: Duyệt nhanh cắt suất sẽ được tính từ ngày hôm nay
                      trở về trước.
                    </h6>

                    <Button
                      size="sm"
                      variant="success"
                      title="Duyệt từ hôm nay trở về trước"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={() => this.handleApproveToday()}
                    >
                      <i className="fa-solid fa-check" /> Duyệt nhanh
                    </Button>
                  </div>
                ) : null}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {this.state.checkloading
                ? this.loading()
                : this.renderCutMealListTable()}
            </Card.Body>
          </Card>
        </Container>
        <Modal
          show={this.state.show}
          size={"sm"}
          onHide={() => this.setState({ show: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Chọn thời gian</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <DatePickerCustom
                selected={this.state.startDate}
                onChange={(date) => this.changeTime(date)}
                inline
              />
            </center>
            <br />
          </Modal.Body>
        </Modal>
        {this.renderDetailCutMealList()}
      </>
    );
  }
}

export default ListmealIndex;
