import React, { Component } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import moment from "moment/moment";
import { toast } from "react-toastify";
import api from "../../../helper/axiosInstance";
import DatePicker from "react-datepicker";
import { vi } from "date-fns/locale";
class RewardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      formData: this.props.data || {
        id_student: "",
        month: new Date() / 1000,
        teacher_comment: "",
      },
      errors: {},
      other: this.props.data?.achievements
        ? [
            ...this.props.data?.achievements,
            {
              achievement: "",
              description: "",
              achieved_date: new Date() / 1000,
            },
          ]
        : [
            {
              achievement: "",
              description: "",
              achieved_date: new Date() / 1000,
            },
          ],
    };
  }
  handleChangeOther = (index, field, value) => {
    if (field === "achieved_date") {
      value = new Date(value) / 1000;
    }
    const { other } = this.state;
    const newList = [...other];
    newList[index][field] = value;

    if (
      index === other.length - 1 &&
      newList[index].achievement &&
      newList[index].description
    ) {
      newList.push({
        achievement: "",
        description: "",
        achieved_date: new Date() / 1000,
      });
    }

    if (
      newList.length == 0 ||
      (newList.length == 1 &&
        (newList[0].achievement || newList[0].description))
    ) {
      newList.push({
        achievement: "",
        description: "",
        achieved_date: new Date() / 1000,
      });
    }

    this.setState({ other: newList });
  };

  handleRemove = (index) => {
    const { other } = this.state;
    const newList = [...other];
    newList.splice(index, 1);
    this.setState({ other: newList });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    if (e.target.name == "month") {
      value = new Date(e.target.value) / 1000;
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
    delete this.state.errors[name];
  };
  createReward = () => {
    let data = {
      id_student: this.props.studentChoice?.id, // required
      id_teacher: localStorage.getItem("auth_id_user"),
      teacher_comment: this.state.formData?.teacher_comment || "",
      month: parseInt(this.state.formData?.month) || "", // nullable, integer gte 1

      achievements: this.state.other
        ?.filter((e) => e.description != "" || e.achievement != "")
        .map((item) => ({
          achievement: item.achievement,
          description: item.description,
          achieved_date: parseInt(item.achieved_date),
        })),
    };

    api
      .post(`reward-create`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Thêm thành công", { type: "success" });
        return this.props.onSubmit();
      })
      .catch((error) => {
        this.setState({ errors: error.response.data.messages });
      });
  };
  updateReward = () => {
    let data = {
      id_student: this.props.studentChoice?.id, // required
      id_teacher: localStorage.getItem("auth_id_user"),
      teacher_comment: this.state.formData?.teacher_comment || "",
      month: parseInt(this.state.formData?.month) || "", // nullable, integer gte 1

      // achievements: this.state.other
      //   ?.filter((e) => e.description != "" || e.achievement != "")
      //   .map((item) => ({
      //     achievement: item.achievement,
      //     description: item.description,
      //     achieved_date: parseInt(item.achieved_date),
      //   })),
    };

    api
      .put(`/reward-update?id=${this.props?.data?.id}`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Cập nhật thành công", { type: "success" });
        return this.props.onSubmit();
      })
      .catch((error) => {
        this.setState({ errors: error.response.data.messages });
      });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.data?.id) {
      this.updateReward();
    } else this.createReward();
  };

  render() {
    const { formData, errors } = this.state;

    return (
      <>
        <Modal show={this.props.show} onHide={this.props?.onHide} size={"lg"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="d-flex">
                <span style={{ whiteSpace: "nowrap" }} className="me-2">
                  Sổ bé ngoan tháng
                </span>
                <DatePicker
                  selected={new Date(this.state.formData?.month * 1000)}
                  onChange={(date) => {
                    delete this.state.errors["month"];
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        ["month"]: new Date(date) / 1000,
                      },
                    });
                  }}
                  showMonthYearPicker
                  dateFormat="MM/yyyy"
                  className="form-control"
                  name="month"
                  locale={vi}
                  placeholderText="Chọn tháng"
                />
              </div>

              <span className="text-form-err">{this.state.errors.month}</span>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="teacher_comment"
                      value={formData.teacher_comment || ""}
                      onChange={this.handleChange}
                      placeholder="Tháng"
                      min={1}
                    ></Form.Control>

                    <Form.Label>
                      Nhận xét của giáo viên
                      <i className="text-danger"></i>
                    </Form.Label>

                    <span className="text-form-err">
                      {errors.teacher_comment}
                    </span>
                  </Form.Floating>
                </Col>
              </Row>
              <Row>
                <p className="text-center fw-bold"> Thành tích</p>
              </Row>
              {this.state.other?.map((criteria, index) => (
                <Row key={index}>
                  <Col md={4}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="text"
                        name="achievement"
                        value={criteria.achievement}
                        required
                        disabled={this.props?.data?.id}
                        onChange={(e) => {
                          delete this.state.errors[
                            `achievements[${index}].achievement`
                          ];
                          this.handleChangeOther(
                            index,
                            "achievement",
                            e.target.value
                          );
                        }}
                        placeholder="Nhập thành tích"
                      />
                      <Form.Label>Thành tích</Form.Label>
                      <span className="text-form-err">
                        {
                          this.state.errors[`achievements.${index}`]
                            ?.achievement
                        }
                      </span>
                      <span className="text-form-err">
                        {
                          this.state.errors[
                            `achievements[${index}].achievement`
                          ]
                        }
                      </span>
                    </Form.Floating>
                  </Col>
                  <Col md={4}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="text"
                        name="description"
                        value={criteria.description}
                        required
                        disabled={this.props?.data?.id}
                        onChange={(e) => {
                          this.handleChangeOther(
                            index,
                            "description",
                            e.target.value
                          );
                          delete this.state.errors[
                            `achievements[${index}].description`
                          ];
                        }}
                        placeholder="Mô tả"
                      />
                      <Form.Label>Mô tả</Form.Label>

                      <span className="text-form-err">
                        {
                          this.state.errors[
                            `achievements[${index}].description`
                          ]
                        }
                      </span>
                    </Form.Floating>
                  </Col>
                  <Col md={3}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="date"
                        // required
                        name="achieved_date"
                        disabled={this.props?.data?.id}
                        value={moment(
                          new Date(criteria.achieved_date * 1000)
                        ).format("YYYY-MM-DD")}
                        onChange={(e) => {
                          this.handleChangeOther(
                            index,
                            "achieved_date",
                            e.target.value
                          );

                          delete this.state.errors[
                            `achievements[${index}].achieved_date`
                          ];
                        }}
                      />
                      <Form.Label>Ngày đạt được</Form.Label>

                      <span className="text-form-err">
                        {
                          this.state.errors[
                            `achievements[${index}].achieved_date`
                          ]
                        }
                      </span>
                    </Form.Floating>
                  </Col>
                  <Col md={1} className="mb-3 d-flex align-items-center">
                    <Button
                      variant="outline-danger"
                      onClick={() => this.handleRemove(index)}
                      disabled={
                        this.state.other.length < 2 || this.props?.data?.id
                      }
                    >
                      X
                    </Button>
                  </Col>
                </Row>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary me-2"
                onClick={() => this.props?.onHide()}
              >
                Đóng
              </Button>
              <Button
                variant="primary"
                // type="submit"
                onClick={() => {
                  this.props?.data?.id
                    ? this.updateReward()
                    : this.createReward();
                }}
              >
                {this.props?.data?.id ? "Cập nhật" : "Lưu"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default RewardModal;
