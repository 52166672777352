import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Row, Col, Card, Container, Breadcrumb } from "react-bootstrap";
import Check from "../other/Check";
import DiscountModal from "./DiscountModal";
import DataTables from "../datatable/DataTables";
import api from "../../../helper/axiosInstance";
import CurrencyFormat from "react-currency-format";

class DiscountIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addModalShow: false,
      discount: [],
      typeModal: "",
      discounts: [],
    };
  }

  componentDidMount() {
    document.title = "Giảm giá";
    this.getDiscount();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.reloadKey !== this.props.reloadKey) {
      this.getDiscount();
    }
  }
  getDiscount = () => {
    api
      .get("/discount-shows")
      .then((res) => {
        this.setState({
          discounts: res.data.data || [],
        });
      })
      .catch((err) => {});
  };

  handleDelete = (event) => {
    let id_discount = event.currentTarget.dataset.discount_id;

    swal({
      title: "Bạn muốn xóa giảm giá?",
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .delete(`/discount-delete/${id_discount}`)
            .then((res) => {
              toast(res.data.message, { type: "success" });
              this.getDiscount();
            })
            .catch((err) => {
              toast(err.response.data.messages || "Hệ thống xảy lỗi", {
                type: "error",
                autoClose: 1000,
              });
            });
        }
      })
      .catch((err) => {});
  };

  dataTable() {
    const columns = [
      {
        name: "Tên ",
        selector: (row) => row.title,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.title}
          </div>
        ),
      },
      {
        name: "Mô tả",
        selector: (row) => row.description,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.description}
          </div>
        ),
      },

      {
        name: "Loại",
        selector: (row) => row.target,
        sortable: true,

        cell: (row) =>
          row.target === "bill"
            ? "Giảm trên tổng phiếu thu"
            : "Giảm theo khoản thu",
      },
      {
        name: "Giá trị",
        selector: (row) => row.value,
        sortable: true,
        right: true,
        cell: (row) => (
          <CurrencyFormat
            thousandSeparator={true}
            displayType="text"
            value={row.value ? parseFloat(row.value) : ""}
            suffix={row.type === "percentage" ? " %" : " đ"}
          />
        ),
      },
      {
        name: "",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    if (this.state.discounts.length > 0) {
      this.state.discounts.map((item, i) => {
        data.push({
          ...item,
          setting: (
            <>
              {new Check().permission([134, 136], "or") ? (
                <Button
                  variant="warning me-2"
                  type="button"
                  size="sm"
                  onClick={() => this.addModalShow(item)}
                  title="Cập nhật giảm giá"
                >
                  <i className="fa-solid fa-pencil"></i>
                </Button>
              ) : null}

              {new Check().permission([137]) ? (
                <Button
                  variant="danger"
                  type="button"
                  size="sm"
                  onClick={this.handleDelete}
                  data-discount_id={item.id}
                  title="Xóa giảm giá"
                >
                  <i className="fas fa-trash"></i>
                </Button>
              ) : null}
            </>
          ),
        });

        return item;
      });
    }

    return <DataTables data={data} columns={columns} />;
  }

  addModalShow = (item) => {
    this.setState(
      {
        discount: item,
        addModalShow: true,
        typeModal: "CN",
      },
      () => {
        console.log(
          this.state.discount,
          this.state.addModalShow,
          this.state.typeModal,
          "type"
        );
      }
    );
  };
  addModalClose = () => {
    this.setState({ addModalShow: false, discount: [] });
    this.getDiscount();
  };

  renderDiscountList() {
    return (
      <>
        <Card.Header>
          {new Check().permission([135]) ? (
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              title="Thêm giảm giá"
              onClick={() =>
                this.setState({ addModalShow: true, typeModal: "TM" })
              }
            >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
          ) : null}
          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách giảm giá
          </Card.Title>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col>{this.dataTable()}</Col>
          </Row>
        </Card.Body>
      </>
    );
  }

  render() {
    return (
      <>
        {this.dataTable()}

        {this.state.addModalShow ? (
          <DiscountModal
            show={this.state.addModalShow}
            onHide={this.addModalClose}
            typeModal={this.state.typeModal}
            obj={{ discount: this.state.discount }}
          />
        ) : (
          ""
        )}
        {/* <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Môn học và học phí</Breadcrumb.Item>
            <Breadcrumb.Item active>Giảm giá</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={12}>
              <Card className="mb-4">{this.renderDiscountList()}</Card>
            </Col>
          </Row>
        </Container> */}
      </>
    );
  }
}

export default DiscountIndex;
