import React, { Component } from "react";
import { toast } from "react-toastify";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import swal from "sweetalert";
import CurrencyFormat from "react-currency-format";
import Check from "../../admin/other/Check";
import api from "../../../helper/axiosInstance";

class DiscountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: {},
      dataInput: {
        ...this.props.obj.discount,
        amount: parseInt(this.props.obj.discount?.amount) || "",
      },
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.typeModal === "TM"
      ? this.createDiscount()
      : this.updateDiscount();
  };

  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;

    let dataInput = { ...this.state.dataInput };

    dataInput[field] = value;

    this.setState({ dataInput: dataInput });

    delete this.state.err[field];
    this.setState({ err: { ...this.state.err } });
  };

  updateDiscount = () => {
    let data = {
      // id: this.state.dataInput?.id,
      title: this.state.dataInput?.title || "", // required
      description: this.state.dataInput?.description || "", // required
      target: this.state.dataInput?.target || "", // required, in: bill, detail
      type: this.state.dataInput?.type || "", // required, in: percentage, fixed
      value: this.state.dataInput?.value || "", // required, integer
    };
    swal({
      title: "Bạn muốn cập nhật giảm giá?",
      icon: "error",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        api({
          method: "put",
          url: `discount-update/${this.state.dataInput?.id}`,
          data: data,
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => {
            toast(res.data.message, { type: "success" });
            this.handleHide();
          })
          .catch((err) => {
            this.setState({ err: err.response.data.messages });
          });
      }
    });
  };

  createDiscount = async () => {
    let data = {
      title: this.state.dataInput?.title || "", // required
      description: this.state.dataInput?.description || "", // required
      target: this.state.dataInput?.target || "", // required, in: bill, detail
      type: this.state.dataInput?.type || "fixed", // required, in: percentage, fixed
      value: this.state.dataInput?.value || "", // required, integer
    };

    await api({
      method: "post",
      url: "discount-create",
      data: data,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        toast(res.data.message, { type: "success" });
        this.handleHide();
      })
      .catch((err) => {
        this.setState({ err: err.response.data.messages });
      });
  };

  handleHide = () => {
    return this.props.onHide(true);
  };

  render() {
    return (
      <>
        <Modal {...this.props} size="md" backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.typeModal === "TM"
                ? " Thêm giảm giá"
                : "Chi tiết giảm giá"}
              &nbsp;&nbsp;&nbsp;
            </Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Tên giảm giá"
                      name="title"
                      value={this.state.dataInput?.title || ""}
                      onChange={(e) => {
                        this.handleInput(e);
                        delete this.state.err["title"];
                      }}
                      required
                    />
                    <Form.Label>
                      Tên giảm giá<i className="text-danger">*</i>
                    </Form.Label>
                    <div className="text-form-err">{this.state.err?.title}</div>
                  </Form.Floating>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <Form.Floating className="mb-3">
                    <CurrencyFormat
                      className="form-control text-end"
                      placeholder="Từ"
                      thousandSeparator={true}
                      name="value"
                      suffix={
                        this.state.dataInput?.type === "percentage"
                          ? " %"
                          : " đ"
                      }
                      required
                      value={this.state.dataInput?.value || ""}
                      onValueChange={(values) => {
                        this.setState({
                          dataInput: {
                            ...this.state.dataInput,
                            value: values.value,
                          },
                        });
                        delete this.state.err["value"];
                      }}
                    />
                    <Form.Label>
                      {this.state.dataInput?.type === "percentage"
                        ? "Phần trăm"
                        : "Số tiền"}
                      {/* Giá<i className="text-danger"></i> (VNĐ) */}
                      <i className="text-danger">*</i>
                    </Form.Label>
                    <div className="invalid-feedback"></div>
                    <div className="text-form-err">{this.state.err?.value}</div>
                  </Form.Floating>
                </Col>
                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      required
                      id="type"
                      name="type"
                      placeholder="Loại"
                      value={this.state.dataInput?.type || ""}
                      onChange={(e) => {
                        this.handleInput(e);
                      }}
                    >
                      {/* <option value="">--Chọn--</option> */}
                      <option value="fixed">Tiền trực tiếp</option>
                      <option value="percentage">Phần trăm</option>
                    </Form.Select>

                    <Form.Label>
                      Loại<i className="text-danger">*</i>
                    </Form.Label>
                    {this.state.err?.type ? (
                      <span className="text-danger">
                        {this.state.err?.type}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Floating>
                </Col>
              </Row>

              <Form.Floating className="mb-3">
                <Form.Select
                  required
                  id="target"
                  name="target"
                  placeholder="Loại"
                  value={this.state.dataInput?.target || ""}
                  onChange={(e) => {
                    this.handleInput(e);
                  }}
                >
                  <option value="">--Chọn--</option>
                  <option value="bill">Giảm trên tổng phiếu thu</option>
                  <option value="detail">Giảm theo khoản thu</option>
                </Form.Select>

                <Form.Label for="idYear">
                  Loại<i className="text-danger">*</i>
                </Form.Label>
                {this.state.err?.type ? (
                  <span className="text-danger">{this.state.err?.type}</span>
                ) : (
                  ""
                )}
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Tên giảm giá"
                  name="description"
                  value={this.state.dataInput?.description || ""}
                  onChange={(e) => {
                    this.handleInput(e);
                  }}
                />
                <Form.Label>Mô tả</Form.Label>
                <div className="text-form-err">
                  {this.state.err?.description}
                </div>
              </Form.Floating>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={this.handleHide}>
                <i className="fa-solid fa-times"></i> Đóng
              </Button>
              {this.props.typeModal === "TM" ? (
                <Button size="sm" variant="success" type="submit">
                  <i className="fa-solid fa-check"></i> Lưu
                </Button>
              ) : new Check().permission(["136"]) ? (
                <Button
                  size="sm"
                  variant="success"
                  onClick={() => this.updateDiscount()}
                >
                  <i className="fa-solid fa-check"></i> Cập nhật
                </Button>
              ) : null}
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default DiscountModal;
