import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import {
  Button,
  Breadcrumb,
  Card,
  Container,
  Tabs,
  Tab,
  Spinner,
  Modal,
} from "react-bootstrap";
import DateVoucherMarket from "./ingredientPriceComponent/DateVoucherMarket";
import IngredientProcessingTable from "./ingredientPriceComponent/IngredientProcessingTable";
import { dateFormatter } from "../../utils/helpers";
import api from "../../../../helper/axiosInstance";
import DatePickerCustom from "../../../uiElements/DatePickerCustom";

const IngredientPriceReport = () => {
  const [school, setSchool] = useState({});
  const [listVoucher, setListVoucher] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [showTimePicker, setShowTimePicker] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [pickedStartDate, setPickedStartDate] = useState(new Date());
  const [pickedEndDate, setPickedEndDate] = useState(new Date());

  useEffect(() => {
    document.title = "Báo cáo chi phí";

    const date = new Date();

    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    end.setHours(23, 59, 59, 0);

    setPickedStartDate(start);
    setPickedEndDate(end);

    getDetailschool();
    getListVoucherMarket(start, end);
  }, []);

  //#region  API
  const getDetailschool = async () => {
    await api
      .get(`/detailSchool`)
      .then((res) => {
        if (res.data.status === true) {
          setSchool(res.data.data[0]);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const getListVoucherMarket = async (startDate, endDate) => {
    setIsLoading(true);
    setListVoucher([]);

    setStartDate(startDate);
    setEndDate(endDate);

    await api
      .get(`voucher-show`, {
        params: {
          start_at: startDate.getTime() / 1000,
          term_at: endDate.getTime() / 1000,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.errCode === 0) {
          setListVoucher(res.data.vouchers);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const deleteVoucherMarket = (voucherMarket) => {
    swal({
      title: "Bạn muốn xóa phiếu đi chợ",
      text: `Ngày ${dateFormatter(new Date(voucherMarket.vou_date * 1000))}`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        await api
          .delete(`/voucher-destroy`, {
            params: {
              id: voucherMarket.id,
            },
          })
          .then((res) => {
            if (res.data.errCode === 0) {
              toast(res.data.message, {
                type: "success",
                autoClose: 1000,
              });

              getListVoucherMarket(startDate, endDate);
            } else {
              toast(res.data.message, {
                type: "error",
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {});
      }
    });
  };
  // #endregion

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Báo cáo</Breadcrumb.Item>
        <Breadcrumb.Item active>Chi phí nguyên liệu</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header className="d-block d-md-flex">
          <Card.Title>
            <i className="fas fa-list me-1" />
            Báo cáo chi phí nguyên liệu {dateFormatter(startDate)} -{" "}
            {dateFormatter(endDate)}
          </Card.Title>

          <Button
            size="sm"
            className="ms-2"
            onClick={() => setShowTimePicker(true)}
          >
            <i className="fa-solid fa-calendar-days" />
          </Button>
        </Card.Header>

        <Card.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Đang tải...</span>
              </Spinner>
            </div>
          ) : (
            <Tabs
              unmountOnExit={true}
              transition={false}
              onSelect={(key) => {}}
              className="mb-3"
            >
              <Tab key={0} title="Phiếu đi chợ hằng ngày" eventKey={0}>
                <DateVoucherMarket
                  listVoucher={listVoucher}
                  school={school}
                  deleteVoucherMarket={deleteVoucherMarket}
                />
              </Tab>

              <Tab key={1} title="Nguyên liệu chế biến" eventKey={1}>
                <IngredientProcessingTable listVoucher={listVoucher} />
              </Tab>
            </Tabs>
          )}
        </Card.Body>
      </Card>

      <Modal show={showTimePicker} size={"sm"}>
        <Modal.Header>
          <Modal.Title>Chọn thời gian</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <DatePickerCustom
              size={{ height: "600px" }}
              onChange={(update) => {
                setPickedStartDate(update[0]);
                setPickedEndDate(update[1]);
              }}
              startDate={pickedStartDate}
              endDate={pickedEndDate}
              calendarStartDay={1}
              selectsRange
              inline
            />
          </center>
          <br />
        </Modal.Body>

        <Modal.Footer>
          <Button
            size="sm"
            onClick={() => setShowTimePicker(false)}
            variant="secondary"
          >
            Hủy
          </Button>
          <Button
            size="sm"
            onClick={() => {
              const start = pickedStartDate;
              start.setHours(0, 0, 0, 0);

              const end = pickedEndDate;
              end.setHours(23, 59, 59, 0);

              setShowTimePicker(false);

              getListVoucherMarket(start, end);
            }}
          >
            Lưu
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default IngredientPriceReport;
