import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal, Button, Form } from "react-bootstrap";
import Check from "../../other/Check";
import api from "../../../../helper/axiosInstance";

const CategoryEdit = ({ show, onHide, selectedCategory }) => {
  const [category, setCategory] = useState({
    id: 0,
    category_name: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    category_name: "",
  });

  useEffect(() => {
    setCategory(selectedCategory);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // API
  const updateCategory = async () => {
    setErrorMessage({
      category_name: "",
    });

    let data = {
      id: category.id,
      category_name: category.category_name,
      categoryable: "post",
      is_status: 1,
      description: category.category_description || "",
    };
    api({
      method: "put",
      url: "new-category-update",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // if (res.data.errCode == 0) {
        toast(res.data.messages || "Thành công", {
          type: "success",
          autoClose: 1000,
        });

        onHide(true);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.messages);
      });
  };
  // API

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa-solid fa-tag"></i> Chi tiết chủ đề
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          updateCategory();
        }}
      >
        <Modal.Body>
          <Form.Floating className="mb-3">
            <Form.Control
              type="text"
              placeholder="Tên chủ đề"
              required
              value={category.category_name}
              onChange={(e) => {
                setCategory({
                  ...category,
                  category_name: e.target.value,
                });
                delete errorMessage["category_name"];
              }}
            />
            <Form.Label>
              Tên chủ đề<i className="text-danger">*</i>
            </Form.Label>

            {errorMessage.category_name && (
              <p
                style={{
                  fontSize: 13,
                  color: "red",
                  marginTop: 6,
                }}
              >
                {errorMessage.category_name}
              </p>
            )}
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Control
              type="text"
              placeholder="mô tả"
              value={category.category_description}
              onChange={(e) => {
                setCategory({
                  ...category,
                  category_description: e.target.value,
                });
                delete errorMessage["category_description"];
              }}
            />
            <Form.Label>
              Mô tả<i className="text-danger"></i>
            </Form.Label>

            {errorMessage.category_description && (
              <p className="text-form-err">
                {errorMessage.category_description}
              </p>
            )}
          </Form.Floating>
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={() => onHide()}>
            <i className="fa-solid fa-times" /> Đóng
          </Button>

          {new Check().permission([151]) ? (
            <Button size="sm" variant="success" type="submit">
              <i className="fa-solid fa-check" /> Cập nhật
            </Button>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CategoryEdit;
