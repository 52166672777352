import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Form,
  Alert,
} from "react-bootstrap";
import api from "../../../helper/axiosInstance";

class DeadLineRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: {
        hours: 0,
        minutes: 0,
      },
      hour_: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      minute_: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
        38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
        56, 57, 58, 59,
      ],
      timeOfDays: [],
      categories: [],
      settings: {
        timesId: [],
        categoriesId: [],
      },
    };
  }

  componentDidMount() {
    document.title = "Cài đặt | Đăng ký suất ăn";
    this.getDeadline();
    this.getTimeOfDays();
    this.getCategories();
    this.getSettingsMealRegistration();
  }

  getDeadline() {
    api
      .get(`/get-deadline-for-menu`)
      .then((res) => {
        this.setState({
          time: res.data.data?.payload || {
            hours: 0,
            minutes: 0,
          },
        });
      })
      .catch((error) => {
        this.setState({
          time: {
            hours: 0,
            minutes: 0,
          },
        });
      });
  }

  getTimeOfDays() {
    api
      .get(`/getTimeOfDays`)
      .then((res) => {
        this.setState({
          timeOfDays: res.data?.times || [],
        });
      })
      .catch((error) => {});
  }

  getCategories() {
    api
      .get(`/category-show`)
      .then((res) => {
        this.setState({
          categories: res.data?.categories || [],
        });
      })
      .catch((error) => {});
  }

  getSettingsMealRegistration() {
    api
      .get(`/get-settings-meal-registration`)
      .then((res) => {
        if (res.data?.data?.payload)
          this.setState({
            settings: res.data.data.payload,
          });
      })
      .catch((error) => {});
  }

  updateSubmit = (e) => {
    e.preventDefault();
    let data = {
      deadline: {
        hours: this.state.time?.hours || 0,
        minutes: this.state.time?.minutes || 0, // from 0 -60, required
      },
    };
    swal({
      title: "Bạn muốn cập nhật thời gian đăng ký suất ăn?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .post(`set-deadline-for-menu`, data)
            .then((res) => {
              toast(res.data.messages || "Thành công", { type: "success" });
            })
            .catch((error) => {
              toast(`Lỗi `, {
                type: "error",
                autoClose: 1000,
              });
            });
        }
      })
      .catch((error) => {});
  };

  updateSettings = (e) => {
    e.preventDefault();
    let data = this.state.settings;
    swal({
      title: "Bạn muốn cập nhật?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .post(`settings-meal-registration`, data)
            .then((res) => {
              toast(res.data.messages || "Thành công", { type: "success" });
            })
            .catch((error) => {
              toast(`Lỗi `, {
                type: "error",
                autoClose: 1000,
              });
            });
        }
      })
      .catch((error) => {});
  };

  renderSetting() {
    return (
      <>
        <Card.Header>
          <Card.Title>
            <i className="fa-solid fa-clock"></i> Thời gian chốt đăng ký suất ăn
          </Card.Title>
        </Card.Header>

        <Form onSubmit={this.updateSubmit}>
          <Card.Body>
            <Alert variant="primary pb-0">
              <strong>*Lưu ý:</strong>
              <ul>
                <li>Phải đăng ký suất ăn ngày hôm sau trước Thời gian chốt.</li>
              </ul>
            </Alert>

            <Row>
              <Col md={3}>
                <Form.Floating>
                  <Form.Select
                    name="time_hour"
                    className=""
                    required
                    value={this.state.time?.hours}
                    onChange={(e) => {
                      this.setState({
                        time: { ...this.state.time, hours: e.target.value },
                      });
                    }}
                  >
                    {this.state.hour_?.map((e) => {
                      return (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <label>Giờ</label>
                </Form.Floating>
              </Col>
              <Col md={3}>
                <Form.Floating>
                  <Form.Select
                    name="time_hour"
                    required
                    value={this.state.time?.minutes}
                    onChange={(e) => {
                      this.setState({
                        time: { ...this.state.time, minutes: e.target.value },
                      });
                    }}
                  >
                    {this.state.minute_?.map((e) => {
                      return (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <label>Phút</label>
                </Form.Floating>
              </Col>
            </Row>
          </Card.Body>

          <Card.Footer className="text-end">
            <Button size="sm" variant="success" type="submit">
              <i className="fas fa-check"></i> Cập nhật
            </Button>
          </Card.Footer>
        </Form>
      </>
    );
  }

  renderSettingMealRegistration() {
    console.log("time>>", this.state.settings.timesId);
    return (
      <>
        <Card.Header>
          <Card.Title>
            <i className="fa-solid fa-pencil"></i> Đăng ký suất ăn
          </Card.Title>
        </Card.Header>

        <Form onSubmit={this.updateSettings}>
          <Card.Body>
            <h5>Buối có ràng buộc món chính và món phụ</h5>
            <Alert variant="primary pb-0">
              <strong>*Lưu ý:</strong>
              <ul>
                <li>
                  Buổi được chọn sẽ ràng buộc số lượng đăng ký của món phụ bằng
                  tổng số lượng đăng ký của món chính.
                </li>
                <li>
                  Buổi không được chọn sẽ không ràng buộc số lượng giữa các món.
                </li>
              </ul>
            </Alert>
            <Row>
              {this.state.timeOfDays
                ?.filter((time) => time.time_name_now !== null)
                ?.map((time, timeIndex) => {
                  const isChecked = this.state.settings?.timesId?.includes(
                    time.id
                  );

                  return (
                    <Col key={`time-key-${timeIndex}`}>
                      <Form.Check
                        type="switch"
                        checked={isChecked}
                        onClick={(e) => {
                          let newTimesId =
                            [...this.state.settings?.timesId] || [];
                          if (isChecked) {
                            newTimesId = newTimesId.filter(
                              (id) => id !== time.id
                            );
                          } else {
                            newTimesId.push(time.id);
                          }
                          this.setState({
                            settings: {
                              ...this.state.settings,
                              timesId: newTimesId,
                            },
                          });
                        }}
                        label={time.time_name_now || time.time_name}
                      />
                    </Col>
                  );
                })}
            </Row>

            <hr />
            <h5>Danh mục món chính</h5>

            <Row>
              {this.state.categories?.map((category, categoryIndex) => {
                const isChecked = this.state.settings?.categoriesId?.includes(
                  category.id
                );
                return (
                  <Col md={4} key={`category-key-${categoryIndex}`}>
                    <Form.Check
                      type="switch"
                      checked={isChecked}
                      onClick={(e) => {
                        let newCategorysId =
                          [...this.state.settings?.categoriesId] || [];
                        if (isChecked) {
                          newCategorysId = newCategorysId.filter(
                            (id) => id !== category.id
                          );
                        } else {
                          newCategorysId.push(category.id);
                        }
                        this.setState({
                          settings: {
                            ...this.state.settings,
                            categoriesId: newCategorysId,
                          },
                        });
                      }}
                      label={category.category_name}
                    />
                  </Col>
                );
              })}
            </Row>
          </Card.Body>
          <Card.Footer className="text-end">
            <Button size="sm" variant="success" type="submit">
              <i className="fas fa-check"></i> Cập nhật
            </Button>
          </Card.Footer>
        </Form>
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
            <Breadcrumb.Item active>Đăng ký suất ăn</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={6}>
              <Card className="mb-4">{this.renderSetting()}</Card>

              <Card className="mb-4">
                {this.renderSettingMealRegistration()}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default DeadLineRegister;
