import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import api from "../../../helper/axiosInstance";
import moment from "moment/moment";
import PasswordInput from "../../uiElements/PasswordInput";
import { getDateNow } from "../../../helper/times";

const UserAddModal = ({ show, onHide, onSave }) => {
  const [user, setUser] = useState({
    user_identifier: "",
    user_population: "",
    user_name: "",
    user_gender: 1,
    user_birthday: "",
    user_ethnic: 1,
    user_phone: "",
    user_email: "",
    user_address: "",
    user_contract: "",
    user_diploma: "",
    user_account: "",
    user_password: "",
    user_confirm_password: "",
    id_role: "",
  });

  const [errorMessage, setErrorMessage] = useState({});
  const [ethnics, setEthnics] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getEthnics();
    getRoles();
  }, []);

  const getEthnics = () => {
    api
      .get("/viewEthnic")
      .then((res) => {
        if (res.data.status === true) {
          setEthnics(res.data.data);
        }
      })
      .catch((err) => {});
  };

  const getRoles = () => {
    api
      .get("/role-shows")
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((error) => {});
  };

  const handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;

    setUser({ ...user, [field]: value });
    setErrorMessage({ ...errorMessage, [field]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataInput = {
      ...user,
      user_birthday: new Date(user.user_birthday) / 1000,
    };

    console.log("Submit", dataInput);

    api({
      method: "post",
      url: "user-create",
      data: dataInput,
    })
      .then((res) => {
        toast(res.data.messages, { type: "success" });
        onSave(true);
      })
      .catch((err) => {
        setErrorMessage(err.response.data?.messages);
        toast(err.response.data.messages, { type: "error" });
      });
  };

  return (
    <Modal
      size="lg"
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Thêm nhân viên</Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col md={4}>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Họ và tên"
                  required
                  value={user.user_name}
                  name="user_name"
                  onChange={handleInput}
                  className={`text-capitalize ${
                    errorMessage?.user_name ? "is-invalid" : ""
                  }`}
                />
                <Form.Label>
                  Họ và tên<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-danger">{errorMessage?.user_name}</span>
              </Form.Floating>
            </Col>

            <Col md={4}>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Mã định danh Bộ GD & ĐT"
                  value={user.user_identifier}
                  name="user_identifier"
                  onChange={handleInput}
                  className={`${
                    errorMessage?.user_identifier ? "is-invalid" : ""
                  }`}
                />
                <Form.Label>Mã định danh Bộ GD & ĐT</Form.Label>
                <span className="text-danger">
                  {errorMessage?.user_identifier}
                </span>
              </Form.Floating>
            </Col>

            <Col md={4}>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Số định danh cá nhân"
                  value={user.user_population}
                  name="user_population"
                  onChange={handleInput}
                  className={`${
                    errorMessage?.user_population ? "is-invalid" : ""
                  }`}
                />
                <Form.Label>Số định danh cá nhân</Form.Label>
                <span className="text-danger">
                  {errorMessage?.user_population}
                </span>
              </Form.Floating>
            </Col>

            <Col md={3}>
              <Form.Floating className="mb-3">
                <input
                  type="date"
                  value={user.user_birthday}
                  required
                  name="user_birthday"
                  onChange={handleInput}
                  className={`form-control ${
                    errorMessage?.user_birthday ? "is-invalid" : ""
                  }`}
                />
                <Form.Label>
                  Ngày sinh
                  <i className="text-danger">*</i>
                </Form.Label>
              </Form.Floating>
              <span className="text-danger">{errorMessage?.user_birthday}</span>
            </Col>

            <Col md={3}>
              <Form.Floating className="mb-3">
                <Form.Select
                  value={user.user_gender}
                  onChange={handleInput}
                  name="user_gender"
                  required
                  className={`${errorMessage?.user_gender ? "is-invalid" : ""}`}
                >
                  <option value="">--Chọn--</option>
                  <option value="1">Nam</option>
                  <option value="2">Nữ</option>
                </Form.Select>
                <Form.Label>
                  Giới tính
                  <i className="text-danger">*</i>
                </Form.Label>
                <span className="text-danger">{errorMessage?.user_gender}</span>
              </Form.Floating>
            </Col>

            <Col md={3}>
              <Form.Floating className="mb-3">
                <Form.Select
                  value={user.user_ethnic}
                  onChange={handleInput}
                  name="user_ethnic"
                  required
                  className={`${errorMessage?.user_ethnic ? "is-invalid" : ""}`}
                >
                  <option value="">--Chọn--</option>
                  {ethnics.length > 0
                    ? ethnics.map((ethnic, indexEthnic) => {
                        return (
                          <option
                            key={`key-ethnic-${indexEthnic}`}
                            value={ethnic.id}
                          >
                            {ethnic.ethnic_name}
                          </option>
                        );
                      })
                    : null}
                </Form.Select>
                <Form.Label>
                  Dân tộc
                  <i className="text-danger">*</i>
                </Form.Label>
                <span className="text-danger">{errorMessage?.user_ethnic}</span>
              </Form.Floating>
            </Col>

            <Col md={3}>
              <Form.Floating className="mb-3">
                <Form.Select
                  value={user.id_role}
                  onChange={handleInput}
                  className={`${errorMessage?.id_role ? "is-invalid" : ""}`}
                  name="id_role"
                  required
                >
                  <option value="">--Chọn--</option>
                  {roles.length > 0
                    ? roles.map((role, indexRole) => {
                        return (
                          <option key={`key-role-${indexRole}`} value={role.id}>
                            {role.role_name}
                          </option>
                        );
                      })
                    : null}
                </Form.Select>
                <Form.Label>
                  Chức vụ
                  <i className="text-danger">*</i>
                </Form.Label>
                <span className="text-danger">{errorMessage?.id_role}</span>
              </Form.Floating>
            </Col>

            <Col md={4}>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Trình độ chuyên môn"
                  value={user.user_diploma}
                  name="user_diploma"
                  onChange={handleInput}
                  className={`${
                    errorMessage?.user_diploma ? "is-invalid" : ""
                  }`}
                />
                <Form.Label>Trình độ chuyên môn</Form.Label>
                <span className="text-danger">
                  {errorMessage?.user_diploma}
                </span>
              </Form.Floating>
            </Col>

            <Col md={4}>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Số điện thoại"
                  value={user.user_phone}
                  name="user_phone"
                  onChange={handleInput}
                  className={`${errorMessage?.user_phone ? "is-invalid" : ""}`}
                />
                <Form.Label>Số điện thoại</Form.Label>
                <span className="text-danger">{errorMessage?.user_phone}</span>
              </Form.Floating>
            </Col>

            <Col md={4}>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Email"
                  value={user.user_email}
                  name="user_email"
                  onChange={handleInput}
                  className={`${errorMessage?.user_email ? "is-invalid" : ""}`}
                />
                <Form.Label>Email</Form.Label>
                <span className="text-danger">{errorMessage?.user_email}</span>
              </Form.Floating>
            </Col>

            <Col md={8}>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Địa chỉ"
                  value={user.user_address}
                  name="user_address"
                  onChange={handleInput}
                  className={`${
                    errorMessage?.user_address ? "is-invalid" : ""
                  }`}
                />
                <Form.Label>Địa chỉ</Form.Label>
                <span className="text-danger">
                  {errorMessage?.user_address}
                </span>
              </Form.Floating>
            </Col>

            <Col md={4}>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Hình thức hợp đồng"
                  value={user.user_contract}
                  name="user_contract"
                  onChange={handleInput}
                  className={`${
                    errorMessage?.user_contract ? "is-invalid" : ""
                  }`}
                />
                <Form.Label>Hình thức hợp đồng</Form.Label>
                <span className="text-danger">
                  {errorMessage?.user_contract}
                </span>
              </Form.Floating>
            </Col>

            <Col md={4}>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Tài khoản"
                  value={user.user_account}
                  name="user_account"
                  onChange={(e) => {
                    handleInput(e);
                    if (e.target.value === "") {
                      setErrorMessage({
                        ...errorMessage,
                        user_password: "",
                        user_confirm_password: "",
                      });
                    }
                  }}
                  className={`${
                    errorMessage?.user_account ? "is-invalid" : ""
                  }`}
                />
                <Form.Label>Tài khoản</Form.Label>
                <span className="text-danger">
                  {errorMessage?.user_account}
                </span>
              </Form.Floating>
            </Col>

            <Col md={4}>
              <PasswordInput
                label={
                  <>
                    Mật khẩu
                    {user.user_account !== "" ? (
                      <span className="text-danger">*</span>
                    ) : (
                      <></>
                    )}
                  </>
                }
                name="user_password"
                value={user.user_password}
                placeholde="Mật khẩu"
                onChange={handleInput}
                errMessage={errorMessage?.user_password}
              />
            </Col>

            <Col md={4}>
              <PasswordInput
                label={
                  <>
                    Nhập lại mật khẩu
                    {user.user_account !== "" ? (
                      <span className="text-danger">*</span>
                    ) : (
                      <></>
                    )}
                  </>
                }
                name="user_confirm_password"
                value={user.user_confirm_password}
                placeholde="Nhập lại mật khẩu"
                onChange={handleInput}
                errMessage={errorMessage?.user_confirm_password}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={onHide}>
            <i className="fa-solid fa-times" /> Đóng
          </Button>

          <Button size="sm" variant="success" type="submit">
            <i className="fa-solid fa-check" /> Lưu
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UserAddModal;
