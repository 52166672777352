import { Card } from "react-bootstrap";


const LoadingSpinner = () => {
    return (
        <Card>
            <Card.Body className="d-flex justify-content-center align-items-center">
                <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{ width: "3rem", height: "3rem" }}
                >
                    <span className="sr-only">Loading...</span>
                </div>
            </Card.Body>
        </Card>
    );
};

export default LoadingSpinner;