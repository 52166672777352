import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Form,
} from "react-bootstrap";
import Check from "../../admin/other/Check";
import api from "../../../helper/axiosInstance";

class DetailSchool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      school: {
        id_school: "",
        school_name: "",
        school_email: "",
        school_phone: "",
        school_address: "",
        school_author: "",
        school_logo: "",
        changePhone: 0,
      },
    };
  }

  componentDidMount() {
    document.title = "Trường học";
    this.getDetailschool();
  }

  getDetailschool() {
    api
      .get(`/detailSchool`)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            school: {
              id_school: res.data.data[0].id,
              school_name: res.data.data[0].school_name,
              school_email: res.data.data[0].school_email,
              school_phone: res.data.data[0].school_phone,
              school_address: res.data.data[0].school_address,
              school_author: res.data.data[0].school_author,
              changePhone: 0,
            },
          });
        }
      })
      .catch((error) => {});
  }

  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    if (event.target.name === "changePhone") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    let school = { ...this.state.school };
    school[field] = value;
    this.setState({ school: school });
  };

  updateSubmit = (e) => {
    e.preventDefault();
    let changePhoneT = "";
    const id_school = this.state.school.id_school;
    const school_name = this.state.school.school_name;
    const school_author = this.state.school.school_author;
    const school_phone = this.state.school.school_phone;
    const school_email = this.state.school.school_email;
    const school_address = this.state.school.school_address;
    const school_logo = this.state.school.school_logo;
    if (this.state.school.changePhone === true) {
      changePhoneT = "1";
    } else {
      changePhoneT = "0";
    }
    const changePhone = changePhoneT;
    swal({
      title: "Bạn muốn cập nhật trường học?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .post(
              `/updatedSchool?id_school=${id_school}&school_name=${school_name}&school_email=${school_email}&school_phone=${school_phone}&school_address=${school_address}&school_author=${school_author}&changePhone=${changePhone}&school_logo=${school_logo}`
            )
            .then((res) => {
              if (res.data.status === true) {
                toast(res.data.data.mess, { type: "success", autoClose: 1000 });
              } else {
                toast(res.data.data.mess, { type: "error", autoClose: 1000 });
              }
            })
            .catch((error) => {});
        }
      })
      .catch((error) => {});
  };

  renderschool() {
    return (
      <>
        <Card.Header>
          <Card.Title>
            <i className="fa-solid fa-school"></i> Thông tin trường học
          </Card.Title>
        </Card.Header>

        <Form onSubmit={this.updateSubmit}>
          <Card.Body>
            <Row>
              <Col md={12}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    onChange={this.handleInput}
                    name="school_name"
                    value={this.state.school.school_name}
                    type="text"
                    id="school_name"
                    placeholder="Tên trường học"
                    required
                  />
                  <label htmlFor="school_name">
                    Tên trường học<i className="text-danger">*</i>
                  </label>
                </Form.Floating>
              </Col>

              <Col md={12}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    id="school_author"
                    name="school_author"
                    onChange={this.handleInput}
                    value={this.state.school.school_author}
                    placeholder="Đại diện"
                    required
                  />
                  <label htmlFor="school_author">
                    Đại diện<i className="text-danger">*</i>
                  </label>
                </Form.Floating>
              </Col>

              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    id="school_phone"
                    name="school_phone"
                    onChange={this.handleInput}
                    value={this.state.school.school_phone}
                    placeholder="Số điện thoại"
                    required
                    // readOnly
                  />

                  <label htmlFor="school_phone">
                    Số điện thoại<i className="text-danger">*</i>
                  </label>
                </Form.Floating>
              </Col>

              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="email"
                    id="school_email"
                    name="school_email"
                    onChange={this.handleInput}
                    value={this.state.school.school_email}
                    placeholder="Email"
                    required
                  />
                  <label htmlFor="school_email">
                    Email<i className="text-danger">*</i>
                  </label>
                </Form.Floating>
              </Col>

              <Col md={12}>
                <Form.Check
                  className="mb-3"
                  type="checkbox"
                  label="Đổi số điện thoại đăng nhập"
                  name="changePhone"
                  onChange={this.handleInput}
                  checked={this.state.school.changePhone}
                />

                <Form.Floating className="mb-3">
                  <Form.Control
                    onChange={this.handleInput}
                    name="school_address"
                    value={this.state.school.school_address}
                    type="text"
                    placeholder="Địa chỉ"
                  />
                  <label htmlFor="school_address">Địa chỉ</label>
                </Form.Floating>
              </Col>
            </Row>
          </Card.Body>

          {new Check().permission([159]) ? (
            <Card.Footer className="text-end">
              <Button size="sm" variant="success" type="submit">
                <i className="fas fa-pencil"></i> Cập nhật
              </Button>
            </Card.Footer>
          ) : (
            <></>
          )}
        </Form>
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
            <Breadcrumb.Item active>Trường học</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={5}>
              <Card className="mb-4">{this.renderschool()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default DetailSchool;
