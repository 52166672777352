import React, { useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import Select from "react-select";
import CurrencyFormat from "react-currency-format";
import { dateFormatter } from "../../utils/helpers";

const MenuExcelTemplate2 = ({
  school,
  weekInMonth,
  startDate,
  endDate,
  listQuantitatives,
  listDays,
  listTimes,
  classGroups,
  selectedClassGroup,
  setSelectedClassGroups,
  show,
  onHide,
  handleExport,
}) => {
  const [numberChild, setNumberChild] = useState(0);
  return (
    <Modal size="sm" backdrop="static" show={show} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Xuất file thực đơn ăn chính</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className="mb-3">
          <Col xs={12} className="mb-3">
            <Select
              options={classGroups}
              placeholder={<div>Chọn nhóm</div>}
              getOptionLabel={(option) => option.group_name}
              getOptionValue={(option) => option.id}
              value={selectedClassGroup}
              onChange={(choice) => {
                setSelectedClassGroups(choice);
              }}
            />
          </Col>
          <Col xs={12}>
            <Form.Floating>
              <CurrencyFormat
                value={numberChild}
                thousandSeparator={true}
                onValueChange={(values) => {
                  setNumberChild(values.value);
                }}
                className="form-control text-end"
                allowNegative={false}
                isNumericString
              />
              <Form.Label>
                Số lượng trẻ
                <span className="text-danger"></span>
              </Form.Label>
            </Form.Floating>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onHide()}>
          <i className="fa-solid fa-times"></i> Đóng
        </Button>

        <Button
          size="sm"
          type="submit"
          variant="success"
          onClick={() => handleExport()}
        >
          <i className="fa-solid fa-check"></i> Xuất
        </Button>
      </Modal.Footer>

      <Table bordered responsive id="menu-export-excel-2" className="d-none">
        <thead className="d-none">
          <tr>
            <th colSpan={5}>{school.school_name?.toUpperCase()}</th>
            <th colSpan={(listDays.length - 2) * 3}>
              THỰC ĐƠN ĂN CHÍNH {selectedClassGroup.group_name?.toUpperCase()}{" "}
              THÁNG{" "}
              {("0" + (startDate.getMonth() + 1)).slice(-2) +
                "/" +
                startDate.getFullYear()}{" "}
              <br /> Tuần {weekInMonth} từ {dateFormatter(startDate)} đến{" "}
              {dateFormatter(endDate)}
            </th>
            <th colSpan={3}>
              {selectedClassGroup.group_name}: {numberChild} trẻ
            </th>
          </tr>
          <tr>
            <th colSpan={5}></th>
            <th colSpan={(listDays.length - 2) * 3}></th>
            <th colSpan={3}>Đơn vị tính: Gam</th>
          </tr>
          <tr>
            <th>Buổi</th>
            <th>DM</th>
            {listDays.map((dayItem) => {
              return (
                <React.Fragment key={dayItem.id_day}>
                  <th>{dayItem.day_name}</th>
                  <th>empty</th>
                  <th>empty</th>
                </React.Fragment>
              );
            })}
          </tr>
        </thead>

        <tbody className="align-middle">
          {listTimes.map((timeItem) => {
            const quantitativeTimes = [];
            let categoryTimes = [];
            listQuantitatives.map((quantitativeItem) => {
              return quantitativeItem.times.map((timeObj) => {
                if (timeObj.id_time === timeItem.id_time) {
                  quantitativeTimes.push(timeObj);
                  let filteredFoods = timeObj.foods.filter((foodItem) =>
                    foodItem.groups.some(
                      (groupItem) =>
                        groupItem.id === selectedClassGroup.id &&
                        groupItem.ingredents.length !== 0
                    )
                  );

                  let categoryTimesToAdd = [];

                  if (filteredFoods.length > 0) {
                    categoryTimesToAdd = filteredFoods.map((foodItem) => {
                      return {
                        id_category: foodItem.id_category,
                        id_category_bank: foodItem.id_category_bank,
                        category_name: foodItem.category_name,
                        count_meal: quantitativeItem?.costPerDays,
                        more_meal:
                          foodItem?.groups?.filter(
                            (meal) => meal.id === selectedClassGroup?.id
                          )[0]?.more_meal ?? 0,
                      };
                    });
                  }
                  // Nếu k có category thì thêm category empty
                  else {
                    categoryTimesToAdd.push({
                      id_category: "",
                      id_category_bank: "",
                      category_name: "empty",
                      count_meal: [],
                      more_meal: 0,
                    });
                  }

                  categoryTimes = categoryTimes.concat(categoryTimesToAdd);
                }

                return timeObj;
              });
            });

            categoryTimes = [
              ...new Map(
                categoryTimes.map((item) => [item["id_category"], item])
              ).values(),
            ].sort(function (a, b) {
              return (
                (a.id_category_bank === null) - (b.id_category_bank === null) ||
                +(a.id_category_bank > b.id_category_bank) ||
                -(a.id_category_bank < b.id_category_bank)
              );
            });

            let dataCate =
              categoryTimes?.filter((ca) => ca.id_category !== "").length > 0
                ? categoryTimes?.filter((ca) => ca.id_category !== "")
                : categoryTimes;

            categoryTimes = dataCate.map((categoryItem) => {
              const listItemRows = quantitativeTimes.map(
                (quantitativeTimeItem) => {
                  let itemRow = [];
                  let rowSpanLength = 0;

                  quantitativeTimeItem.foods
                    .filter(
                      (foodItem) =>
                        foodItem.id_category === categoryItem.id_category
                    )
                    .map((foodItem) => {
                      if (
                        foodItem.groups.some(
                          (groupItem) =>
                            groupItem.id === selectedClassGroup.id &&
                            groupItem.ingredents.length !== 0
                        )
                      ) {
                        const group = foodItem.groups.find(
                          (groupItem) => groupItem.id === selectedClassGroup.id
                        );

                        rowSpanLength += 1;
                        rowSpanLength += foodItem.groups[0].ingredents.length;

                        itemRow.push({
                          isFood: true,
                          id: foodItem.id,
                          name: foodItem.food_name,
                          quantity: 0,
                          marketQuantity: 0,
                          moreMeal: group.more_meal,
                        });

                        foodItem.groups
                          .find(
                            (groupItem) =>
                              groupItem.id === selectedClassGroup.id &&
                              groupItem.ingredents.length !== 0
                          )
                          .ingredents.map((ingredientItem) => {
                            const quantity = Number(
                              (ingredientItem.ing_quantity *
                                ingredientItem.volume_market) /
                                ingredientItem.volume_cook
                            ).toFixed(4);
                            const intoQuantity =
                              (quantity *
                                (group.more_meal > 0
                                  ? group.more_meal
                                  : numberChild)) /
                              1000;

                            itemRow.push({
                              isFood: false,
                              id: ingredientItem.id,
                              name: ingredientItem.ingredent_name,
                              quantity: quantity,
                              intoQuantity: intoQuantity,
                            });

                            return ingredientItem;
                          });
                      }

                      return foodItem;
                    });

                  return {
                    id: quantitativeTimeItem.id,
                    items: itemRow,
                    length: rowSpanLength !== 0 ? rowSpanLength : 1,
                  };
                }
              );

              const maxRowSpanLength = Math.max(
                ...listItemRows.map((itemRow) => itemRow.length)
              );

              return {
                ...categoryItem,
                timeQuantitative: listItemRows.map((itemRow) => {
                  return {
                    ...itemRow,
                    length: maxRowSpanLength,
                  };
                }),
                cateLength: maxRowSpanLength,
              };
            });

            return (
              <React.Fragment key={timeItem.id_time}>
                <tr>
                  <th className="text-center">{timeItem.time_name}</th>
                  <th className="text-center">
                    {categoryTimes[0]?.category_name}
                  </th>
                  {categoryTimes[0]?.timeQuantitative?.map((rowItem) => {
                    return (
                      <React.Fragment key={rowItem.id}>
                        <th>{rowItem.items[0]?.name || "empty"}</th>
                        <th>empty</th>
                        <th>empty</th>
                      </React.Fragment>
                    );
                  })}
                </tr>

                {categoryTimes.map(
                  (categoryTimeItem, categoryTimeItemIndex) => {
                    const arr = [];

                    for (
                      let i = categoryTimeItemIndex === 0 ? 1 : 0;
                      i < categoryTimeItem.cateLength;
                      i++
                    ) {
                      arr.push(
                        <tr key={i} className="text-center">
                          <th>empty</th>
                          <th>
                            {i === 0 ? categoryTimeItem.category_name : "empty"}
                          </th>

                          {categoryTimeItem?.timeQuantitative?.map(
                            (rowItem) => {
                              if (rowItem.items[i]?.isFood) {
                                const food = rowItem.items[i];
                                const foodName =
                                  rowItem.items[i]?.name +
                                  (food.moreMeal > 0
                                    ? ` (SL: ${food.moreMeal})`
                                    : "");

                                return (
                                  <React.Fragment key={rowItem.id}>
                                    <th>{foodName}</th>
                                    <th>empty</th>
                                    <th>empty</th>
                                  </React.Fragment>
                                );
                              }

                              return (
                                <React.Fragment key={rowItem.id}>
                                  <td>{rowItem.items[i]?.name || "empty"}</td>
                                  <td>
                                    {rowItem.items[i]?.quantity ? (
                                      <CurrencyFormat
                                        value={parseFloat(
                                          rowItem.items[i]?.quantity
                                        )}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                      />
                                    ) : (
                                      "empty"
                                    )}
                                  </td>
                                  <td>
                                    {rowItem.items[i]?.quantity ? (
                                      <CurrencyFormat
                                        value={parseFloat(
                                          rowItem.items[i]?.intoQuantity
                                        )}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                      />
                                    ) : (
                                      "empty"
                                    )}
                                  </td>
                                </React.Fragment>
                              );
                            }
                          )}
                        </tr>
                      );
                    }

                    return arr;
                  }
                )}
              </React.Fragment>
            );
          })}
        </tbody>

        <tfoot>
          <tr>
            {(() => {
              const arr = [];
              for (let i = 0; i < listDays.length * 3 + 2; i++) {
                arr.push(<td>empty</td>);
              }
              return arr;
            })()}
          </tr>

          <tr className="align-middle text-center">
            {(() => {
              const arr = [];
              for (let i = 0; i < listDays.length * 3 + 2; i++) {
                if (i === 0) {
                  arr.push(<th key={i}>Người lập</th>);
                  continue;
                }

                if (i === Math.ceil((listDays.length * 3 + 2) / 2) + 1) {
                  arr.push(<th key={i}>Hiệu phó bán trú</th>);
                  continue;
                }

                arr.push(<th key={i}>empty</th>);
              }
              return arr;
            })()}
          </tr>
        </tfoot>
      </Table>
    </Modal>
  );
};

export default MenuExcelTemplate2;
