import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Form,
} from "react-bootstrap";
import Check from "../../admin/other/Check";
import moment from "moment/moment";

import api from "../../../helper/axiosInstance";

class DetailSchool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      school: {
        id_school: "",
        school_name: "",
        school_email: "",
        school_phone: "",
        school_address: "",
        school_author: "",
        school_logo: "",
        changePhone: 0,
      },
      provinces: [],
      err: {},
    };
  }

  componentDidMount() {
    document.title = "Trường học";
    this.getProvince();
  }
  getProvince() {
    api
      .get(`/address-vn-show`)
      .then((res) => {
        this.setState(
          {
            provinces: res.data.data,
          },
          () => this.getDetailschool()
        );
      })
      .catch((error) => {});
  }

  getDetailschool() {
    api
      .get(`/school-show`)
      .then((res) => {
        this.setState({
          school: res.data.data,
        });
      })
      .catch((error) => {});
  }

  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    if (event.target.name === "changePhone") {
      value = event.target.checked ? 1 : 0;
    } else {
      value = event.target.value;
    }
    let school = { ...this.state.school };
    school[field] = value;
    let err = { ...this.state.err };
    err[field] = "";
    this.setState({ school: school, err });
  };

  updateSubmit = (e) => {
    e.preventDefault();

    let data = {
      id_province: this.state.school?.id_province,
      school_name: this.state.school?.school_name,
      school_email: this.state.school?.school_email,
      school_phone: this.state.school?.school_phone,
      school_phoneAccount: this.state.school?.school_phoneAccount,
      school_address: this.state.school?.school_address,
      school_author: this.state.school?.school_author,
      school_logo: "",
      change_phone_account: this.state.school.changePhone,
    };
    swal({
      title: "Bạn muốn cập nhật trường học?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        api
          .put("school-update", data, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            toast(res.data.messages || "Thành công", { type: "success" });
          })
          .catch((error) => {
            this.setState({ err: error.response.data.messages });
          });
      }
    });
  };

  renderschool() {
    return (
      <>
        <Card.Header>
          <Card.Title>
            <i className="fa-solid fa-school"></i> Thông tin trường học
          </Card.Title>
        </Card.Header>

        <Form onSubmit={this.updateSubmit}>
          <Card.Body>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Floating>
                  <Form.Control
                    onChange={this.handleInput}
                    name="school_name"
                    value={this.state.school.school_name}
                    type="text"
                    id="school_name"
                    placeholder="Tên trường học"
                    className={this.state.err.school_name ? `is-invalid` : ""}
                    // required
                  />
                  <Form.Label>
                    Tên trường học<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
                <span className="text-danger">
                  {this.state.err.school_name}
                </span>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Floating>
                  <Form.Control
                    type="text"
                    id="school_author"
                    name="school_author"
                    onChange={this.handleInput}
                    value={this.state.school.school_author}
                    placeholder="Đại diện"
                    className={this.state.err.school_author ? `is-invalid` : ""}
                    // required
                  />
                  <Form.Label>
                    Đại diện<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
                <span className="text-danger">
                  {this.state.err.school_author}
                </span>
              </Col>

              <Col md={6} className="mb-3">
                <Form.Floating>
                  <Form.Control
                    type="text"
                    id="school_phone"
                    name="school_phone"
                    onChange={this.handleInput}
                    value={this.state.school.school_phone}
                    placeholder="Số điện thoại"
                    className={this.state.err.school_phone ? `is-invalid` : ""}
                    // required
                  />
                  <Form.Label>
                    Số điện thoại<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
                <span className="text-danger">
                  {this.state.err.school_phone}
                </span>
              </Col>

              <Col md={6} className="mb-3">
                <Form.Floating>
                  <Form.Control
                    type="email"
                    id="school_email"
                    name="school_email"
                    onChange={this.handleInput}
                    value={this.state.school.school_email}
                    placeholder="Email"
                    // required
                    className={this.state.err.school_email ? `is-invalid` : ""}
                  />
                  <Form.Label>
                    Email<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
                <span className="text-danger">
                  {this.state.err.school_email}
                </span>
              </Col>

              <Col md={6} className="mb-3">
                <Form.Floating>
                  <Form.Select
                    value={this.state.school.id_province}
                    onChange={this.handleInput}
                    name="id_province"
                    // required
                    className={this.state.err.id_province ? `is-invalid` : ""}
                  >
                    <option value="">--Chọn--</option>
                    {this.state.provinces?.length > 0 ? (
                      this.state.provinces?.map((province) => {
                        return (
                          <option
                            key={`province${province.id}`}
                            value={province.id}
                          >
                            {province.name}
                          </option>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </Form.Select>
                  <Form.Label>
                    Thành phố<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
                <span className="text-danger">
                  {this.state.err.id_province}
                </span>
              </Col>

              <Col md={12} className="mb-3">
                <Form.Floating>
                  <Form.Control
                    onChange={this.handleInput}
                    name="school_address"
                    value={this.state.school.school_address}
                    type="text"
                    placeholder="Địa chỉ"
                    className={
                      this.state.err.school_address ? `is-invalid` : ""
                    }
                  />
                  <Form.Label>Địa chỉ</Form.Label>
                </Form.Floating>
                <span className="text-danger">
                  {this.state.err.school_address}
                </span>
              </Col>

              <Col md={6} className="align-content-center mb-3">
                <Form.Check
                  type="switch"
                  label="Đổi số điện thoại đăng nhập"
                  name="changePhone"
                  onChange={(e) => {
                    this.handleInput(e);
                  }}
                  checked={this.state.school.changePhone === 1 ? true : false}
                />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Floating>
                  <Form.Control
                    type="text"
                    id="school_phoneAccount"
                    name="school_phoneAccount"
                    onChange={this.handleInput}
                    value={this.state.school.school_phoneAccount}
                    placeholder="Số điện thoại"
                    // required
                    disabled={!this.state.school.changePhone}
                    className={
                      this.state.err.school_phoneAccount ? `is-invalid` : ""
                    }
                  />
                  <Form.Label>
                    Số điện thoại đăng nhập<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
                <span className="text-danger">
                  {this.state.err.school_phoneAccount}
                </span>
              </Col>
              {/* <hr />
              <h5>Gói cước</h5>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Giới hạn nhân viên"
                    className="text-end"
                    disabled
                    value={this.state.school.max_user}
                  />
                  <Form.Label>Giới hạn nhân viên</Form.Label>
                </Form.Floating>
              </Col>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Giới hạn học sinh"
                    className="text-end"
                    disabled
                    value={this.state.school.max_student}
                  />
                  <Form.Label>Giới hạn học sinh</Form.Label>
                </Form.Floating>
              </Col> */}
              <Col md={6} className="mb-3">
                <Form.Floating>
                  <Form.Control
                    type="text"
                    id="exp_date"
                    placeholder="TNgày hết hạn"
                    className="text-end"
                    disabled
                    value={moment(
                      new Date(this.state.school.exp_date * 1000)
                    ).format("DD/MM/YYYY")}
                  />

                  <Form.Label>Ngày hết hạn</Form.Label>
                </Form.Floating>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Floating>
                  <Form.Control
                    name="updated_at"
                    type="text"
                    id="updated_at"
                    placeholder="Cập nhật"
                    className="text-end"
                    required
                    disabled
                    value={moment(
                      new Date(this.state.school.updated_at * 1000)
                    ).format("DD/MM/YYYY HH:mm:ss")}
                  />

                  <Form.Label>Cập nhật</Form.Label>
                </Form.Floating>
              </Col>
            </Row>
          </Card.Body>

          {new Check().permission([159]) ? (
            <Card.Footer className="text-end">
              <Button size="sm" variant="success" type="submit">
                <i className="fas fa-check"></i> Cập nhật
              </Button>
            </Card.Footer>
          ) : (
            <></>
          )}
        </Form>
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
            <Breadcrumb.Item active>Trường học</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={5}>
              <Card className="mb-4">{this.renderschool()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default DetailSchool;
