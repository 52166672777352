import React, { Component } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import moment from "moment/moment";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import api from "../../../helper/axiosInstance";
import { dateFormatter } from "../utils/helpers";
class ChildGrowthModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      formData: this.props.data || {
        id_student: "", // required
        height: 0, // required, float, gte 1 cm
        weight: 0, // required, float, gte 1 kg
        month: "", // nullable, integer gte 1
        implementation_date: new Date() / 1000, // required, integer
        // "other": [{"id": "123"}, {}]
      },
      errors: {},
      other: this.props.data?.other
        ? [
            ...this.props.data.other.map((item) => {
              const key = Object.keys(item)[0];
              return { key: key, value: item[key] };
            }),
            { key: "", value: "" },
          ]
        : [{ key: "", value: "" }],
    };
  }
  handleChangeOther = (index, field, value) => {
    const { other } = this.state;
    const newList = [...other];
    newList[index][field] = value;

    if (
      index === other.length - 1 &&
      newList[index].key &&
      newList[index].value
    ) {
      newList.push({ key: "", value: "" });
    }

    if (
      newList.length == 0 ||
      (newList.length == 1 && (newList[0].key || newList[0].value))
    ) {
      newList.push({ key: "", value: "" });
    }

    this.setState({ other: newList });
  };

  handleRemove = (index) => {
    const { other } = this.state;
    const newList = [...other];
    newList.splice(index, 1);
    this.setState({ other: newList });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    if (e.target.name == "implementation_date") {
      value = new Date(e.target.value) / 1000;
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
    delete this.state.errors[name];
  };
  calculateAgeInMonths = (birth, current) => {
    const birthDate = new Date(birth * 1000);
    const currentDate = new Date(current * 1000);

    const yearsDifference = currentDate.getFullYear() - birthDate.getFullYear();
    const monthsDifference = currentDate.getMonth() - birthDate.getMonth();

    let ageInMonths = yearsDifference * 12 + monthsDifference;

    if (currentDate.getDate() < birthDate.getDate()) {
      ageInMonths -= 1;
    }

    return ageInMonths;
  };
  createChildGrowth = () => {
    let data = {
      id_student: this.props.studentChoice?.id, // required
      height: this.state.formData?.height || 0, // required, float, gte 1 cm
      weight: this.state.formData?.weight || 0, // required, float, gte 1 kg
      month:
        this.calculateAgeInMonths(
          this.props?.studentChoice?.student_birthday,
          this.state.formData?.implementation_date
        ) || "", // nullable, integer gte 1
      implementation_date: parseInt(this.state.formData?.implementation_date),
      other: this.state.other
        ?.filter((e) => e.value != "" && e.key != "")
        .map((item) => ({ [item.key]: item.value })),
    };

    api
      .post(`child-growth-standard-create`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Thêm thành công", { type: "success" });
        return this.props.onSubmit();
      })
      .catch((error) => {
        this.setState({ errors: error.response.data.messages });
      });
  };
  updateChildGrowth = () => {
    let data = {
      id_student: this.props.studentChoice?.id, // required
      height: this.state.formData?.height || 0, // required, float, gte 1 cm
      weight: this.state.formData?.weight || 0, // required, float, gte 1 kg
      month:
        this.calculateAgeInMonths(
          this.props?.studentChoice?.student_birthday,
          parseInt(this.state.formData?.implementation_date) ||
            new Date() / 1000
        ) || "", // nullable, integer gte 1
      implementation_date:
        parseInt(this.state.formData?.implementation_date) || new Date() / 1000, // required, integer
      other: this.state.other
        ?.filter((e) => e.value != "" && e.key != "")
        .map((item) => ({ [item.key]: item.value })),
    };

    api
      .put(`/child-growth-standard-update?id=${this.props?.data?.id}`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Cập nhật thành công", { type: "success" });
        return this.props.onSubmit();
      })
      .catch((error) => {
        this.setState({ errors: error.response.data.messages });
      });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.data?.id) {
      this.updateChildGrowth();
    } else this.createChildGrowth();
  };

  render() {
    const { formData, errors } = this.state;

    return (
      <>
        <Modal show={this.props.show} onHide={this.props?.onHide} size={"lg"}>
          <Modal.Header closeButton>
            <Modal.Title>
              Sổ sức khỏe {this.props?.studentChoice?.student_name}
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      className="text-end"
                      readOnly
                      value={this.props.studentChoice?.gender_name}
                    />
                    <Form.Label>
                      Giới tính
                      <i className="text-danger"></i>
                    </Form.Label>
                  </Form.Floating>
                </Col>
                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="date"
                      className="text-end"
                      readOnly
                      value={moment(
                        new Date(
                          this.props.studentChoice?.student_birthday * 1000
                        )
                      ).format("YYYY-MM-DD")}
                    />
                    <Form.Label>
                      Ngày sinh
                      <i className="text-danger"></i>
                    </Form.Label>

                    <span className="text-form-err">
                      {errors.implementation_date}
                    </span>
                  </Form.Floating>
                </Col>

                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="number"
                      name="month"
                      className="text-end"
                      // value={formData.month || ""}
                      value={parseInt(
                        this.calculateAgeInMonths(
                          this.props?.studentChoice?.student_birthday,
                          formData.implementation_date
                        )
                      )}
                      // onChange={this.handleChange}
                      disabled
                      placeholder="Tháng"
                      min={1}
                    ></Form.Control>

                    <Form.Label>
                      Độ tuổi (tháng)
                      <i className="text-danger"></i>
                    </Form.Label>

                    <span className="text-form-err">{errors.month}</span>
                  </Form.Floating>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <CurrencyFormat
                      className="form-control text-end"
                      name="height"
                      thousandSeparator={true}
                      value={formData.height || ""}
                      placeholder="Nhập Chiều cao"
                      required
                      suffix=" cm"
                      onValueChange={(values) => {
                        const { value } = values;
                        let dataInput = { ...this.state.formData };
                        dataInput.height = value;
                        this.setState({ formData: dataInput });
                        delete this.state.errors["height"];
                      }}
                    />
                    <Form.Label>
                      Chiều cao (cm)<i className="text-danger">*</i>
                    </Form.Label>

                    <span className="text-form-err">{errors.height}</span>
                  </Form.Floating>
                </Col>
                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <CurrencyFormat
                      className="form-control text-end"
                      name="weight"
                      thousandSeparator={true}
                      placeholder="Cân nặng (kg)"
                      suffix=" kg"
                      value={formData.weight || ""}
                      required
                      onValueChange={(values) => {
                        const { value } = values;
                        let dataInput = { ...this.state.formData };
                        dataInput.weight = value;
                        this.setState({ formData: dataInput });
                        delete this.state.errors["weight"];
                      }}
                    />
                    <Form.Label>
                      Cân nặng (kg)<i className="text-danger">*</i>
                    </Form.Label>

                    <span className="text-form-err">{errors.weight}</span>
                  </Form.Floating>
                </Col>
                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="date"
                      required
                      name="implementation_date"
                      value={moment(
                        new Date(formData.implementation_date * 1000)
                      ).format("YYYY-MM-DD")}
                      onChange={this.handleChange}
                    />
                    <Form.Label>
                      Ngày kiểm tra
                      <i className="text-danger">*</i>
                    </Form.Label>

                    <span className="text-form-err">
                      {errors.implementation_date}
                    </span>
                  </Form.Floating>
                </Col>
              </Row>{" "}
              <Row> </Row>
              <Row>
                <p className="text-center fw-bold"> Tiêu chí khác</p>
              </Row>
              {this.state.other?.map((criteria, index) => (
                <Row key={index}>
                  <Col md={6}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="text"
                        name="key"
                        value={criteria.key}
                        onChange={(e) =>
                          this.handleChangeOther(index, "key", e.target.value)
                        }
                        placeholder="Nhập tiêu chí"
                      />
                      <Form.Label>Tiêu chí</Form.Label>
                    </Form.Floating>
                  </Col>
                  <Col md={5}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="text"
                        name="value"
                        value={criteria.value}
                        onChange={(e) =>
                          this.handleChangeOther(index, "value", e.target.value)
                        }
                        placeholder="Nhập đánh giá"
                      />
                      <Form.Label>Đánh giá</Form.Label>
                    </Form.Floating>
                  </Col>
                  <Col md={1} className="mb-3 d-flex align-items-center">
                    <Button
                      variant="outline-danger"
                      onClick={() => this.handleRemove(index)}
                      disabled={this.state.other.length < 2}
                    >
                      X
                    </Button>
                  </Col>
                </Row>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary me-2"
                onClick={() => this.props?.onHide()}
              >
                Đóng
              </Button>
              <Button variant="primary" type="submit">
                {this.props?.data?.id ? "Cập nhật" : "Lưu"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default ChildGrowthModal;
