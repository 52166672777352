import { Form, Table } from "react-bootstrap";
import Select from "react-select";
import CurrencyFormat from "react-currency-format";
import { dateFormatter } from "../../utils/helpers";

const MarketListTable = ({
  voucherMarket,
  setVoucherMarket,
  school,
  suppliers,
  isChangeVoucherMarket,
}) => {
  if (!voucherMarket) {
    return "";
  }

  let marketMoney = 0;
  let storeMoney = 0;

  return (
    // {console.log(suppliers)}
    <div style={{ overflowX: "scroll" }} className="table-responsive-600">
      <Table bordered style={{ minWidth: 1024 }}>
        <thead>
          <tr className="text-center">
            <th colSpan="9">
              Tổng hợp định lượng đi chợ{" "}
              {dateFormatter(new Date(voucherMarket.menu_date * 1000))}
            </th>
          </tr>

          <tr className="text-center tr-sticky-first">
            <th>STT</th>
            <th>NGUYÊN LIỆU</th>
            <th>NHÀ CUNG CẤP</th>
            <th>ĐƠN VỊ TÍNH</th>
            <th>SỐ LƯỢNG CẦN MUA</th>
            <th>HÀNG TỒN</th>
            <th>ĐƠN GIÁ</th>
            <th>THÀNH TIỀN</th>
            <th>GHI CHÚ</th>
          </tr>
        </thead>
        <tbody>
          {/* Market */}
          {voucherMarket.ingredents.filter(
            (ingredientItem) => ingredientItem.ingredent_type === 1
          ).length !== 0 ? (
            <>
              {voucherMarket.ingredents
                .filter(
                  (ingredientItem) =>
                    Number(ingredientItem.ingredent_type) === 1
                )
                ?.sort((a, b) =>
                  a?.ingredent_name?.localeCompare(b?.ingredent_name)
                )
                ?.map((ingredientItem, ingredientIndex) => {
                  const ingredientItemPrice =
                    ingredientItem.ing_quantity * ingredientItem.ing_price;
                  marketMoney += parseFloat(ingredientItemPrice);

                  return (
                    <tr
                      key={`ingredient-key-${ingredientItem.id_ingredent}`}
                      className="align-middle"
                    >
                      <td className="text-center">{ingredientIndex + 1}</td>
                      <td>{ingredientItem.ingredent_name}</td>
                      <td>
                        {isChangeVoucherMarket ? (
                          <Select
                            options={suppliers}
                            getOptionLabel={(option) => option.supplier_name}
                            getOptionValue={(option) => option.id}
                            value={{
                              id: ingredientItem.id,
                              supplier_name: ingredientItem.supplier_name,
                            }}
                            placeholder={<div>Chọn nhà cung cấp</div>}
                            onChange={(choice) => {
                              setVoucherMarket({
                                ...voucherMarket,
                                ingredents: voucherMarket.ingredents.map(
                                  (ingredientObj) => {
                                    if (
                                      ingredientItem.id_ingredent ===
                                      ingredientObj.id_ingredent
                                    ) {
                                      return {
                                        ...ingredientObj,
                                        id_supplier: choice.id,
                                        supplier_name: choice.supplier_name,
                                      };
                                    }
                                    return ingredientObj;
                                  }
                                ),
                              });
                            }}
                            menuPortalTarget={document.querySelector("body")}
                          />
                        ) : (
                          ingredientItem.supplier_name
                        )}
                      </td>
                      <td>{ingredientItem.unit_market_name}</td>
                      <td className="text-end">
                        {isChangeVoucherMarket ? (
                          <CurrencyFormat
                            defaultValue={
                              ingredientItem.ing_quantity > 0
                                ? ingredientItem.ing_quantity
                                : 0
                            }
                            displayType={
                              !isChangeVoucherMarket ? "text" : "input"
                            }
                            thousandSeparator={true}
                            onValueChange={(values) => {
                              setVoucherMarket({
                                ...voucherMarket,
                                ingredents: voucherMarket.ingredents.map(
                                  (ingredientObj) => {
                                    if (
                                      ingredientItem.id_ingredent ===
                                      ingredientObj.id_ingredent
                                    ) {
                                      return {
                                        ...ingredientObj,
                                        ing_quantity: values.value,
                                      };
                                    }

                                    return ingredientObj;
                                  }
                                ),
                              });
                            }}
                            className={`${
                              isChangeVoucherMarket ? "form-control" : ""
                            } text-end`}
                            allowNegative={false}
                            disabled={!isChangeVoucherMarket}
                            decimalScale={4}
                          />
                        ) : (
                          <CurrencyFormat
                            value={
                              ingredientItem.ing_quantity > 0
                                ? ingredientItem.ing_quantity
                                : 0
                            }
                            displayType="text"
                            thousandSeparator={true}
                            className="text-end"
                            allowNegative={false}
                            decimalScale={4}
                          />
                        )}
                      </td>
                      <td></td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={ingredientItem.ing_price}
                          displayType={
                            !isChangeVoucherMarket ? "text" : "input"
                          }
                          thousandSeparator={true}
                          onValueChange={(values) => {
                            setVoucherMarket({
                              ...voucherMarket,
                              ingredents: voucherMarket.ingredents.map(
                                (ingredientObj) => {
                                  if (
                                    ingredientItem.id_ingredent ===
                                    ingredientObj.id_ingredent
                                  ) {
                                    return {
                                      ...ingredientObj,
                                      ing_price: values.value,
                                    };
                                  }

                                  return ingredientObj;
                                }
                              ),
                            });
                          }}
                          className={`${
                            isChangeVoucherMarket ? "form-control" : ""
                          } text-end`}
                          allowNegative={false}
                          isNumericString
                          disabled={!isChangeVoucherMarket}
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseFloat(ingredientItemPrice)}
                          displayType={"text"}
                          thousandSeparator={true}
                          className="text-end"
                          isNumericString
                          decimalScale={0}
                        />
                      </td>
                      <td>
                        {isChangeVoucherMarket ? (
                          <Form.Control
                            type="text"
                            value={
                              ingredientItem.vou_notes
                                ? ingredientItem.vou_notes
                                : ""
                            }
                            onChange={(e) => {
                              setVoucherMarket({
                                ...voucherMarket,
                                ingredents: voucherMarket.ingredents.map(
                                  (ingredientObj) => {
                                    if (
                                      ingredientItem.id_ingredent ===
                                      ingredientObj.id_ingredent
                                    ) {
                                      return {
                                        ...ingredientObj,
                                        vou_notes: e.target.value,
                                      };
                                    }

                                    return ingredientObj;
                                  }
                                ),
                              });
                            }}
                            disabled={!isChangeVoucherMarket}
                          />
                        ) : (
                          ingredientItem.vou_notes
                        )}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <th></th>
                <th>TỔNG CỘNG</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th className="text-end">
                  <CurrencyFormat
                    value={marketMoney}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                  />
                </th>
                <th></th>
              </tr>
            </>
          ) : (
            <tr>
              <td colSpan={9} className="text-center">
                Không có hàng chợ
              </td>
            </tr>
          )}

          {/* Store */}
          {voucherMarket.ingredents.filter(
            (ingredientItem) => ingredientItem.ingredent_type === 2
          ).length !== 0 ? (
            <>
              {voucherMarket.ingredents
                .filter(
                  (ingredientItem) =>
                    Number(ingredientItem.ingredent_type) === 2
                )
                ?.sort((a, b) =>
                  a?.ingredent_name?.localeCompare(b?.ingredent_name)
                )
                ?.map((ingredientItem, ingredientIndex) => {
                  const ingredientItemPrice = Number(
                    ingredientItem.ing_quantity * ingredientItem.ing_price
                  ).toFixed(2);
                  storeMoney += parseFloat(ingredientItemPrice);

                  return (
                    <tr
                      key={ingredientItem.id_ingredent}
                      className="align-middle"
                    >
                      <td className="text-center">{ingredientIndex + 1}</td>
                      <td>{ingredientItem.ingredent_name} (Kho)</td>
                      <td>{ingredientItem.supplier_name} </td>
                      <td>{ingredientItem.unit_market_name}</td>
                      <td className="text-end">
                        {isChangeVoucherMarket ? (
                          <CurrencyFormat
                            defaultValue={
                              ingredientItem.ing_quantity > 0
                                ? ingredientItem.ing_quantity
                                : 0
                            }
                            displayType={
                              !isChangeVoucherMarket ? "text" : "input"
                            }
                            thousandSeparator={true}
                            onValueChange={(values) => {
                              setVoucherMarket({
                                ...voucherMarket,
                                ingredents: voucherMarket.ingredents.map(
                                  (ingredientObj) => {
                                    if (
                                      ingredientItem.id_ingredent ===
                                      ingredientObj.id_ingredent
                                    ) {
                                      return {
                                        ...ingredientObj,
                                        ing_quantity: values.value,
                                      };
                                    }

                                    return ingredientObj;
                                  }
                                ),
                              });
                            }}
                            className={`${
                              isChangeVoucherMarket ? "form-control" : ""
                            } text-end`}
                            allowNegative={false}
                            disabled={!isChangeVoucherMarket}
                            decimalScale={4}
                          />
                        ) : (
                          <CurrencyFormat
                            value={
                              ingredientItem.ing_quantity > 0
                                ? ingredientItem.ing_quantity
                                : 0
                            }
                            displayType="text"
                            thousandSeparator={true}
                            className="text-end"
                            allowNegative={false}
                            decimalScale={4}
                          />
                        )}
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={ingredientItem.inventory_num}
                          displayType={"text"}
                          thousandSeparator={true}
                          className={`text-end ${
                            ingredientItem.inventory_num < 0
                              ? "text-danger"
                              : ""
                          }`}
                          isNumericString
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={ingredientItem.ing_price}
                          displayType={"text"}
                          thousandSeparator={true}
                          className="text-end"
                          allowNegative={false}
                          isNumericString
                          disabled={true}
                        />
                      </td>
                      {/* thành tiền */}
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseFloat(ingredientItemPrice)}
                          displayType={"text"}
                          thousandSeparator={true}
                          className="text-end"
                          decimalScale={0}
                        />
                      </td>
                      <td>
                        {isChangeVoucherMarket ? (
                          <Form.Control
                            type="text"
                            value={
                              ingredientItem.vou_notes
                                ? ingredientItem.vou_notes
                                : ""
                            }
                            onChange={(e) => {
                              setVoucherMarket({
                                ...voucherMarket,
                                ingredents: voucherMarket.ingredents.map(
                                  (ingredientObj) => {
                                    if (
                                      ingredientItem.id_ingredent ===
                                      ingredientObj.id_ingredent
                                    ) {
                                      return {
                                        ...ingredientObj,
                                        vou_notes: e.target.value,
                                      };
                                    }

                                    return ingredientObj;
                                  }
                                ),
                              });
                            }}
                            disabled={!isChangeVoucherMarket}
                            className="text-start"
                          />
                        ) : (
                          ingredientItem.vou_notes
                        )}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <th></th>
                <th>TỔNG CỘNG</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th className="text-end">
                  <CurrencyFormat
                    value={storeMoney}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                  />
                </th>
                <th></th>
              </tr>
            </>
          ) : (
            <tr>
              <td colSpan={9} className="text-center">
                Không có hàng kho
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Table
        style={{ minWidth: 1024 }}
        className="d-none"
        id="market-list-table"
      >
        <thead>
          <tr>
            <th>{school.school_name?.toUpperCase()}</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
          </tr>
          <tr className="text-center">
            <th>
              Tổng hợp định lượng đi chợ{" "}
              {dateFormatter(new Date(voucherMarket.menu_date * 1000))}
            </th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
          </tr>
          <tr>
            <th>STT</th>
            <th>NGUYÊN LIỆU</th>
            <th>NHÀ CUNG CẤP</th>
            <th>ĐƠN VỊ TÍNH</th>
            <th>SỐ LƯỢNG CẦN MUA</th>
            <th>HÀNG TỒN</th>
            <th>ĐƠN GIÁ</th>
            <th>THÀNH TIỀN</th>
            <th>GHI CHÚ</th>
          </tr>
        </thead>
        <tbody>
          {/* Market */}
          {voucherMarket.ingredents.filter(
            (ingredientItem) => ingredientItem.ingredent_type === 1
          ).length !== 0 ? (
            <>
              {voucherMarket.ingredents
                .filter(
                  (ingredientItem) =>
                    Number(ingredientItem.ingredent_type) === 1
                )
                ?.sort((a, b) =>
                  a?.ingredent_name?.localeCompare(b?.ingredent_name)
                )
                ?.map((ingredientItem, ingredientIndex) => {
                  const ingredientItemPrice = Math.round(
                    Number(
                      ingredientItem.ing_quantity * ingredientItem.ing_price
                    )
                  );

                  return (
                    <tr
                      key={ingredientItem.id_ingredent}
                      className="align-middle"
                    >
                      <td className="text-center">{ingredientIndex + 1}</td>
                      <td>{ingredientItem.ingredent_name}</td>
                      <td>{ingredientItem.supplier_name}</td>
                      <td>{ingredientItem.unit_market_name}</td>

                      <td className="text-end">
                        {ingredientItem.ing_quantity}
                      </td>
                      <td className="text-end">empty</td>
                      <td className="text-end">{ingredientItem.ing_price}</td>
                      <td className="text-end">{ingredientItemPrice}</td>
                      <td>
                        {ingredientItem.vou_notes
                          ? ingredientItem.vou_notes
                          : "empty"}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <th>empty</th>
                <th>TỔNG CỘNG</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th className="text-end">
                  <CurrencyFormat
                    value={marketMoney}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </th>
                <th>empty</th>
              </tr>
            </>
          ) : (
            <tr>
              <td>Không có hàng chợ</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
            </tr>
          )}

          {/* Store */}
          {voucherMarket.ingredents.filter(
            (ingredientItem) => ingredientItem.ingredent_type === 2
          ).length !== 0 ? (
            <>
              {voucherMarket.ingredents
                .filter(
                  (ingredientItem) =>
                    Number(ingredientItem.ingredent_type) === 2
                )
                ?.sort((a, b) =>
                  a?.ingredent_name?.localeCompare(b?.ingredent_name)
                )
                ?.map((ingredientItem, ingredientIndex) => {
                  const ingredientItemPrice = Math.round(
                    Number(
                      ingredientItem.ing_quantity * ingredientItem.ing_price
                    )
                  );

                  return (
                    <tr
                      key={ingredientItem.id_ingredent}
                      className="align-middle"
                    >
                      <td className="text-center">{ingredientIndex + 1}</td>
                      <td>{ingredientItem.ingredent_name} (Kho)</td>
                      <td>{ingredientItem.supplier_name}</td>
                      <td>{ingredientItem.unit_market_name}</td>

                      <td className="text-end">
                        {ingredientItem.ing_quantity}
                      </td>
                      <td className="text-end">
                        {ingredientItem.inventory_num}
                      </td>
                      <td className="text-end">{ingredientItem.ing_price}</td>
                      <td className="text-end">{ingredientItemPrice}</td>
                      <td>
                        {ingredientItem.vou_notes
                          ? ingredientItem.vou_notes
                          : "empty"}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <th>empty</th>
                <th>TỔNG CỘNG</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th className="text-end">
                  <CurrencyFormat
                    value={storeMoney}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </th>
                <th>empty</th>
              </tr>
            </>
          ) : (
            <tr>
              <td>Không có hàng kho</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default MarketListTable;
