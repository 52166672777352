import { Button, Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import Check from "../../other/Check";
import { IS_ACTIVE } from "../../../../constants";

const MenuExcelTemplate0 = ({
  menuDays,
  menuTimes,
  selectedClassGroup,
  handleUnSavedMealDaily,
  handleShowModal,
  weekInMonth,
  startDate,
  termDate,
  group,
  showIngredient,
}) => {
  const stringFirstDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2);
  const stringLastDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear();

  return (
    <div style={{ overflowX: "auto" }}>
      {menuDays.length > 0 ? (
        <Table bordered style={{ minWidth: 999 }}>
          <thead>
            <tr className="text-bg-light text-center align-middle">
              <th>Thời gian</th>
              {menuDays.map((day) => {
                return (
                  <th key={day.id}>
                    {day.day_name}

                    {day.is_meal_saved === IS_ACTIVE.TRUE ? (
                      <Button
                        size="sm"
                        onClick={() => handleUnSavedMealDaily(day.id)}
                        className="ms-2"
                        variant="outline-danger"
                        title="Hủy tính khẩu phần ăn"
                      >
                        <i className="fa-solid fa-times" />
                      </Button>
                    ) : (
                      ""
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {menuTimes.length > 0 && menuDays.length > 0 ? (
              menuTimes.map((timeItem) => {
                return (
                  <tr key={timeItem.time_name}>
                    <th> {timeItem.time_name}</th>
                    {menuDays.length > 0
                      ? menuDays.map((day) => {
                          let timesWithDay = day.times.filter(
                            (time) => time.id_time === timeItem.id_time
                          );

                          return (
                            <td
                              key={day.id}
                              className={`menu-table-cell ${
                                !timesWithDay[0]?.isValidTime ? "bg-light" : ""
                              }`}
                              onClick={() => {
                                if (
                                  timesWithDay[0]?.isValidTime &&
                                  new Check().permission(["53", "54"], "or")
                                ) {
                                  if (day.is_meal_saved === 0)
                                    handleShowModal({
                                      id_day: day.id_day,
                                      day_name: day.day_name,
                                      id_time: timeItem.id_time,
                                      time_name: timeItem.time_name,
                                      foods: timesWithDay[0]?.foods,
                                    });
                                  else
                                    toast("Đã lưu Tính khẩu phần ăn.", {
                                      type: "error",
                                      autoClose: 1000,
                                    });
                                }
                              }}
                              title={`${
                                !timesWithDay[0]?.isValidTime
                                  ? "Không có cài đặt"
                                  : "Chọn thêm món"
                              }`}
                            >
                              {timesWithDay[0]?.foods.length > 0
                                ? timesWithDay[0]?.foods.map((food) => {
                                    const countGroup = food.groups.length;
                                    const countEmpty =
                                      countGroup -
                                      food.groups.filter(String).length;
                                    const groupUse = food.groups.filter(
                                      (item) => item.id > 0
                                    );

                                    return (
                                      <div key={food.id}>
                                        {Number(selectedClassGroup.id) === 0 ? (
                                          <>
                                            <strong>{food.food_name}</strong>
                                            {countGroup - countEmpty === 1
                                              ? group?.length > 2
                                                ? ` (${groupUse[0].group_name}${
                                                    Number(
                                                      groupUse[0].more_meal
                                                    ) > 0
                                                      ? "; SL: " +
                                                        groupUse[0].more_meal
                                                      : ""
                                                  })`
                                                : Number(
                                                    groupUse[0].more_meal
                                                  ) > 0
                                                ? ` (${
                                                    Number(
                                                      groupUse[0].more_meal
                                                    ) > 0
                                                      ? " SL: " +
                                                        groupUse[0].more_meal
                                                      : ""
                                                  })`
                                                : ""
                                              : ""}
                                          </>
                                        ) : food.groups.some(
                                            (groupItem) =>
                                              Number(groupItem.id) ===
                                              Number(selectedClassGroup.id)
                                          ) ? (
                                          <>
                                            <strong>{food.food_name}</strong> (
                                            {food.category_name})
                                            {countGroup - countEmpty === 1 &&
                                            Number(groupUse[0].more_meal) > 0
                                              ? `(SL: ${groupUse[0].more_meal})`
                                              : ""}
                                          </>
                                        ) : (
                                          <></>
                                        )}

                                        {Number(selectedClassGroup.id) !== 0 &&
                                        showIngredient
                                          ? groupUse
                                              .find(
                                                (groupItem) =>
                                                  groupItem.id ===
                                                  Number(selectedClassGroup.id)
                                              )
                                              ?.ingredents?.map(
                                                (ingredienItem) => {
                                                  return (
                                                    <div key={ingredienItem.id}>
                                                      {
                                                        ingredienItem.ingredent_name
                                                      }
                                                      &nbsp;
                                                      <CurrencyFormat
                                                        value={parseFloat(
                                                          ingredienItem.ing_quantity
                                                        )}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                      />
                                                      &nbsp;
                                                      {
                                                        ingredienItem.unit_cook_name
                                                      }
                                                    </div>
                                                  );
                                                }
                                              )
                                          : ""}
                                      </div>
                                    );
                                  })
                                : ""}
                            </td>
                          );
                        })
                      : ""}
                  </tr>
                );
              })
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      ) : (
        <div className="text-center">Không có dữ liệu...</div>
      )}

      <Table
        bordered
        style={{ minWidth: 999 }}
        id="menu-table"
        className="d-none"
      >
        <thead>
          <tr>
            <th colSpan={Math.floor((menuDays.length + 1) / 2)}>Học sinh</th>
            <th colSpan={Math.ceil((menuDays.length + 1) / 2)}>
              Thực đơn Tháng {startDate.getMonth() + 1} (Tuần {weekInMonth}){" "}
              {stringFirstDate(startDate)} - {stringLastDate(termDate)}
            </th>
          </tr>

          <tr className="text-center">
            <th colSpan={2}>Thời gian</th>
            {menuDays.map((day) => {
              return <th key={day.id}>{day.day_name}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {menuTimes.length > 0 && menuDays.length > 0 ? (
            menuTimes.map((timeItem) => {
              return (
                <tr key={timeItem.time_name}>
                  <th className="text-center">{timeItem.time_name}</th>
                  <th className="text-center">{timeItem.time_name}</th>
                  {menuDays.length > 0
                    ? menuDays.map((day) => {
                        let timesWithDay = day.times.filter(
                          (time) => time.id_time === timeItem.id_time
                        );

                        return (
                          <td key={day.id} className="menu-table-cell">
                            {timesWithDay[0]?.foods.length > 0
                              ? timesWithDay[0]?.foods.map(
                                  (food, foodIndex) => {
                                    const countGroup = food.groups.length;
                                    const countEmpty =
                                      countGroup -
                                      food.groups.filter(String).length;
                                    const groupUse = food.groups.filter(
                                      (item) => item.id > 0
                                    );
                                    return (
                                      <div key={food.id}>
                                        {Number(selectedClassGroup.id) === 0 ? (
                                          <>
                                            <strong>{food.food_name}</strong>

                                            {countGroup - countEmpty === 1
                                              ? group?.length > 2
                                                ? ` (${groupUse[0].group_name}${
                                                    Number(
                                                      groupUse[0].more_meal
                                                    ) > 0
                                                      ? `; SL:  ${groupUse[0].more_meal}`
                                                      : ""
                                                  })`
                                                : Number(
                                                    groupUse[0].more_meal
                                                  ) > 0
                                                ? ` (SL: ${groupUse[0].more_meal})`
                                                : ""
                                              : ""}
                                          </>
                                        ) : (
                                          <strong>
                                            {food.groups.some(
                                              (groupItem) =>
                                                Number(groupItem.id) ===
                                                Number(selectedClassGroup.id)
                                            )
                                              ? food.food_name
                                              : ""}
                                          </strong>
                                        )}

                                        {Number(selectedClassGroup.id) !== 0 ? (
                                          <br />
                                        ) : (
                                          <></>
                                        )}

                                        {Number(selectedClassGroup.id) !== 0
                                          ? groupUse
                                              .find(
                                                (groupItem) =>
                                                  groupItem.id ===
                                                  Number(selectedClassGroup.id)
                                              )
                                              ?.ingredents?.map(
                                                (
                                                  ingredienItem,
                                                  ingredientItemIndex
                                                ) => {
                                                  return (
                                                    <div key={ingredienItem.id}>
                                                      {
                                                        ingredienItem.ingredent_name
                                                      }{" "}
                                                      <CurrencyFormat
                                                        value={parseFloat(
                                                          ingredienItem.ing_quantity
                                                        )}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                      />
                                                      {
                                                        ingredienItem.unit_cook_name
                                                      }
                                                      {ingredientItemIndex ===
                                                      groupUse.find(
                                                        (groupItem) =>
                                                          groupItem.id ===
                                                          Number(
                                                            selectedClassGroup.id
                                                          )
                                                      )?.ingredents.length -
                                                        1 ? (
                                                        ""
                                                      ) : (
                                                        <br />
                                                      )}
                                                    </div>
                                                  );
                                                }
                                              )
                                          : ""}

                                        {timesWithDay[0]?.foods.length - 1 ===
                                        foodIndex ? (
                                          ""
                                        ) : (
                                          <>
                                            <br />
                                            <br />
                                          </>
                                        )}
                                      </div>
                                    );
                                  }
                                )
                              : "empty"}
                          </td>
                        );
                      })
                    : ""}
                </tr>
              );
            })
          ) : (
            <></>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default MenuExcelTemplate0;
