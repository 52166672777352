import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import cookies from "../../../assets/admin/assets/img/cookie-consent.svg";

const Consent = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem("cookie-consent");
    if (!hasAcceptedCookies) {
      setShow(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookie-consent", "true");
    setShow(false);
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {}}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body>
        <Modal.Title>
          <img src={cookies} alt="cookies" /> Cookies
        </Modal.Title>
        <div className="py-3">
          Bằng cách nhấp vào “Chấp nhận”, bạn đồng ý KATEC có thể lưu trữ cookie
          trên thiết bị của bạn và tiết lộ thông tin theo Chính sách cookie của
          chúng tôi.
        </div>
        <div className="d-flex justify-content-end">
          <Button variant="primary" onClick={() => handleAccept()}>
            Chấp nhận
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Consent;
