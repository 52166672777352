import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx-js-style";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { vi } from "date-fns/locale";
import { startOfWeek, endOfWeek } from "date-fns";
import {
  Button,
  Container,
  Card,
  Row,
  Col,
  Form,
  Table,
  Spinner,
} from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import swal from "sweetalert";
import Select from "react-select";

import {
  groupArrayByField,
  dateFormatter,
  priceFormatter,
} from "../../../utils/helpers";
import { exportNutritionDay } from "./exportFunctions";
import api from "../../../../../helper/axiosInstance";

const NutritionDayReport = () => {
  const [listQuantitatives, setListQuantitatives] = useState([]);
  const [quantitative, setQuantitative] = useState({
    menu_date: new Date().getTime() / 1000,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [classGroups, setClassGroups] = useState([]);
  const [selectedClassGroup, setSelectedClassGroups] = useState({});

  const [listDays, setListDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState({});

  useEffect(() => {
    document.title = "Tính khẩu phần ăn";

    let initialWeek = datesOfWeek(new Date());

    let todayIs = new Date().getDay();
    if (todayIs === 0) {
      todayIs = 7;
    }

    getListQuantitatives(initialWeek[0], initialWeek[6], todayIs);
    getListClassGroups();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const CustomInput = ({ value, onClick }) => (
    <Button variant="primary" onClick={onClick}>
      <i className="fa-solid fa-calendar" />
    </Button>
  );
  // API
  const getListQuantitatives = async (startDate, endDate, dayId) => {
    setIsLoading(true);
    setListQuantitatives([]);
    setQuantitative({
      menu_date: startDate.getTime() / 1000,
    });

    await api
      .get(`menu-show`, {
        params: {
          start_at: startDate.getTime() / 1000,
          term_at: endDate.getTime() / 1000,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          const newListQuantitatives = res.data.days.map((quantitativeItem) => {
            return {
              ...quantitativeItem,
              times: quantitativeItem.times?.map((timeItem) => {
                return {
                  ...timeItem,
                  foods: timeItem.foods?.map((foodItem) => {
                    return {
                      ...foodItem,
                      groups: foodItem.groups?.map((groupItem) => {
                        return {
                          ...groupItem,
                          ingredents:
                            groupItem.more_meal === null
                              ? groupItem.ingredents
                              : [], //Là TH Nấu riêng nên không TKPA => []
                        };
                      }),
                    };
                  }),
                };
              }),
            };
          });

          setListDays(
            res.data.days.map((dayItem) => {
              return {
                id_day: dayItem.id_day,
                day_name: dayItem.day_name,
                date: new Date(dayItem.menu_date * 1000),
              };
            })
          );

          setListQuantitatives(newListQuantitatives);
          setQuantitative(
            newListQuantitatives.find(
              (listQuantitativesItem) => listQuantitativesItem.id_day === dayId
            )
              ? newListQuantitatives.find(
                  (listQuantitativesItem) =>
                    listQuantitativesItem.id_day === dayId
                )
              : newListQuantitatives[0]
          );
          setSelectedDay(
            newListQuantitatives.find(
              (listQuantitativesItem) => listQuantitativesItem.id_day === dayId
            )
              ? newListQuantitatives.find(
                  (listQuantitativesItem) =>
                    listQuantitativesItem.id_day === dayId
                )?.id_day
              : newListQuantitatives[0].id_day
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const getListClassGroups = async () => {
    await api
      .get(`/group-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setClassGroups(res.data.groups);
          setSelectedClassGroups(res.data.groups[0]);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };
  // API

  // Logic
  const exportExcel = () => {
    const wb = XLSX.utils.book_new();

    let ingredientArray = [];

    quantitative.times?.map((timeItem) => {
      timeItem.foods?.map((foodItem) => {
        foodItem.groups
          ?.find((groupItem) => groupItem.id === selectedClassGroup.id)
          ?.ingredents?.map((ingredientItem) => {
            ingredientArray.push(ingredientItem);
            return ingredientItem;
          });
        return foodItem;
      });
      return timeItem;
    });

    const groupIngredientArray = groupArrayByField(ingredientArray, "id");

    const ws = exportNutritionDay(groupIngredientArray.length);

    XLSX.utils.book_append_sheet(wb, ws, "Dưỡng chất 1 trẻ");
    XLSX.writeFile(
      wb,
      `Dưỡng chất 1 trẻ (${dateFormatter(
        new Date(quantitative.menu_date * 1000)
      )}).xlsx`
    );
  };

  const handleXportNutrionDayReport = () => {
    swal({
      title: `Bạn muốn xuất file dưỡng chất 1 trẻ`,
      text: `Ngày ${dateFormatter(new Date(quantitative.menu_date * 1000))}`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        exportExcel();
      }
    });
  };

  const datesOfWeek = (current) => {
    var week = [];
    // Starting Monday not Sunday
    current.setDate(current.getDate() - current.getDay() + 1);
    current.setHours(0, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
      current.setHours(0, 0, 0, 0);
    }
    return week;
  };
  // Logic

  // Render
  const RenderOption = () => {
    return (
      <section className="mb-2">
        <div className="d-block d-sm-flex justify-content-between align-items-center my-0">
          <Row>
            {listDays.map((dayItem) => {
              return (
                <Col key={dayItem.id_day} md="auto" xs={6} className="d-flex">
                  <Form.Check
                    type="radio"
                    value={dayItem.id_day}
                    checked={selectedDay === dayItem.id_day}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedDay(Number(e.target.value));
                        setQuantitative(
                          listQuantitatives.find(
                            (quantitativeItem) =>
                              quantitativeItem.id_day === Number(e.target.value)
                          )
                        );
                      }
                    }}
                    name="day"
                    className="me-2"
                  />
                  {dayItem.day_name}
                </Col>
              );
            })}
          </Row>

          <Select
            options={classGroups}
            placeholder={<div>Tất cả</div>}
            getOptionLabel={(option) => option.group_name}
            getOptionValue={(option) => option.id}
            value={selectedClassGroup}
            onChange={(choice) => {
              setSelectedClassGroups(choice);
            }}
            className="mt-3 mt-sm-0"
          />
        </div>
      </section>
    );
  };

  const NutritionDayReport = () => {
    // Get init data
    const currentCostPerDays = quantitative.costPerDays?.find(
      (costPerDay) => costPerDay.id_group === selectedClassGroup.id
    );

    const feeOther = Number(currentCostPerDays?.fee_other || 0);
    const recommendFrom = Number(currentCostPerDays?.recommended_from);
    const enegryRatio = Number(currentCostPerDays?.enegry_ratio);

    // Nutrition variables
    let energyStat = 0;
    let proteinStat = 0;
    let lipidStat = 0;
    let glucidStat = 0;

    let ingredientArray = [];

    quantitative.times?.map((timeItem) => {
      timeItem.foods?.map((foodItem) => {
        foodItem.groups
          ?.find((groupItem) => groupItem.id === selectedClassGroup.id)
          ?.ingredents?.map((ingredientItem) => {
            ingredientArray.push(ingredientItem);

            const ingredientQuantity = ingredientItem.ing_quantity;

            const gramOneChild = Number(
              ((ingredientQuantity * ingredientItem.volume_market) /
                ingredientItem.volume_cook) *
                ((100 - ingredientItem.ingredent_ratio) / 100)
            );

            energyStat +=
              (gramOneChild * Number(ingredientItem.ingredent_energy)) / 100;
            proteinStat +=
              (gramOneChild * Number(ingredientItem.ingredent_protein)) / 100;
            lipidStat +=
              (gramOneChild * Number(ingredientItem.ingredent_lipid)) / 100;
            glucidStat +=
              (gramOneChild * Number(ingredientItem.ingredent_glucid)) / 100;

            return ingredientItem;
          });
        return foodItem;
      });
      return timeItem;
    });

    const groupIngredientArray = groupArrayByField(ingredientArray, "id").sort(
      (a, b) => {
        let amountA = a.reduce((accumulator, currentValue) => {
          return Number(
            accumulator +
              (currentValue.ing_quantity * currentValue.volume_market) /
                currentValue.volume_cook
          );
        }, 0);

        let amountB = b.reduce((accumulator, currentValue) => {
          return Number(
            accumulator +
              (currentValue.ing_quantity * currentValue.volume_market) /
                currentValue.volume_cook
          );
        }, 0);

        return a[0].ingredent_type - b[0].ingredent_type || amountB - amountA;
      }
    );

    const plgTotal = proteinStat * 4 + lipidStat * 9 + glucidStat * 4;
    const energyPersen =
      (energyStat * 100 * 100) / (recommendFrom * enegryRatio);

    let totalMoney = 0;

    return (
      <>
        <Table bordered responsive>
          <thead>
            <tr className="text-center align-middle">
              <th rowSpan={2}>STT</th>
              <th rowSpan={2}>Tên thực phẩm</th>
              <th rowSpan={2}>Năng lượng (kcal)</th>
              <th colSpan={2}>Đạm (g)</th>
              <th colSpan={2}>Béo (g)</th>
              <th rowSpan={2}>Bột Đường (g)</th>
              <th rowSpan={2}>Ăn được (g)</th>
              <th rowSpan={2}>Đi chợ (g)</th>
              <th rowSpan={2}>Đơn giá (đ/100g)</th>
              <th rowSpan={2}>Thành tiền (đ)</th>
            </tr>
            <tr className="text-center align-middle">
              <th>ĐV</th>
              <th>TV</th>
              <th>ĐV</th>
              <th>TV</th>
            </tr>
          </thead>
          <tbody>
            {groupIngredientArray.map(
              (groupIngredientItem, ingredientIndex) => {
                // Gram
                let gramEatable = 0;
                let gramMarket = 0;
                let gram100Price = 0;
                let ingredientPrice = 0;

                // Nutrition
                let energy = 0;
                let animalProtein = 0;
                let plantProtein = 0;
                let animalLipid = 0;
                let plantLipid = 0;
                let glucid = 0;

                groupIngredientItem.map((ingredientItem) => {
                  gramEatable += Number(
                    ((ingredientItem.ing_quantity *
                      ingredientItem.volume_market) /
                      ingredientItem.volume_cook) *
                      ((100 - ingredientItem.ingredent_ratio) / 100)
                  );
                  gramMarket += Number(
                    (ingredientItem.ing_quantity *
                      ingredientItem.volume_market) /
                      ingredientItem.volume_cook
                  );
                  ingredientPrice += Number(
                    (ingredientItem.ing_quantity *
                      ingredientItem.ing_price_expect) /
                      ingredientItem.volume_cook
                  );

                  return ingredientItem;
                });

                gram100Price =
                  Math.round(Number((ingredientPrice * 100) / gramMarket)) || 0;

                totalMoney += ingredientPrice;

                ingredientPrice = Math.round(ingredientPrice);
                gramEatable = Number(gramEatable);
                gramMarket = Number(gramMarket);

                if (groupIngredientItem[0].id_subspecies === 1) {
                  animalProtein +=
                    (gramEatable *
                      Number(groupIngredientItem[0].ingredent_protein)) /
                    100;
                  animalLipid +=
                    (gramEatable *
                      Number(groupIngredientItem[0].ingredent_lipid)) /
                    100;
                } else {
                  plantProtein =
                    (gramEatable *
                      Number(groupIngredientItem[0].ingredent_protein)) /
                    100;
                  plantLipid =
                    (gramEatable *
                      Number(groupIngredientItem[0].ingredent_lipid)) /
                    100;
                }

                energy +=
                  (gramEatable *
                    Number(groupIngredientItem[0].ingredent_energy)) /
                  100;
                glucid +=
                  (gramEatable *
                    Number(groupIngredientItem[0].ingredent_glucid)) /
                  100;

                return (
                  <tr
                    key={groupIngredientItem[0].id}
                    className="text-end align-middle"
                  >
                    <td className="text-center">{ingredientIndex + 1}</td>
                    <td className="text-start">
                      {groupIngredientItem[0].ingredent_name}
                    </td>
                    <td>{priceFormatter.format(energy)}</td>
                    <td>
                      {animalProtein !== 0
                        ? priceFormatter.format(animalProtein)
                        : ""}
                    </td>
                    <td>
                      {plantProtein !== 0
                        ? priceFormatter.format(plantProtein)
                        : ""}
                    </td>
                    <td>
                      {animalLipid !== 0
                        ? priceFormatter.format(animalLipid)
                        : ""}
                    </td>
                    <td>
                      {plantLipid !== 0
                        ? priceFormatter.format(plantLipid)
                        : ""}
                    </td>
                    <td>{priceFormatter.format(glucid)}</td>
                    <td>
                      <CurrencyFormat
                        value={priceFormatter.format(gramEatable)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={priceFormatter.format(gramMarket)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={gram100Price}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={ingredientPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  </tr>
                );
              }
            )}

            <tr className="text-center align-middle">
              <th colSpan={2}>Tổng cộng</th>
              <th>
                {energyStat !== 0 ? (
                  <div>
                    {priceFormatter.format(energyStat)}{" "}
                    <div
                      style={
                        energyPersen >= 100
                          ? { color: "green" }
                          : { color: "red" }
                      }
                      className="d-inline"
                    >
                      (Đạt: {priceFormatter.format(energyPersen)}%)
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </th>
              <th colSpan={2}>
                {proteinStat !== 0 ? priceFormatter.format(proteinStat) : ""}
              </th>
              <th colSpan={2}>
                {lipidStat !== 0 ? priceFormatter.format(lipidStat) : ""}
              </th>
              <th>
                {glucidStat !== 0 ? priceFormatter.format(glucidStat) : ""}
              </th>
              <th colSpan={4} className="text-end">
                <CurrencyFormat
                  value={Math.round(totalMoney)}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </th>
            </tr>

            <tr className="text-center align-middle">
              <th colSpan={2}>P : L : G</th>
              <th></th>
              <th colSpan={2}>
                {plgTotal !== 0
                  ? priceFormatter.format((proteinStat * 100 * 4) / plgTotal)
                  : ""}
              </th>
              <th colSpan={2}>
                {plgTotal !== 0
                  ? priceFormatter.format((lipidStat * 100 * 9) / plgTotal)
                  : ""}
              </th>
              <th>
                {plgTotal !== 0
                  ? priceFormatter.format(
                      100 -
                        (Number(
                          priceFormatter.format(
                            (proteinStat * 100 * 4) / plgTotal
                          )
                        ) +
                          Number(
                            priceFormatter.format(
                              (lipidStat * 100 * 9) / plgTotal
                            )
                          ))
                    )
                  : ""}
              </th>
              <th colSpan={4}></th>
            </tr>

            <tr className="text-center align-middle">
              <th colSpan={2}>Chi phí khác cho 1 trẻ</th>
              <th colSpan={10} className="text-end">
                <CurrencyFormat
                  value={feeOther}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </th>
            </tr>
          </tbody>
        </Table>

        <Table bordered responsive id="nutrition-day-report" className="d-none">
          <thead>
            <tr>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>

              <td className="text-end align-top">
                {dateFormatter(new Date(quantitative.menu_date * 1000))}
              </td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
            </tr>
            <tr className="text-center align-middle">
              <th>DƯỠNG CHẤT DÀNH CHO MỘT TRẺ</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <td>
                Độ tuổi: {selectedClassGroup.group_name}
                <br />
                Số Tiền Trên Mỗi Trẻ: &nbsp;{" "}
                <CurrencyFormat
                  value={Math.round(totalMoney + feeOther)}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>

              <td></td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
            </tr>
            <tr className="text-center align-middle">
              <th>STT</th>
              <th>Tên thực phẩm</th>
              <th>Năng lượng (Kcal)</th>
              <th>Đạm(g)</th>
              <th>empty</th>
              <th>Béo(g)</th>
              <th>empty</th>
              <th>Bột Đường(g)</th>
              <th>Ăn được (g)</th>
              <th>Đi chợ (g)</th>
              <th>Đơn giá (đ/100g)</th>
              <th>Thành tiền (đ)</th>
            </tr>
            <tr className="text-center align-middle">
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>

              <th>ĐV</th>
              <th>TV</th>
              <th>ĐV</th>
              <th>TV</th>

              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
          </thead>
          <tbody>
            {groupIngredientArray.map(
              (groupIngredientItem, ingredientIndex) => {
                // Gram
                let gramEatable = 0;
                let gramMarket = 0;
                let gram100Price = 0;
                let ingredientPrice = 0;

                // Nutrition
                let energy = 0;
                let animalProtein = 0;
                let plantProtein = 0;
                let animalLipid = 0;
                let plantLipid = 0;
                let glucid = 0;

                groupIngredientItem.map((ingredientItem) => {
                  gramEatable += Number(
                    ((ingredientItem.ing_quantity *
                      ingredientItem.volume_market) /
                      ingredientItem.volume_cook) *
                      ((100 - ingredientItem.ingredent_ratio) / 100)
                  );
                  gramMarket += Number(
                    (ingredientItem.ing_quantity *
                      ingredientItem.volume_market) /
                      ingredientItem.volume_cook
                  );
                  ingredientPrice += Number(
                    (ingredientItem.ing_quantity *
                      ingredientItem.ing_price_expect) /
                      ingredientItem.volume_cook
                  );

                  return ingredientItem;
                });

                gram100Price =
                  Math.round(Number((ingredientPrice * 100) / gramMarket)) || 0;

                ingredientPrice = Math.round(ingredientPrice);
                gramEatable = Number(gramEatable);
                gramMarket = Number(gramMarket);

                if (groupIngredientItem[0].id_subspecies === 1) {
                  animalProtein +=
                    (gramEatable *
                      Number(groupIngredientItem[0].ingredent_protein)) /
                    100;
                  animalLipid +=
                    (gramEatable *
                      Number(groupIngredientItem[0].ingredent_lipid)) /
                    100;
                } else {
                  plantProtein =
                    (gramEatable *
                      Number(groupIngredientItem[0].ingredent_protein)) /
                    100;
                  plantLipid =
                    (gramEatable *
                      Number(groupIngredientItem[0].ingredent_lipid)) /
                    100;
                }

                energy +=
                  (gramEatable *
                    Number(groupIngredientItem[0].ingredent_energy)) /
                  100;
                glucid +=
                  (gramEatable *
                    Number(groupIngredientItem[0].ingredent_glucid)) /
                  100;

                return (
                  <tr
                    key={groupIngredientItem[0].id}
                    className="text-end align-middle"
                  >
                    <td className="text-center">{ingredientIndex + 1}</td>
                    <td className="text-start">
                      {groupIngredientItem[0].ingredent_name}
                    </td>
                    <td>{priceFormatter.format(energy)}</td>
                    <td>
                      {animalProtein !== 0
                        ? priceFormatter.format(animalProtein)
                        : "empty"}
                    </td>
                    <td>
                      {plantProtein !== 0
                        ? priceFormatter.format(plantProtein)
                        : "empty"}
                    </td>
                    <td>
                      {animalLipid !== 0
                        ? priceFormatter.format(animalLipid)
                        : "empty"}
                    </td>
                    <td>
                      {plantLipid !== 0
                        ? priceFormatter.format(plantLipid)
                        : "empty"}
                    </td>
                    <td>{priceFormatter.format(glucid)}</td>
                    <td>
                      <CurrencyFormat
                        value={priceFormatter.format(gramEatable)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={priceFormatter.format(gramMarket)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={gram100Price}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={ingredientPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  </tr>
                );
              }
            )}

            <tr className="text-center align-middle">
              <th>Tổng cộng</th>
              <th>empty</th>

              <th>
                {energyStat !== 0 ? priceFormatter.format(energyStat) : "empty"}
              </th>
              <th>
                {proteinStat !== 0
                  ? priceFormatter.format(proteinStat)
                  : "empty"}
              </th>
              <th>empty</th>

              <th>
                {lipidStat !== 0 ? priceFormatter.format(lipidStat) : "empty"}
              </th>
              <th>empty</th>

              <th>
                {glucidStat !== 0 ? priceFormatter.format(glucidStat) : "empty"}
              </th>
              <th className="text-end">
                <CurrencyFormat
                  value={Math.round(totalMoney)}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>

            <tr className="text-center align-middle">
              <th>P : L : G</th>
              <th>empty</th>

              <th>empty</th>
              <th>
                {energyPersen !== 0
                  ? priceFormatter.format((proteinStat * 100 * 4) / plgTotal)
                  : ""}
              </th>
              <th>empty</th>
              <th>
                {energyPersen !== 0
                  ? priceFormatter.format((lipidStat * 100 * 9) / plgTotal)
                  : ""}
              </th>
              <th>empty</th>
              <th>
                {energyPersen !== 0
                  ? priceFormatter.format((glucidStat * 100 * 4) / plgTotal)
                  : ""}
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>

            <tr className="text-center align-middle">
              <th>Chi phí khác cho 1 trẻ</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={feeOther}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <Container fluid>
      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block d-md-flex align-items-center">
              <Card.Title className="me-2">
                Dưỡng chất 1 trẻ{" "}
                {dateFormatter(new Date(quantitative?.menu_date * 1000))}
              </Card.Title>

              {/* Select Week */}
              <div
                className="me-2 d-flex align-items-center"
                style={{ zIndex: "1021" }}
              >
                <DatePicker
                  disabled={isLoading}
                  selected={
                    listDays?.length > 0
                      ? new Date(listDays[0]?.date)
                      : new Date()
                  }
                  startDate={
                    listDays?.length > 0
                      ? new Date(listDays[0]?.date)
                      : new Date()
                  }
                  endDate={
                    listDays?.length > 0
                      ? new Date(listDays[listDays.length - 1]?.date)
                      : new Date()
                  }
                  onChange={(date) => {
                    const startDateGMT = startOfWeek(date, { weekStartsOn: 1 });
                    const endDateGMT = startOfWeek(
                      endOfWeek(date, { weekStartsOn: 1 })
                    );
                    getListQuantitatives(
                      new Date(startDateGMT),
                      new Date(endDateGMT),
                      selectedDay
                    );
                  }}
                  showWeekNumbers
                  dateFormat="yyyy-MM-dd"
                  calendarClassName="custom-calendar"
                  customInput={<CustomInput />}
                  locale={vi}
                />
              </div>
            </div>

            <Button
              size="sm"
              variant="success"
              onClick={() => handleXportNutrionDayReport()}
              disabled={
                isLoading || Object.keys(quantitative || [])?.length <= 1
              }
            >
              Xuất file <i className="fa-solid fa-file-export" />
            </Button>
          </div>
        </Card.Header>

        <Card.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Đang tải...</span>
              </Spinner>
            </div>
          ) : Object.keys(quantitative || [])?.length > 1 ? (
            <Row>
              {RenderOption()}

              {NutritionDayReport()}
            </Row>
          ) : (
            ""
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default NutritionDayReport;
