import * as XLSX from "xlsx-js-style";

const fontStyle = {
  name: "Times New Roman",
};

const borderStyle = {
  top: { style: "thin" },
  bottom: { style: "thin" },
  left: { style: "thin" },
  right: { style: "thin" },
};

const exportMartketList = (ingredientList) => {
  const table = document.getElementById("market-list-table");

  var ws = XLSX.utils.table_to_sheet(table);

  const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
  const colAlpha_Sum = ["A", "C", "D", "E", "F", "G", "I"];

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 8 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 8 } },
  ];

  // Header
  colAlpha.map((alpha) => {
    ws[`${alpha}1`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
      },
    };

    return alpha;
  });

  colAlpha.map((alpha) => {
    ws[`${alpha}2`].s = {
      font: {
        ...fontStyle,
        sz: 14,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    return alpha;
  });

  colAlpha.map((alpha) => {
    ws[`${alpha}3`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
    };

    return alpha;
  });
  // Header

  // Set Column Width
  var wscols = [
    { wch: 4 },
    { wch: 20 },
    { wch: 20 },
    { wch: 7 },
    { wch: 11 },
    { wch: 11 },
    { wch: 11 },
    { wch: 11 },
    { wch: 11 },
  ];

  ws["!cols"] = wscols;
  ws["!rows"] = [{ hpt: 20 }, { hpt: 35 }, { hpt: 40 }];

  // Format number Price, Unit, Will Money
  const numberFormat = "#,###,###,###";
  let index = 4;

  ingredientList
    .filter((item) => Number(item.ingredent_type) === 1)
    .map((item) => {
      let priceCol = `G${index}`;
      let unitCol = `E${index}`;
      let willMoneyCol = `H${index}`;

      colAlpha.map((alpha) => {
        if (ws[`${alpha}${index}`].v === "empty") {
          ws[`${alpha}${index}`].v = " ";
        }

        ws[`${alpha}${index}`].s = {
          font: fontStyle,
          border: borderStyle,
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: alpha === "A" ? "center" : "",
          },
        };

        return alpha;
      });

      ws[priceCol.toString()].z = numberFormat;
      delete ws[priceCol.toString()].w;
      XLSX.utils.format_cell(ws[priceCol.toString()]);

      ws[unitCol.toString()].z = "#,###,###,##0.0000";
      delete ws[unitCol.toString()].w;
      XLSX.utils.format_cell(ws[unitCol.toString()]);

      ws[willMoneyCol.toString()].z = numberFormat;
      delete ws[willMoneyCol.toString()].w;
      XLSX.utils.format_cell(ws[willMoneyCol.toString()]);

      index += 1;

      return item;
    });

  if (
    ingredientList.filter((item) => Number(item.ingredent_type) === 1)
      .length !== 0
  ) {
    ws[`H${index}`].z = numberFormat;
    delete ws[`H${index}`].w;
    XLSX.utils.format_cell(ws[`H${index}`]);

    colAlpha_Sum.map((alpha) => {
      ws[`${alpha}${index}`].v = " ";

      ws[`${alpha}${index}`].s = {
        font: fontStyle,
        border: borderStyle,
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });

    ws[`B${index}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[`H${index}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    index++;
  } else {
    colAlpha.map((alpha) => {
      ws[`${alpha}${index}`].s = {
        font: fontStyle,
        border: borderStyle,
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });
    merge.push({ s: { r: index - 1, c: 0 }, e: { r: index - 1, c: 8 } });

    index++;
  }

  ingredientList
    .filter((item) => Number(item.ingredent_type) === 2)
    .map((item) => {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${index}`].v === "empty") {
          ws[`${alpha}${index}`].v = " ";
        }

        ws[`${alpha}${index}`].s = {
          font: fontStyle,
          border: borderStyle,
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: alpha === "A" ? "center" : "",
          },
        };

        return alpha;
      });

      let priceCol = `G${index}`;
      let amountCol = `E${index}`;
      let inventoryCol = `F${index}`;
      let willMoneyCol = `H${index}`;

      ws[priceCol.toString()].z = numberFormat;
      delete ws[priceCol.toString()].w;
      XLSX.utils.format_cell(ws[priceCol.toString()]);

      ws[amountCol.toString()].z = "#,###,###,##0.0000";
      delete ws[amountCol.toString()].w;
      XLSX.utils.format_cell(ws[amountCol.toString()]);

      ws[inventoryCol.toString()].z = "#,###,###,##0.00";
      delete ws[inventoryCol.toString()].w;
      XLSX.utils.format_cell(ws[inventoryCol.toString()]);

      ws[willMoneyCol.toString()].z = numberFormat;
      delete ws[willMoneyCol.toString()].w;
      XLSX.utils.format_cell(ws[willMoneyCol.toString()]);

      index += 1;

      return item;
    });

  if (
    ingredientList.filter((item) => Number(item.ingredent_type) === 2)
      .length !== 0
  ) {
    ws[`H${index}`].z = numberFormat;
    delete ws[`H${index}`].w;
    XLSX.utils.format_cell(ws[`H${index}`]);

    colAlpha_Sum.map((alpha) => {
      ws[`${alpha}${index}`].v = " ";

      ws[`${alpha}${index}`].s = {
        font: fontStyle,
        border: borderStyle,
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });

    ws[`B${index}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[`H${index}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      border: borderStyle,
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };
  } else {
    colAlpha.map((alpha) => {
      ws[`${alpha}${index}`].s = {
        font: fontStyle,
        border: borderStyle,
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });

    merge.push({ s: { r: index - 1, c: 0 }, e: { r: index - 1, c: 8 } });
  }

  ws["!merges"] = merge;

  return ws;
};

export { exportMartketList };
