import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "../../assets/admin/css/styles.css";
import { toast } from "react-toastify";
import Navbar from "../admin/Navbar";
import Sidebar from "./Sidebar";
import Footer from "../admin/Footer";
import NavbarFooter from "../admin/NavbarFooter";
import api from "../../helper/axiosInstance";

const MasterLayout = () => {
  useEffect(() => {
    checkLogin();
  }, []);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const removeLocalstore = () => {
    localStorage.removeItem("auth_id_shool");
    localStorage.removeItem("auth_id_user");
    localStorage.removeItem("auth_name");
    localStorage.removeItem("role_rank");
    localStorage.removeItem("role_id");
    localStorage.removeItem("role_name");
    localStorage.removeItem("permission");
    localStorage.removeItem("token_type");
    localStorage.removeItem("access_token");
    localStorage.removeItem("auth_phone");
    localStorage.removeItem("auth_account");
    localStorage.removeItem("vip");
    localStorage.removeItem("version");
  };

  const checkLogin = async () => {
    setIsLoading(false);
    await api
      .get(`/authenticated`)
      .then((res) => {
        const result = res.data.data;
        const permissions = result.permissions.map(
          (permission) => permission.id
        );

        localStorage.setItem("auth_name", result.user.user_name);
        localStorage.setItem("permission", permissions);

        setIsLoading(true);
      })
      .catch(
        (err) => {
          removeLocalstore();
          toast(err.message, { type: "error" });
          navigate("/login");
          setIsLoading(true);
        },
        [navigate]
      );
  };

  const sidenavToggle = () => {
    const sbNavFixed = document.getElementById("sb-nav-fixed");
    let testClass = sbNavFixed.className;

    if (
      testClass === "sb-nav-fixed sb-sidenav-toggled" &&
      window.innerWidth < 992
    ) {
      sbNavFixed.classList.remove("sb-sidenav-toggled");
    }
  };

  return (
    <div className="sb-nav-fixed" id="sb-nav-fixed">
      <Navbar />
      <NavbarFooter className="menu-phone" />

      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <Sidebar />
        </div>

        <div id="layoutSidenav_content" onClick={sidenavToggle}>
          <main>
            <Outlet />
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MasterLayout;
