import React, { Component } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { INJECTIONS } from "../../../constants";
import moment from "moment/moment";
import Select from "react-select";
import { toast } from "react-toastify";
import api from "../../../helper/axiosInstance";

class VaccinationFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      formData: {
        id_student: "",
        id_disease_type: "",
        sequence: "",
        vaccination_date: new Date() / 1000,
        vaccine_name: "",
        dosage: "",
        physician: "",
        route_and_site: "",
        vaccinator: "",
        mfr: "",
        lot_exp: new Date() / 1000,
        notes: "",
      },
      errors: {},
    };
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    if (name == "vaccination_date" || name == "lot_exp") {
      value = new Date(e.target.value) / 1000;
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
    delete this.state.errors[name];
  };
  createVaccin = () => {
    let data = {
      id_student: this.state.formData?.id_student,
      id_disease_type: this.state.formData?.id_disease_type,
      sequence: INJECTIONS?.filter(
        (e) => e.value == this.state.formData?.sequence
      )[0]?.label, //mũi tiêm 1,2,3
      vaccination_date: parseInt(this.state.formData?.vaccination_date), // ngày tiêm
      vaccine_name: this.state.formData?.vaccine_name, // tên vắc xin
      dosage: this.state.formData?.dosage, //liều lượng
      physician: this.state.formData?.physician, //bs chỉ định
      route_and_site: this.state.formData?.route_and_site, // vi trí tiêm
      vaccinator: this.state.formData?.vaccinator, // kỹ thuật viên
      mfr: this.state.formData?.mfr, // nhà sản xuất
      lot_exp: parseInt(this.state.formData?.lot_exp), // ngày hết hạn của lô thuốc
      notes: this.state.formData?.notes,
    };

    api
      .post("vaccination-create", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Tạo thành công", { type: "success" });
        return this.props.submit();
      })
      .catch((error) => {
        this.setState({ errors: error.response.data.messages });
      });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.createVaccin();
  };

  render() {
    const { formData, errors } = this.state;

    return (
      <>
        <Modal show={this.props.show} onHide={this.props?.onHide} size={"lg"}>
          <Modal.Header closeButton>
            <Modal.Title>Thêm lịch tiêm ngừa</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col md={6} className="mb-3">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        minHeight: "50px",
                      }),
                    }}
                    options={this.props.students}
                    placeholder={
                      <>
                        Chọn học sinh
                        <i className="text-danger">*</i>
                      </>
                    }
                    name="id_student"
                    value={
                      this.props.students.filter(
                        (item) => item.value == this.state.formData?.id_student
                      )[0] || "Chọn học sinh"
                    }
                    required
                    onChange={(choice) => {
                      let data = this.state.formData;
                      data.id_student = choice.value;
                      this.setState({ formData: data });
                      delete this.state.errors["id_student"];
                    }}
                  />

                  <span className="text-form-err">{errors.id_student}</span>
                </Col>
                <Col md={6} className="mb-3">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        minHeight: "50px",
                      }),
                    }}
                    required
                    options={this.props.disease}
                    placeholder={
                      <>
                        Chọn loại bệnh
                        <i className="text-danger">*</i>
                      </>
                    }
                    name="id_disease_type"
                    value={
                      this.props.disease.filter(
                        (item) =>
                          item.value == this.state.formData?.id_disease_type
                      )[0] || "Chọn loại bệnh"
                    }
                    onChange={(choice) => {
                      let data = this.state.formData;
                      data.id_disease_type = choice.value;
                      this.setState({ formData: data });
                      delete this.state.errors["id_disease_type"];
                    }}
                  />

                  <span className="text-form-err">
                    {errors.id_disease_type}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      type="text"
                      name="sequence"
                      value={formData.sequence}
                      onChange={this.handleChange}
                      placeholder="Mũi tiêm"
                      required
                    >
                      <option value="">--Chọn--</option>
                      {INJECTIONS?.map((e) => {
                        return <option value={e.value}>{e.label}</option>;
                      })}
                    </Form.Select>

                    <Form.Label>
                      Mũi tiêm
                      <i className="text-danger">*</i>
                    </Form.Label>

                    <span className="text-form-err">{errors.sequence}</span>
                  </Form.Floating>
                </Col>
                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="date"
                      // required
                      name="vaccination_date"
                      // value={formData.vaccination_date}
                      value={moment(
                        new Date(formData.vaccination_date * 1000)
                      ).format("YYYY-MM-DD")}
                      onChange={this.handleChange}
                    />
                    <Form.Label>
                      Ngày tiêm
                      <i className="text-danger">*</i>
                    </Form.Label>

                    <span className="text-form-err">
                      {errors.vaccination_date}
                    </span>
                  </Form.Floating>
                </Col>
              </Row>{" "}
              <Row>
                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="vaccine_name"
                      value={formData.vaccine_name}
                      onChange={this.handleChange}
                      placeholder="Nhập tên vắc xin"
                      required
                    />
                    <Form.Label>
                      Tên vắc xin
                      <i className="text-danger">*</i>
                    </Form.Label>

                    <span className="text-form-err">{errors.vaccine_name}</span>
                  </Form.Floating>
                </Col>
                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="dosage"
                      value={formData.dosage}
                      onChange={this.handleChange}
                      placeholder="Nhập liều lượng"
                    />
                    <Form.Label>Liều lượng</Form.Label>

                    <span className="text-form-err">{errors.dosage}</span>
                  </Form.Floating>
                </Col>
                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="mfr"
                      value={formData.mfr}
                      onChange={this.handleChange}
                      placeholder="Nhập nhà sản xuất"
                    />
                    <Form.Label>Nhà sản xuất</Form.Label>

                    <span className="text-form-err">{errors.mfr}</span>
                  </Form.Floating>
                </Col>
                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="route_and_site"
                      value={formData.route_and_site}
                      onChange={this.handleChange}
                      placeholder="Nhập vị trí tiêm"
                    />
                    <Form.Label>Vị trí tiêm</Form.Label>

                    <span className="text-form-err">
                      {errors.route_and_site}
                    </span>
                  </Form.Floating>
                </Col>
                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="date"
                      name="lot_exp"
                      value={moment(new Date(formData.lot_exp * 1000)).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={this.handleChange}
                      placeholder="Nhập ngày hết hạn"
                    />
                    <Form.Label>Ngày hết hạn lô thuốc</Form.Label>

                    <span className="text-form-err">{errors.lot_exp}</span>
                  </Form.Floating>
                </Col>
                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      // as="textarea"
                      name="notes"
                      placeholder="Ghi chú"
                      value={formData.notes}
                      onChange={this.handleChange}
                      rows={3}
                    />
                    <Form.Label>Ghi chú</Form.Label>
                    <span className="text-form-err">{errors.notes}</span>
                  </Form.Floating>
                </Col>
                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="physician"
                      value={formData.physician}
                      onChange={this.handleChange}
                      placeholder="Nhập tên bác sĩ"
                    />
                    <Form.Label>Bác sĩ chỉ định</Form.Label>

                    <span className="text-form-err">{errors.physician}</span>
                  </Form.Floating>
                </Col>

                <Col md={6}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      name="vaccinator"
                      value={formData.vaccinator}
                      onChange={this.handleChange}
                      placeholder="Nhập tên kỹ thuật viên"
                    />
                    <Form.Label>Kỹ thuật viên</Form.Label>

                    <span className="text-form-err">{errors.vaccinator}</span>
                  </Form.Floating>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary me-2"
                onClick={() => this.props?.onHide()}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>
              <Button variant="success" type="submit">
                <i className="fa-solid fa-check"></i> Lưu
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default VaccinationFormModal;
