import messaging from "./config";
import { getToken } from "firebase/messaging";

const FCMService = {
  getFCMToken: async () => {
    try {
      const swRegistration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js"
      );

      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
        serviceWorkerRegistration: swRegistration,
      });
      if (currentToken) {
        return currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    } catch (error) {
      console.error("An error occurred while retrieving token.", error);
    }
  },
};

export default FCMService;
