import * as XLSX from "xlsx-js-style";

const fontStyle = {
	name: "Times New Roman",
};

const borderStyle = {
	top: { style: "thin" },
	bottom: { style: "thin" },
	left: { style: "thin" },
	right: { style: "thin" },
};

const exportDetailVoucherMarket = (ingredientList) => {
	const table = document.getElementById("market-list-table");

	var ws = XLSX.utils.table_to_sheet(table);

	const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H"];
	const colAlpha_Sum = ["A", "C", "D", "E", "F", "H"];

	const merge = [
		{ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
		{ s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
	];

	// Header
	colAlpha.map((alpha) => {
		ws[`${alpha}1`].s = {
			font: {
				...fontStyle,
				bold: true,
			},
			alignment: {
				vertical: "center",
				horizontal: "left",
			},
		};

		return alpha;
	});

	colAlpha.map((alpha) => {
		ws[`${alpha}2`].s = {
			font: {
				...fontStyle,
				sz: 16,
				bold: true,
			},
			alignment: {
				vertical: "center",
				horizontal: "center",
			},
		};

		return alpha;
	});

	colAlpha.map((alpha) => {
		ws[`${alpha}3`].s = {
			font: {
				...fontStyle,
				bold: true,
			},
			border: borderStyle,
			alignment: {
				vertical: "center",
				horizontal: "center",
				wrapText: true,
			},
		};

		return alpha;
	});
	// Header

	// Set Column Width
	var wscols = [{ wch: 4 }, { wch: 20 }, { wch: 20 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 15 }, { wch: 10 }];

	ws["!cols"] = wscols;
	ws["!rows"] = [{ hpt: 20 }, { hpt: 35 }, { hpt: 40 }];

	// Format number Price, Unit, Will Money
	const numberFormat = "#,###,###,###";
	let index = 4;

	ingredientList
		.filter((item) => Number(item.ingredent_type) === 1)
		.map((item) => {
			let priceCol = `E${index}`;
			let unitCol = `F${index}`;
			let willMoneyCol = `G${index}`;

			colAlpha.map((alpha) => {
				if (ws[`${alpha}${index}`].v === "empty") {
					ws[`${alpha}${index}`].v = " ";
				}

				ws[`${alpha}${index}`].s = {
					font: fontStyle,
					border: borderStyle,
				};

				return alpha;
			});

			ws[priceCol.toString()].z = numberFormat;
			delete ws[priceCol.toString()].w;
			XLSX.utils.format_cell(ws[priceCol.toString()]);

			ws[unitCol.toString()].z = "#,###,###,##0.0000";
			delete ws[unitCol.toString()].w;
			XLSX.utils.format_cell(ws[unitCol.toString()]);

			ws[willMoneyCol.toString()].z = numberFormat;
			delete ws[willMoneyCol.toString()].w;
			XLSX.utils.format_cell(ws[willMoneyCol.toString()]);

			index += 1;

			return item;
		});

	if (ingredientList.filter((item) => Number(item.ingredent_type) === 1).length !== 0) {
		ws[`G${index}`].z = numberFormat;
		delete ws[`G${index}`].w;
		XLSX.utils.format_cell(ws[`G${index}`]);

		colAlpha_Sum.map((alpha) => {
			ws[`${alpha}${index}`].v = " ";

			ws[`${alpha}${index}`].s = {
				font: fontStyle,
				border: borderStyle,
				alignment: {
					vertical: "center",
					horizontal: "center",
				},
			};

			return alpha;
		});

		ws[`B${index}`].s = {
			font: {
				...fontStyle,
				bold: true,
			},
			border: borderStyle,
			alignment: {
				vertical: "center",
				horizontal: "center",
			},
		};

		ws[`G${index}`].s = {
			font: {
				...fontStyle,
				bold: true,
			},
			border: borderStyle,
			alignment: {
				vertical: "center",
				horizontal: "right",
			},
		};

		index++;
	} else {
		colAlpha.map((alpha) => {
			ws[`${alpha}${index}`].s = {
				font: fontStyle,
				border: borderStyle,
				alignment: {
					vertical: "center",
					horizontal: "center",
				},
			};

			return alpha;
		});
		merge.push({ s: { r: index - 1, c: 0 }, e: { r: index - 1, c: 7 } });

		index++;
	}

	ingredientList
		.filter((item) => Number(item.ingredent_type) === 2)
		.map((item) => {
			colAlpha.map((alpha) => {
				if (ws[`${alpha}${index}`].v === "empty") {
					ws[`${alpha}${index}`].v = " ";
				}

				ws[`${alpha}${index}`].s = {
					font: fontStyle,
					border: borderStyle,
				};

				return alpha;
			});

			let priceCol = `E${index}`;
			let amountCol = `F${index}`;
			let willMoneyCol = `G${index}`;

			ws[priceCol.toString()].z = numberFormat;
			delete ws[priceCol.toString()].w;
			XLSX.utils.format_cell(ws[priceCol.toString()]);

			ws[amountCol.toString()].z = "#,###,###,##0.0000";
			delete ws[amountCol.toString()].w;
			XLSX.utils.format_cell(ws[amountCol.toString()]);

			ws[willMoneyCol.toString()].z = numberFormat;
			delete ws[willMoneyCol.toString()].w;
			XLSX.utils.format_cell(ws[willMoneyCol.toString()]);

			index += 1;

			return item;
		});

	if (ingredientList.filter((item) => Number(item.ingredent_type) === 2).length !== 0) {
		ws[`G${index}`].z = numberFormat;
		delete ws[`G${index}`].w;
		XLSX.utils.format_cell(ws[`G${index}`]);

		colAlpha_Sum.map((alpha) => {
			ws[`${alpha}${index}`].v = " ";

			ws[`${alpha}${index}`].s = {
				font: fontStyle,
				border: borderStyle,
				alignment: {
					vertical: "center",
					horizontal: "center",
				},
			};

			return alpha;
		});

		ws[`B${index}`].s = {
			font: {
				...fontStyle,
				bold: true,
			},
			border: borderStyle,
			alignment: {
				vertical: "center",
				horizontal: "center",
			},
		};

		ws[`G${index}`].s = {
			font: {
				...fontStyle,
				bold: true,
			},
			border: borderStyle,
			alignment: {
				vertical: "center",
				horizontal: "right",
			},
		};
	} else {
		colAlpha.map((alpha) => {
			ws[`${alpha}${index}`].s = {
				font: fontStyle,
				border: borderStyle,
				alignment: {
					vertical: "center",
					horizontal: "center",
				},
			};

			return alpha;
		});

		merge.push({ s: { r: index - 1, c: 0 }, e: { r: index - 1, c: 7 } });
	}

	ws["!merges"] = merge;

	return ws;
};

export { exportDetailVoucherMarket };
