import React, { Component } from "react";
import DataTables from "../datatable/DataTables";
import {
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Alert,
  Tabs,
  Tab,
  Form,
  Button,
} from "react-bootstrap";
import IngredentModalFromBank from "./IngredentModalFromBank";
import Check from "../other/Check";
import api from "../../../helper/axiosInstance";

class BankIngredent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ingredents: [],
      ingredents_search: [],
      ingredentTextSearch: "",
      deps: [],
      addModalShow: false,
      editModalShow: false,
      alertShow: false,
      ingredent: [],
      selectedRows: [],
      tabkey: "1",

      checkload: true,
      arrIngredent: [],
      modalAddIngredent: false,
      clear: false,
      listIngredent: [],
      currentRow: {},
    };
  }

  componentDidMount() {
    document.title = "Ngân hàng nguyên liệu";
    this.getIngredentsData(this.state.tabkey);
    this.getIngredents();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.clear !== this.state.clear && this.state.clear) {
      this.setState({ clear: false });
    }
  }
  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };

  changeTab = (key) => {
    this.setState({ tabkey: key, checkload: true });
    this.getIngredentsData(key);
    this.setState({ ingredentTextSearch: "" });
  };

  getIngredentsData = (key) => {
    api.get(`/ingredent-bank`).then((res) => {
      if (res.data.errCode === 0) {
        this.setState({
          ingredents: res.data.ingredents?.filter(
            (e) => Number(e.id_subspecies) === Number(key)
          ),
        });
        this.handleIngredentSearch(
          res.data.ingredents?.filter(
            (e) => Number(e.id_subspecies) === Number(key)
          ),
          this.state.ingredentTextSearch
        );
      }
      this.setState({ checkload: false });
    });
  };
  getIngredents = () => {
    api
      .get(`/ingredent-show`)
      .then((res) => {
        if (res.data.errCode == 0) {
          this.setState({
            listIngredent: res.data.ingredents,
          });
        }
      })
      .catch((err) => {});
  };
  formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  renderSelectedRows() {
    let num = this.state.selectedRows.length;
    if (num > 0 && this.state.alertShow === true) {
      return (
        <Alert
          variant="info mb-0 py-2"
          onClose={() => this.setState({ alertShow: false })}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  }

  toSlug(str) {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường
    // Xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    return str;
  }

  handleIngredentSearch(root, text) {
    let root_ = root;

    this.setState({
      ingredentTextSearch: text,
    });

    if (text !== "") {
      root_ = this.state.ingredents.filter(
        (e) =>
          this.toSlug(String(e.ingredent_name)).includes(
            this.toSlug(text.trim())
          ) ||
          this.toSlug(String(e.ingredent_code)).includes(
            this.toSlug(text.trim())
          )
      );
    }

    this.setState({
      ingredents_search: root_,
    });
  }

  dataTable() {
    const columns = [
      {
        name: "Mã số",
        selector: (row) => row.ingredent_code,
        sortable: true,
      },
      {
        name: "Nguyên liệu",
        selector: (row) => row.ingredent_name,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap ">
            {row.ingredent_name}
          </div>
        ),
      },
      {
        name: "TL thải bỏ (%)",
        selector: (row) => row.ingredent_ratio,
        sortable: true,
        center: true,
        cell: (row) => (
          <div>{this.formatter.format(row.ingredent_ratio * 100)}</div>
        ),
      },
      {
        name: "Năng lượng (kcal)",
        selector: (row) => parseFloat(row.ingredent_energy),
        sortable: true,
        center: true,
        cell: (row) => this.formatter.format(row.ingredent_energy),
      },
      {
        name: "Protein (g)",
        selector: (row) => parseFloat(row.ingredent_protein),
        center: true,
        sortable: true,
        cell: (row) => this.formatter.format(row.ingredent_protein),
      },
      {
        name: "Lipid (g)",
        selector: (row) => parseFloat(row.ingredent_lipid),
        center: true,
        sortable: true,
        cell: (row) => this.formatter.format(row.ingredent_lipid),
      },
      {
        name: "Glucid (g)",
        selector: (row) => parseFloat(row.ingredent_glucid),
        center: true,
        sortable: true,
        cell: (row) => this.formatter.format(row.ingredent_glucid),
      },
    ];

    const data = [];

    if (this.state.ingredents_search.length > 0) {
      this.state.ingredents_search.map((item) => {
        data.push({
          ingredent_code: item.ingredent_code,
          ingredent_name: item.ingredent_name,
          ingredent_ratio: item.ingredent_ratio * 100,
          ingredent_energy: item.ingredent_energy,
          ingredent_protein: item.ingredent_protein,
          ingredent_lipid: item.ingredent_lipid,
          ingredent_glucid: item.ingredent_glucid,
          volume_cook: item.volume_cook,
          volume_market: item.volume_market,
          unit_market_name: item.unit_market_name,
          unit_cook_name: item.unit_cook_name,
        });

        return item;
      });
    }

    const ExpandedComponent = (listData) => {
      const columns = [
        {},
        {
          name: "ĐV chợ",
          selector: (row) => row.unit_market_name,
        },
        {
          name: "KL chợ (g)",
          selector: (row) => row.volume_market,
        },
        {
          name: "ĐV định lượng",
          selector: (row) => row.unit_cook_name,
        },
        {
          name: "TL ĐV (chợ/ định lượng)",
          selector: (row) => row.volume_cook,
        },
      ];
      return (
        <DataTables
          pagination={false}
          data={[listData.listData]}
          columns={columns}
          className="ms-5"
        />
      );
    };
    const selectedRowIng = (selectedRows) => {
      this.setState({ arrIngredent: selectedRows });
    };

    const selectableRowDisabled = (row) => {
      return this.state.listIngredent?.some(
        (item) => item.ingredent_code == row.ingredent_code
      );
    };

    return (
      <>
        <DataTables
          onSelectedRowsChange={(item) => selectedRowIng(item.selectedRows)}
          selectableRowsNoSelectAll
          data={this.state.ingredents_search}
          selectableRows={new Check().permission(["41"])}
          clearSelectedRows={this.state.clear}
          columns={columns}
          selectableRowDisabled={selectableRowDisabled}
          expandableRows
          onRowExpandToggled={(bool, row) => this.setState({ currentRow: row })}
          expandableRowExpanded={(row) => row == this.state.currentRow}
          expandableRowsComponent={({ data }) => (
            <ExpandedComponent listData={data} />
          )}
          paginationRowsPerPageOptions={[30, 50, 100, 500, 1000]}
        />
      </>
    );
  }

  renderTableIngredent() {
    return (
      <>
        {this.state.ingredents_search.length > 0 ? (
          this.dataTable()
        ) : (
          <div className="text-center mt-4">Không có dữ liệu</div>
        )}
      </>
    );
  }

  renderIngredentList() {
    return (
      <>
        <Card.Header
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Card.Title>
            <i className="fas fa-list me-1"></i> Ngân hàng nguyên liệu
          </Card.Title>

          {new Check().permission(["41"]) ? (
            <Button
              style={{ float: "right" }}
              variant="success"
              size="sm"
              onClick={() => this.setState({ modalAddIngredent: true })}
              disabled={this.state.arrIngredent?.length < 1}
            >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
          ) : (
            <></>
          )}
        </Card.Header>

        <Card.Body>
          <Row>
            <Col md={4}>{this.renderSelectedRows()}</Col>
          </Row>

          <Col lg={3} md={4} className="mb-2">
            <Form.Control
              style={{ height: 39 }}
              type="text"
              placeholder="Tìm nguyên liệu"
              value={this.state.ingredentTextSearch}
              onChange={(e) => {
                this.handleIngredentSearch(
                  this.state.ingredents,
                  e.target.value
                );
              }}
            />
          </Col>

          <Tabs
            activeKey={this.state.tabkey}
            onSelect={(k) => {
              this.changeTab(k);
            }}
            className="mb-3"
          >
            <Tab key={1} title="Động vật" eventKey={1}>
              {this.state.checkload
                ? this.loading()
                : this.renderTableIngredent()}
            </Tab>
            <Tab key={2} title="Thực vật" eventKey={2}>
              {this.state.checkload
                ? this.loading()
                : this.renderTableIngredent()}
            </Tab>
          </Tabs>
        </Card.Body>
      </>
    );
  }
  onHideModal = () => {
    this.setState({ modalAddIngredent: false, clear: true, arrIngredent: [] });
    this.getIngredents();
  };
  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Kho hàng</Breadcrumb.Item>
            <Breadcrumb.Item active>Ngân hàng nguyên liệu</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={12}>
              <Card className="mb-4">{this.renderIngredentList()}</Card>
              {this.state.modalAddIngredent ? (
                <>
                  <IngredentModalFromBank
                    show={this.state.modalAddIngredent}
                    onHide={this.onHideModal}
                    data={this.state.arrIngredent}
                  />
                </>
              ) : null}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default BankIngredent;
