import { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../../other/Check";
import DataTables from "../../datatable/DataTables";
import { dateFormatter } from "../../utils/helpers";
import swal from "sweetalert";
import api from "../../../../helper/axiosInstance";

const ClassBySubjectRegistered = ({
  show,
  onHide,
  selectedStudyGroupId,
  selectedStudyGroupName,
  subject,
}) => {
  const [listClassesBySubjectDetails, setListClassesBySubjectDetails] =
    useState([]);
  const [isTableSelectable, setIsTableSelectable] = useState(true);
  const [ids, setIds] = useState([]);
  useEffect(() => {
    getClassBySubjectDetail();
  }, []);

  //#region API
  const getClassBySubjectDetail = async () => {
    await api
      .get(
        `/get-list-of-registered-students?id_study_group=${selectedStudyGroupId}`
      )
      .then((res) => {
        setListClassesBySubjectDetails(res.data.data);
      })
      .catch((err) => {
        toast("Đã có lỗi xảy ra vui lòng thử lại", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const handleBrowseStudyGroup = () => {
    swal({
      title: "Bạn muốn duyệt học sinh vào nhóm?",
      icon: "warning",
      buttons: ["Đóng", "Duyệt"],
    })
      .then(async (ok) => {
        if (ok) {
          let data = {
            id_study_group: selectedStudyGroupId, // Duyệt theo nhóm theo ds học sinh
            ids_student: ids?.map((e) => {
              return e.id_student;
            }),
          };

          api
            .post(`quick-browse-registered-students`, data, {
              headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
              toast(res.data.messages || "Thành công", { type: "success" });
              setIds([]);
              setIsTableSelectable(false);
              getClassBySubjectDetail();
            })
            .catch((error) => {
              toast(error.message || "Thất bại", { type: "error" });
            });
        }
      })
      .catch((err) => {});
  };

  const dataTableColumns = [
    {
      name: "Học sinh",
      selector: (row) => row.student?.student_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Nhóm",
      selector: (row) => row.study_group?.name,
      wrap: true,
    },
    {
      name: "Giáo viên",
      selector: (row) => row.study_group?.teacher?.user_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Ngày ghi danh",
      selector: (row) => Number(row.enrollment_date),
      sortable: true,
      center: true,
      cell: (row) => dateFormatter(new Date(row.enrollment_date * 1000)),
    },
  ];
  const onSelectedRowsChange = (rows) => {
    setIds(rows);
  };
  return (
    <>
      <Modal keyboard={false} show={show} onHide={() => onHide()} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa fa-graduation-cap me-2"></i>
            Nhóm {selectedStudyGroupName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-4">
            <Card.Header>
              <Card.Title>
                <i className="fas fa-list me-1" /> Danh sách học sinh ghi danh
                {new Check().permission([143]) ? (
                  <Button
                    variant="success"
                    size="sm"
                    className="float-end ms-2"
                    onClick={() => handleBrowseStudyGroup()}
                    disabled={ids?.length < 1}
                    title="Duyệt"
                  >
                    <i className="fa-solid fa-check" /> Duyệt
                  </Button>
                ) : null}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <DataTables
                data={listClassesBySubjectDetails}
                columns={dataTableColumns}
                selectableRows
                clearSelectedRows={isTableSelectable}
                onSelectedRowsChange={(item) =>
                  onSelectedRowsChange(item.selectedRows)
                }
              />
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
  //#endregion Render
};
export default ClassBySubjectRegistered;
