import React, { Component } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../../admin/other/Check";

import swal from "sweetalert";
import DatePicker from "react-datepicker";
import api from "../../../helper/axiosInstance";

class HolidayModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInput: { ...this.props.datarole },
      isCheckGroup: [],
      startDate: this.props.datarole.start_date
        ? new Date(this.props.datarole.start_date * 1000)
        : new Date(),
      endDate: this.props.datarole.end_date
        ? new Date(this.props.datarole.end_date * 1000)
        : new Date(),
      err: {},
    };
  }

  componentDidMount() {}
  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataInput };
    dataInput[field] = value;

    let errIinput = { ...this.state.err };
    delete errIinput["holiday_name"];
    this.setState({ err: errIinput });
    this.setState({ dataInput: dataInput });
  };

  handleSelectGroup = (e) => {
    const { value, checked } = e.target;
    const idChildArr = e.target.dataset.id_child.split(",");

    if (idChildArr.length > 0) {
      const dataInput = { ...this.state.dataInput };

      dataInput.permission = [...dataInput.permission, ...idChildArr];
      this.setState({ dataInput: dataInput });

      if (!checked) {
        idChildArr.map((id, i) => {
          dataInput.permission = dataInput.permission.filter(
            (item) => item !== id
          );

          this.setState({ dataInput: dataInput });

          return id;
        });
      }
    }

    this.setState({
      isCheckGroup: [...this.state.isCheckGroup, String(value)],
    });

    if (!checked) {
      this.setState({
        isCheckGroup: this.state.isCheckGroup.filter(
          (item) => item !== String(value)
        ),
      });
    }
  };

  createRole = () => {
    let data = {
      id_school_year: this.props.id_add,
      holiday_name: this.state.dataInput.holiday_name || "",
      start_date: parseInt(
        new Date(this.state.startDate.setHours(0, 0, 0)) / 1000
      ),
      end_date: parseInt(
        new Date(this.state.endDate.setHours(23, 59, 59)) / 1000
      ),
    };
    api
      .post(`/holiday-create`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(`${res.data.messages}`, { type: "success", autoClose: 1000 });
        return this.props.submit();
      })
      .catch((error) => {
        this.setState({ err: error.response?.data?.messages });
      });
  };

  updateRole = () => {
    // let data = this.state.dataInput;
    let data = {
      id: this.state.dataInput.id,
      id_school_year: this.state.dataInput.id_school_year,
      holiday_name: this.state.dataInput.holiday_name || "",
      start_date: parseInt(
        new Date(this.state.startDate.setHours(0, 0, 0)) / 1000
      ),
      end_date: parseInt(
        new Date(this.state.endDate.setHours(23, 59, 59)) / 1000
      ),
    };
    swal({
      title: "Bạn muốn cập nhật ngày nghỉ?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .put(`/holiday-update`, data, {
              headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
              toast(`${res.data.messages}`, {
                type: "success",
                autoClose: 1000,
              });
              return this.props.submit();
            })
            .catch((error) => {
              this.setState({ err: error.response?.data?.messages });
            });
        }
      })
      .catch((err) => {});
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.dataInput.id === "") {
      this.createRole();
    } else {
      this.updateRole();
    }
  };

  handleHide = () => {
    return this.props.onHide();
  };

  render() {
    const { onHide, ...other } = { ...this.props };
    return (
      <>
        <Modal
          show={other.show}
          onHide={onHide}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{other.modal.title}</Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Form.Floating className="mb-1">
                <Form.Control
                  type="text"
                  name="holiday_name"
                  defaultValue={this.state.dataInput?.holiday_name || ""}
                  placeholder="Tên năm học"
                  onChange={this.handleInput}
                />
                <Form.Label>
                  Tên kỳ nghỉ<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-form-err">
                  {this.state.err.holiday_name}
                </span>
              </Form.Floating>
              <center>
                Thời gian diễn ra<i className="text-danger">*</i>
              </center>
              <div className="d-flex">
                <DatePicker
                  selected={this.state.startDate}
                  onChange={(date) => {
                    delete this.state.err["start_date"];
                    this.setState({ startDate: date });
                  }}
                  selectsStart
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  className="form-control text-end"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Chọn ngày bắt đầu"
                  required
                />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0.5rem",
                  }}
                >
                  -
                </span>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={(date) => {
                    this.setState({ endDate: date });
                    delete this.state.err["end_date"];
                  }}
                  selectsEnd
                  startDate={this.state.endDate}
                  endDate={this.state.endDate}
                  className="form-control text-end"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Chọn ngày kết thúc"
                  required
                />
              </div>
              <Row>
                <Col md={6}>
                  <span className="text-form-err">
                    {this.state.err.start_date}
                  </span>
                </Col>{" "}
                <Col md={6}>
                  <span className="text-form-err">
                    {this.state.err.end_date}
                  </span>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddIngredent"
                onClick={this.handleHide}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              {(new Check().permission(["101"]) &&
                other.modal.type === "add") ||
              (new Check().permission(["102"]) &&
                other.modal.type === "edit") ? (
                <Button size="sm" variant="success" type="submit">
                  {other.modal.button}
                </Button>
              ) : (
                <></>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default HolidayModal;
