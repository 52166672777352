export const findStartDateAndEndDateOfMonth = (dateRFC) => {
  try {
    const currentMonth = dateRFC.getMonth();
    const currentYear = dateRFC.getFullYear();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const timestampSt = firstDayOfMonth.getTime();
    const firstUnixTimestamp = Math.floor(timestampSt / 1000);

    const firstOfNextMonth = new Date(
      currentMonth === 11 ? currentYear + 1 : currentYear,
      currentMonth === 11 ? 0 : currentMonth + 1,
      1
    );

    const lastDayOfMonth = new Date(firstOfNextMonth.getTime() - 1);
    const lastUnixTimestamp = Math.floor(lastDayOfMonth / 1000);

    return {
      startDate: firstUnixTimestamp,
      endDate: lastUnixTimestamp,
    };
  } catch (e) {
    return e.message;
  }
};

export const DAYS_OF_WEEK = [
  { id: 1, day_name: "Thứ 2" },
  { id: 2, day_name: "Thứ 3" },
  { id: 3, day_name: "Thứ 4" },
  { id: 4, day_name: "Thứ 5" },
  { id: 5, day_name: "Thứ 6" },
  { id: 6, day_name: "Thứ 7" },
  { id: 7, day_name: "Chủ nhật" },
];

export const convertTimeToTimestamp = (timeString) => {
  const [hours, minutes] = timeString.split(":").map(Number);

  const currentDate = new Date();

  currentDate.setHours(hours, minutes, 0, 0);

  const timestamp = Math.floor(currentDate.getTime() / 1000);

  return timestamp;
};

export const getDateNow = () => {
  let ternNow = new Date(Math.floor(Date.now()));
  var day_ = ("0" + ternNow.getDate()).slice(-2);
  var month_ = ("0" + (ternNow.getMonth() + 1)).slice(-2);
  var year_ = ternNow.getFullYear();

  var time_ =
    ternNow.getHours() +
    ":" +
    ternNow.getMinutes() +
    ":" +
    ternNow.getSeconds();
  var hours_ = ternNow.getHours();
  var min_ = ternNow.getMinutes();
  var sec_ = ternNow.getSeconds();
  var id_day = ternNow.getDay();

  let result = {
    timestamp: parseInt(Math.floor(Date.now()) / 1000),
    day: day_,
    month: month_,
    year: year_,
    time: time_,
    hours: hours_,
    min: min_,
    sec: sec_,
    id_day: id_day,
  };

  return result;
};

export const formatTimestamp = (timestamp, type = "dd/mm/yyyy hh:mm:ss") => {
  var date = new Date(timestamp * 1000);

  var day = ("0" + date.getDate()).slice(-2);
  var month = ("0" + (date.getMonth() + 1)).slice(-2);
  var year = date.getFullYear();
  var hours = ("0" + date.getHours()).slice(-2);
  var minutes = ("0" + date.getMinutes()).slice(-2);
  var seconds = ("0" + date.getSeconds()).slice(-2);

  return `${day}/${month}/${year}${
    type === "dd/mm/yyyy hh:mm:ss" ? ` ${hours}:${minutes}:${seconds}` : ""
  }`;
};

export const lastDayOfMonth = (year, month) => {
  const nextMonth = new Date(year, month, 1);
  nextMonth.setDate(nextMonth.getDate() - 1);
  return new Date(nextMonth.setHours(23, 59, 59));
};

export const convertDate = (date) => {
  let tern_ = new Date(Math.floor(date * 1000));
  var day_ = ("0" + tern_.getDate()).slice(-2);
  var month_ = ("0" + (tern_.getMonth() + 1)).slice(-2);
  var year_ = tern_.getFullYear();

  var time_ =
    tern_.getHours() + ":" + tern_.getMinutes() + ":" + tern_.getSeconds();
  var hours_ = tern_.getHours();
  var min_ = tern_.getMinutes();
  var sec_ = tern_.getSeconds();
  var id_day = tern_.getDay();

  let result = {
    id_day: id_day,
    date: `${day_}/${month_}/${year_}`,
    day: day_,
    month: month_,
    year: year_,
    time: time_,
    hours: hours_,
    min: min_,
    sec: sec_,
  };
  return result;
};

export const datesOfWeek = (current) => {
  var week = [];
  current.setDate(current.getDate() - current.getDay() + 1);
  current.setHours(0, 0, 0, 0);
  for (var i = 0; i < 7; i++) {
    week.push(new Date(current));
    current.setDate(current.getDate() + 1);
    current.setHours(0, 0, 0, 0);
  }
  return week;
};
