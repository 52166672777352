import { useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import api from "../../../../helper/axiosInstance";

const errorTextStyle = {
  fontSize: 13,
  color: "red",
  marginTop: 6,
};

const ClassBySubjectDetailEdit = ({
  show,
  onHide,
  listStudents,
  classBySubjectDetail,
  subject,
}) => {
  const [detail, setDetail] = useState({
    id_student: classBySubjectDetail.id_student,
    tuition_fee: parseInt(subject.subject_price, 10),
    reduce_money: classBySubjectDetail.reduce_money
      ? parseInt(classBySubjectDetail.reduce_money, 10)
      : "",
    notes: classBySubjectDetail.notes,
  });

  const [errorMessage, setErrorMessage] = useState({
    id_student: "",
    tuition_fee: "",
    reduce_money: "",
    notes: "",
  });

  // API
  const updateClassBySubjectDetail = async () => {
    setErrorMessage({
      id_student: "",
      tuition_fee: "",
      reduce_money: "",
      notes: "",
    });

    await api({
      method: "put",
      url: "class-subject-detail-update",
      data: {
        id: classBySubjectDetail.id,
        id_study_group: classBySubjectDetail.id_study_group,
        tuition_fee: detail.tuition_fee,
        reduce_money: detail.reduce_money ?? 0,
        notes: detail.notes ?? null,
      },
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        toast(res.data.messages, {
          type: "success",
          autoClose: 1000,
        });
        onHide(true);
      })
      .catch((err) => {
        toast("Cập nhật thất bại", {
          type: "error",
          autoClose: 1000,
        });
        setErrorMessage(err.response.data.messages);
      });
  };
  // API

  const checkInput = () => {
    swal({
      title: "Bạn muốn cập nhật học phí?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        let hasError = false;
        const errorMessage = {
          id_student: "",
          tuition_fee: "",
          reduce_money: "",
          notes: "",
        };

        if (detail.id_student.length === 0) {
          hasError = true;
          errorMessage.id_student = "Không được để trống";
        }

        const tuitionFee = Number(detail.tuition_fee);
        if (
          tuitionFee < 0 ||
          tuitionFee > 999999999 ||
          isNaN(tuitionFee) ||
          !Number.isInteger(tuitionFee)
        ) {
          hasError = true;
          errorMessage.tuition_fee =
            "Vui lòng nhập số nguyên trong khoảng 0 đến 999999999";
        }

        const reduceMoney = Number(detail.reduce_money);
        if (
          reduceMoney < 0 ||
          reduceMoney > 999999999 ||
          isNaN(reduceMoney) ||
          !Number.isInteger(reduceMoney)
        ) {
          hasError = true;
          errorMessage.reduce_money =
            "Vui lòng nhập số nguyên trong khoảng 0 đến 999999999";
        }

        if (
          Number.isInteger(tuitionFee) &&
          Number.isInteger(reduceMoney) &&
          reduceMoney > tuitionFee
        ) {
          hasError = true;
          errorMessage.reduce_money = "Giảm giá không lớn hơn học phí";
        }

        if (hasError) {
          setErrorMessage(errorMessage);
        } else {
          updateClassBySubjectDetail();
        }
      }
    });
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide()}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Chi tiết học phí</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* Hoc sinh */}
        <Form.Floating className="mb-3">
          <Form.Select
            disabled
            value={detail.id_student}
            onChange={(e) => {
              setDetail({
                ...detail,
                id_student: e.target.value,
              });
            }}
            required
          >
            <option value="">--Chọn--</option>
            {listStudents.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.student_name}
                </option>
              );
            })}
          </Form.Select>
          <Form.Label>
            Học sinh<i className="text-danger">*</i>
          </Form.Label>

          {errorMessage.id_student && (
            <p style={errorTextStyle}>{errorMessage.id_student}</p>
          )}
        </Form.Floating>
        <Row>
          <Col>
            {/* Hoc phi */}
            <Form.Floating className="mb-3">
              <CurrencyFormat
                className="form-control  text-end"
                thousandSeparator={true}
                suffix=" ₫"
                displayType="input"
                value={detail.tuition_fee}
                onValueChange={(e) => {
                  setDetail({
                    ...detail,
                    tuition_fee: e.value,
                  });
                }}
                placeholder="Học phí"
                required
              />
              <Form.Label>
                Học phí
                <i className="text-danger">*</i>
              </Form.Label>

              {errorMessage.tuition_fee && (
                <p style={errorTextStyle}>{errorMessage.tuition_fee}</p>
              )}
            </Form.Floating>
          </Col>
          <Col>
            {/* Giam gia */}
            <Form.Floating className="mb-3">
              <CurrencyFormat
                className="form-control text-end"
                thousandSeparator={true}
                suffix=" ₫"
                displayType="input"
                value={detail.reduce_money}
                onValueChange={(e) => {
                  setDetail({
                    ...detail,
                    reduce_money: e.value,
                  });
                }}
                placeholder="Giảm giá"
                required
              />
              <Form.Label>Giảm giá</Form.Label>

              {errorMessage.reduce_money && (
                <p style={errorTextStyle}>{errorMessage.reduce_money}</p>
              )}
            </Form.Floating>
          </Col>
        </Row>
        {/* Ghi chu */}
        {/* <Form.Floating className="mb-3">
          <Form.Control
            type="text"
            value={detail.notes}
            onChange={(e) => {
              setDetail({
                ...detail,
                notes: e.target.value,
              });
            }}
            as="textarea"
            rows={3}
            style={{ height: "100px" }}
            placeholder="Ghi chú"
            required
          />
          <Form.Label>Ghi chú</Form.Label>

          {errorMessage.notes && (
            <p style={errorTextStyle}>{errorMessage.notes}</p>
          )}
        </Form.Floating> */}
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onHide()}>
          <i className="fa-solid fa-times"></i> Đóng
        </Button>

        <Button
          size="sm"
          type="submit"
          variant="success"
          onClick={() => checkInput()}
        >
          <i className="fa-solid fa-check"></i> Cập nhật
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClassBySubjectDetailEdit;
