import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { dateFormatter } from "../utils/helpers";
import { Modal, Form, Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import api from "../../../helper/axiosInstance";

const SubjectInfo = ({ show, onHide, selectedSubjectId }) => {
  const [subjectDetail, setSubjectDetail] = useState({
    subject_code: "",
    subject_name: "",
    subject_price: "",
    subject_description: "",
    type: "",
    prices: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const typeOptions = [
    {
      type: "DEFAULT",
      text: "Chính khóa",
    },
    {
      type: "EXTRA",
      text: "Học thêm",
    },
  ];

  useEffect(() => {
    getSubjectDetail();
  }, []);

  // API
  const getSubjectDetail = async () => {
    setIsLoading(true);
    await api
      .get(`/subject-show?id=${selectedSubjectId}`)
      .then((res) => {
        const subject = res.data.data.subject;
        setSubjectDetail({
          id: subject.id,
          subject_code: subject.subject_code,
          subject_name: subject.subject_name,
          subject_price: parseInt(subject.subject_price, 10),
          subject_description: subject.subject_description,
          type: subject.type,
          prices: subject.prices,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        toast("Đã có lỗi xảy ra vui lòng thử lại", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  // API
  return (
    <Modal keyboard={false} show={show} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa fa-graduation-cap me-2"></i>
          Thông tin môn học
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="mb-1">Loại</div>
            <Form.Select value={subjectDetail.type} disabled className="mb-3">
              {typeOptions.map((item) => {
                return (
                  <option key={item.type} value={item.type}>
                    {item.text}
                  </option>
                );
              })}
            </Form.Select>

            <div className="mb-1">Mã môn học</div>
            <Form.Control
              className="mb-3"
              type="text"
              value={subjectDetail.subject_code}
              placeholder="Mã môn học"
              disabled
            />

            <div className="mb-1">Tên môn học</div>
            <Form.Control
              className="mb-3"
              type="text"
              value={subjectDetail.subject_name}
              placeholder="Tên môn học"
              disabled
            />
            {subjectDetail.type === "DEFAULT" ? null : (
              <>
                <div className="mb-1">Học phí</div>
                <CurrencyFormat
                  className="form-control mb-3"
                  thousandSeparator={true}
                  suffix=" ₫"
                  displayType="input"
                  value={subjectDetail.subject_price}
                  placeholder="Học phí"
                  disabled
                />
              </>
            )}

            <div className="mb-1">Mô tả</div>
            <Form.Control
              className="mb-3"
              value={subjectDetail.subject_description}
              as="textarea"
              rows={3}
              placeholder="Mô tả môn học"
              style={{ height: "100px" }}
              disabled
            />
            {subjectDetail.type === "DEFAULT" ? null : (
              <>
                <hr></hr>
                <div className="mb-1">
                  <strong>Lịch sử học phí</strong>
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th>Ngày nhập</th>
                      <th>Học phí</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subjectDetail.prices.map((price) => {
                      return (
                        <tr>
                          <td>
                            {dateFormatter(new Date(price.created_at * 1000))}
                          </td>
                          <td>
                            <CurrencyFormat
                              thousandSeparator={true}
                              suffix=" ₫"
                              displayType="text"
                              value={parseInt(price.price, 10)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SubjectInfo;
