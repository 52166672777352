import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx-js-style";
import DatePicker from "react-datepicker";
import { vi } from "date-fns/locale";
import { startOfWeek, endOfWeek } from "date-fns";
import { Breadcrumb, Button, Card, Container, Spinner } from "react-bootstrap";
import Select from "react-select";

import api from "../../../helper/axiosInstance";
import { datesOfWeek } from "../../../helper/times";
import TableReportRegisterMeal from "./TableReportRegisterMeal";
import { exportReportRegisterMeal } from "./exportFunctions";
export default function ReportRegister() {
  const [resultRegister, setResultRegister] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [classGroupOptions, setClassGroupOptions] = useState([]);
  const [selectedClassGroup, setSelectedClassGroups] = useState({
    id: 0,
    group_name: "Tất cả",
  });

  const [startDate, setStartDate] = useState(new Date());
  const [termDate, setTermDate] = useState(new Date());
  // const [school, setSchool] = useState({});

  useEffect(() => {
    document.title = "Đăng ký suất ăn";

    let initialWeek = setDatesOfWeek(new Date());
    // setWeekInMonth((0 | (initialWeek[0].getDate() / 7)) + 1);

    getResultRegisterMeal(initialWeek[0], initialWeek[6]);

    getListClassGroups();
    // getDetailschool();
  }, []);

  // API
  // const getDetailschool = async () => {
  //   await api
  //     .get(`/detailSchool`)
  //     .then((res) => {
  //       if (res.data.status === true) {
  //         setSchool(res.data.data[0]);
  //       } else {
  //         toast(res.data.message, { type: "error" });
  //       }
  //     })
  //     .catch((error) => {});
  // };
  const getResultRegisterMeal = (startDate, endDate) => {
    setIsLoading(true);
    api
      .get(`get-menu-list-with-registered-servings`, {
        params: {
          from_date: startDate.getTime() / 1000,
          to_date: endDate.getTime() / 1000,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setResultRegister(res.data.data);
      });
  };

  const getListClassGroups = async () => {
    await api
      .get(`/group-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          const classGroupOption = res.data.groups.map((group) => {
            return {
              id: group.id,
              group_name: group.group_name,
            };
          });

          classGroupOption.unshift({ id: 0, group_name: "Tất cả" });
          setClassGroupOptions(classGroupOption);
        }
      })
      .catch((error) => {});
  };

  const setDatesOfWeek = (theDate) => {
    const week = datesOfWeek(theDate);

    setStartDate(week[0]);
    setTermDate(week[week.length - 1]);

    return week;
  };

  const stringFirstDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2);
  const stringLastDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear();
  // Logic
  const exportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = exportReportRegisterMeal(resultRegister, selectedClassGroup);

    XLSX.utils.book_append_sheet(wb, ws, "Đăng ký suất ăn");
    XLSX.writeFile(
      wb,
      `Đăng ký suất ăn ${stringFirstDate(startDate)} - ${stringLastDate(
        termDate
      )} ${selectedClassGroup?.group_name}.xlsx`
    );
  };
  // Render
  const RenderMenu = () => {
    if (isLoading) {
      return (
        <div className="d-flex justify-content-center ">
          <Spinner
            animation="border"
            role="status"
            variant="primary"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="visually-hidden">Đang tải thực đơn...</span>
          </Spinner>
        </div>
      );
    }

    return (
      <>
        <TableReportRegisterMeal
          data={resultRegister}
          groupChoice={selectedClassGroup}
        />
      </>
    );
  };

  const CustomInput = ({ value, onClick }) => (
    <Button variant="primary" onClick={onClick}>
      <i className="fa-solid fa-calendar" />
    </Button>
  );

  const handleWeekChangeMenu = (date) => {
    const startDateGMT = startOfWeek(date, { weekStartsOn: 1 });
    const endDateGMT = startOfWeek(endOfWeek(date, { weekStartsOn: 1 }));

    // setWeekInMonth((0 | (startDateGMT.getDate() / 7)) + 1);

    getResultRegisterMeal(startDateGMT, endDateGMT);
    setStartDate(startDateGMT);
    setTermDate(endDateGMT);
  };

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Báo cáo</Breadcrumb.Item>
        <Breadcrumb.Item active>Tổng hợp đăng ký suất ăn</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block d-md-flex align-items-center">
              <Card.Title className="me-2">
                Tổng hợp đăng ký suất ăn {stringFirstDate(startDate)} -{" "}
                {stringLastDate(termDate)}
              </Card.Title>

              <div className="d-flex mb-2 mb-md-0">
                <div className="me-2 d-flex align-items-center">
                  <DatePicker
                    selected={startDate}
                    startDate={startDate}
                    endDate={termDate}
                    onChange={handleWeekChangeMenu}
                    showWeekNumbers
                    dateFormat="yyyy-MM-dd"
                    calendarClassName="custom-calendar"
                    customInput={<CustomInput />}
                    locale={vi}
                  />
                </div>

                <Select
                  options={classGroupOptions}
                  placeholder={<div>Tất cả</div>}
                  getOptionLabel={(option) => option.group_name}
                  getOptionValue={(option) => option.id}
                  value={selectedClassGroup}
                  onChange={(choice) => {
                    setSelectedClassGroups(choice);
                  }}
                />
              </div>
            </div>

            <div className="d-flex flex-wrap">
              <>
                <Button
                  size="sm"
                  variant="success"
                  onClick={() => {
                    exportExcel(resultRegister, selectedClassGroup);
                  }}
                  title="Xuất báo cáo đăng ký suất ăn"
                  className="me-2 mb-2 mb-md-0"
                  disabled={isLoading}
                >
                  <i className="fa-solid fa-file-export" /> Xuất file
                </Button>
              </>
            </div>
          </div>
        </Card.Header>

        <Card.Body>{RenderMenu()}</Card.Body>
      </Card>
    </Container>
  );
}
