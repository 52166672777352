import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ScatterController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Colors,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  ScatterController,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Colors
);

const GrowthChartWeight = ({ dataChart, listChildGrowth }) => {
  const linearInterpolation = (data, x) => {
    for (let i = 0; i < data.length - 1; i++) {
      const x1 = data[i].x;
      const y1 = data[i].y;
      const x2 = data[i + 1].x;
      const y2 = data[i + 1].y;

      if (x >= x1 && x <= x2) {
        return parseFloat((y1 + ((x - x1) * (y2 - y1)) / (x2 - x1)).toFixed(1));
      }
    }
    return null;
  };

  const handleSpot = (att) => {
    const arr = [];

    for (let x = 0; x <= 66; x++) {
      const y = linearInterpolation(
        dataChart?.map((e) => {
          return { x: e.Month, y: e[att] };
        }),
        x
      );
      arr.push({ x, y });
    }

    return arr;
  };

  const updateWeightBasedOnMonth = (continuousMonths) => {
    return continuousMonths.map((month) => {
      const child = listChildGrowth?.find(
        (child) => Number(child.month) === Number(month)
      );
      return {
        month,
        weight: child ? child.weight : null,
      };
    });
  };

  const data = {
    // labels: months,
    labels: handleSpot("WSD2pos")?.map((e) => e.x) || [],

    datasets: [
      {
        label: "",
        type: "scatter",
        data: updateWeightBasedOnMonth(
          handleSpot("WSD2pos")?.map((e) => e.x) || []
        )?.map((child) => child.weight),
        borderColor: "#000",
        backgroundColor: "#000",
        showLine: false,
        pointRadius: 3,
      },

      {
        label: "Suy dinh dưỡng, thể nhẹ cân",
        // data: WSD2neg,
        data: handleSpot("WSD2neg")?.map((e) => e.y) || [],
        borderWidth: 1,

        borderColor: "#6c757d",
        backgroundColor: "#fecdd3",
        fill: true,
        pointRadius: 0,

        tension: 0.4,
      },
      // {
      //   label: "Cân nặng 0 SD (kg)",
      //   // data: WSD0,
      //   data: handleSpot("WSD0")?.map((e) => e.y),

      //   borderColor: "#6c757d",
      //   backgroundColor: "#a5f3fc",
      //   pointRadius: 0,
      //   fill: true,
      //   pointRadius: 0,
      //   tension: 0.7,
      // },
      {
        label: "Bình thường",
        // data: WSD2pos,
        data: handleSpot("WSD2pos")?.map((e) => e.y) || [],
        borderWidth: 1,

        borderColor: "#6c757d",
        backgroundColor: "#a5f3fc",
        fill: true,
        pointRadius: 0,
        tension: 0.5,
      },
      {
        label: "Béo phì",
        // data: HSD2neg,
        data: handleSpot("WSD2pos")?.map((e) => 30) || [],
        type: "line",
        // borderColor: "red",
        backgroundColor: "#fef3c7",
        borderWidth: 1,

        fill: true,
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "black",
          filter: (legendItem) => {
            return legendItem.text !== "";
          },
        },
        // title: {
        //   display: true,
        //   text: "Biểu đồ Tăng trưởng cân nặng của trẻ",
        // },
      },
      title: {
        display: true,
        text: "Biểu đồ tăng trưởng cân nặng của trẻ",
        color: "#000",
        font: {
          size: 16,
          weight: "normal",
        },
        padding: {
          top: 10,
          bottom: 10,
        },
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            return `Tháng: ${tooltipItems[0].label}`;
          },
          label: function (tooltipItem) {
            const weight = tooltipItem.raw;
            return `Cân nặng: ${weight} kg`;
          },
        },
      },
    },
    hover: {
      mode: "nearest",
      intersect: false,
    },
    scales: {
      y: {
        type: "linear",
        // beginAtZero: false,
        title: {
          display: true,
          text: "Cân nặng (kg)",
        },
        ticks: {
          stepSize: 2,
          max: 30,
        },
      },

      x: {
        type: "linear",
        title: {
          display: true,
          text: "Tháng tuổi (tháng)",
        },
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <div
      style={{ minHeight: "300px", position: "relative", maxHeight: "300px" }}
    >
      <Line data={data} options={options} />
    </div>
  );
};

export default GrowthChartWeight;
