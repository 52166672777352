import React, { Component } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import moment from "moment/moment";
import { toast } from "react-toastify";
import api from "../../../helper/axiosInstance";
import Check from "../other/Check";
class AchievementModalEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      other: [
        {
          key: this.props.data?.achievement,
          value: this.props.data?.description,
          date: this.props.data?.achieved_date,
        },
      ],
    };
  }
  handleChangeOther = (index, field, value) => {
    if (field === "date") {
      value = new Date(value) / 1000;
    }
    const { other } = this.state;
    const newList = [...other];
    newList[index][field] = value;
    this.setState({ other: newList });
  };

  updateAchievementGrowth = () => {
    let data = {
      achievement: this.state.other[0]?.key,
      description: this.state.other[0]?.value,
      achieved_date: parseInt(this.state.other[0]?.date),
    };

    api
      .put(`achievement-update?id=${this.props?.data?.id}`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Cập thành công", { type: "success" });
        return this.props.onSubmit();
      })
      .catch((error) => {
        this.setState({ errors: error.response.data.messages });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.updateAchievementGrowth();
  };
  submitFormById = (e) => {
    const form = document.getElementById("formAchievement");
    if (form) {
      this.handleSubmit(e);
      // form.submit();
    } else {
      console.error("Form không tồn tại");
    }
  };

  render() {
    return (
      <>
        <Modal show={this.props.show} onHide={this.props?.onHide} size={"lg"}>
          <Modal.Header closeButton>
            <Modal.Title>Thành tích</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.submitFormById} id="formAchievement">
            <Modal.Body>
              {this.state.other?.map((criteria, index) => (
                <Row key={index}>
                  <Col md={4}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="text"
                        name="key"
                        value={criteria.key}
                        required
                        onChange={(e) => {
                          delete this.state.errors["achievement"];
                          this.handleChangeOther(index, "key", e.target.value);
                        }}
                        placeholder="Nhập thành tích"
                      />
                      <Form.Label>Thành tích</Form.Label>
                      <span className="text-form-err">
                        {this.state.errors?.achievement}
                      </span>
                    </Form.Floating>
                  </Col>
                  <Col md={4}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="text"
                        name="value"
                        value={criteria.value}
                        required
                        onChange={(e) => {
                          this.handleChangeOther(
                            index,
                            "value",
                            e.target.value
                          );
                          delete this.state.errors["description"];
                        }}
                        placeholder="Mô tả"
                      />
                      <Form.Label>Mô tả</Form.Label>

                      <span className="text-form-err">
                        {this.state.errors?.description}
                      </span>
                    </Form.Floating>
                  </Col>
                  <Col md={3}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="date"
                        // required
                        name="date"
                        value={moment(new Date(criteria.date * 1000)).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={(e) => {
                          this.handleChangeOther(index, "date", e.target.value);

                          delete this.state.errors["achieved_date"];
                        }}
                      />
                      <Form.Label>Ngày đạt được</Form.Label>

                      <span className="text-form-err">
                        {this.state.errors?.achieved_date}
                      </span>
                    </Form.Floating>
                  </Col>
                </Row>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary me-2"
                onClick={() => this.props?.onHide()}
              >
                Đóng
              </Button>

              {new Check().permission([178]) ? (
                <Button variant="primary" onClick={this.submitFormById}>
                  {this.props?.data?.id ? "Cập nhật" : "Lưu"}
                </Button>
              ) : (
                <></>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default AchievementModalEdit;
