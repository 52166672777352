import * as XLSX from "xlsx-js-style";
import swal from "sweetalert";
import { Button, Modal, Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { exportDetailVoucherMarket } from "./exportFunctions";
import { dateFormatter } from "../../../utils/helpers";

const DetailVoucherMarket = ({ show, onHide, voucherMarket, school }) => {
  const exportExcel = () => {
    const wb = XLSX.utils.book_new();

    const ws = exportDetailVoucherMarket(voucherMarket.ingredents);
    XLSX.utils.book_append_sheet(wb, ws, "Danh sách đi chợ");

    XLSX.writeFile(
      wb,
      `Danh sách đi chợ (${dateFormatter(
        new Date(voucherMarket.vou_date * 1000)
      )}).xlsx`
    );
  };

  const handleXportMarketList = () => {
    swal({
      title: `Bạn muốn xuất phiếu đi chợ`,
      text: `Ngày ${dateFormatter(new Date(voucherMarket.vou_date * 1000))}`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        exportExcel();
      }
    });
  };

  let totalMarketMoney = 0;
  let totalStoreMoney = 0;

  return (
    <Modal show={show} onHide={() => onHide()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Chi tiết phiếu đi chợ</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div style={{ overflowX: "scroll" }}>
          <Table striped bordered style={{ minWidth: 999 }}>
            <thead>
              <tr>
                <th colSpan="8">
                  {school.school_name?.toUpperCase()}
                  <Button
                    className="float-end"
                    variant="success"
                    size="sm"
                    onClick={() => handleXportMarketList()}
                  >
                    Xuất file <i className="fa-solid fa-file-export"></i>
                  </Button>
                </th>
              </tr>
              <tr className="text-center">
                <th colSpan="8">
                  Tổng hợp định lượng đi chợ ngày{" "}
                  {dateFormatter(new Date(voucherMarket.vou_date * 1000))}
                </th>
              </tr>
              <tr className="text-center">
                <th>STT</th>
                <th>NGUYÊN LIỆU</th>
                <th>NHÀ CUNG CẤP</th>
                <th>ĐƠN VỊ TÍNH</th>
                <th>ĐƠN GIÁ</th>
                <th>SỐ LƯỢNG</th>
                <th>THÀNH TIỀN DỰ KIẾN</th>
                <th>GHI CHÚ</th>
              </tr>
            </thead>
            <tbody>
              {voucherMarket.ingredents.filter(
                (ingredientItem) => ingredientItem.ingredent_type === 1
              ).length !== 0 ? (
                <>
                  {voucherMarket.ingredents
                    .filter(
                      (ingredientItem) => ingredientItem.ingredent_type === 1
                    )
                    .map((ingredientItem, ingredientIndex) => {
                      const ingredientItemPrice = Math.round(
                        Number(ingredientItem.ing_quantity).toFixed(4) *
                          Number(ingredientItem.ing_price)
                      );
                      totalMarketMoney += ingredientItemPrice;

                      return (
                        <tr key={ingredientItem.id}>
                          <td className="text-center">{ingredientIndex + 1}</td>
                          <td>{ingredientItem.ingredent_name}</td>
                          <td>{ingredientItem.supplier_name}</td>
                          <td>{ingredientItem.unit_market_name}</td>
                          <td className="text-end">
                            <CurrencyFormat
                              value={ingredientItem.ing_price}
                              displayType={"text"}
                              thousandSeparator={true}
                              isNumericString
                            />
                          </td>
                          <td className="text-end">
                            {ingredientItem.ing_quantity}
                          </td>
                          <td className="text-end">
                            <CurrencyFormat
                              value={ingredientItemPrice}
                              displayType={"text"}
                              thousandSeparator={true}
                              isNumericString
                            />
                          </td>
                          <td>{ingredientItem.vou_notes}</td>
                        </tr>
                      );
                    })}

                  <tr>
                    <th></th>
                    <th className="text-center">TỔNG CỘNG</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className="text-end">
                      <CurrencyFormat
                        value={totalMarketMoney}
                        thousandSeparator={true}
                        allowNegative={false}
                        displayType="text"
                      />
                    </th>
                    <th></th>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    Không có hàng chợ
                  </td>
                </tr>
              )}

              {voucherMarket.ingredents.filter(
                (ingredientItem) => ingredientItem.ingredent_type === 2
              ).length !== 0 ? (
                <>
                  {voucherMarket.ingredents
                    .filter(
                      (ingredientItem) => ingredientItem.ingredent_type === 2
                    )
                    .map((ingredientItem, ingredientIndex) => {
                      const ingredientItemPrice = Math.round(
                        Number(ingredientItem.ing_quantity).toFixed(4) *
                          Number(ingredientItem.ing_price)
                      );

                      totalStoreMoney += ingredientItemPrice;

                      return (
                        <tr key={ingredientItem.id}>
                          <td className="text-center">{ingredientIndex + 1}</td>
                          <td>{ingredientItem.ingredent_name}</td>
                          <td>{ingredientItem.supplier_name}</td>
                          <td>{ingredientItem.unit_market_name}</td>
                          <td className="text-end">
                            <CurrencyFormat
                              value={ingredientItem.ing_price}
                              displayType={"text"}
                              thousandSeparator={true}
                              isNumericString
                            />
                          </td>
                          <td className="text-end">
                            {ingredientItem.ing_quantity}
                          </td>
                          <td className="text-end">
                            <CurrencyFormat
                              value={ingredientItemPrice}
                              displayType={"text"}
                              thousandSeparator={true}
                              isNumericString
                            />
                          </td>
                          <td>{ingredientItem.vou_notes}</td>
                        </tr>
                      );
                    })}

                  <tr>
                    <th></th>
                    <th className="text-center">TỔNG CỘNG</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className="text-end">
                      <CurrencyFormat
                        value={totalStoreMoney}
                        thousandSeparator={true}
                        allowNegative={false}
                        displayType="text"
                      />
                    </th>
                    <th></th>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    Không có hàng kho
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <Table
          striped
          bordered
          style={{ minWidth: 999 }}
          id="market-list-table"
          className="d-none"
        >
          <thead>
            <tr>
              <th>{school.school_name?.toUpperCase()}</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr className="text-center">
              <th>
                Tổng hợp định lượng đi chợ ngày{" "}
                {dateFormatter(new Date(voucherMarket.vou_date * 1000))}
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr className="text-center">
              <th>STT</th>
              <th>NGUYÊN LIỆU</th>
              <th>NHÀ CUNG CẤP</th>
              <th>ĐƠN VỊ TÍNH</th>
              <th>ĐƠN GIÁ</th>
              <th>SỐ LƯỢNG</th>
              <th>THÀNH TIỀN DỰ KIẾN</th>
              <th>GHI CHÚ</th>
            </tr>
          </thead>
          <tbody>
            {voucherMarket.ingredents.filter(
              (ingredientItem) => ingredientItem.ingredent_type === 1
            ).length !== 0 ? (
              <>
                {voucherMarket.ingredents
                  .filter(
                    (ingredientItem) => ingredientItem.ingredent_type === 1
                  )
                  .map((ingredientItem, ingredientIndex) => {
                    const ingredientItemPrice = Math.round(
                      Number(ingredientItem.ing_quantity).toFixed(4) *
                        Number(ingredientItem.ing_price)
                    );

                    return (
                      <tr key={ingredientItem.id}>
                        <td>{ingredientIndex + 1}</td>
                        <td>{ingredientItem.ingredent_name}</td>
                        <td>{ingredientItem.supplier_name}</td>
                        <td>{ingredientItem.unit_market_name}</td>
                        <td className="text-end">
                          <CurrencyFormat
                            value={ingredientItem.ing_price}
                            displayType={"text"}
                            thousandSeparator={true}
                            isNumericString
                          />
                        </td>
                        <td>{ingredientItem.ing_quantity}</td>
                        <td>
                          <CurrencyFormat
                            value={ingredientItemPrice}
                            displayType={"text"}
                            thousandSeparator={true}
                            isNumericString
                          />
                        </td>
                        <td>{ingredientItem.vou_notes ?? "empty"}</td>
                      </tr>
                    );
                  })}

                <tr>
                  <th>empty</th>
                  <th className="text-center">TỔNG CỘNG</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th className="text-end">
                    <CurrencyFormat
                      value={totalMarketMoney}
                      thousandSeparator={true}
                      allowNegative={false}
                      displayType="text"
                    />
                  </th>
                  <th>empty</th>
                </tr>
              </>
            ) : (
              <tr>
                <td className="text-center">Không có hàng chợ</td>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
              </tr>
            )}

            {voucherMarket.ingredents.filter(
              (ingredientItem) => ingredientItem.ingredent_type === 2
            ).length !== 0 ? (
              <>
                {voucherMarket.ingredents
                  .filter(
                    (ingredientItem) => ingredientItem.ingredent_type === 2
                  )
                  .map((ingredientItem, ingredientIndex) => {
                    const ingredientItemPrice = Math.round(
                      Number(ingredientItem.ing_quantity).toFixed(4) *
                        Number(ingredientItem.ing_price)
                    );

                    return (
                      <tr key={ingredientItem.id}>
                        <td>{ingredientIndex + 1}</td>
                        <td>{ingredientItem.ingredent_name}</td>
                        <td>{ingredientItem.supplier_name}</td>
                        <td>{ingredientItem.unit_market_name}</td>
                        <td>
                          <CurrencyFormat
                            value={ingredientItem.ing_price}
                            displayType={"text"}
                            thousandSeparator={true}
                            isNumericString
                          />
                        </td>
                        <td>{ingredientItem.ing_quantity}</td>
                        <td>
                          <CurrencyFormat
                            value={ingredientItemPrice}
                            displayType={"text"}
                            thousandSeparator={true}
                            isNumericString
                          />
                        </td>
                        <td>{ingredientItem.vou_notes ?? "empty"}</td>
                      </tr>
                    );
                  })}

                <tr>
                  <th>empty</th>
                  <th className="text-center">TỔNG CỘNG</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th className="text-end">
                    <CurrencyFormat
                      value={totalStoreMoney}
                      thousandSeparator={true}
                      allowNegative={false}
                      displayType="text"
                    />
                  </th>
                  <th>empty</th>
                </tr>
              </>
            ) : (
              <tr>
                <td className="text-center">Không có hàng kho</td>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
                <td>empty</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={() => onHide()}>
          <i className="fa-solid fa-times" /> Đóng
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DetailVoucherMarket;
