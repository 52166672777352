import React, { Component } from "react";
import {
  Button,
  Breadcrumb,
  Card,
  Container,
  Table,
  Tabs,
  Tab,
  Modal,
} from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import DatePicker, { registerLocale } from "react-datepicker";
import vi from "date-fns/locale/vi";
import api from "../../../helper/axiosInstance";
import DatePickerCustom from "../../uiElements/DatePickerCustom";
registerLocale("vi", vi);

class SummaryStoreMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classifies: [],
      isLoading: false,

      selectedTab: 2,

      storeMaterialList: [],

      startDate: new Date(),
      endDate: new Date(),
      tmpStartDate: new Date(),
      tmpEndDate: new Date(),

      show: false,
    };
  }

  async componentDidMount() {
    document.title = "Tổng hợp hàng Kho - Vật tư";

    const date = new Date();

    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    end.setHours(23, 59, 59, 0);

    this.setState({
      startDate: start,
      endDate: end,

      tmpStartDate: start,
      tmpEndDate: end,
    });

    this.getlistinventory(2, 100, start, end);
  }

  getlistinventory = (type, limit, startDate, endDate) => {
    let start_ = parseInt(startDate?.setHours(0, 0, 0) / 1000);
    let end_ = parseInt(endDate?.setHours(23, 59, 59) / 1000);
    api
      .get(`store-show?start_at=${start_}&term_at=${end_}`)
      .then((res) => {
        if (res.data.errCode === 0) {
          this.setState({
            storeMaterialList: res.data.ingredents?.filter(
              (e) => e.id_ing_type == type
            ),
          });
        }
      })
      .catch((err) => {});
  };

  stringDate = (dateNumber) => {
    const date = new Date(dateNumber * 1000);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );
  };

  groupArrayBy = (arr, key) => {
    return [
      ...arr
        .reduce(
          (acc, o) => acc.set(o[key], (acc.get(o[key]) || []).concat(o)),
          new Map()
        )
        .values(),
    ];
  };
  sumKey = (key, data) => {
    let sum = 0;
    data?.map((e) => {
      sum += e[key];
      return e;
    });

    return sum;
  };

  RenderStoreMaterialIngredientPrice() {
    return (
      <div>
        <Table striped>
          <thead className="text-center">
            <tr>
              <th>STT</th>
              <th className="text-start">Nguyên liệu</th>
              <th className="text-start">Đơn vị tính</th>
              <th className="text-end">Số lượng mua</th>
              <th className="text-end">Tổng tiền mua</th>
              <th className="text-end">Số lượng xuất</th>
            </tr>
          </thead>
          <tbody>
            {this.state.storeMaterialList?.length !== 0 ? (
              <>
                {this.state.storeMaterialList.map((item, index) => {
                  return (
                    <tr key={`material-${index}`}>
                      <td className="text-center">{index + 1}</td>
                      <td>{item.ingredent_name}</td>
                      <td>{item.unit_name}</td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={item.total_qty_import?.toFixed(2)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={item.total_money_import?.toFixed(0)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={item.total_qty_export?.toFixed(2)}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <th></th>
                  <th className="text-center">Tổng cộng</th>
                  <th></th>
                  <th className="text-end">
                    <CurrencyFormat
                      value={this.sumKey(
                        "total_qty_import",
                        this.state.storeMaterialList
                      )?.toFixed(2)}
                      thousandSeparator={true}
                      allowNegative={false}
                      isNumericString
                      displayType="text"
                    />
                  </th>
                  <th className="text-end">
                    <CurrencyFormat
                      value={this.sumKey(
                        "total_money_import",
                        this.state.storeMaterialList
                      )?.toFixed(0)}
                      thousandSeparator={true}
                      allowNegative={false}
                      isNumericString
                      displayType="text"
                    />
                  </th>
                  <th className="text-end">
                    <CurrencyFormat
                      value={this.sumKey(
                        "total_qty_export",
                        this.state.storeMaterialList
                      )?.toFixed(2)}
                      thousandSeparator={true}
                      allowNegative={false}
                      isNumericString
                      displayType="text"
                    />
                  </th>
                </tr>
              </>
            ) : (
              <tr className="text-center">
                <td colSpan="7">Không có dữ liệu</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <Container fluid>
        <Breadcrumb>
          <Breadcrumb.Item active>Kho hàng</Breadcrumb.Item>
          <Breadcrumb.Item active>Tổng hợp hàng Kho - Vật tư</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header className="d-block d-md-flex">
            <Card.Title>
              <i className="fas fa-list me-1" />
              Tổng hợp hàng Kho - Vật tư{" "}
              {this.stringDate(this.state.startDate / 1000)} -{" "}
              {this.stringDate(this.state.endDate / 1000)}
            </Card.Title>

            <Button
              size="sm"
              className="ms-2"
              onClick={() => {
                this.setState({ show: true });
              }}
            >
              <i className="fa-solid fa-calendar-days" />
            </Button>
          </Card.Header>

          <Card.Body>
            {!this.state.isLoading ? (
              <>
                <Tabs
                  unmountOnExit={true}
                  transition={false}
                  onSelect={(key) => {
                    this.getlistinventory(
                      Number(key),
                      100,
                      this.state.startDate,
                      this.state.endDate
                    );
                    this.setState({
                      selectedTab: Number(key),
                    });
                  }}
                  activeKey={this.state.selectedTab}
                  className="mb-3"
                >
                  <Tab key={2} title="Hàng kho" eventKey={2}>
                    {this.RenderStoreMaterialIngredientPrice()}
                  </Tab>

                  <Tab key={3} title="Hàng vật tư" eventKey={3}>
                    {this.RenderStoreMaterialIngredientPrice()}
                  </Tab>
                </Tabs>
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center mt-5">
                <div
                  className="spinner-border text-primary"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                >
                  <span className="sr-only">Đang tải tổng hợp kho/vật tư</span>
                </div>
              </div>
            )}
          </Card.Body>
        </Card>

        <Modal show={this.state.show} size={"sm"}>
          <Modal.Header>
            <Modal.Title>Chọn thời gian</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <DatePickerCustom
                size={{ height: "600px" }}
                onChange={(update) => {
                  this.setState({
                    tmpStartDate: update[0],
                    tmpEndDate: update[1],
                  });
                }}
                startDate={this.state.tmpStartDate}
                endDate={this.state.tmpEndDate}
                calendarStartDay={1}
                selectsRange
                inline
              />
            </center>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                this.setState({ show: false });
              }}
            >
              Hủy
            </Button>
            <Button
              size="sm"
              disabled={
                this.state.tmpStartDate === null ||
                this.state.tmpEndDate === null
              }
              onClick={() => {
                const start = this.state.tmpStartDate;
                start.setHours(0, 0, 0, 0);

                const end = this.state.tmpEndDate;
                end.setHours(23, 59, 59, 0);

                this.setState({
                  show: false,
                  startDate: start,
                  endDate: end,
                });

                this.getlistinventory(this.state.selectedTab, 100, start, end);
              }}
            >
              Lưu
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default SummaryStoreMaterial;
