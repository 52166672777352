import { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../../other/Check";
import DataTables from "../../datatable/DataTables";
import ClassBySubjectDetailAdd from "./ClassBySubjectDetailAdd";
import ClassBySubjectDetailEdit from "./ClassBySubjectDetailEdit";
import { dateFormatter } from "../../utils/helpers";
import swal from "sweetalert";
import CurrencyFormat from "react-currency-format";
import api from "../../../../helper/axiosInstance";

const ClassBySubjectDetail = ({
  show,
  onHide,
  selectedStudyGroupId,
  selectedStudyGroupName,
  subject,
}) => {
  const [listAllStudents, setListAllStudents] = useState([]);
  const [listClassesBySubjectDetails, setListClassesBySubjectDetails] =
    useState([]);
  const [addModalShow, setAddModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [classesBySubjectDetailSelected, setClassesBySubjectDetailSelected] =
    useState();
  const [listStudentIdsInGroupSelected, setListStudentIdsInGroupSelected] =
    useState([]);

  useEffect(() => {
    getAllStudents();
    getClassBySubjectDetail();
  }, []);

  //#region API
  const getClassBySubjectDetail = async () => {
    await api
      .get(`/class-subject-detail-shows?id=${selectedStudyGroupId}`)
      .then((res) => {
        setListClassesBySubjectDetails(
          res.data.data.classesBySubjectDetails.filter(
            (element) => element.id_study_group === selectedStudyGroupId
          )
        );
      })
      .catch((err) => {
        toast("Đã có lỗi xảy ra vui lòng thử lại", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const getAllStudents = () => {
    //TODO: Danh sách học sinh cần filter theo năm không?
    api.get(`/student-shows`).then((res) => {
      setListAllStudents(res.data.data.students);
    });
  };

  const deleteClassBySubjectDetail = (idSelected) => {
    api
      .delete(`/class-subject-detail-delete?id=${idSelected}`)
      .then((res) => {
        toast(res.data.messages, {
          type: "success",
          autoClose: 1000,
        });
        getClassBySubjectDetail();
      })
      .catch((err) => {
        const msg = err.response.data.messages ?? "Hệ thống xảy ra lỗi";
        toast(msg, {
          type: "error",
          autoClose: 1000,
        });
      });
  };
  //#endregion API

  //#region Logic
  const findStudentNameById = (studentId) => {
    const student = listAllStudents.find((student) => student.id === studentId);
    return student?.student_name ?? studentId;
  };

  const handleShowAddModal = () => {
    setAddModalShow(true);
    setListStudentIdsInGroupSelected(
      listClassesBySubjectDetails.map((element) => element.id_student)
    );
  };
  const handleCloseAddModal = (isReload) => {
    setAddModalShow(false);
    if (isReload) {
      getClassBySubjectDetail();
    }
  };
  const handleShowEditModal = (item) => {
    setClassesBySubjectDetailSelected(item);
    setEditModalShow(true);
  };
  const handleCloseEditModal = (isReload) => {
    setEditModalShow(false);
    if (isReload) {
      getClassBySubjectDetail();
    }
  };

  const handleDeleteStudyGroup = (studyGroup) => {
    swal({
      title: "Bạn muốn xóa học sinh khỏi nhóm?",
      text: `"${findStudentNameById(studyGroup.id_student)}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        deleteClassBySubjectDetail(studyGroup.id);
      }
    });
  };

  const dataTableColumns = [
    {
      name: "Học sinh",
      selector: (row) => findStudentNameById(row.id_student),
      sortable: true,
      wrap: true,
    },
    {
      name: "Học phí (₫)",
      selector: (row) => row.tuition_fee,
      right: true,
      sortable: true,
      cell: (row) => (
        <CurrencyFormat
          thousandSeparator={true}
          displayType="text"
          value={parseInt(row.tuition_fee, 10)}
        />
      ),
    },
    {
      name: "Giảm giá (đ)",
      selector: (row) => row.reduce_money,
      sortable: true,
      right: true,
      cell: (row) => (
        <CurrencyFormat
          thousandSeparator={true}
          displayType="text"
          value={row.reduce_money ? parseInt(row.reduce_money, 10) : ""}
        />
      ),
    },
    {
      name: "Cập nhật",
      selector: (row) => Number(row.updated_at),
      sortable: true,
      cell: (row) => dateFormatter(new Date(row.updated_at * 1000)),
      width: "8rem",
    },
    {
      name: "Phương thức",
      center: true,
      cell: (row) => (
        <>
          {new Check().permission([146]) ? (
            <>
              <Button
                size="sm"
                variant="warning me-2"
                type="button"
                onClick={() => handleShowEditModal(row)}
                title="Chi tiết học phí"
              >
                <i className="fa-solid fa-pencil"></i>
              </Button>
              <Button
                size="sm"
                variant="danger"
                type="button"
                onClick={() => handleDeleteStudyGroup(row)}
              >
                <i className="fas fa-trash"></i>
              </Button>
            </>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Modal keyboard={false} show={show} onHide={() => onHide()} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa fa-graduation-cap me-2"></i>
            Nhóm {selectedStudyGroupName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-4">
            <Card.Header>
              <Card.Title>
                <i className="fas fa-list me-1" /> Danh sách học sinh
                {new Check().permission([146]) ? (
                  <Button
                    variant="success"
                    size="sm"
                    className="float-end ms-2"
                    onClick={() => handleShowAddModal()}
                  >
                    <i className="fa-solid fa-plus" /> Thêm học sinh
                  </Button>
                ) : null}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <DataTables
                data={listClassesBySubjectDetails}
                columns={dataTableColumns}
              />
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>

      {addModalShow && (
        <ClassBySubjectDetailAdd
          show={addModalShow}
          onHide={handleCloseAddModal}
          listStudents={listAllStudents}
          studyGroupId={selectedStudyGroupId}
          subject={subject}
          listStudentIdsInGroup={listStudentIdsInGroupSelected}
        />
      )}

      {editModalShow && (
        <ClassBySubjectDetailEdit
          show={editModalShow}
          onHide={handleCloseEditModal}
          listStudents={listAllStudents}
          classBySubjectDetail={classesBySubjectDetailSelected}
          subject={subject}
        />
      )}
    </>
  );
  //#endregion Render
};
export default ClassBySubjectDetail;
