export const capitalizeEachWord = (sentence) => {
  return sentence
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const roundDownToDecimals = (number, decimals) => {
  const factor = Math.pow(10, decimals);
  return Math.floor(number * factor) / factor;
};
