import React from "react";
import DatePicker from "react-datepicker";
import { Button } from "react-bootstrap";
import { vi } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";

const CustomInput = ({ value, onClick }) => (
  <Button variant="primary" onClick={onClick}>
    <i className="fa-solid fa-calendar" />
  </Button>
);

function DatePickerCustom(props) {
  return (
    <DatePicker
      // calendarClassName="custom-calendar"
      customInput={<CustomInput />}
      locale={vi}
      {...props}
    />
  );
}

export default DatePickerCustom;
