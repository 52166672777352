import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import Check from "../other/Check";
import api from "../../../helper/axiosInstance";

const errorTextStyle = {
  fontSize: 13,
  color: "red",
  marginTop: 6,
};

const SubjectEdit = ({ show, onHide, selectedSubjectId }) => {
  const [subjectDetail, setSubjectDetail] = useState({
    subject_code: "",
    subject_name: "",
    subject_price: "",
    subject_description: "",
    type: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    subject_code: "",
    subject_name: "",
    subject_price: "",
    subject_description: "",
    type: "",
  });

  const typeOptions = [
    {
      type: "DEFAULT",
      text: "Chính khóa",
    },
    {
      type: "EXTRA",
      text: "Học thêm",
    },
  ];

  useEffect(() => {
    getSubjectDetail();
  }, [selectedSubjectId]);

  // API
  const getSubjectDetail = async (id = selectedSubjectId) => {
    await api
      .get(`/subject-show?id=${id}`)
      .then((res) => {
        const subject = res.data?.data;
        console.log("subject>>", subject);

        setSubjectDetail({
          id: subject.id,
          subject_code: subject.subject_code,
          subject_name: subject.subject_name,
          subject_price: parseInt(subject.subject_price, 10),
          subject_description: subject.subject_description,
          type: subject.type,
        });
      })
      .catch((err) => {
        toast(err.message, {
          type: "error",
        });
      });
  };

  const updateSubject = async () => {
    setErrorMessage({
      subject_code: "",
      subject_name: "",
      subject_price: "",
      subject_description: "",
      type: "",
    });

    await api({
      method: "put",
      url: "subject-update",
      data: subjectDetail,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        toast(res.data.messages, {
          type: "success",
          autoClose: 1000,
        });
        onHide(true);
      })
      .catch((err) => {
        toast("Cập nhật thất bại", {
          type: "error",
          autoClose: 1000,
        });
        setErrorMessage(err.response.data.messages);
      });
  };
  // API

  const checkInput = () => {
    let hasError = false;
    const errorMessage = {
      subject_code: "",
      subject_name: "",
      subject_price: "",
      subject_description: "",
      type: "",
    };

    if (subjectDetail.subject_code.length > 20) {
      hasError = true;
      errorMessage.subject_code = "Không quá 20 ký tự";
    }

    if (subjectDetail.subject_name.length === 0) {
      hasError = true;
      errorMessage.subject_name = "Không được để trống";
    }
    if (subjectDetail.subject_name.length > 100) {
      hasError = true;
      errorMessage.subject_name = "Không quá 100 ký tự";
    }
    if (subjectDetail.type !== "DEFAULT") {
      if (subjectDetail.subject_price.length === 0) {
        hasError = true;
        errorMessage.subject_price = "Không được để trống";
      } else {
        try {
          const num = Number(subjectDetail.subject_price);
          if (
            num < 0 ||
            num > 999999999 ||
            isNaN(num) ||
            !Number.isInteger(num)
          ) {
            hasError = true;
            errorMessage.subject_price =
              "Vui lòng nhập số nguyên trong khoảng 0 đến 999999999";
          }
        } catch (_) {
          hasError = true;
          errorMessage.subject_price =
            "Vui lòng nhập số nguyên trong khoảng 0 đến 999999999";
        }
      }
    }

    if (subjectDetail.type.length === 0) {
      hasError = true;
      errorMessage.type = "Không được để trống";
    }

    if (hasError) {
      setErrorMessage(errorMessage);
    } else {
      updateSubject();
    }
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide()}
    >
      <Modal.Header closeButton>
        <Modal.Title>Chi tiết môn học</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col xs={6}>
            <Form.Floating className="mb-3">
              <Form.Select
                value={subjectDetail.type}
                onChange={(e) => {
                  setSubjectDetail({
                    ...subjectDetail,
                    type: e.target.value,
                    subject_price:
                      subjectDetail.subject_price >= 0
                        ? subjectDetail.subject_price
                        : 0,
                  });
                }}
                required
              >
                {typeOptions.map((item) => {
                  return (
                    <option key={item.type} value={item.type}>
                      {item.text}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Label>
                Loại<i className="text-danger">*</i>
              </Form.Label>

              {errorMessage.type && (
                <p style={errorTextStyle}>{errorMessage.type}</p>
              )}
            </Form.Floating>
          </Col>

          <Col xs={6}>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                value={subjectDetail.subject_code}
                onChange={(e) => {
                  setSubjectDetail({
                    ...subjectDetail,
                    subject_code: e.target.value,
                  });
                }}
                placeholder="Mã môn học"
                required
              />
              <Form.Label>Mã môn học</Form.Label>

              {errorMessage.subject_code && (
                <p style={errorTextStyle}>{errorMessage.subject_code}</p>
              )}
            </Form.Floating>
          </Col>

          <Col xs={12}>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                value={subjectDetail.subject_name}
                onChange={(e) => {
                  setSubjectDetail({
                    ...subjectDetail,
                    subject_name: e.target.value,
                  });
                }}
                placeholder="Tên môn học"
                required
              />
              <Form.Label>
                Tên môn học
                <i className="text-danger">*</i>
              </Form.Label>

              {errorMessage.subject_name && (
                <p style={errorTextStyle}>{errorMessage.subject_name}</p>
              )}
            </Form.Floating>
          </Col>

          {subjectDetail.type === "DEFAULT" ? null : (
            <Col xs={12}>
              <Form.Floating className="mb-3">
                <CurrencyFormat
                  className="form-control"
                  thousandSeparator={true}
                  suffix=" ₫"
                  displayType="input"
                  value={subjectDetail.subject_price}
                  onValueChange={(e) => {
                    setSubjectDetail({
                      ...subjectDetail,
                      subject_price: e.value,
                    });
                  }}
                  placeholder="Học phí"
                  required
                />
                <Form.Label>
                  Học phí
                  <i className="text-danger">*</i>
                </Form.Label>

                {errorMessage.subject_price && (
                  <p style={errorTextStyle}>{errorMessage.subject_price}</p>
                )}
              </Form.Floating>
            </Col>
          )}

          <Col xs={12}>
            <Form.Floating className="mb-3">
              <Form.Control
                value={subjectDetail.subject_description}
                onChange={(e) => {
                  setSubjectDetail({
                    ...subjectDetail,
                    subject_description: e.target.value,
                  });
                }}
                as="textarea"
                rows={5}
                placeholder="Mô tả môn học"
                style={{ height: "120px" }}
              />
              <Form.Label>Mô tả</Form.Label>

              {errorMessage.subject_description && (
                <p style={errorTextStyle}>{errorMessage.subject_description}</p>
              )}
            </Form.Floating>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onHide()}>
          <i className="fa-solid fa-times"></i> Đóng
        </Button>

        {new Check().permission([141]) ? (
          <Button
            size="sm"
            type="submit"
            variant="success"
            onClick={() => checkInput()}
          >
            <i className="fa-solid fa-check"></i> Cập nhật
          </Button>
        ) : (
          <></>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SubjectEdit;
