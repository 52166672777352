import React, { Component } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Table,
  Modal,
} from "react-bootstrap";
import * as XLSX from "xlsx-js-style";
import swal from "sweetalert";
import api from "../../../../helper/axiosInstance";
import DatePickerCustom from "../../../uiElements/DatePickerCustom";
import {
  IS_ALL_DAY,
  IS_MEAL_CUT,
  IS_STATUS,
  IS_VALID,
} from "../../../../constants";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listStudent: [],
      startDate: 0,
      endDate: 0,
      show: false,
      checkload: true,
      classrooms: [],
      totalStudent: 0,
      totalStudentsGoingToSchool: 0,
      totalStudentOff: 0,
      totalStudentOnValidLeave: 0,
      totalStudentsNotEat: 0,
      totalStudentsEating: 0,
      totalInvalidStudentLeave: 0,
    };
  }

  async componentDidMount() {
    document.title = "Thống kê sĩ số";
    const start = new Date();
    start.setHours(0, 0, 0);
    const end = new Date();
    end.setHours(23, 59, 59);
    this.setState({
      startDate: start,
      endDate: end,
    });
    this.getClassRoom(start, end);
  }

  getDate_ = (data) => {
    let date = new Date(data);
    let result = `${date?.getDate()}/${
      date?.getMonth() + 1
    }/${date?.getFullYear()}`;
    return result;
  };

  getDate = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  getClassRoom = (start, end) => {
    let start_ = parseInt(start.setHours(0, 0, 0) / 1000);
    let end_ = parseInt(end.setHours(23, 59, 59) / 1000);
    api
      .get(`/classroom-shows?start_date=${start_}&end_date=${end_}`)
      .then((res) => {
        this.getlistCutAll(res.data.data?.classrooms, start_, end_);
      })
      .catch((err) => {});
  };

  getlistCutAll = (classrooms, start_date, end_date) => {
    api
      .get(`/report-class?start_date=${start_date}&end_date=${end_date}`)
      .then((res) => {
        const reportClassrooms = res.data.data[0]?.class;

        let totalStudent = 0;
        let totalStudentsGoingToSchool = 0;
        let totalStudentOff = 0;
        let totalStudentOnValidLeave = 0;
        let totalStudentsNotEat = 0;
        let totalStudentsEating = 0;
        let totalInvalidStudentLeave = 0;

        classrooms = classrooms?.map((classroom) => {
          const reportClassroom = reportClassrooms.find(
            (item) => item.id_class === classroom.id
          );

          const countStudent = reportClassroom?.countStudent || 0;
          const countStudentOff = reportClassroom?.countStudentOff || 0;
          const countStudentOnValidLeave =
            reportClassroom?.countStudentOnValidLeave || 0;
          const countStudentsNotEat =
            reportClassroom?.countNotEatAndGoToSchool || 0;

          const countStudentsGoingToSchool = countStudent - countStudentOff;

          const countStudentsEating =
            countStudentsGoingToSchool - countStudentsNotEat;

          const countInvalidStudentLeave =
            countStudentOff - countStudentOnValidLeave;

          totalStudent += countStudent;
          totalStudentsGoingToSchool += countStudentsGoingToSchool;
          totalStudentOff += countStudentOff;
          totalStudentOnValidLeave += countStudentOnValidLeave;
          totalStudentsNotEat += countStudentsNotEat;
          totalStudentsEating += countStudentsEating;
          totalInvalidStudentLeave += countInvalidStudentLeave;

          return {
            ...classroom,
            countStudent,
            countStudentsGoingToSchool,
            countStudentOff,
            countStudentOnValidLeave,
            countStudentsNotEat,
            countStudentsEating,
            countInvalidStudentLeave,
          };
        });

        this.setState({
          classrooms: classrooms,
          totalStudent,
          totalStudentsGoingToSchool,
          totalStudentOff,
          totalStudentOnValidLeave,
          totalStudentsNotEat,
          totalStudentsEating,
          totalInvalidStudentLeave,
          checkload: false,
        });
      })
      .catch((err) => {
        this.setState({
          classrooms: [],
          totalStudent: 0,
          totalStudentsGoingToSchool: 0,
          totalStudentOff: 0,
          totalStudentOnValidLeave: 0,
          totalStudentsNotEat: 0,
          totalStudentsEating: 0,
          totalInvalidStudentLeave: 0,
          checkload: false,
        });
      });
  };

  changeTime = (update) => {
    const startDate = new Date(update.setHours(0, 0, 0));
    const endDate = new Date(update.setHours(23, 59, 59));

    this.setState({
      show: false,
      startDate: startDate,
      endDate: endDate,
      checkload: true,
    });

    this.getClassRoom(startDate, endDate);
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  renderCutMealListTable() {
    return (
      <>
        {this.state.checkload ? (
          this.loading()
        ) : (
          <div style={{ overflow: "auto" }}>
            <Table bordered style={{ borderColor: "#000" }}>
              <thead class>
                <tr>
                  <th className="text-center" colSpan={10}>
                    THỐNG KÊ SĨ SỐ ({this.getDate_(this.state.startDate)})
                  </th>
                </tr>
                <tr>
                  <th
                    className="align-middle text-center bg-light"
                    style={{ backgroundColor: "#f0f0f0" }}
                  >
                    STT
                  </th>
                  <th
                    className="align-middle text-center bg-light"
                    style={{ backgroundColor: "#f0f0f0" }}
                  >
                    Lớp
                  </th>
                  <th
                    className="align-middle text-center bg-light"
                    style={{ backgroundColor: "#f0f0f0" }}
                  >
                    Giáo viên
                  </th>
                  <th
                    className=" align-middle text-center bg-light"
                    style={{ backgroundColor: "#f0f0f0" }}
                  >
                    Tổng DS
                  </th>
                  <th
                    className="align-middle text-center"
                    style={{ backgroundColor: "#d2e7d6" }}
                  >
                    Đi học
                  </th>
                  <th
                    className="align-middle text-center"
                    style={{ backgroundColor: "#d2e7d6" }}
                  >
                    Có ăn
                  </th>
                  <th
                    className="align-middle text-center"
                    style={{ backgroundColor: "#d2e7d6" }}
                  >
                    Không ăn
                  </th>

                  <th
                    className="align-middle text-center"
                    style={{ backgroundColor: "#fbd9d3" }}
                  >
                    Tổng nghỉ
                  </th>
                  <th
                    className=" align-middle text-center"
                    style={{ backgroundColor: "#fbd9d3" }}
                  >
                    Không phép
                  </th>
                  <th
                    className="align-middle text-center"
                    style={{ backgroundColor: "#fbd9d3" }}
                  >
                    Có phép
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.classrooms?.length > 0 ? (
                  this.state.classrooms.map((student, index) => {
                    const {
                      countStudent,
                      countStudentOff,
                      countStudentsNotEat,
                      countStudentsEating,
                      countStudentOnValidLeave,
                      countStudentsGoingToSchool,
                      countInvalidStudentLeave,
                    } = student;

                    return (
                      <tr className="text-center">
                        <td>{index + 1}</td>
                        <td className="text-start">{student.class_name}</td>
                        <td className="text-start">{student.teacher_name}</td>
                        <td>{countStudent}</td>
                        <td>{countStudentsGoingToSchool}</td>
                        <td>{countStudentsEating}</td>
                        <td>{countStudentsNotEat}</td>
                        <td>{countStudentOff}</td>
                        <td>{countInvalidStudentLeave}</td>
                        <td>{countStudentOnValidLeave}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      Không có dữ liệu...
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr className="text-center">
                  <th colSpan="3">Tổng</th>
                  <th>{this.state.totalStudent}</th>
                  <th>{this.state.totalStudentsGoingToSchool}</th>
                  <th>{this.state.totalStudentsEating}</th>
                  <th>{this.state.totalStudentsNotEat}</th>
                  <th>{this.state.totalStudentOff}</th>
                  <th>{this.state.totalInvalidStudentLeave}</th>
                  <th>{this.state.totalStudentOnValidLeave}</th>
                </tr>
              </tfoot>
            </Table>

            <Table id="CutUserTable" className="d-none">
              <thead>
                <tr>
                  <th>
                    THỐNG KÊ SĨ SỐ ({this.getDate_(this.state.startDate)})
                  </th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>

                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                </tr>
                <tr>
                  <th>STT</th>
                  <th>Lớp</th>
                  <th>Giáo viên</th>
                  <th>Tổng DS</th>
                  <th>Đi học</th>
                  <th>Có ăn</th>
                  <th>Không ăn</th>
                  <th>Tổng nghỉ</th>
                  <th>Không phép</th>
                  <th>Có phép</th>
                </tr>
              </thead>
              <tbody>
                {this.state.classrooms?.length > 0 ? (
                  this.state.classrooms.map((student, index) => {
                    const {
                      countStudent,
                      countStudentOff,
                      countStudentsNotEat,
                      countStudentsEating,
                      countStudentOnValidLeave,
                      countStudentsGoingToSchool,
                      countInvalidStudentLeave,
                    } = student;

                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{student.class_name}</td>
                        <td>{student?.teacher_name || " "}</td>
                        <td>{countStudent}</td>
                        <td>{countStudentsGoingToSchool}</td>
                        <td>{countStudentsEating}</td>
                        <td>{countStudentsNotEat}</td>
                        <td>{countStudentOff}</td>
                        <td>{countInvalidStudentLeave}</td>
                        <td>{countStudentOnValidLeave}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>Không có dữ liệu...</td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <th>Tổng</th>
                  <th>empty</th>
                  <th>empty</th>
                  <th>{this.state.totalStudent}</th>
                  <th>{this.state.totalStudentsGoingToSchool}</th>
                  <th>{this.state.totalStudentsEating}</th>
                  <th>{this.state.totalStudentsNotEat}</th>
                  <th>{this.state.totalStudentOff}</th>
                  <th>{this.state.totalInvalidStudentLeave}</th>
                  <th>{this.state.totalStudentOnValidLeave}</th>
                </tr>
              </tfoot>
            </Table>
          </div>
        )}
      </>
    );
  }

  xport = () => {
    const table = document.getElementById("CutUserTable");
    const wb = XLSX.utils.table_to_book(table);
    var ws = wb.Sheets["Sheet1"];

    const colAlpha = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
    ];

    //Title
    ws[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 16,
        bold: true,
      },
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
    };

    //thead
    colAlpha.slice(0, 10).map((alpha, index) => {
      ws[`${alpha}2`].s = {
        fill:
          alpha === "E" || alpha === "F" || alpha === "G"
            ? {
                patternType: "solid",
                fgColor: {
                  theme: 8,
                  tint: 0.3999755851924192,
                  rgb: "d2e7d6",
                },
                bgColor: { indexed: 64 },
              }
            : alpha === "H" || alpha === "I" || alpha === "J"
            ? {
                patternType: "solid",
                fgColor: {
                  theme: 8,
                  tint: 0.3999755851924192,
                  rgb: "fbd9d3",
                },
                bgColor: { indexed: 64 },
              }
            : {
                patternType: "solid",
                fgColor: {
                  theme: 8,
                  tint: 0.3999755851924192,
                  rgb: "f0f0f0",
                },
                bgColor: { indexed: 64 },
              },
        font: {
          name: "Times New Roman",
          sz: 11,
          bold: true,
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
      return alpha;
    });

    //body
    for (let i = 3; i < this.state.classrooms?.length + 4; i++) {
      colAlpha.slice(0, 10).map((alpha) => {
        ws[`${alpha}${i}`].s = {
          font: {
            name: "Times New Roman",
            sz: 11,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: alpha === "B" || alpha === "C" ? "left" : "center",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        return alpha;
      });
    }

    //footer
    for (
      let i = this.state.classrooms?.length + 3;
      i < this.state.classrooms?.length + 4;
      i++
    ) {
      colAlpha.slice(0, 10).map((alpha) => {
        ws[`${alpha}${i}`].s = {
          font: {
            name: "Times New Roman",
            sz: 11,
            bold: true,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        return alpha;
      });
    }

    ws["!cols"] = [
      { wch: 4 },
      { wch: 15 },
      { wch: 25 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
      { wch: 7 },
    ];
    ws["!rows"] = [{ hpt: 30 }];

    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 9 } },

      {
        s: { r: this.state.classrooms?.length + 2, c: 0 },
        e: { r: this.state.classrooms?.length + 2, c: +2 },
      },
    ];
    ws["!merges"] = merge;

    XLSX.writeFile(
      wb,
      `Thống kê sĩ số ${this.getDate_(this.state.startDate)} .xlsx`
    );
  };
  handleExport = () => {
    swal({
      title: `Xác nhận`,
      text: `Xuất danh sách thống kê sĩ số ${this.getDate_(
        this.state.startDate
      )}`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        this.xport();
      }
    });
  };
  render() {
    return (
      <>
        <Container fluid>
          <Breadcrumb>
            <Breadcrumb.Item active>Suất ăn</Breadcrumb.Item>
            <Breadcrumb.Item active>Thống kê sĩ số</Breadcrumb.Item>
          </Breadcrumb>
          <Card>
            <Card.Header>
              <Card.Title>
                <i className="fas fa-list me-1"></i>Thống kê sĩ số{" "}
                {this.state.startDate
                  ? this.getDate_(this.state.startDate)
                  : ""}{" "}
                <Button
                  size="sm"
                  onClick={() => {
                    this.setState({ show: true });
                  }}
                  className="mb-2 mb-md-0"
                >
                  <i className="fa-solid fa-calendar-days" />
                </Button>
                <Button
                  variant="success"
                  size="sm"
                  style={{ marginLeft: "5px", float: "right" }}
                  onClick={() => this.handleExport()}
                  disabled={this.state.classrooms?.length === 0}
                >
                  <i className="fa-solid fa-file-export"></i> Xuất danh sách
                </Button>
              </Card.Title>
            </Card.Header>
            <Card.Body>{this.renderCutMealListTable()}</Card.Body>
          </Card>
        </Container>
        <Modal
          show={this.state.show}
          size={"sm"}
          onHide={() => this.setState({ show: false })}
          keyboard={false}
          backdrop="static"
        >
          <Modal.Header closeButton={this.state.endDate != null}>
            <Modal.Title>Chọn thời gian</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <DatePickerCustom
                selected={this.state.startDate}
                onChange={(date) => this.changeTime(date)}
                inline
              />
            </center>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Report;
