import React, { Component } from "react";
import { Breadcrumb, Button, Card, Container, Table } from "react-bootstrap";
import * as XLSX from "xlsx-js-style";
import swal from "sweetalert";
import Select from "react-select";
import api from "../../../helper/axiosInstance";
import DatePickerCustom from "../../uiElements/DatePickerCustom";
import { IS_STATUS } from "../../../constants";
import { toast } from "react-toastify";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolInfo: {},
      startDate: new Date(),
      endDate: new Date(),
      show: false,
      start_: new Date(),
      end_: new Date(),
      chociceClass: {
        value: 0,
        label: "Tất cả lớp",
      },
      startMonth: new Date(),
      listDate: [],
      listDay: [],
      listStudentByClass: [],
      checkload: false,
      listHoliday: [],

      classrooms: [],
      listcutAll: [],
      listCut: [],
      listcutbyClass: [],
      listCutByIdClass: {},
    };
  }

  async componentDidMount() {
    document.title = "Báo cáo chi tiết sĩ số";
    this.getDetailschool();
    this.getClassRoom(new Date(), new Date());
    this.getDetailHoliday();
    this.showDate(new Date());
    const start = new Date();
    start.setHours(0, 0, 0, 0);
    const end = new Date();
    end.setHours(23, 59, 59, 0);
    this.setState({
      startDate: start,
      endDate: end,
    });
  }
  getDetailschool = () => {
    api
      .get(`/detailSchool`)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            schoolInfo: res.data.data[0],
          });
        }
      })
      .catch((err) => {});
  };
  getDetailHoliday = () => {
    api
      .get(`/viewHolidaysBySchoolYear`)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            listHoliday: res.data.data,
          });
        } else
          this.setState({
            listHoliday: [],
          });
      })
      .catch((err) => {});
  };
  findDate(day, listDateOff) {
    let findNumber = {};
    findNumber = listDateOff?.find(
      (date) => new Date(date.date * 1000).getDate() == day
    )?.class[0];
    return findNumber;
  }
  sumHienDien = (day) => {
    let date_ = new Date(this.state.startMonth?.setDate(day));
    // if (day <= new Date().getDate()) {
    if (date_ <= new Date()) {
      let data = this.state.listCut;
      const dateDetail =
        data.find((detail) => new Date(detail.date * 1000).getDate() == day)
          ?.class || [];

      return dateDetail.reduce(
        (total, cls) => total + (cls.countStudent - cls.countStudentOff),
        0
      );
    } else return 0;
  };
  sumVang = (day) => {
    let data = this.state.listCut;
    const dateDetail =
      data.find((detail) => new Date(detail.date * 1000).getDate() == day)
        ?.class || [];

    return dateDetail.reduce((total, cls) => total + cls.countStudentOff, 0);
  };
  getAllStudent = () => {
    var tong = 0;
    this.state.listcutAll.map((item) => (tong += item?.count));
    return tong;
  };

  getlistCutAll = (listClass, start, end) => {
    this.setState({ checkload: true });
    api
      .get(
        `/report-class?start_date=${parseInt(
          this.changeStartMonth(start) / 1000
        )}&end_date=${this.changeEndMonth(end) / 1000}`
      )
      .then((res) => {
        this.setState({ checkload: false });

        let arr = [];
        for (const obj1 of listClass)
          arr.push({
            id_class: obj1.id,
            class_name: obj1.class_name,
            count: obj1.countStudent,
            detail: res.data.data?.map((item) => {
              const filteredClasses = item.class.filter(
                (c) => c.id_class == obj1.id
              );
              return { ...item, class: filteredClasses };
            }),
          });
        this.setState({ listcutAll: arr, listCut: res.data.data });
      })
      .catch((err) => {
        toast.error(
          err.messages || err?.response?.data?.messages || "Xảy ra lỗi"
        );
      });
  };
  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  customStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: "150px",
      marginRight: "10px",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "4px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "blue" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };

  getDate = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  getClassRoom = (start, end) => {
    var arr = [];
    arr.push({ value: 0, label: "Tất cả lớp" });
    api
      .get(
        `/classroom-shows?start_date=${parseInt(
          this.changeStartMonth(start) / 1000
        )}&end_date=${this.changeEndMonth(end) / 1000}`
      )
      .then((res) => {
        this.getlistCutAll(res.data.data?.classrooms, start, end);
        for (const obj1 of res.data.data?.classrooms)
          arr.push({
            value: obj1.id,
            count: obj1.count_student,
            label: obj1.class_name,
            total: obj1.total,
          });
        this.setState({ classrooms: arr });
      })
      .catch((err) => {});
  };

  removeDuplicates(arr) {
    const uniqueItems = new Set();
    const result = [];
    for (const item of arr) {
      const itemAsString = JSON.stringify(item);
      if (!uniqueItems.has(itemAsString)) {
        uniqueItems.add(itemAsString);
        result.push(item);
      }
    }
    return result;
  }

  checkProcess = (start, end, process) => {
    let isProcessValid = false;
    for (var i = 0; i < process?.length; i++) {
      if (process[i].end_at != null) {
        if (process[i].start_at <= start && process[i].end_at >= end) {
          isProcessValid = true;
          break;
        }
      } else {
        if (process[i].start_at <= start) {
          isProcessValid = true;
          break;
        }
      }
    }

    if (isProcessValid) {
      return true;
    } else {
      return false;
    }
  };

  getStudentbyClass = async (id, start, end) => {
    this.setState({ checkload: true });

    await api
      .get(
        `/student-shows?id_class=${id}&search_start_date=${parseInt(
          this.changeStartMonth(start) / 1000
        )}&search_end_date=${parseInt(this.changeEndMonth(end) / 1000)}`
      )
      .then((res) => {
        this.setState({ checkload: false });

        const students = res.data.data?.students?.filter(
          (student) =>
            student.id_class === id &&
            parseInt(student.student_status) === IS_STATUS.ACTIVE
        );

        const listCutByIdClass = this.state.listcutAll.find(
          (cut) => cut.id_class === id
        );

        this.setState({ listStudentByClass: students, listCutByIdClass });

        this.getMealByClasses(
          id,
          this.changeStartMonth(start),
          this.changeEndMonth(end)
        );
      })
      .catch((err) => {
        this.setState({ listStudentByClass: [] });
        this.getMealByClasses(
          id,
          this.changeStartMonth(start),
          this.changeEndMonth(end)
        );
      });
  };

  getMealByClasses = (classId, start, end) => {
    api
      .get(
        `day-off-shows?id_class=${classId}&start_date=${parseInt(
          start / 1000
        )}&end_date=${parseInt(end / 1000)}`
      )
      .then((res) => {
        this.setState({ listcutbyClass: res.data.data });
      })
      .catch((err) => {});
  };

  changeStartMonth = (date) => {
    return new Date(new Date(date.setDate(1)).setHours(0, 0, 0));
  };
  changeEndMonth = (date) => {
    return new Date(
      new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59)
    );
  };

  showDate = (month) => {
    var viewDate = [];
    var viewDay = [];
    var lastDayOfLastMonth = new Date(
      month.getFullYear(),
      month.getMonth() + 1,
      0
    );
    for (let i = 1; i <= lastDayOfLastMonth.getDate(); i++) {
      viewDate.push({ value: i, label: i });
      viewDay.push({
        value: this.changeDateToDay(i),
        label: this.changeDateToDay(i),
      });
    }
    this.setState({ listDay: viewDay });

    this.setState({ listDate: viewDate });
  };
  changeDateToDay = (date) => {
    var data_ = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth(),
      date
    );
    var daysOfWeek = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];

    var rs = daysOfWeek[data_.getDay()];

    return rs;
  };

  countStudentDateByClassDatailColor = (id_student, date, process) => {
    var total = [];
    var date_ = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth(),
      date
    );
    var date_1 = parseInt(new Date(date_.setHours(0, 0, 0)) / 1000);
    var date_2 = new Date(date_.setHours(23, 59, 59)) / 1000;

    total = this.state.listcutbyClass?.filter(
      (data_) =>
        data_.id_student == id_student &&
        data_.date_off >= date_1 &&
        data_.date_off <= date_2 &&
        data_.is_day_off == 1 &&
        data_.is_status == 1
    );
    var kp = total.filter((e) => e.is_valid == 0)?.length;
    var cp = total.filter((e) => e.is_valid != 0)?.length;
    var rs = "";
    // if (this.CheckHoliday(date)) {
    //   rs = "#ff9a0a";
    // }
    // else {

    if (total.length > 0) {
      if (kp > 0) rs = "#FEA1A1";
      if (cp > 0) rs = "#a7c957";
    } else rs = "#FFF";

    // }

    if (date_.setHours(0, 0, 0) - new Date().setHours(0, 0, 0) > 0) {
      rs = "#FFF";
    }
    // nếu có lỗi thì check lại cái này ('-')
    // if (this.checkProcessByClass(date_1 + 5, date_2 + 5, process)) {
    //   rs = "#FFF";
    // }

    return rs;
  };

  countStudentDateByClassDatail = (
    id_student,
    process,
    date,
    classHistories
  ) => {
    var total = [];
    var date_ = new Date(
      this.state.startMonth?.getFullYear(),
      this.state.startMonth?.getMonth(),
      date
    );
    var date_1 = new Date(date_.setHours(0, 0, 0)) / 1000;
    var date_2 = new Date(date_.setHours(23, 59, 59)) / 1000;
    total = this.state.listcutbyClass?.filter(
      (data_) =>
        data_.id_student == id_student &&
        data_.date_off >= date_1 &&
        data_.date_off <= date_2 &&
        data_.is_day_off == 1 &&
        data_.is_status == 1
    );
    var kp = total.filter((e) => e.is_valid == 0)?.length;
    var cp = total.filter((e) => e.is_valid != 0)?.length;
    var rs = "";
    if (this.CheckHoliday(date)) {
      rs = "N";
    } else {
      if (total?.length > 0) {
        if (kp > 0) rs = "K";
        if (cp > 0) rs = "P";
      } else rs = "X";
    }

    if (date_.setHours(0, 0, 0) - new Date().setHours(0, 0, 0) > 0) {
      rs = "-";
    }

    if (!this.isInRange(date_ / 1000 + 5, process)) {
      rs = "-";
    }
    if (!this.isClass(date_, classHistories)) rs = "-";
    return rs;
  };
  isClass(date, items) {
    const timestamp = date / 1000;
    for (let item of items) {
      const startAt = item.start_at;
      const endAt = item.end_at;
      if (
        timestamp >= startAt &&
        (timestamp <= endAt || endAt == null) &&
        this.state.chociceClass?.value == item.id_class
      ) {
        return true;
      }
    }

    return false;
  }
  isInRange(x, intervals) {
    for (let i = 0; i < intervals?.length; i++) {
      let start = intervals[i].start_at;
      let end = intervals[i].end_at;

      if (end === null) {
        if (x >= start) {
          return true;
        }
      } else {
        if (x >= start && x <= end) {
          return true;
        }
      }
    }

    return false;
  }

  checkProcessByClass = (start, end, process) => {
    let isProcessValid = false;

    for (var i = 0; i < process?.length; i++) {
      if (process[i].end_at != null) {
        if (process.length == 1) {
          if (process[i].end_at <= end || process[i].start_at >= start) {
            isProcessValid = true;
            break;
          }
        } else {
          if (process[i].end_at >= end && process[i].start_at <= start) {
            isProcessValid = false;
            break;
          }
        }
      } else {
        if (process[i].start_at >= start) {
          isProcessValid = true;
          break;
        }
      }
    }

    if (isProcessValid) {
      return true;
    } else {
      return false;
    }
  };
  getlastDayOfMonth = (year, month) => {
    var nextMonth = new Date(year, month + 1, 1);

    var lastDay = new Date(nextMonth.getTime() - 1);

    return new Date(lastDay.setHours(23, 59, 59));
  };
  countnghihoc = (id_student) => {
    var total = [];
    var date_1 = new Date(this.state.startMonth?.setHours(0, 0, 0));
    date_1 = parseInt(new Date(date_1.setDate(1)) / 1000);
    var date_2 =
      this.getlastDayOfMonth(
        this.state.startMonth.getFullYear(),
        this.state.startMonth.getMonth()
      ) / 1000;
    total = this.state.listcutbyClass?.filter((data_) =>
      !this.CheckHoliday(new Date(data_.meal_day * 1000).getDate())
        ? data_.id_student == id_student &&
          data_.date_off >= date_1 &&
          data_.date_off <= date_2 &&
          data_.is_day_off == 1 &&
          data_.is_status == 1
        : null
    );

    return total.length;
  };

  countClassMonthKP = (id_class) => {
    var total = [];
    var date_1 = new Date(this.state.startMonth.setHours(0, 0, 0));
    date_1 = parseInt(new Date(date_1.setDate(1)) / 1000);
    var date_2 =
      this.getlastDayOfMonth(
        this.state.startMonth.getFullYear(),
        this.state.startMonth.getMonth()
      ) / 1000;
    total = this.state.listcutbyClass?.filter((data_) =>
      !this.CheckHoliday(new Date(data_.meal_day * 1000).getDate())
        ? data_.date_off >= date_1 &&
          data_.date_off <= date_2 &&
          data_.is_day_off == 1 &&
          data_.is_status == 1
        : null
    );
    var kp = total.filter((e) => e.is_valid == 0)?.length;

    return kp;
  };
  countClassMonthCP = (id_class) => {
    var total = [];
    var date_1 = new Date(this.state.startMonth.setHours(0, 0, 0));
    date_1 = parseInt(new Date(date_1.setDate(1)) / 1000);
    var date_2 =
      this.getlastDayOfMonth(
        this.state.startMonth.getFullYear(),
        this.state.startMonth.getMonth()
      ) / 1000;
    total = this.state.listcutbyClass?.filter((data_) =>
      !this.CheckHoliday(new Date(data_.meal_day * 1000).getDate())
        ? data_.date_off >= date_1 &&
          data_.date_off <= date_2 &&
          data_.is_day_off == 1 &&
          data_.is_status == 1
        : null
    );

    var cp = total.filter((e) => e.is_valid != 0)?.length;

    return cp;
  };
  countkhongphep = (id_student) => {
    var total = [];
    var date_1 = new Date(this.state.startMonth?.setHours(0, 0, 0));
    date_1 = parseInt(new Date(date_1.setDate(1)) / 1000);
    var date_2 =
      this.getlastDayOfMonth(
        this.state.startMonth.getFullYear(),
        this.state.startMonth.getMonth()
      ) / 1000;
    total = this.state.listcutbyClass.filter((data_) =>
      !this.CheckHoliday(new Date(data_.meal_day * 1000).getDate())
        ? data_.id_student == id_student &&
          data_.date_off >= date_1 &&
          data_.date_off <= date_2 &&
          data_.is_day_off == 1 &&
          data_.is_status == 1
        : null
    );
    var kp = total.filter((e) => e.is_valid == 0)?.length;
    return kp;
  };

  countStudentDetail = (date) => {
    var total = 0;
    var date_ = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth(),
      date
    );
    var date_1 = parseInt(new Date(date_.setHours(0, 0, 0)) / 1000);
    var date_2 = new Date(date_.setHours(23, 59, 59)) / 1000;

    total = this.state.listcutbyClass?.filter(
      (data_) =>
        data_.date_off >= date_1 &&
        data_.date_off <= date_2 &&
        data_.is_day_off == 1 &&
        data_.is_status == 1
    ).length;
    var rs = "";
    if (
      date_.setHours(0, 0, 0) - new Date().setHours(0, 0, 0) > 0 ||
      this.state.listStudentByClass?.filter((e) =>
        this.checkProcessBy_Class(date_1, date_2, e.studyTimelines)
      ).length == 0
    ) {
      rs = "-";
    } else {
      rs =
        this.state.listStudentByClass?.filter(
          (e) =>
            this.checkProcessBy_Class(date_1, date_2, e.studyTimelines) &&
            this.isClass(date_, e.classHistories)
        ).length - total;
    }

    return rs;
  };
  checkProcessBy_Class = (start, end, process) => {
    let isProcessValid = false;

    for (var i = 0; i < process?.length; i++) {
      if (process[i].end_at != null) {
        if (process[i].start_at <= start && process[i].end_at >= end) {
          isProcessValid = true;
          break;
        }
      } else {
        if (process[i].start_at <= start) {
          isProcessValid = true;
          break;
        }
      }
    }

    if (isProcessValid) {
      return true;
    } else {
      return false;
    }
  };
  CheckHoliday = (day) => {
    let array = this.state.listHoliday;
    var date_ =
      new Date(
        this.state.startMonth.getFullYear(),
        this.state.startMonth.getMonth(),
        day
      ) /
        1000 +
      1;
    for (let i = 0; i < array?.length; i++) {
      if (date_ >= array[i].start_date && date_ <= array[i].end_date) {
        return true;
      }
    }
    return false;
  };

  countStudentDetailKP = (date) => {
    var total = 0;
    var date_ = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth(),
      date
    );
    var date_1 = parseInt(new Date(date_.setHours(0, 0, 0)) / 1000);
    var date_2 = new Date(date_.setHours(23, 59, 59)) / 1000;

    total = this.state.listcutbyClass?.filter((data_) =>
      !this.CheckHoliday(date)
        ? data_.date_off >= date_1 &&
          data_.date_off <= date_2 &&
          data_.is_day_off == 1 &&
          data_.is_status == 1 &&
          data_.is_valid == 0
        : null
    ).length;

    return total;
  };
  countStudentDetailCP = (date) => {
    var total = 0;
    var date_ = new Date(
      this.state.startMonth.getFullYear(),
      this.state.startMonth.getMonth(),
      date
    );
    var date_1 = parseInt(new Date(date_.setHours(0, 0, 0)) / 1000);
    var date_2 = new Date(date_.setHours(23, 59, 59)) / 1000;

    total = this.state.listcutbyClass?.filter(
      (data_) =>
        data_.date_off >= date_1 &&
        data_.date_off <= date_2 &&
        data_.is_day_off == 1 &&
        data_.is_status == 1 &&
        data_.is_valid != 0
    ).length;

    return total;
  };
  countHienDien = () => {
    let tong = 0;
    this.state.listDate?.map((item) => {
      tong +=
        this.findDate(item.value, this.state.listCutByIdClass?.detail)
          ?.countStudent != undefined
          ? this.CheckHoliday(item.label)
            ? 0
            : Number(this.countStudentDetail(item.label))
            ? Number(this.countStudentDetail(item.label))
            : 0
          : 0;
    });
    return tong;
  };

  // Export Excel
  xport = () => {
    const wb = XLSX.utils.book_new();

    if (this.state.chociceClass.value > 0) {
      XLSX.utils.book_append_sheet(
        wb,
        this.classDetailReport(),
        `Báo cáo hiện diện tháng ${this.state.startMonth.getMonth() + 1}`
      );
    } else {
      XLSX.utils.book_append_sheet(
        wb,
        this.summaryReport(),
        `Báo cáo hiện diện tháng ${this.state.startMonth.getMonth() + 1}`
      );
    }

    XLSX.writeFile(
      wb,
      `Báo cáo hiện diện tháng ${this.state.startMonth.getMonth() + 1}.xlsx`
    );
  };

  summaryReport = () => {
    const table = document.getElementById("CutUserTable");
    var ws = XLSX.utils.table_to_sheet(table);

    const colAlpha = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
    ];

    const colAlphaHeader = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
    ];

    const colAlphaFooter1 = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
    ];
    const colAlphaFooter2 = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
    ];

    // Style header
    colAlpha.slice(0, this.state.listDate?.length + 3).map((alpha) => {
      ws[`${alpha}1`].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    colAlpha.slice(0, this.state.listDate?.length + 3).map((alpha) => {
      ws[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          italic: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    colAlpha.slice(0, this.state.listDate?.length + 3).map((alpha) => {
      ws[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          sz: 16,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    colAlphaHeader.slice(0, this.state.listDate?.length + 3).map((alpha) => {
      ws[`${alpha}4`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
      return alpha;
    });

    colAlphaHeader.slice(0, this.state.listDate?.length + 3).map((alpha) => {
      ws[`${alpha}5`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
      return alpha;
    });
    // Style header

    // Style body
    let classIndex = 6;
    // css excel
    this.state.listcutAll.map((_, index) => {
      colAlpha.slice(0, this.state.listDate?.length + 3).map((alpha, indx) => {
        if (
          indx < 3 ||
          this.findDate(
            this.state.listDate[indx - 3]?.value,
            this.state.listCutByIdClass?.detail
          )?.countStudent != undefined
        ) {
          ws[`${alpha}${classIndex}`].s = {
            font: {
              name: "Times New Roman",
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        } else {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: {
                theme: 8,
                tint: 0.3999755851924192,
                rgb: "EEEEEE",
              },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        }
      });

      classIndex++;

      return index;
    });
    // Style body

    // Style footer
    colAlphaFooter1
      .slice(0, this.state.listDate?.length + 3)
      .map((alpha, index) => {
        if (
          index < 3 ||
          this.findDate(
            this.state.listDate[index - 3]?.value,
            this.state.listCutByIdClass?.detail
          )?.countStudent != undefined
        ) {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "DDB892" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        } else {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "EEEEEE" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        }
      });

    colAlphaFooter2
      .slice(0, this.state.listDate?.length + 3)
      .map((alpha, index) => {
        if (
          index < 3 ||
          this.findDate(
            this.state.listDate[index - 3]?.value,
            this.state.listCutByIdClass?.detail
          )?.countStudent != undefined
        ) {
          ws[`${alpha}${classIndex + 1}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "DDB892" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        } else {
          ws[`${alpha}${classIndex + 1}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "EEEEEE" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        }

        return alpha;
      });
    // Style footer

    ws["!rows"] = [{}, { hpt: 20 }, { hpt: 40 }];

    const merge = [
      { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
      { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
      { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },

      { s: { r: 0, c: 0 }, e: { r: 0, c: this.state.listDate.length + 2 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: this.state.listDate.length + 2 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: this.state.listDate.length + 2 } },
      { s: { r: classIndex - 1, c: 0 }, e: { r: classIndex - 1, c: 1 } },
      { s: { r: classIndex, c: 0 }, e: { r: classIndex, c: 2 } },
    ];
    ws["!merges"] = merge;

    return ws;
  };

  classDetailReport = () => {
    const table = document.getElementById("CutUserTable");
    var ws = XLSX.utils.table_to_sheet(table);

    const colAlpha = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
      "AI",
      "AJ",
      "AK",
    ];

    const colAlphaHeader = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
      "AI",
      "AJ",
      "AK",
    ];

    const colAlphaFooter1 = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
      "AI",
      "AJ",
      "AK",
    ];
    const colAlphaFooter2 = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
      "AI",
      "AJ",
      "AK",
    ];

    // Style header
    colAlpha.slice(0, this.state.listDate?.length + 3).map((alpha) => {
      ws[`${alpha}1`].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    colAlpha.slice(0, this.state.listDate?.length + 6).map((alpha) => {
      ws[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          italic: true,
          sz: 12,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    colAlpha.slice(0, this.state.listDate?.length + 6).map((alpha) => {
      ws[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          sz: 16,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    colAlphaHeader
      .slice(0, this.state.listDate?.length + 6)
      .map((alpha, index) => {
        if (
          colAlpha.slice(0, this.state.listDate?.length + 6).length - 4 <
          index
        ) {
          ws[`${alpha}4`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "C9CBFF" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
              textRotation: 90,
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }

        ws[`${alpha}4`].s = {
          font: {
            name: "Times New Roman",
            bold: true,
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };
        return alpha;
      });

    colAlphaHeader.slice(0, this.state.listDate?.length + 6).map((alpha) => {
      ws[`${alpha}5`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
      return alpha;
    });
    // Style header

    // Style body
    let classIndex = 6;

    this.state.listStudentByClass.map((_, index) => {
      colAlpha.slice(0, this.state.listDate?.length + 6).map((alpha, indx) => {
        if (
          colAlpha.slice(0, this.state.listDate?.length + 6).length - 4 <
          indx
        ) {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "C9CBFF" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }
        if (ws[`${alpha}${classIndex}`].v === "N") {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "ff9a0a" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }
        if (ws[`${alpha}${classIndex}`].v === "P") {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "A7C957" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }

        if (ws[`${alpha}${classIndex}`].v === "K") {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "FEA1A1" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }

        ws[`${alpha}${classIndex}`].s = {
          font: {
            name: "Times New Roman",
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        if (ws[`${alpha}${classIndex}`].v === "empty") {
          ws[`${alpha}${classIndex}`].v = " ";

          if (alpha === "C") {
            ws[`${alpha}${classIndex}`].s = {
              font: {
                name: "Times New Roman",
              },
              alignment: {
                vertical: "center",
                horizontal: "center",
              },
              border: {
                top: { style: "thin" },
                bottom: { style: "thin" },
                left: { style: "thin" },
                right: { style: "thin" },
              },
            };
          } else {
            ws[`${alpha}${classIndex}`].s = {
              fill: {
                patternType: "solid",
                fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "EEEEEE" },
                bgColor: { indexed: 64 },
              },
              font: {
                name: "Times New Roman",
              },
              alignment: {
                vertical: "center",
                horizontal: "center",
              },
              border: {
                top: { style: "thin" },
                bottom: { style: "thin" },
                left: { style: "thin" },
                right: { style: "thin" },
              },
            };
          }
        }

        return alpha;
      });

      classIndex++;

      return index;
    });
    // Style body

    // Style footer
    colAlphaFooter1
      .slice(0, this.state.listDate?.length + 6)
      .map((alpha, index) => {
        if (
          colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            3 ===
          index
        ) {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "FAE392" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }

        if (
          index < 3 ||
          this.findDate(
            this.state.listDate[index - 3]?.value,
            this.state.listCutByIdClass?.detail
          )?.countStudent != undefined
        ) {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "DDB892" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        } else {
          ws[`${alpha}${classIndex}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "EEEEEE" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        }

        return alpha;
      });

    colAlphaFooter2
      .slice(0, this.state.listDate?.length + 6)
      .map((alpha, index) => {
        if (
          colAlphaFooter2.slice(0, this.state.listDate?.length + 6).length -
            3 ===
          index
        ) {
          ws[`${alpha}${classIndex + 1}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "FAE392" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }

        if (
          index < 3 ||
          this.findDate(
            this.state.listDate[index - 3]?.value,
            this.state.listCutByIdClass?.detail
          )?.countStudent != undefined
        ) {
          ws[`${alpha}${classIndex + 1}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "DDB892" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        } else {
          ws[`${alpha}${classIndex + 1}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "EEEEEE" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        }
        return alpha;
      });

    colAlphaFooter2
      .slice(0, this.state.listDate?.length + 6)
      .map((alpha, index) => {
        if (
          colAlphaFooter2.slice(0, this.state.listDate?.length + 6).length -
            3 ===
          index
        ) {
          ws[`${alpha}${classIndex + 2}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "FAE392" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };

          return alpha;
        }

        if (
          index < 3 ||
          this.findDate(
            this.state.listDate[index - 3]?.value,
            this.state.listCutByIdClass?.detail
          )?.countStudent != undefined
        ) {
          ws[`${alpha}${classIndex + 2}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "DDB892" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        } else {
          ws[`${alpha}${classIndex + 2}`].s = {
            fill: {
              patternType: "solid",
              fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "EEEEEE" },
              bgColor: { indexed: 64 },
            },
            font: {
              name: "Times New Roman",
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          };
        }

        return alpha;
      });

    colAlphaFooter2.slice(0, this.state.listDate?.length + 6).map((alpha) => {
      if (ws[`${alpha}${classIndex + 3}`].v === "empty") {
        ws[`${alpha}${classIndex + 3}`].v = " ";
      }

      ws[`${alpha}${classIndex + 3}`].s = {
        font: {
          name: "Times New Roman",
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });

    colAlphaFooter2.slice(0, this.state.listDate?.length + 6).map((alpha) => {
      if (ws[`${alpha}${classIndex + 4}`].v === "empty") {
        ws[`${alpha}${classIndex + 4}`].v = " ";
      }

      ws[`${alpha}${classIndex + 4}`].s = {
        font: {
          name: "Times New Roman",
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
      };

      return alpha;
    });
    // Style footer

    ws["!cols"] = [{ wch: 10 }, { wch: 20 }];
    ws["!rows"] = [{}, { hpt: 20 }, { hpt: 40 }, { hpt: 30 }, { hpt: 30 }];

    const merge = [
      { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
      { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
      { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },

      {
        s: { r: 3, c: this.state.listDate.length + 5 },
        e: { r: 4, c: this.state.listDate.length + 5 },
      },
      {
        s: { r: 3, c: this.state.listDate.length + 4 },
        e: { r: 4, c: this.state.listDate.length + 4 },
      },
      {
        s: { r: 3, c: this.state.listDate.length + 3 },
        e: { r: 4, c: this.state.listDate.length + 3 },
      },

      { s: { r: 0, c: 0 }, e: { r: 0, c: this.state.listDate.length + 5 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: this.state.listDate.length + 5 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: this.state.listDate.length + 5 } },
      { s: { r: classIndex - 1, c: 0 }, e: { r: classIndex - 1, c: 1 } },
      {
        s: {
          r: classIndex - 1,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            3,
        },
        e: {
          r: classIndex - 1,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            1,
        },
      },
      { s: { r: classIndex, c: 0 }, e: { r: classIndex, c: 2 } },
      {
        s: {
          r: classIndex,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            3,
        },
        e: {
          r: classIndex,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            1,
        },
      },
      { s: { r: classIndex + 1, c: 0 }, e: { r: classIndex + 1, c: 2 } },
      {
        s: {
          r: classIndex + 1,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            3,
        },
        e: {
          r: classIndex + 1,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            1,
        },
      },

      { s: { r: classIndex + 2, c: 0 }, e: { r: classIndex + 2, c: 4 } },
      {
        s: {
          r: classIndex + 2,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            10,
        },
        e: {
          r: classIndex + 2,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            1,
        },
      },

      { s: { r: classIndex + 3, c: 0 }, e: { r: classIndex + 3, c: 4 } },
      {
        s: {
          r: classIndex + 3,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            10,
        },
        e: {
          r: classIndex + 3,
          c:
            colAlphaFooter1.slice(0, this.state.listDate?.length + 6).length -
            1,
        },
      },
    ];
    ws["!merges"] = merge;

    return ws;
  };

  handleExport = () => {
    swal({
      title: `Xác nhận`,
      text: `Xuất danh sách báo cáo hiện diện tháng ${
        this.state.startMonth.getMonth() + 1
      } `,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        this.xport();
      }
    });
  };
  // Export Excel

  renderCutMealListTable() {
    return (
      <>
        {this.state.checkload ? (
          this.loading()
        ) : (
          <div style={{ overflow: "auto" }}>
            <Table bordered style={{ borderColor: "#000" }}>
              <thead>
                <tr
                  className={this.state.chociceClass?.value > 0 ? "" : "d-none"}
                >
                  <th
                    className="text-left "
                    colSpan={
                      7 +
                      new Date(
                        this.state.startMonth.getFullYear(),
                        this.state.startMonth.getMonth() + 1,
                        0
                      ).getDate()
                    }
                  >
                    <p
                      className=""
                      style={{
                        fontSize: "12px",
                        fontStyle: "italic",
                        margin: "0",
                        fontWeight: "400",
                      }}
                    >
                      {this.state.chociceClass?.value > 0
                        ? "Chú thích: X: đi học, P: có phép, K: không phép (xin trễ)"
                        : "Chú thích:"}
                    </p>
                  </th>
                </tr>
                <tr>
                  <th
                    className="text-center text-uppercase "
                    colSpan={
                      7 +
                      new Date(
                        this.state.startMonth.getFullYear(),
                        this.state.startMonth.getMonth() + 1,
                        0
                      ).getDate()
                    }
                  >
                    {this.state.chociceClass?.value > 0
                      ? `Bảng hiện diện lớp ${this.state.chociceClass.label} `
                      : ` Báo cáo tổng hợp hiện diện theo lớp `}{" "}
                    Tháng {this.state.startMonth.getMonth() + 1} năm{" "}
                    {this.state.startMonth.getFullYear()}
                  </th>
                </tr>

                <tr>
                  <th rowSpan="2" className="align-middle text-center">
                    STT
                  </th>
                  <th rowSpan="2" className="align-middle text-center">
                    {this.state.chociceClass.value > 0 ? "Họ và tên" : "Lớp"}
                  </th>
                  <th rowSpan="2" className=" align-middle text-center">
                    {this.state.chociceClass.value > 0 ? "Nữ" : "Sĩ số"}
                  </th>

                  {this.state.listDate?.map((item) => {
                    return (
                      <th
                        className="align-middle text-center"
                        style={{
                          backgroundColor:
                            this.state.listCut[item.value - 1]?.is_day !==
                            "study"
                              ? "#eee"
                              : "white",
                        }}
                      >
                        {item.label}
                      </th>
                    );
                  })}

                  {this.state.chociceClass.value > 0 ? (
                    <>
                      <th
                        rowSpan="2"
                        style={{
                          transform: "rotate(180deg)",
                          writingMode: "vertical-rl",
                          backgroundColor: "#C9CBFF",
                        }}
                      >
                        Tổng nghỉ học
                      </th>
                      <th
                        rowSpan="2"
                        style={{
                          transform: "rotate(180deg)",
                          writingMode: "vertical-rl",
                          backgroundColor: "#C9CBFF",
                        }}
                      >
                        Tổng không phép
                      </th>
                      <th
                        rowSpan="2"
                        style={{
                          transform: "rotate(180deg)",
                          writingMode: "vertical-rl",
                          backgroundColor: "#C9CBFF",
                        }}
                      >
                        Tổng có phép
                      </th>
                    </>
                  ) : (
                    ""
                  )}
                </tr>

                <tr>
                  {this.state.listDate?.map((item) => {
                    return (
                      <th
                        className="align-middle text-center"
                        style={{
                          backgroundColor:
                            this.state.listCut[item.value - 1]?.is_day !==
                            "study"
                              ? "#eee"
                              : "white",
                        }}
                      >
                        {this.changeDateToDay(item.label)}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {this.state.chociceClass.value > 0 ? (
                <tbody>
                  {this.state.listStudentByClass?.map((student, index) => (
                    <>
                      <tr>
                        <td> {index + 1} </td>
                        <td> {student.student_name} </td>
                        <td className=" align-middle text-center">
                          {student.gender_name == "Nữ" ? "X" : ""}
                        </td>
                        {this.state.listDate?.map((item) => {
                          return (
                            <td
                              className="align-middle text-center"
                              style={{
                                backgroundColor:
                                  this.state.listCut[item.value - 1]?.is_day ===
                                  "study"
                                    ? this.countStudentDateByClassDatailColor(
                                        student.id,
                                        item.value,
                                        student.studyTimelines
                                      )
                                    : "#EEEEEE",
                              }}
                            >
                              {this.state.listCut[item.value - 1]?.is_day ===
                              "study"
                                ? this.countStudentDateByClassDatail(
                                    student.id,
                                    student.studyTimelines,
                                    item.value,
                                    student.classHistories
                                  )
                                : ""}
                            </td>
                          );
                        })}
                        <td style={{ backgroundColor: "#C9CBFF" }}>
                          {this.countnghihoc(student.id)}
                        </td>
                        <td style={{ backgroundColor: "#C9CBFF" }}>
                          {this.countkhongphep(student.id)}
                        </td>
                        <td style={{ backgroundColor: "#C9CBFF" }}>
                          {this.countnghihoc(student.id) -
                            this.countkhongphep(student.id)}
                        </td>
                      </tr>
                    </>
                  ))}

                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th colSpan="2"> Hiện diện</th>
                    <th>
                      {
                        this.state.listStudentByClass.filter(
                          (e) => e.gender_name == "Nữ"
                        ).length
                      }
                    </th>
                    {this.state.listDate?.map((item) => {
                      return (
                        <th
                          className="align-middle text-center"
                          style={{
                            backgroundColor:
                              this.state.listCut[item.value - 1]?.is_day ===
                              "study"
                                ? "#ddb892"
                                : "#EEEEEE",
                          }}
                        >
                          {this.state.listCut[item.value - 1]?.is_day ===
                          "study"
                            ? this.CheckHoliday(item.label)
                              ? 0
                              : this.countStudentDetail(item.label) == "-"
                              ? 0
                              : this.countStudentDetail(item.label)
                            : 0}
                        </th>
                      );
                    })}
                    <th style={{ backgroundColor: "#FAE392" }} colSpan={3}>
                      {this.countHienDien()}
                    </th>
                  </tr>

                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th colSpan="3">Vắng không phép</th>

                    {this.state.listDate?.map((item) => {
                      return (
                        <th
                          className="align-middle text-center"
                          style={{
                            backgroundColor:
                              // this.findDate(
                              //   item.value,
                              //   this.state.listCutByIdClass?.detail
                              // )?.countStudent != undefined
                              this.state.listCut[item.value - 1]?.is_day ===
                              "study"
                                ? "#ddb892"
                                : "#EEEEEE",
                          }}
                        >
                          {this.countStudentDetailKP(item.label)}
                        </th>
                      );
                    })}
                    <th style={{ backgroundColor: "#FAE392" }} colSpan={3}>
                      {this.countClassMonthKP(this.state.chociceClass?.value)}
                    </th>
                  </tr>
                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th colSpan="3"> Vắng có phép</th>

                    {this.state.listDate?.map((item) => {
                      return (
                        <th
                          className="align-middle text-center"
                          style={{
                            backgroundColor:
                              this.state.listCut[item.value - 1]?.is_day ===
                              "study"
                                ? "#ddb892"
                                : "#EEEEEE",
                          }}
                        >
                          {" "}
                          {this.CheckHoliday(item.label)
                            ? 0
                            : this.countStudentDetailCP(item.label)}{" "}
                        </th>
                      );
                    })}
                    <th style={{ backgroundColor: "#FAE392" }} colSpan={3}>
                      {this.countClassMonthCP(this.state.chociceClass.value)}
                    </th>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {this.state.listcutAll.map((student, index) =>
                    student.id_class > 0 ? (
                      <tr>
                        <td className="align-middle text-center">
                          {" "}
                          {index + 1}{" "}
                        </td>
                        <td className="align-middle text-center">
                          {" "}
                          {student.class_name}{" "}
                        </td>
                        <td className="align-middle text-center">
                          {student.count}
                        </td>

                        {this.state.listDate?.map((item) => (
                          <td
                            className="align-middle text-center"
                            style={{
                              backgroundColor:
                                this.state.listCut[item.value - 1]?.is_day !==
                                "study"
                                  ? "#EEEEEE"
                                  : "white",
                            }}
                          >
                            {new Date(
                              this.state.startMonth.getFullYear(),
                              this.state.startMonth.getMonth(),
                              item.label
                            ).setHours(0, 0, 0) -
                              new Date().setHours(0, 0, 0) >
                              0 ||
                            this.findDate(item.value, student.detail)
                              ?.countStudent == undefined
                              ? "-"
                              : this.findDate(item.value, student.detail)
                                  ?.countStudent -
                                this.findDate(item.value, student.detail)
                                  ?.countStudentOff}
                            {/* here */}
                          </td>
                        ))}
                      </tr>
                    ) : null
                  )}
                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th colSpan="2"> Hiện diện</th>

                    <th className="text-center">{this.getAllStudent()}</th>
                    {this.state.listDate?.map((item) => {
                      return (
                        <th
                          className="align-middle text-center"
                          style={{
                            backgroundColor:
                              this.state.listCut[item.value - 1]?.is_day !==
                              "study"
                                ? "#EEEEEE"
                                : "#ddb892",
                          }}
                        >
                          {this.sumHienDien(item.value)}
                        </th>
                      );
                    })}
                  </tr>
                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th colSpan="3"> Vắng</th>

                    {this.state.listDate?.map((item) => {
                      return (
                        <th
                          className="align-middle text-center"
                          style={{
                            backgroundColor:
                              this.state.listCut[item.value - 1]?.is_day !==
                              "study"
                                ? "#EEEEEE"
                                : "#ddb892",
                          }}
                        >
                          {this.sumVang(item.value)}
                        </th>
                      );
                    })}
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
        )}
      </>
    );
  }

  renderCutMealListTableExport() {
    return (
      <>
        {this.state.checkload ? (
          ""
        ) : (
          <div className="d-none">
            <Table bordered id="CutUserTable" style={{ borderColor: "#000" }}>
              <thead className="d-none">
                <tr>
                  <th className="text-center">
                    {this.state.schoolInfo.school_name}
                  </th>
                  <th>empty</th>
                  <th>empty</th>

                  {this.state.chociceClass.value > 0 ? (
                    <>
                      <th>empty</th>
                      <th>empty</th>
                      <th>empty</th>
                    </>
                  ) : (
                    ""
                  )}

                  {this.state.listDate?.map((_, index) => {
                    return <th key={index}>empty</th>;
                  })}
                </tr>
                <tr>
                  <th className="text-left">
                    <p
                      className=""
                      style={{
                        fontSize: "12px",
                        fontStyle: "italic",
                        margin: "0",
                        fontWeight: "400",
                      }}
                    >
                      {this.state.chociceClass?.value > 0
                        ? "Chú thích: X: đi học, P: có phép, K: không phép (xin trễ), -: chưa diễn ra"
                        : "Chú thích: -: chưa diễn ra"}
                    </p>
                  </th>
                  <th>empty</th>
                  <th>empty</th>

                  {this.state.chociceClass.value > 0 ? (
                    <>
                      <th>empty</th>
                      <th>empty</th>
                      <th>empty</th>
                    </>
                  ) : (
                    ""
                  )}

                  {this.state.listDate?.map((_, index) => {
                    return <th key={index}>empty</th>;
                  })}
                </tr>

                <tr>
                  <th className="text-center text-uppercase">
                    {this.state.chociceClass?.value > 0
                      ? `Bảng hiện diện lớp ${this.state.chociceClass.label} `
                      : ` Báo cáo tổng hợp hiện diện theo lớp `}{" "}
                    Tháng {this.state.startMonth.getMonth() + 1} năm{" "}
                    {this.state.startMonth.getFullYear()}
                  </th>

                  <th>empty</th>
                  <th>empty</th>

                  {this.state.chociceClass.value > 0 ? (
                    <>
                      <th>empty</th>
                      <th>empty</th>
                      <th>empty</th>
                    </>
                  ) : (
                    ""
                  )}

                  {this.state.listDate?.map((_, index) => {
                    return <th key={index}>empty</th>;
                  })}
                </tr>

                <tr>
                  <th className="align-middle text-center">STT</th>
                  <th className="align-middle text-center">
                    {this.state.chociceClass.value > 0 ? "Họ và tên" : "Lớp"}
                  </th>
                  <th className=" align-middle text-center">
                    {this.state.chociceClass.value > 0 ? "Nữ" : "Sĩ số"}
                  </th>

                  {this.state.listDate?.map((item) => {
                    return (
                      <th className="align-middle text-center">{item.label}</th>
                    );
                  })}

                  {this.state.chociceClass.value > 0 ? (
                    <>
                      <th
                        style={{
                          transform: "rotate(180deg)",
                          writingMode: "vertical-rl",
                          backgroundColor: "#C9CBFF",
                        }}
                      >
                        Tổng nghỉ học
                      </th>
                      <th
                        style={{
                          transform: "rotate(180deg)",
                          writingMode: "vertical-rl",
                          backgroundColor: "#C9CBFF",
                        }}
                      >
                        Tổng không phép
                      </th>
                      <th
                        style={{
                          transform: "rotate(180deg)",
                          writingMode: "vertical-rl",
                          backgroundColor: "#C9CBFF",
                        }}
                      >
                        Tổng có phép
                      </th>
                    </>
                  ) : (
                    ""
                  )}
                </tr>

                <tr>
                  <th>empty</th>
                  <th>empty</th>
                  <th>empty</th>
                  {this.state.listDate?.map((item) => {
                    return (
                      <th className="align-middle text-center">
                        {this.changeDateToDay(item.label)}
                      </th>
                    );
                  })}

                  {this.state.chociceClass.value > 0 ? (
                    <>
                      <th>empty</th>
                      <th>empty</th>
                      <th>empty</th>
                    </>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>

              {this.state.chociceClass.value > 0 ? (
                <tbody>
                  {this.state.listStudentByClass.map((student, index) => {
                    return (
                      <tr>
                        <td> {index + 1} </td>
                        <td> {student.student_name} </td>
                        <td className=" align-middle text-center">
                          {student.gender_name == "Nữ" ? "X" : "empty"}
                        </td>
                        {this.state.listDate?.map((item) => {
                          return (
                            <td className="align-middle text-center">
                              {this.findDate(
                                item.value,
                                this.state.listCutByIdClass?.detail
                              )?.countStudent != undefined
                                ? this.countStudentDateByClassDatail(
                                    student.id,
                                    student.studyTimelines,
                                    item.value,
                                    student.classHistories
                                  )
                                : "empty"}
                            </td>
                          );
                        })}
                        <td style={{ backgroundColor: "#C9CBFF" }}>
                          {this.countnghihoc(student.id)}
                        </td>
                        <td style={{ backgroundColor: "#C9CBFF" }}>
                          {this.countkhongphep(student.id)}
                        </td>
                        <td style={{ backgroundColor: "#C9CBFF" }}>
                          {this.countnghihoc(student.id) -
                            this.countkhongphep(student.id)}
                        </td>
                      </tr>
                    );
                  })}

                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th> Hiện diện</th>
                    <th>empty</th>
                    <th>
                      {
                        this.state.listStudentByClass.filter(
                          (e) => e.gender_name == "Nữ"
                        ).length
                      }
                    </th>
                    {this.state.listDate?.map((item) => {
                      return (
                        <th className="align-middle text-center">
                          {this.findDate(
                            item.value,
                            this.state.listCutByIdClass?.detail
                          )?.countStudent != undefined
                            ? this.CheckHoliday(item.label)
                              ? 0
                              : this.countStudentDetail(item.label) == "-"
                              ? 0
                              : this.countStudentDetail(item.label)
                            : 0}
                        </th>
                      );
                    })}
                    <th style={{ backgroundColor: "#FAE392" }}>
                      {this.countHienDien()}
                    </th>
                    <th>empty</th>
                    <th>empty</th>
                  </tr>
                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th> Vắng không phép</th>
                    <th>empty</th>
                    <th>empty</th>

                    {this.state.listDate?.map((item) => {
                      return (
                        <th className="align-middle text-center">
                          {" "}
                          {this.countStudentDetailKP(item.label)}{" "}
                        </th>
                      );
                    })}
                    <th style={{ backgroundColor: "#FAE392" }}>
                      {this.countClassMonthKP(this.state.chociceClass.value)}
                    </th>
                    <th>empty</th>
                    <th>empty</th>
                  </tr>
                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th> Vắng có phép</th>
                    <th>empty</th>
                    <th>empty</th>

                    {this.state.listDate?.map((item) => {
                      return (
                        <th className="align-middle text-center">
                          {" "}
                          {this.CheckHoliday(item.label)
                            ? 0
                            : this.countStudentDetailCP(item.label)}{" "}
                        </th>
                      );
                    })}
                    <th style={{ backgroundColor: "#FAE392" }}>
                      {this.countClassMonthCP(this.state.chociceClass.value)}
                    </th>
                    <th>empty</th>
                    <th>empty</th>
                  </tr>

                  <tr>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>

                    {this.state.listDate
                      ?.slice(9, this.state.listDate?.length)
                      .map((_, index) => {
                        return <th key={index}>empty</th>;
                      })}

                    <th>
                      Ngày {this.state.listDate?.length} tháng{" "}
                      {this.state.startMonth.getMonth() + 1} năm{" "}
                      {this.state.startMonth.getFullYear()}
                    </th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                  </tr>

                  <tr>
                    <th>Phụ trách bộ phận</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>

                    {this.state.listDate
                      ?.slice(9, this.state.listDate?.length)
                      .map((_, index) => {
                        return <th key={index}>empty</th>;
                      })}

                    <th>Giáo viên</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                    <th>empty</th>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {this.state.listcutAll.map((student, index) => {
                    return (
                      <tr>
                        <td> {index + 1} </td>
                        <td> {student.class_name} </td>
                        <td>{student.count}</td>
                        {this.state.listDate?.map((item) => {
                          return (
                            <td className="align-middle text-center">
                              {new Date(
                                this.state.startMonth.getFullYear(),
                                this.state.startMonth.getMonth(),
                                item.label
                              ).setHours(0, 0, 0) -
                                new Date().setHours(0, 0, 0) >
                                0 ||
                              this.findDate(item.value, student.detail)
                                ?.countStudent == undefined
                                ? "-"
                                : this.findDate(item.value, student.detail)
                                    ?.countStudent -
                                  this.findDate(item.value, student.detail)
                                    ?.countStudentOff}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}

                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th> Hiện diện</th>
                    <th>empty</th>
                    <th>{this.getAllStudent()}</th>
                    {this.state.listDate?.map((item) => {
                      return (
                        <th className="align-middle text-center">
                          {this.sumHienDien(item.value)}
                        </th>
                      );
                    })}
                  </tr>
                  <tr style={{ backgroundColor: "#ddb892" }}>
                    <th>Vắng</th>
                    <th>empty</th>
                    <th>empty</th>

                    {this.state.listDate?.map((item) => {
                      return (
                        <th className="align-middle text-center">
                          {this.sumVang(item.value)}
                        </th>
                      );
                    })}
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
        )}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid>
          <Breadcrumb>
            <Breadcrumb.Item active>Báo cáo</Breadcrumb.Item>
            <Breadcrumb.Item active>Chi tiết sĩ số</Breadcrumb.Item>
          </Breadcrumb>
          <Card>
            <Card.Header>
              <Card.Title
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                className="d-lg-flex"
              >
                <div>
                  <i className="fas fa-list me-1"></i>
                  <span style={{ padding: "0 10px", fontSize: "1.13rem" }}>
                    Báo cáo hiện diện tháng{" "}
                    {this.state.startMonth.getMonth() + 1} /
                    {this.state.startMonth.getFullYear()}{" "}
                  </span>
                </div>

                <div className="d-flex">
                  <DatePickerCustom
                    className="me-2"
                    selected={this.state.startMonth}
                    onChange={(date) => {
                      this.setState({
                        startMonth: date,
                      });
                      this.showDate(date);
                      {
                        this.getStudentbyClass(
                          this.state.chociceClass.value,
                          date,
                          date
                        );
                        this.getClassRoom(date, date);
                      }
                    }}
                    showMonthYearPicker
                  />
                  <Select
                    className="ms-2"
                    options={this.state.classrooms}
                    value={this.state.chociceClass}
                    onChange={(choice) => {
                      this.setState({ chociceClass: choice });

                      if (choice.value > 0)
                        this.getStudentbyClass(
                          choice.value,
                          this.state.startMonth,
                          this.state.startMonth
                        );
                    }}
                    styles={this.customStyles}
                  ></Select>
                  <Button
                    variant="success"
                    size="sm"
                    style={{
                      whiteSpace: "nowrap",
                      float: "right",
                      padding: "2px",
                    }}
                    onClick={() => this.handleExport()}
                  >
                    Xuất file <i className="fa-solid fa-file-export ms-1"></i>
                  </Button>
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {this.renderCutMealListTable()}
              {this.renderCutMealListTableExport()}
            </Card.Body>
          </Card>
        </Container>
      </>
    );
  }
}

export default Report;
