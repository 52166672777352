import React, { Component } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { Button, Form, Modal, Table, Row, Col } from "react-bootstrap";
import moment from "moment";
import NotificationBell from "./NotificationBell";
import api from "../../helper/axiosInstance";
import UserEditModal from "../../components/admin2/user/UserEditModal";

class NavbarFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listUser: [],
      user: {
        id_user: "",
        user_identifier: "",
        user_name: "",
        user_gender: "",
        user_birthday: "",
        user_ethnic: "",
        user_phone: "",
        user_contract: "",
        user_diploma: "",
        user_account: "",
        password: "",
        id_role: localStorage.getItem("role_rank"),
        is_meal: 0,
      },
      listEthnic: [],
      showModalUser: false,
      listrole: [
        {
          id: localStorage.getItem("role_id"),
          role_name: localStorage.getItem("role_name"),
        },
      ],
      showAlarm: false,
      ingredent_alarmlist: [],
      count: 0,
      showNotification: false,
    };
  }

  async componentDidMount() {
    // this.getAllUser();
    // this.getlistrole();
    // this.getIngredent();
    // this.getEthnic();
  }

  handleClose = () => {
    this.setState({ show: false, showAlarm: false });
  };

  // getAllUser = () => {
  //   api
  //     .get("/showAllUser")
  //     .then((res) => {
  //       if (res.data?.status) {
  //         this.setState({
  //           listUser: res.data.data,
  //           user: res.data.data.filter(
  //             (e) =>
  //               Number(e.id) === Number(localStorage.getItem("auth_id_user"))
  //           )[0],
  //         });
  //       }
  //     })
  //     .catch((error) => {});
  // };

  getEthnic = () => {
    api
      .get("/viewEthnic")
      .then((res) => {
        this.setState({
          listEthnic: res.data?.data || [],
        });
      })
      .catch((error) => {});
  };

  sidenavToggle = (event) => {
    document
      .getElementById("sb-nav-fixed")
      .classList.toggle("sb-sidenav-toggled");
  };

  dateNotTime(data) {
    let date = new Date(data * 1000);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );
  }

  logout = () => {
    swal({
      title: "Bạn muốn đăng xuất?",
      icon: "warning",
      buttons: ["Đóng", "Đồng ý"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        api
          .post("/logout")
          .then(
            (res) => {
              toast(res.data.message, { type: "success" });

              localStorage.removeItem("auth_id_shool");
              localStorage.removeItem("auth_id_user");
              // localStorage.removeItem("auth_name");
              localStorage.removeItem("permission");
              localStorage.removeItem("access_token");
              localStorage.removeItem("version");

              this.props.navHook("/login");
            },
            [this.navigate]
          )
          .catch((error) => {
            toast(error.response.data?.messages || "Đăng xuất thất bại!", {
              type: "error",
            });
          });
      }
    });
  };

  getDate = (data) => {
    let date = new Date(data * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  updateuser = (e) => {
    e.preventDefault();

    if (this.state.user?.user_identifier.length > 35) {
      toast("Mã định danh không được nhiều hơn 35 kí tự", {
        type: "error",
        autoClose: 2000,
      });

      return;
    }

    if (
      Number(
        moment(moment(this.state.user?.user_birthday * 1000), "YYYY")
          .fromNow()
          .split(" ")[0]
      ) < 18
    ) {
      toast("Sai ngày sinh không hợp lệ", {
        type: "error",
        autoClose: 1000,
      });
      return;
    }

    if (this.state.user?.user_phone !== "") {
      if (
        !this.state.user?.user_phone.match(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4,5})$/
        )
      ) {
        toast("Sai định dạng điện thoại", {
          type: "error",
          autoClose: 1000,
        });
        return;
      }
    }

    swal({
      title: "Bạn muốn cập nhật tài khoản?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        api
          .post(`/updateUser`, null, {
            params: {
              id_user: localStorage.getItem("auth_id_user"),
              user_identifier: this.state.user?.user_identifier || "",
              user_name: this.state.user?.user_name || "",
              user_account: this.state.user?.user_account || "",
              user_password: this.state.user?.password || "",
              user_gender: this.state.user?.user_gender || "",
              user_birthday: this.state.user?.user_birthday,
              user_ethnic: this.state.user?.user_ethnic,
              user_phone: this.state.user?.user_phone,
              user_contract: this.state.user?.user_contract || "",
              user_diploma: this.state.user?.user_diploma || "",
              id_role: this.state.user?.id_role || "",
              is_meal: this.state.user?.is_meal || 0,
            },
          })
          .then((res) => {
            if (res.data.status) {
              this.handleClose();
              this.getAllUser();
              localStorage.setItem("auth_name", this.state.user?.user_name);
              this.setState({ id_user: "" });
              toast(`${res.data.data.mess}`, {
                type: "success",
                autoClose: 1000,
              });
            } else {
              toast(`${res.data.data.mess}`, {
                type: "error",
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  getIngredent = () => {
    api
      .get("/viewInventoryAll")
      .then((res) => {
        this.setState({
          ingredent_alarmlist:
            res.data?.data.filter(
              (item) =>
                (item.expriry_date >
                  new Date(new Date().setHours(0, 0, 0)) / 1000 &&
                  new Date(new Date().setHours(0, 0, 0)) / 1000) +
                  parseInt(item.ingredent_alarm) * 86400 >=
                item.expriry_date
            ) || [],
          count: res.data?.data.filter(
            (item) =>
              (item.expriry_date >
                new Date(new Date().setHours(0, 0, 0)) / 1000 &&
                new Date(new Date().setHours(0, 0, 0)) / 1000) +
                parseInt(item.ingredent_alarm) * 86400 >=
              item.expriry_date
          ).length,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // getlistrole = () => {
  //   api
  //     .post("/viewRole")
  //     .then((res) => {
  //       if (res.data?.status) {
  //         this.setState({
  //           listrole: res.data.data.filter(
  //             (e) => e.is_rank >= Number(localStorage.getItem("role_rank"))
  //           ),
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     });
  // };

  handleShowAlarm = () => {
    this.getIngredent();
    if (this.state.count > 0) this.setState({ showAlarm: true });
  };

  handleUpdate = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    if (event.target.name === "is_meal") {
      if (event.target.checked) {
        value = 1;
      } else {
        value = 0;
      }
    }
    let user = { ...this.state.user };
    user[field] = value;
    this.setState({ user: user });
  };

  updateToken = () => {
    api.defaults.headers["Authorization"] =
      "Bearer " + localStorage.getItem("access_token");
  };
  renderModal = () => {
    return (
      <>
        <Modal
          show={this.state.show}
          size="md"
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa-solid fa-chalkboard-user"></i>&nbsp; Thông tin
            </Modal.Title>
          </Modal.Header>

          <Form onSubmit={(e) => this.updateuser(e)}>
            <Modal.Body>
              <Form.Floating className="mb-3">
                <Form.Control
                  name="user_identifier"
                  type="text"
                  onChange={this.handleUpdate}
                  defaultValue={this.state.user?.user_identifier}
                  placeholder="Mã định danh Bộ GD&ĐT"
                  required
                />
                <Form.Label>
                  Mã định danh Bộ GD&ĐT<i className="text-danger">*</i>
                </Form.Label>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  name="user_name"
                  onChange={this.handleUpdate}
                  defaultValue={this.state.user?.user_name}
                  placeholder="Họ và tên"
                  required
                />
                <Form.Label>
                  Họ và tên<i className="text-danger">*</i>
                </Form.Label>
              </Form.Floating>

              <Row>
                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      defaultValue={this.state.user?.user_gender}
                      onChange={this.handleUpdate}
                      name="user_gender"
                      required
                    >
                      <option value="">--Chọn--</option>
                      <option value="1">Nam</option>
                      <option value="2">Nữ</option>
                      <option value="3">Khác</option>
                    </Form.Select>
                    <Form.Label>
                      Giới tính<i className="text-danger">*</i>
                    </Form.Label>
                  </Form.Floating>
                </Col>

                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <input
                      type="date"
                      className="form-control"
                      required
                      defaultValue={moment(
                        new Date(this.state.user?.user_birthday * 1000)
                      ).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        this.setState({
                          user: {
                            ...this.state.user,
                            user_birthday:
                              new Date(e.target.value).getTime() / 1000,
                          },
                        });
                      }}
                    />
                    <Form.Label>
                      Ngày sinh<i className="text-danger">*</i>
                    </Form.Label>
                  </Form.Floating>
                </Col>

                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      defaultValue={this.state.user?.user_ethnic}
                      onChange={this.handleUpdate}
                      name="user_ethnic"
                      required
                    >
                      <option value="">--Chọn--</option>
                      {this.state.listEthnic.length > 0
                        ? this.state.listEthnic.map((ethnic_) => {
                            return (
                              <option key={ethnic_.id} value={ethnic_.id}>
                                {ethnic_.ethnic_name}
                              </option>
                            );
                          })
                        : null}
                    </Form.Select>
                    <Form.Label>
                      Dân tộc<i className="text-danger">*</i>
                    </Form.Label>
                  </Form.Floating>
                </Col>
              </Row>

              <Form.Floating className="mb-3">
                <Form.Control
                  className="form-control"
                  defaultValue={this.state.user?.user_phone}
                  onChange={(e) => {
                    this.setState({
                      user: {
                        ...this.state.user,
                        user_phone: e.target.value,
                      },
                    });
                  }}
                  placeholder="Số điện thoại"
                  id="user_phone"
                  name="user_phone"
                />
                <Form.Label>Số điện thoại</Form.Label>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Select
                  onChange={this.handleUpdate}
                  defaultValue={this.state.user?.id_role}
                  name="id_role"
                  required
                  disabled={this.state.user?.id_role === 1}
                >
                  <option value="">--Chọn--</option>
                  {this.state.listrole.map((role) => {
                    return (
                      <option key={role.id} value={role.id}>
                        {role.role_name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Label>
                  Chức vụ<i className="text-danger">*</i>
                </Form.Label>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  name="user_contract"
                  onChange={this.handleUpdate}
                  defaultValue={this.state.user?.user_contract}
                  placeholder="Hình thức hợp đồng"
                />
                <Form.Label>Hình thức hợp đồng</Form.Label>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  name="user_diploma"
                  onChange={this.handleUpdate}
                  defaultValue={this.state.user?.user_diploma}
                  placeholder="Trình độ chuyên môn"
                />
                <Form.Label>Trình độ chuyên môn</Form.Label>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  onChange={this.handleUpdate}
                  defaultValue={this.state.user?.user_account}
                  name="user_account"
                  placeholder="Tài khoản"
                />
                <Form.Label>
                  Tài khoản<i className="text-danger"></i>
                </Form.Label>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Control
                  type="password"
                  name="password"
                  onChange={this.handleUpdate}
                  defaultValue={""}
                  placeholder="Mật khẩu"
                />
                <Form.Label>
                  Mật khẩu<i className="text-danger"></i>
                </Form.Label>
              </Form.Floating>

              <Form.Check
                className="mb-3"
                type="checkbox"
                label="Không ăn ở trường"
                name="is_meal"
                onChange={this.handleUpdate}
                checked={this.state.user?.is_meal}
              />
            </Modal.Body>

            <Modal.Footer>
              <Button
                size="sm"
                type="button"
                variant="secondary"
                onClick={this.handleClose}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              <Button size="sm" type="submit" variant="success">
                <i className="fa-solid fa-pencil"></i> Cập nhật
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          show={this.state.showAlarm}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Hàng sắp hết hạn</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th style={{ width: "60px" }}> STT</th>
                  <th style={{ width: "170px" }}> Tên nguyên liệu</th>
                  <th style={{ width: "120px" }}> Lô</th>
                  <th style={{ width: "120px" }}> Ngày hết hạn</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: "36px" }}></tr>
                {this.state.ingredent_alarmlist?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ width: "60px" }}>{index + 1}</td>
                      <td style={{ width: "170px" }}> {item.ingredent_name}</td>
                      <td style={{ width: "120px" }}>{item.code_inventory}</td>
                      <td style={{ width: "120px" }}>
                        {
                          this.dateNotTime(item.expriry_date)
                          // new FormatData().dateNotTime1(item.expriry_date)
                          // new Date(item.expriry_date *1000)
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              onClick={() => {
                this.setState({ showAlarm: false });
                this.props.navHook("/admin-v2/store");
              }}
            >
              {" "}
              Tới kho hàng
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  render() {
    return (
      <>
        {this.renderModal()}

        <nav className="sb-topnav bg-primary navbar-dark  menu-phone px-3">
          <Link
            className="d-flex flex-column align-items-center "
            to={`/admin${
              localStorage.getItem("version") === "2.0" ? "-v2" : ""
            }`}
          >
            <i className="fa-solid fa-house fa-lg text-white"></i>
            <span className="text-white pt-2">Tổng quan</span>
          </Link>

          <div
            className="d-flex flex-column align-items-center"
            onClick={this.sidenavToggle}
          >
            <i className="fas fa-bars text-white fa-lg" id="sidebarToggle"></i>
            <span className="text-white pt-2">Danh mục</span>
          </div>

          <div className="d-flex flex-column align-items-center">
            <NotificationBell />
            <span className="text-white pt-2">Thông báo</span>
          </div>

          <div
            className="d-flex flex-column align-items-center"
            onClick={() => (window.location.href = "tel:+84889881010")}
          >
            <i className="fa-solid fa-phone-volume fa-lg text-white"></i>
            <span className="text-white pt-2">Liên hệ</span>
          </div>

          <ul className="navbar-nav">
            <li className="nav-item dropdown d-flex flex-column align-items-center">
              <Link
                className="nav-link dropdown-toggle text-light pb-0"
                id="navbarDropdown"
                to="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-user fa-lg "></i>
              </Link>
              <span className="text-white">Tài khoản</span>

              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link
                    className="dropdown-item"
                    onClick={() => this.setState({ showModalUser: true })}
                  >
                    <i className="fa-solid fa-gear"></i> Thông tin
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    className="dropdown-item text-danger"
                    onClick={this.logout}
                  >
                    <i className="fa-solid fa-right-from-bracket"></i> Đăng xuất
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>

        {this.state.showModalUser ? (
          <UserEditModal
            show={this.state.showModalUser}
            onHide={() => this.setState({ showModalUser: false })}
            onSave={() => this.setState({ showModalUser: false })}
            userId={localStorage.getItem("auth_id_user")}
            isType="private"
          />
        ) : null}
      </>
    );
  }
}

function myParams(Component) {
  return (props) => (
    <Component navHook={useNavigate()} {...props} params={useParams()} />
  );
}

export default myParams(NavbarFooter);
