import { BrowserRouter, Routes, Route } from "react-router-dom";

import "sweetalert/dist/sweetalert.min";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Home from "../components/frontend/Home";
import Login from "../components/frontend/auth/Login";

import ErrorHome404 from "../components/admin/errors/ErrorHome404";

import MasterLayout2 from "../layouts/admin2/MasterLayout";
import Dashboard2 from "../components/admin2/Dashboard";

import MenuIndex2 from "../components/admin2/menu/menu/MenuIndex";
import MenuIndexV2 from "../components/admin2/menuV2/menu/MenuIndex";
import PortionCalculate from "../components/admin2/menu/portionCalculate/PortionCalculate";
import NutritionEnergyNew from "../components/admin2/menu/nutritionEnergy/NutritionEnergy";
import MarketList2 from "../components/admin2/menu/marketList/MarketList";
import MarketListV2 from "../components/admin2/menuV2/marketList/MarketList";
import QuantitativeKitchen2 from "../components/admin2/menu/quantitativeKitchen/QuantitativeKitchen";

import IngredientIndex2 from "../components/admin2/ingredient/IngredientIndex";
import BankIngredient2 from "../components/admin2/ingredient/BankIngredent";

import FoodIndex2 from "../components/admin2/food/FoodIndex";
import BankFood2 from "../components/admin2/food/BankFood";

import AbsentReportIndex2 from "../components/admin/report/absentreport/AbsentReportIndex";
import ReportMealDetail2 from "../components/admin/report/absentreport/ReportMealDetail";

import IngredientPriceReport2 from "../components/admin2/report/ingredientPrice/IngredientPriceReport";
import FinanceBookV2 from "../components/admin2/report/financereport2/FinanceBook";
import FinanceReport2 from "../components/admin2/report/financereport/FinanceReport";
import SupplierShoppingReportIndex2 from "../components/admin2/report/suppliershoppingreport/SupplierShoppingReportIndex";

import ThreeStepBook2 from "../components/admin2/form/ThreeStepBook";
import SampleBook2 from "../components/admin2/form/SampleBook";
import FormSetting2 from "../components/admin2/form/FormSetting";

import UnitIndex2 from "../components/admin2/unit/UnitIndex";
import YearSchool2 from "../components/admin2/yearschool/YearSchool";
import SettingIndex2 from "../components/admin2/setting/MainSetting";
import CreateSetting2 from "../components/admin2/setting/CreateSetting";
import DetailSchool2 from "../components/admin2/school/DetailSchool";

// Supplier
import Supplier2 from "../components/admin2/Supplier/SupplierIndex";
import SupplierHistory2 from "../components/admin2/Supplier/SupplierIndexHistory";
// Student
import StudentIndexV2 from "../components/admin2/studentV2/StudentIndex";
import GroupIndexV2 from "../components/admin2/groupV2/GroupIndex";
// Meal Cut V2
import ListmealIndexV2 from "../components/admin2/mealV2/Listmeal/ListmealIndex";
import MealcutIndexV2 from "../components/admin2/mealV2/MealcutIndex";
import ReportV2 from "../components/admin2/mealV2/ReportAttendance/Report";
import ListCutV2 from "../components/admin2/mealV2/ListCut";
import ReportMealDetailV2 from "../components/admin2/mealV2/ReportMealDetail";
// User
import UserIndex2 from "../components/admin2/user/UserIndex";
import RoleIndex2 from "../components/admin2/role/RoleIndex";

// Store
import BuyIndex2 from "../components/admin2/store/BuyIndex";
import Storeinventory2 from "../components/admin2/store/Storeinventory";
import SummaryStoreMaterial2 from "../components/admin2/store/SummaryStoreMaterial";
import ExportMaterial2 from "../components/admin2/store/ExportMaterial";
import StoreIndex2 from "../components/admin2/store/StoreIndex";
import Receipt from "../components/admin2/store/Receipt";

// Subjects
import SubjectsIndex from "../components/admin2/subjects/SubjectsIndex";
import SudyGroupIndex from "../components/admin2/subjects/StudyGroup/StudyGroupIndex";

// Parent Accounts
import ParentAccountsIndex from "../components/admin2/parentAccount/ParentAccountsIndex";

// Schedule
import ScheduleIndex from "../components/admin2/schedule/ScheduleIndex";
import TuitionIndex from "../components/admin2/tuition/TuitionIndex";
import BillIndex from "../components/admin2/bill/BillIndex";
import EvaluationIndex from "../components/admin2/evaluation/EvaluationIndex";
import ResultEvaluation from "../components/admin2/evaluation/ResultEvaluation";
import MedicationIndex from "../components/admin2/medication/MedicationIndex";
import PostIndex from "../components/admin2/post/PostIndex";
import PaymentIndex from "../components/admin2/payment/PaymentIndex";
import NotificationIndex from "../components/admin2/notification/NotificationIndex";
import VaccinationIndex from "../components/admin2/vaccination/VaccinationIndex";
import Check from "../components/admin2/other/Check";
import RegisterMealIndex from "../components/admin2/registerMeal/RegisterMeal";

import PortionCalculateV2 from "../components/admin2/menuV2/portionCalculate/PortionCalculate";
import DeadLineRegister from "../components/admin2/registerMeal/DeadLineRegister";
import QuantitativeKitchen from "../components/admin2/quantitativeKitchenRegisterMeal/QuantitativeKitchen";
import ReportRegister from "../components/admin2/reportRegister/ReportRegister";
import DetailSchool from "../components/admin2/school/SchoolNew";
// ********** Admin 2 ********** //
import DiscountIndex from "../components/admin2/discount/DiscountIndex";
import Dashboard from "../components/admin2/dashboard/Dashboard";

const admin2Routes = [
  {
    id: 1,
    component: <Dashboard />,
    path: "dashboard",
    permission: true,
  },
  {
    id: 2,
    component: <ListmealIndexV2 />,
    path: "meal",
    permission: new Check().permission(["72"]),
  },
  {
    id: 3,
    component: <MealcutIndexV2 />,
    path: "meal-cut",
    permission: new Check().permission([73, 72], "or"),
  },
  {
    id: 4,
    component: <ReportV2 />,
    path: "student-number-report",
    permission: new Check().permission(["72"]),
  },
  {
    id: 5,
    component: <StudentIndexV2 />,
    path: "student",
    permission: new Check().permission(["17"]),
  },
  {
    id: 6,
    component: <GroupIndexV2 />,
    path: "student-group",
    permission: new Check().permission(["7"]),
  },
  {
    id: 8,
    component: <MenuIndex2 />,
    path: "menu",
    permission: new Check().permission(["52"]),
  },
  {
    id: 9,
    component: <PortionCalculate />,
    path: "portion-calculate",
    permission: new Check().permission(["120"]),
  },

  {
    id: 32,
    component: <NutritionEnergyNew />,
    path: "nutrition-report",
    permission: new Check().permission(["56"]),
  },
  {
    id: 33,
    component: <MarketList2 />,
    path: "market",
    permission: new Check().permission(["116"]),
  },

  {
    id: 34,
    component: <QuantitativeKitchen2 />,
    path: "quantitative-kitchen",
    permission: new Check().permission(["57"]),
  },

  // #region Ingredient
  {
    id: 10,
    component: <IngredientIndex2 />,
    path: "ingredient",
    permission: new Check().permission(["40"]),
  },
  {
    id: 11,
    component: <BankIngredient2 />,
    path: "bank-ingredient",
    permission: new Check().permission(["44"]),
  },

  // #region Foods
  {
    id: 12,
    component: <FoodIndex2 />,
    path: "food",
    permission: new Check().permission(["28"]),
  },
  {
    id: 13,
    component: <BankFood2 />,
    path: "bank-food",
    permission: new Check().permission(["33"]),
  },

  // Setting
  {
    id: 14,
    component: <UnitIndex2 />,
    path: "unit",
    permission: new Check().permission(["67"]),
  },
  {
    id: 15,
    component: <SettingIndex2 />,
    path: "mainsetting",
    permission: new Check().permission(["59"]),
  },
  {
    id: 16,
    component: <CreateSetting2 />,
    path: "mainsetting/create-setting",
    permission: new Check().permission(["59"]),
  },
  {
    id: 17,
    component: <YearSchool2 />,
    path: "year-school",
    permission: new Check().permission(["100"]),
  },
  {
    id: 18,
    component: <Supplier2 />,
    path: "supplier",
    permission: new Check().permission(["23"]),
  },
  {
    id: 19,
    component: <SupplierHistory2 />,
    path: "price-history",
    permission: new Check().permission(["23"]),
  },
  // {
  //   id: 20,
  //   component: <DetailSchool2 />,
  //   path: "school",
  //   permission: new Check().permission([159]),
  // },

  // User
  {
    id: 21,
    component: <UserIndex2 />,
    path: "user",
    permission: new Check().permission(["2"]),
  },

  {
    id: 22,
    component: <RoleIndex2 />,
    path: "role",
    permission: new Check().permission(["2"]),
  },

  // Form
  {
    id: 23,
    component: <ThreeStepBook2 />,
    path: "3-step-book",
    permission: new Check().permission(["77"]),
  },
  {
    id: 24,
    component: <SampleBook2 />,
    path: "samplebook",
    permission: new Check().permission(["78"]),
  },
  {
    id: 24,
    component: <FormSetting2 />,
    path: "form-setting",
    permission: new Check().permission(["79"]),
  },

  // Report
  {
    id: 25,
    component: <AbsentReportIndex2 />,
    path: "absent-report",
    permission: new Check().permission(["83"]),
  },
  {
    id: 26,
    component: <IngredientPriceReport2 />,
    path: "report-expense-ingredients",
    permission: new Check().permission([116, 84], "or"),
  },
  // {
  //   id: 27,
  //   component: <FinanceBook2 />,
  //   path: "finance-book",
  //   permission: new Check().permission(["89"]),
  // },
  {
    id: 271,
    component: <FinanceBookV2 />,
    path: "finance-book",
    permission: new Check().permission(["89"]),
  },
  {
    id: 28,
    component: <ReportMealDetail2 />,
    path: "report-detail",
    permission: new Check().permission(["92"]),
  },
  {
    id: 28,
    component: <ReportMealDetailV2 />,
    path: "report-student-attendance",
    permission: new Check().permission(["92"]),
  },
  {
    id: 29,
    component: <FinanceReport2 />,
    path: "finance-report",
    permission: new Check().permission(["90"]),
  },
  {
    id: 30,
    component: <SupplierShoppingReportIndex2 />,
    path: "report-supplier",
    permission: new Check().permission(["91"]),
  },
  {
    id: 31,
    component: <ListCutV2 />,
    path: "report-meal-cuts",
    permission: new Check().permission(["82"]),
  },
  {
    id: 34,
    component: <BuyIndex2 />,
    path: "buy",
    permission: new Check().permission(["47"]),
  },
  {
    id: 35,
    component: <Storeinventory2 />,
    path: "store-inventory",
    permission: new Check().permission([46]),
  },
  {
    id: 36,
    component: <SummaryStoreMaterial2 />,
    path: "store-material",
    permission: new Check().permission([46]),
  },
  {
    id: 37,
    component: <ExportMaterial2 />,
    path: "material",
    permission: new Check().permission([48]),
  },
  {
    id: 38,
    component: <StoreIndex2 />,
    path: "store",
    permission: new Check().permission(["46"]),
  },
  {
    id: 39,
    component: <Receipt />,
    path: "receipt",
    permission: new Check().permission(["46"]),
  },

  // Subject
  {
    id: 40,
    component: <SubjectsIndex />,
    path: "subject",
    permission: new Check().permission([139]),
  },
  {
    id: 41,
    component: <SudyGroupIndex />,
    path: "study-group",
    permission: new Check().permission([144]),
  },

  // Parent Accounts
  {
    id: 42,
    component: <ParentAccountsIndex />,
    path: "parent-account",
    permission: new Check().permission([124]),
  },
  {
    id: 43,
    component: <ScheduleIndex />,
    path: "schedule/:classId",
    permission: new Check().permission([106]),
  },
  {
    id: 44,
    component: <TuitionIndex />,
    path: "tuition",
    permission: new Check().permission([134]),
  },
  {
    id: 44,
    component: <BillIndex />,
    path: "bill",
    permission: new Check().permission(["129"]),
  },
  {
    id: 45,
    component: <EvaluationIndex />,
    path: "evaluation",
    permission: new Check().permission(["111"]),
  },
  {
    id: 46,
    component: <ResultEvaluation />,
    path: "evaluation/:idevaluation",
    permission: new Check().permission(["111"]),
  },
  {
    id: 47,
    component: <MedicationIndex />,
    path: "medication",
    permission: new Check().permission([86]),
  },
  {
    id: 48,
    component: <PaymentIndex />,
    path: "payment-setting",
    permission: new Check().permission([160]),
  },
  {
    id: 49,
    component: <PostIndex />,
    path: "post",
    permission: new Check().permission([149, 154], "or"),
  },
  {
    id: 50,
    component: <NotificationIndex />,
    path: "notification",
    permission: new Check().permission([161]),
  },
  {
    id: 51,
    component: <VaccinationIndex />,
    path: "vaccination",
    permission: new Check().permission([161]),
  },
  {
    id: 52,
    component: <MenuIndexV2 />,
    path: "kitchen-menu",
    permission: new Check().permission([163]),
  },
  {
    id: 53,
    component: <RegisterMealIndex />,
    path: "kitchen-register-meal",
    permission: new Check().permission([164, 165, 169], "or"),
  },
  {
    id: 9,
    component: <PortionCalculateV2 />,
    path: "kitchen-portion-calculate",
    permission: new Check().permission([163]),
  },
  {
    id: 33,
    component: <MarketListV2 />,
    path: "kitchen-voucher-daily",
    permission: new Check().permission([168]),
  },
  {
    id: 54,
    component: <DeadLineRegister />,
    path: "kitchen-deadline-register-meal",
    permission: new Check().permission([165]),
  },
  {
    id: 55,
    component: <QuantitativeKitchen />,
    path: "kitchen-ingredient-quantity",
    permission: new Check().permission([167]),
  },
  {
    id: 56,
    component: <ReportRegister />,
    path: "kitchen-report-register-meal",
    permission: new Check().permission([166]),
  },
  {
    id: 57,
    component: <DetailSchool />,
    path: "school",
    permission: new Check().permission([159]),
  },
  {
    id: 58,
    component: <DiscountIndex />,
    path: "discount",
    // permission: new Check().permission([159]),
    permission: true,
  },
];

export default function router() {
  return (
    <>
      <ToastContainer autoClose={1000} />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />

          <Route path="/admin-v2" element={<MasterLayout2 />}>
            <Route index element={<Dashboard2 />} />
            {admin2Routes.map((route) => {
              if (!route.permission) {
                return "";
              }

              return (
                <Route
                  key={route.id}
                  path={route.path}
                  element={route.component}
                />
              );
            })}
          </Route>
          <Route path="*" element={<ErrorHome404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
