import React, { useState } from "react";
import { Table, Row, Col, Form } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import Select from "react-select";
import {
  groupArrayByField,
  findeQuantitativeZone,
  isInRange,
  getNutritionalColorByRange,
} from "../../../utils/helpers";
import { toast } from "react-toastify";
import { roundDownToDecimals } from "../../../../../helper/texts";

const unqualified = { color: "red" };

const QuantitativeTable = ({
  quantitative,
  setQuantitative,
  selectedClassGroup,
  isChangeMarket,
  suppliers,
}) => {
  const [wasWarning, setWasWarning] = useState(false);
  const rangeQuantity = findeQuantitativeZone(selectedClassGroup.id_age_group);

  if (!quantitative) {
    return "";
  }

  // Get init data
  const currentCostPerDays = quantitative.costPerDays?.find(
    (costPerDay) => costPerDay.id_group === selectedClassGroup.id
  );
  const numberChildren = currentCostPerDays?.meal_quantity || 0;

  // Nutrition variables
  let proteinStat = 0;
  let lipidStat = 0;
  let glucidStat = 0;

  // Change data structure of quantitative to ingredient array
  let ingredientArray = [];
  let extraIngredientArray = [];

  let foodIdMoreArray = [];
  let foodIdMainArray = [];

  quantitative.times?.map((timeItem) => {
    timeItem.foods?.map((foodItem) => {
      foodItem.groups
        ?.find(
          (groupItem) =>
            groupItem.id === selectedClassGroup.id &&
            groupItem.more_meal === null
        )
        ?.ingredents?.map((ingredientItem) => {
          ingredientArray.push({ ...ingredientItem, id_food: foodItem.id });
          const ingredientQuantity = isChangeMarket
            ? ingredientItem.tmp_ing_quantity
            : ingredientItem.ing_quantity;

          const gramOneChild = Number(
            ((ingredientQuantity * ingredientItem.volume_market) /
              ingredientItem.volume_cook) *
              ((100 - ingredientItem.ingredent_ratio) / 100)
          );

          proteinStat +=
            (gramOneChild * Number(ingredientItem.ingredent_protein)) / 100;
          lipidStat +=
            (gramOneChild * Number(ingredientItem.ingredent_lipid)) / 100;
          glucidStat +=
            (gramOneChild * Number(ingredientItem.ingredent_glucid)) / 100;

          if (!foodIdMainArray.includes(foodItem.id))
            foodIdMainArray.push(foodItem.id);

          return ingredientItem;
        });

      foodItem.groups.map((groupItem) => {
        if (
          groupItem.id === selectedClassGroup.id &&
          groupItem.more_meal > 0 &&
          groupItem.more_meal !== null
        ) {
          groupItem?.ingredents?.map((ingredientItem) => {
            extraIngredientArray.push({
              ...ingredientItem,
              mealQuantity: groupItem.more_meal,
            });

            return ingredientItem;
          });

          if (!foodIdMoreArray.includes(foodItem.id))
            foodIdMoreArray.push(foodItem.id);
        }

        return groupItem;
      });

      return foodItem;
    });
    return timeItem;
  });

  const groupIngredientArray = groupArrayByField(ingredientArray, "id").sort(
    (a, b) => {
      let amountA = a.reduce((accumulator, currentValue) => {
        return Number(
          accumulator +
            (currentValue.ing_quantity * currentValue.volume_market) /
              currentValue.volume_cook
        );
      }, 0);

      let amountB = b.reduce((accumulator, currentValue) => {
        return Number(
          accumulator +
            (currentValue.ing_quantity * currentValue.volume_market) /
              currentValue.volume_cook
        );
      }, 0);

      return a[0].ingredent_type - b[0].ingredent_type || amountB - amountA;
    }
  );

  const groupExtraIngredientArray = groupArrayByField(
    extraIngredientArray,
    "id"
  ).sort((a, b) => {
    let amountA = a.reduce((accumulator, currentValue) => {
      return Number(
        accumulator +
          (currentValue.ing_quantity * currentValue.volume_market) /
            currentValue.volume_cook
      );
    }, 0);

    let amountB = b.reduce((accumulator, currentValue) => {
      return Number(
        accumulator +
          (currentValue.ing_quantity * currentValue.volume_market) /
            currentValue.volume_cook
      );
    }, 0);

    return a[0].ingredent_type - b[0].ingredent_type || amountB - amountA;
  });

  let totalMoney = 0;
  let totalExtraMoney = 0;

  // Calculate nutrition
  const plgTotal = proteinStat * 4 + lipidStat * 9 + glucidStat * 4;

  const proteinRatio = (proteinStat * 100 * 4) / plgTotal;
  const lipidRatio = (lipidStat * 100 * 9) / plgTotal;
  const glucidRatio = (glucidStat * 100 * 4) / plgTotal;

  const proteinColor = getNutritionalColorByRange(
    proteinRatio,
    "PROTEIN",
    rangeQuantity
  );

  const lipidColor = getNutritionalColorByRange(
    lipidRatio,
    "LIPID",
    rangeQuantity
  );

  const glucidColor = getNutritionalColorByRange(
    glucidRatio,
    "GLUCID",
    rangeQuantity
  );

  const isProteinQualified =
    plgTotal !== 0
      ? isInRange((proteinStat * 100 * 4) / plgTotal, "PROTEIN", rangeQuantity)
        ? false
        : true
      : false;

  const isLipidQualified =
    plgTotal !== 0
      ? isInRange((lipidStat * 100 * 9) / plgTotal, "LIPID", rangeQuantity)
        ? //   (lipidStat * 100 * 9) / plgTotal < 26
          false
        : true
      : false;

  const isGlucidQualified =
    plgTotal !== 0
      ? isInRange((glucidStat * 100 * 4) / plgTotal, "GLUCID", rangeQuantity)
        ? //   (glucidStat * 100 * 4) / plgTotal < 60
          false
        : true
      : false;

  return (
    <div className="mb-2" style={{ overflowY: "scroll", maxHeight: "446px" }}>
      <Row>
        <Col>
          <i className="fa-solid fa-arrow-up text-success" /> Cần tăng định
          lượng; <i className="fa-solid fa-arrow-down text-danger" /> Cần giảm
          định lượng;
        </Col>
      </Row>

      {groupIngredientArray.length > 0 ? (
        <Table bordered hover>
          <thead className="align-middle text-center sticky-md-top bg-light">
            <tr>
              <th>STT</th>
              <th>Tên thực phẩm</th>
              <th>ĐVT</th>
              <th style={{ minWidth: "10rem" }}>Nhà cung cấp</th>
              <th style={{ minWidth: "6rem" }}>Giá tiền (đ)</th>
              <th>1 trẻ (g)</th>
              <th>Ăn được (g)</th>
              <th>Đi chợ (g)</th>
              <th>Giá 100g (đ)</th>
              <th style={{ minWidth: "5rem" }}>Đi chợ theo ĐVT</th>
              <th>Thành tiền (đ)</th>
            </tr>
          </thead>

          <tbody>
            {groupIngredientArray.map((groupIngredientItem, index) => {
              let sumQuantity = 0;

              groupIngredientItem?.map((ingredientItem) => {
                sumQuantity += Number(
                  isChangeMarket
                    ? ingredientItem.tmp_ing_quantity
                    : ingredientItem.ing_quantity
                );
                return ingredientItem;
              });

              const {
                volume_market,
                volume_cook,
                ing_price_expect,
                ingredent_ratio,
              } = groupIngredientItem[0];

              sumQuantity = Number(sumQuantity) || 0; //.toFixed(2)

              const gramOneChild =
                (sumQuantity * volume_market) / volume_cook || 0;
              const gramEatable = Number(
                (((sumQuantity * numberChildren * volume_market) /
                  volume_cook) *
                  (100 - ingredent_ratio)) /
                  100
              );

              const gramMarket = gramOneChild * numberChildren || 0; //.toFixed(2);

              const marketQuantity =
                Number(gramMarket / volume_market).toFixed(4) || 0;

              const ingredientPrice =
                Math.round(marketQuantity * ing_price_expect) || 0;

              const gram100Price =
                Math.round((ingredientPrice * 100) / gramMarket) || 0;

              totalMoney += parseInt(ingredientPrice);

              // Check nutrition
              const ingredientProtein = Number(
                groupIngredientItem[0].ingredent_protein
              );
              const ingredientLipid = Number(
                groupIngredientItem[0].ingredent_lipid
              );
              const ingredientGlucid = Number(
                groupIngredientItem[0].ingredent_glucid
              );

              let isMain = "p";
              if (
                ingredientProtein >= ingredientLipid &&
                ingredientProtein >= ingredientGlucid
              ) {
                isMain = "p";
              } else if (
                ingredientLipid >= ingredientProtein &&
                ingredientLipid >= ingredientGlucid
              ) {
                isMain = "l";
              } else {
                isMain = "g";
              }

              const textColorIngredient =
                groupIngredientItem[0].is_spice === 1
                  ? ""
                  : isMain === "p" && proteinColor !== "text-success"
                  ? proteinColor
                  : isMain === "l" && lipidColor !== "text-success"
                  ? lipidColor
                  : isMain === "g" && glucidColor !== "text-success"
                  ? glucidColor
                  : "";

              const countIngredient = groupIngredientItem.length;

              return (
                <tr
                  key={`ingIndex_${index}`}
                  className={`align-middle text-end `}
                >
                  <td className="text-center p-1">{index + 1}</td>
                  <td className={`text-start p-1 `}>
                    {groupIngredientItem[0].ingredent_name}&nbsp;
                    {textColorIngredient === "text-info" ? (
                      <>
                        <i
                          className="fa-solid fa-arrow-up text-success cursor-help"
                          title="Cần tăng định lượng"
                        />{" "}
                      </>
                    ) : textColorIngredient === "text-danger" ? (
                      <>
                        <i
                          className="fa-solid fa-arrow-down text-danger cursor-help"
                          title="Cần giảm định lượng"
                        />{" "}
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td className="text-center p-1">
                    {groupIngredientItem[0].unit_market_name}
                  </td>
                  <td className="text-start p-1">
                    {isChangeMarket &&
                    groupIngredientItem[0].ingredent_type === 1 ? (
                      <Select
                        options={suppliers}
                        placeholder={<div>Chọn NCC</div>}
                        getOptionLabel={(option) => option.supplier_name}
                        getOptionValue={(option) => option.id}
                        value={{
                          id: groupIngredientItem[0].id_supplier,
                          supplier_name: groupIngredientItem[0].supplier_name,
                        }}
                        onChange={(choice) => {
                          setQuantitative({
                            ...quantitative,
                            times: quantitative.times?.map((timeItem) => {
                              return {
                                ...timeItem,
                                foods: timeItem.foods?.map((foodItem) => {
                                  return {
                                    ...foodItem,
                                    groups: foodItem.groups?.map(
                                      (groupItem) => {
                                        return {
                                          ...groupItem,
                                          ingredents: groupItem.ingredents?.map(
                                            (ingredientItem) => {
                                              if (
                                                ingredientItem.id ===
                                                groupIngredientItem[0].id
                                              ) {
                                                return {
                                                  ...ingredientItem,
                                                  id_supplier: choice.id,
                                                  supplier_name:
                                                    choice.supplier_name,
                                                };
                                              }
                                              return ingredientItem;
                                            }
                                          ),
                                        };
                                      }
                                    ),
                                  };
                                }),
                              };
                            }),
                          });
                        }}
                        styles={{
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            color: "black",
                          }),
                        }}
                        isDisabled={groupIngredientItem[0].ingredent_type !== 1}
                      />
                    ) : (
                      <span title="Hàng kho sẽ thay đổi Nhà cung cấp khi nhập kho.">
                        {groupIngredientItem[0].supplier_name}
                      </span>
                    )}
                  </td>
                  <td className="p-1">
                    {isChangeMarket &&
                    groupIngredientItem[0].ingredent_type === 1 ? (
                      <CurrencyFormat
                        value={groupIngredientItem[0].ing_price_expect}
                        thousandSeparator={true}
                        displayType="input"
                        onValueChange={(values) => {
                          setQuantitative({
                            ...quantitative,
                            times: quantitative.times?.map((timeItem) => {
                              return {
                                ...timeItem,
                                foods: timeItem.foods?.map((foodItem) => {
                                  return {
                                    ...foodItem,
                                    groups: foodItem.groups?.map(
                                      (groupItem) => {
                                        return {
                                          ...groupItem,
                                          ingredents: groupItem.ingredents?.map(
                                            (ingredientItem) => {
                                              if (
                                                ingredientItem.id ===
                                                groupIngredientItem[0].id
                                              ) {
                                                return {
                                                  ...ingredientItem,
                                                  ing_price_expect:
                                                    values.value,
                                                };
                                              }
                                              return ingredientItem;
                                            }
                                          ),
                                        };
                                      }
                                    ),
                                  };
                                }),
                              };
                            }),
                          });
                        }}
                        className="form-control text-end"
                        allowNegative={false}
                        isNumericString
                        disabled={groupIngredientItem[0].ingredent_type !== 1}
                      />
                    ) : (
                      <CurrencyFormat
                        value={Number(groupIngredientItem[0].ing_price_expect)}
                        displayType={"text"}
                        thousandSeparator={true}
                        title="Hàng kho sẽ thay đổi giá khi nhập kho."
                      />
                    )}
                  </td>
                  <td className="p-1">
                    <CurrencyFormat
                      value={Number(gramOneChild).toFixed(1)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={1}
                    />
                  </td>
                  <td className="p-1">
                    <CurrencyFormat
                      value={Number(gramEatable).toFixed(1)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={1}
                    />
                  </td>
                  <td className="p-1">
                    <CurrencyFormat
                      value={Number(gramMarket).toFixed(1)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={1}
                    />
                  </td>
                  <td className="p-1">
                    <CurrencyFormat
                      value={parseFloat(gram100Price)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={0}
                    />
                  </td>
                  <td className="p-1">
                    {isChangeMarket ? (
                      <CurrencyFormat
                        defaultValue={parseFloat(marketQuantity)}
                        thousandSeparator={true}
                        displayType="input"
                        onValueChange={(values) => {
                          const quantity = values.value;
                          if (Number(values.value) === 0 && !wasWarning) {
                            toast(
                              "Cảnh báo: nhập số lượng đi chợ bằng 0 sẽ ảnh hưởng đến định lượng bếp",
                              {
                                type: "error",
                                autoClose: 5000,
                              }
                            );

                            setWasWarning(true);
                          }

                          const newTotalQuantityMarket = Number(
                            (quantity * volume_cook) / numberChildren
                          );

                          let oldTotalQuantityMarket = 0;

                          groupIngredientItem?.map((ingredientItem) => {
                            oldTotalQuantityMarket += Number(
                              ingredientItem.ing_quantity
                            );
                            return ingredientItem;
                          });

                          let remainingTotalQuantity = newTotalQuantityMarket;

                          let n = 0;

                          let updatedIngredients = [];

                          let ingredientNeedUpdate = {};

                          let quantitativeNeedUpdate = 0;

                          quantitative.times?.map((timeItem) => {
                            return {
                              ...timeItem,
                              foods: timeItem.foods?.map((foodItem) => {
                                return {
                                  ...foodItem,
                                  groups: foodItem.groups?.map((groupItem) => {
                                    if (
                                      groupItem.id === selectedClassGroup.id
                                    ) {
                                      return {
                                        ...groupItem,
                                        ingredents: groupItem.ingredents?.map(
                                          (ingredientItem) => {
                                            if (
                                              ingredientItem.id ===
                                                groupIngredientItem[0].id &&
                                              (groupItem.more_meal === null ||
                                                foodIdMainArray.includes(
                                                  foodItem.id
                                                ))
                                            ) {
                                              let quantityOfIngredient =
                                                roundDownToDecimals(
                                                  Number(
                                                    (ingredientItem.ing_quantity *
                                                      newTotalQuantityMarket) /
                                                      oldTotalQuantityMarket
                                                  ) || 0,
                                                  1
                                                );

                                              n++;

                                              if (countIngredient === n) {
                                                ingredientNeedUpdate =
                                                  updatedIngredients.filter(
                                                    (ingItem) =>
                                                      ingItem.id_ingredient ===
                                                        ingredientItem.id &&
                                                      ingItem.id_food ===
                                                        foodItem.id
                                                  );

                                                if (
                                                  ingredientNeedUpdate.length ===
                                                  0
                                                ) {
                                                  quantitativeNeedUpdate =
                                                    remainingTotalQuantity;
                                                } else {
                                                  quantitativeNeedUpdate =
                                                    parseFloat(
                                                      Number(
                                                        quantityOfIngredient +
                                                          (remainingTotalQuantity -
                                                            quantityOfIngredient) /
                                                            (ingredientNeedUpdate.length +
                                                              1)
                                                      ).toFixed(6)
                                                    );

                                                  quantityOfIngredient =
                                                    quantitativeNeedUpdate;
                                                }

                                                remainingTotalQuantity = 0;
                                              } else {
                                                remainingTotalQuantity =
                                                  parseFloat(
                                                    Number(
                                                      remainingTotalQuantity -
                                                        quantityOfIngredient
                                                    ).toFixed(6)
                                                  );

                                                updatedIngredients.push({
                                                  id_ingredient:
                                                    ingredientItem.id,
                                                  ingredient_name:
                                                    ingredientItem.ingredent_name,
                                                  id_food: foodItem.id,
                                                  food_name: foodItem.food_name,
                                                });
                                              }
                                            }

                                            return ingredientItem;
                                          }
                                        ),
                                      };
                                    }
                                    return groupItem;
                                  }),
                                };
                              }),
                            };
                          });

                          n = 0;

                          setQuantitative({
                            ...quantitative,
                            times: quantitative.times?.map((timeItem) => {
                              return {
                                ...timeItem,
                                foods: timeItem.foods?.map((foodItem) => {
                                  return {
                                    ...foodItem,
                                    groups: foodItem.groups?.map(
                                      (groupItem) => {
                                        if (
                                          groupItem.id === selectedClassGroup.id
                                        ) {
                                          return {
                                            ...groupItem,
                                            ingredents:
                                              groupItem.ingredents?.map(
                                                (ingredientItem) => {
                                                  if (
                                                    ingredientItem.id ===
                                                      groupIngredientItem[0]
                                                        .id &&
                                                    (groupItem.more_meal ===
                                                      null ||
                                                      foodIdMainArray.includes(
                                                        foodItem.id
                                                      ))
                                                  ) {
                                                    let quantityOfIngredient =
                                                      roundDownToDecimals(
                                                        Number(
                                                          (ingredientItem.ing_quantity *
                                                            newTotalQuantityMarket) /
                                                            oldTotalQuantityMarket
                                                        ) || 0,
                                                        1
                                                      );

                                                    n++;
                                                    if (
                                                      ingredientNeedUpdate.some(
                                                        (item) =>
                                                          item.id_ingredient ===
                                                            ingredientItem.id &&
                                                          item.id_food ===
                                                            foodItem.id
                                                      ) ||
                                                      countIngredient === n
                                                    ) {
                                                      quantityOfIngredient =
                                                        quantitativeNeedUpdate;
                                                    }

                                                    return {
                                                      ...ingredientItem,
                                                      tmp_ing_quantity:
                                                        quantityOfIngredient,
                                                    };
                                                  }
                                                  return ingredientItem;
                                                }
                                              ),
                                          };
                                        }
                                        return groupItem;
                                      }
                                    ),
                                  };
                                }),
                              };
                            }),
                          });
                        }}
                        className="form-control text-end"
                        allowNegative={false}
                        isNumericString
                        decimalScale={4}
                      />
                    ) : (
                      <CurrencyFormat
                        value={parseFloat(marketQuantity)}
                        thousandSeparator={true}
                        displayType="text"
                        allowNegative={false}
                        isNumericString
                        decimalScale={4}
                      />
                    )}
                  </td>
                  <td className="p-1">
                    <CurrencyFormat
                      value={ingredientPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={0}
                    />
                  </td>
                </tr>
              );
            })}

            <tr className="align-middle text-end">
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th className="text-end">Tổng tiền</th>
              <th>
                <CurrencyFormat
                  value={totalMoney}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </th>
            </tr>
          </tbody>
        </Table>
      ) : (
        <div className="text-muted text-center">Không có dữ liệu...</div>
      )}
      {groupExtraIngredientArray.length > 0 ? (
        <>
          <h5 className="mb-2">Nguyện liệu món dự trù</h5>
          <Table bordered>
            <thead className="align-middle text-center sticky-md-top bg-light">
              <tr>
                <th>STT</th>
                <th>Tên thực phẩm</th>
                <th>ĐVT</th>
                <th style={{ minWidth: "10rem" }}>Nhà cung cấp</th>
                <th style={{ minWidth: "6rem" }}>Giá tiền (đ)</th>
                <th>1 trẻ (g)</th>
                <th>Ăn được (g)</th>
                <th>Đi chợ (g)</th>
                <th>Giá 100g (đ)</th>
                <th style={{ minWidth: "5rem" }}>Đi chợ theo ĐVT</th>
                <th>Thành tiền (đ)</th>
              </tr>
            </thead>

            <tbody>
              {groupExtraIngredientArray.map((groupIngredientItem, index) => {
                let gramOneChild = 0;
                let gramEatable = 0;
                let gramMarket = 0;
                let gramMarket2 = 0;

                groupIngredientItem?.map((ingredientItem) => {
                  const {
                    mealQuantity,
                    tmp_ing_quantity,
                    ing_quantity,
                    volume_market,
                    volume_cook,
                    ingredent_ratio,
                  } = ingredientItem;

                  const ingredientQuantity = isChangeMarket
                    ? tmp_ing_quantity
                    : ing_quantity;

                  const ingredientQuantity2 =
                    isChangeMarket && ingredientItem.tmp_ing_quantity > 0
                      ? ingredientItem.tmp_ing_quantity
                      : ingredientItem.ing_quantity;

                  gramOneChild += Number(
                    (ingredientQuantity * volume_market) / volume_cook
                  );

                  gramEatable += Number(
                    ((ingredientQuantity * mealQuantity * volume_market) /
                      volume_cook) *
                      ((100 - ingredent_ratio) / 100)
                  );

                  gramMarket += Number(
                    (mealQuantity * ingredientQuantity * volume_market) /
                      volume_cook
                  );

                  gramMarket2 +=
                    (ingredientQuantity2 * mealQuantity) / volume_cook;

                  return ingredientItem;
                });

                const {
                  ingredent_name,
                  unit_market_name,
                  volume_market,
                  volume_cook,
                  ing_price_expect,
                  ingredent_ratio,
                } = groupIngredientItem[0];

                gramOneChild = Number(gramOneChild) || 0;

                gramEatable = Number(gramEatable) || 0;

                gramMarket = Number(gramMarket) || 0;

                const marketQuantity =
                  Number(gramMarket / volume_market).toFixed(4) || 0;

                const ingredientPrice =
                  Math.round(marketQuantity * Number(ing_price_expect)) || 0;

                const gram100Price =
                  Math.round((ingredientPrice * 100) / gramMarket) || 0;

                totalExtraMoney += parseInt(ingredientPrice);

                return (
                  <tr
                    key={`ingredientKey_${index}`}
                    className="align-middle text-end"
                  >
                    <td className="p-1 text-center">{index + 1}</td>
                    <td className="p-1 text-start">{ingredent_name}</td>
                    <td className="p-1 text-center">{unit_market_name}</td>
                    <td className="p-1 text-start">
                      {isChangeMarket &&
                      groupIngredientItem[0].ingredent_type === 1 ? (
                        <Select
                          options={suppliers}
                          placeholder={<div>Chọn NCC</div>}
                          getOptionLabel={(option) => option.supplier_name}
                          getOptionValue={(option) => option.id}
                          value={{
                            id: groupIngredientItem[0].id_supplier,
                            supplier_name: groupIngredientItem[0].supplier_name,
                          }}
                          onChange={(choice) => {
                            setQuantitative({
                              ...quantitative,
                              times: quantitative.times?.map((timeItem) => {
                                return {
                                  ...timeItem,
                                  foods: timeItem.foods?.map((foodItem) => {
                                    return {
                                      ...foodItem,
                                      groups: foodItem.groups?.map(
                                        (groupItem) => {
                                          return {
                                            ...groupItem,
                                            ingredents:
                                              groupItem.ingredents?.map(
                                                (ingredientItem) => {
                                                  if (
                                                    ingredientItem.id ===
                                                    groupIngredientItem[0].id
                                                  ) {
                                                    return {
                                                      ...ingredientItem,
                                                      id_supplier: choice.id,
                                                      supplier_name:
                                                        choice.supplier_name,
                                                    };
                                                  }
                                                  return ingredientItem;
                                                }
                                              ),
                                          };
                                        }
                                      ),
                                    };
                                  }),
                                };
                              }),
                            });
                          }}
                          styles={{
                            menu: (baseStyles) => ({
                              ...baseStyles,
                              color: "black",
                            }),
                          }}
                          isDisabled={
                            groupIngredientItem[0].ingredent_type !== 1
                          }
                        />
                      ) : (
                        <span title="Hàng kho sẽ thay đổi Nhà cung cấp khi nhập kho.">
                          {groupIngredientItem[0].supplier_name}
                        </span>
                      )}
                    </td>
                    <td className="p-1">
                      {isChangeMarket &&
                      groupIngredientItem[0].ingredent_type === 1 ? (
                        <CurrencyFormat
                          value={groupIngredientItem[0].ing_price_expect}
                          thousandSeparator={true}
                          displayType="input"
                          onValueChange={(values) => {
                            setQuantitative({
                              ...quantitative,
                              times: quantitative.times?.map((timeItem) => {
                                return {
                                  ...timeItem,
                                  foods: timeItem.foods?.map((foodItem) => {
                                    return {
                                      ...foodItem,
                                      groups: foodItem.groups?.map(
                                        (groupItem) => {
                                          return {
                                            ...groupItem,
                                            ingredents:
                                              groupItem.ingredents?.map(
                                                (ingredientItem) => {
                                                  if (
                                                    ingredientItem.id ===
                                                    groupIngredientItem[0].id
                                                  ) {
                                                    return {
                                                      ...ingredientItem,
                                                      ing_price_expect:
                                                        values.value,
                                                    };
                                                  }
                                                  return ingredientItem;
                                                }
                                              ),
                                          };
                                        }
                                      ),
                                    };
                                  }),
                                };
                              }),
                            });
                          }}
                          className="form-control text-end"
                          allowNegative={false}
                          isNumericString
                          disabled={groupIngredientItem[0].ingredent_type !== 1}
                        />
                      ) : (
                        <CurrencyFormat
                          value={Number(
                            groupIngredientItem[0].ing_price_expect
                          )}
                          displayType={"text"}
                          thousandSeparator={true}
                          title="Hàng kho sẽ thay đổi giá khi nhập kho."
                        />
                      )}
                    </td>
                    <td className="p-1">
                      <CurrencyFormat
                        value={parseFloat(gramOneChild)}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={1}
                      />
                    </td>
                    <td className="p-1">
                      <CurrencyFormat
                        value={parseFloat(gramEatable)}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={1}
                      />
                    </td>
                    <td className="p-1">
                      <CurrencyFormat
                        value={parseFloat(gramMarket)}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={1}
                      />
                    </td>
                    <td className="p-1">
                      <CurrencyFormat
                        value={parseFloat(gram100Price)}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={1}
                      />
                    </td>
                    <td className="p-1">
                      {isChangeMarket ? (
                        <CurrencyFormat
                          defaultValue={parseFloat(marketQuantity)}
                          thousandSeparator={true}
                          displayType="input"
                          onValueChange={(values) => {
                            if (Number(values.value) === 0 && !wasWarning) {
                              toast(
                                "Cảnh báo: nhập số lượng đi chợ bằng 0 sẽ ảnh hưởng đến định lượng bếp",
                                {
                                  type: "error",
                                  autoClose: 5000,
                                }
                              );

                              setWasWarning(true);
                            }

                            setQuantitative({
                              ...quantitative,
                              times: quantitative.times?.map((timeItem) => {
                                return {
                                  ...timeItem,
                                  foods: timeItem.foods?.map((foodItem) => {
                                    return {
                                      ...foodItem,
                                      groups: foodItem.groups?.map(
                                        (groupItem) => {
                                          if (
                                            groupItem.id ===
                                            selectedClassGroup.id
                                          ) {
                                            return {
                                              ...groupItem,
                                              ingredents:
                                                groupItem.ingredents?.map(
                                                  (ingredientItem) => {
                                                    if (
                                                      ingredientItem.id ===
                                                        groupIngredientItem[0]
                                                          .id &&
                                                      (groupItem.more_meal >
                                                        0 ||
                                                        foodIdMoreArray.includes(
                                                          foodItem.id
                                                        ))
                                                    ) {
                                                      const ingQuantity =
                                                        ingredientItem.tmp_ing_quantity >
                                                        0
                                                          ? ingredientItem.tmp_ing_quantity
                                                          : ingredientItem.ing_quantity;
                                                      const tmpIngQty = Number(
                                                        (ingQuantity /
                                                          parseFloat(
                                                            gramMarket2
                                                          )) *
                                                          Number(values.value)
                                                      );
                                                      return {
                                                        ...ingredientItem,
                                                        tmp_ing_quantity:
                                                          tmpIngQty || 0,
                                                      };
                                                    }
                                                    return ingredientItem;
                                                  }
                                                ),
                                            };
                                          }
                                          return groupItem;
                                        }
                                      ),
                                    };
                                  }),
                                };
                              }),
                            });
                          }}
                          className="form-control text-end"
                          allowNegative={false}
                          isNumericString
                          decimalScale={4}
                        />
                      ) : (
                        <CurrencyFormat
                          value={parseFloat(marketQuantity) || 0}
                          thousandSeparator={true}
                          displayType="text"
                          decimalScale={4}
                        />
                      )}
                    </td>
                    <td className="p-1">
                      <CurrencyFormat
                        value={ingredientPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  </tr>
                );
              })}

              <tr className="align-middle text-end">
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th className="text-end">Tổng tiền</th>
                <th>
                  <CurrencyFormat
                    value={totalExtraMoney}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </th>
              </tr>
            </tbody>
          </Table>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default QuantitativeTable;
