import { Table } from "react-bootstrap";

const daySession = [
  { id: 1, name: "Sáng", nameEn: "morning" },
  { id: 2, name: "Trưa", nameEn: "noon" },
  { id: 3, name: "Chiều", nameEn: "afternoon" },
];

const schoolDays = [
  { id: 1, name: "Thứ 2", nameEn: "monday" },
  { id: 2, name: "Thứ 3", nameEn: "tuesday" },
  { id: 3, name: "Thứ 4", nameEn: "wednesday" },
  { id: 4, name: "Thứ 5", nameEn: "thursday" },
  { id: 5, name: "Thứ 6", nameEn: "friday" },
  { id: 6, name: "Thứ 7", nameEn: "saturday" },
  { id: 7, name: "Chủ nhật", nameEn: "sunday" },
];

const ScheduleTable = ({ classroom, schedules, showModal, timeTitle }) => {
  return (
    <div style={{ overflowX: "auto" }}>
      {daySession.length > 0 ? (
        <Table bordered style={{ minWidth: 999 }} id={`tb-tkb`}>
          <thead>
            <tr className="text-center align-middle d-none ">
              <th colSpan={8}>
                Thời khóa biểu - Lớp {classroom?.class_name}
                <br />
                {timeTitle}
              </th>
            </tr>
            <tr className="text-center align-middle bg-light">
              <th key="0" style={{ width: `10%`, minWidth: "120px" }}>
                Buổi
              </th>
              {schoolDays.map((day) => {
                return (
                  <th
                    key={day.id}
                    style={{
                      width: `${90 / schoolDays.length}%`,
                      minWidth: "200px",
                    }}
                  >
                    {day.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {daySession.map((sesion) => {
              return (
                <tr key={`sesion-${sesion.id}`}>
                  <th className="text-center">{sesion.name}</th>
                  {schoolDays.map((day) => {
                    if (schedules) {
                      const scheduleDay = schedules[day.nameEn];
                      if (scheduleDay) {
                        const scheduleDaySessions = scheduleDay[sesion.nameEn];
                        if (scheduleDaySessions?.length > 0) {
                          return (
                            <td
                              key={`${sesion.id}-${day.id}`}
                              onClick={() => {
                                showModal(
                                  schedules[day.nameEn][sesion.nameEn],
                                  day.id,
                                  sesion.id
                                );
                              }}
                            >
                              {scheduleDaySessions.map(
                                (scheduleDaySession, index) => (
                                  <div key={`scheduleDaySession-${index}`}>
                                    {`◦ ${scheduleDaySession.subjects.subject_name}`}
                                    <br />
                                  </div>
                                )
                              )}
                            </td>
                          );
                        }
                      }
                    }
                    return (
                      <td
                        key={`${sesion.id}-${day.id}`}
                        onClick={() => {
                          showModal([], day.id, sesion.id);
                        }}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="text-center">Không có dữ liệu...</div>
      )}
    </div>
  );
};

export default ScheduleTable;
