import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../other/Check";
import DataTables from "../datatable/DataTables";
import { dateFormatter } from "../utils/helpers";
import swal from "sweetalert";
import api from "../../../helper/axiosInstance";
import AchievementModal from "./AchievementModal";
import AchievementModalEdit from "./AchievementModalEdit";

const Achievement = ({ dataStudent }) => {
  const [listAchievement, setListAchievement] = useState([]);
  const [showAchievementAdd, setShowAchievementAdd] = useState(false);
  const [showAchievementEdit, setShowAchievementEdit] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    getAchievement();
  }, []);

  //#region API

  const getAchievement = async () => {
    await api
      .get(`/achievement-shows?id_student=${dataStudent?.id}`)
      .then((res) => {
        setListAchievement(res.data.data);
      })
      .catch((err) => {
        toast("Đã có lỗi xảy ra vui lòng thử lại", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const deleteAchievement = (id) => {
    swal({
      title: `Bạn chắc muốn xóa thành tích?`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        await api
          .delete(`/achievement-delete?id=${id}`)
          .then((res) => {
            toast(res.data.messages, { type: "success" });
            getAchievement();
          })
          .catch((err) => {
            toast(err.message, { type: "error" });
          });
      }
    });
  };
  const dataTableColumns = [
    {
      name: "Thành tích",
      selector: (row) => row.achievement,
      sortable: true,
      wrap: true,
    },
    {
      name: "Mô tả",
      selector: (row) => row.description,
      wrap: true,
      right: true,
    },
    {
      name: "Thời gian",
      selector: (row) => Number(row.achieved_date),
      sortable: true,
      right: true,
      cell: (row) => dateFormatter(new Date(row.achieved_date * 1000)),
      wrap: true,
    },

    {
      name: "Phương thức",
      selector: (row) => row,
      cell: (row) => (
        <div div style={{ display: "flex" }}>
          {new Check().permission([176, 178], "or") ? (
            <Button
              variant="warning me-2"
              size="sm"
              type="button"
              onClick={() => {
                setData(row);
                setShowAchievementEdit(true);
              }}
              title="Chi tiết"
            >
              <i
                className={`fa-solid fa-${
                  new Check().permission([178]) ? "pencil" : "info"
                }`}
              ></i>
            </Button>
          ) : null}

          {new Check().permission([179]) ? (
            <Button
              variant="danger"
              type="button"
              size="sm"
              onClick={() => {
                deleteAchievement(row.id);
              }}
              title="Xóa"
            >
              <i className="fas fa-trash"></i>
            </Button>
          ) : null}
        </div>
      ),
      center: true,
    },
  ];

  return (
    <>
      <Card className="mb-4">
        <Card.Header>
          <Card.Title>
            <i className="fas fa-list me-1" /> Danh sách
            {new Check().permission([177]) ? (
              <Button
                variant="success"
                size="sm"
                className="float-end ms-2"
                onClick={() => {
                  setShowAchievementAdd(true);
                }}
                title="Thêm"
              >
                + Thêm
              </Button>
            ) : null}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <DataTables data={listAchievement} columns={dataTableColumns} />
        </Card.Body>
      </Card>
      {showAchievementAdd ? (
        <AchievementModal
          show={showAchievementAdd}
          onHide={() => {
            setShowAchievementAdd(false);
          }}
          data={null}
          onSubmit={() => {
            setShowAchievementAdd(false);
            getAchievement();
          }}
          studentChoice={dataStudent}
        />
      ) : null}
      {showAchievementEdit ? (
        <AchievementModalEdit
          show={showAchievementEdit}
          onHide={() => {
            setShowAchievementEdit(false);
          }}
          data={data}
          onSubmit={() => {
            setShowAchievementEdit(false);
            getAchievement();
          }}
          studentChoice={dataStudent}
        />
      ) : null}
    </>
  );
  //#endregion Render
};
export default Achievement;
