import { Table } from "react-bootstrap";

const MenuExcelTemplate5 = ({
  menuDays,
  menuTimes,
  selectedClassGroup,
  handleUnSavedMealDaily,
  handleShowModal,
  weekInMonth,
  startDate,
  termDate,
  group,
}) => {
  const stringFirstDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2);
  const stringLastDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear();
  const renderTableData = (menuDays) => {
    const tdElements = [];
    for (let i = 1; i < parseInt(menuDays.length / 2) + 1; i++) {
      tdElements.push(<td key={i}></td>);
    }
    return tdElements;
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <Table
        bordered
        style={{ minWidth: 999 }}
        id="menu-table-5"
        className="d-none"
      >
        <thead>
          <tr>
            <th>{selectedClassGroup?.group_name}</th>
            {renderTableData(menuDays)}
            <th>
              Thực đơn Tháng {startDate.getMonth() + 1} (Tuần {weekInMonth}){" "}
              {stringFirstDate(startDate)} - {stringLastDate(termDate)}
            </th>
            {renderTableData(menuDays)}
          </tr>

          <tr className="text-center">
            <th>Buổi</th>
            {menuDays.map((day) => {
              return <th key={day.id}>{day.day_name}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {menuTimes.length > 0 && menuDays.length > 0 ? (
            menuTimes.map((timeItem) => {
              return (
                <tr key={timeItem.time_name}>
                  <th className="text-center">{timeItem.time_name}</th>
                  {menuDays.length > 0
                    ? menuDays.map((day) => {
                        let timesWithDay = day.times.filter(
                          (time) => time.id_time === timeItem.id_time
                        );
                        return (
                          <td key={day.id} className="menu-table-cell">
                            {timesWithDay[0]?.foods.length > 0
                              ? timesWithDay[0]?.foods.map(
                                  (food, foodIndex) => {
                                    const groupMatch = food.groups.some(
                                      (groupItem) =>
                                        Number(groupItem.id) ===
                                        Number(selectedClassGroup.id)
                                    );

                                    return groupMatch ? (
                                      <div key={food.id}>
                                        <strong>
                                          {food.food_name}

                                          {food.groups.filter(
                                            (groupItem) =>
                                              Number(groupItem.id) ===
                                              Number(selectedClassGroup.id)
                                          )[0]?.more_meal
                                            ? ` (Số lượng: ${
                                                food.groups.filter(
                                                  (groupItem) =>
                                                    Number(groupItem.id) ===
                                                    Number(
                                                      selectedClassGroup.id
                                                    )
                                                )[0]?.more_meal
                                              })`
                                            : null}
                                        </strong>
                                        {`\n`}
                                      </div>
                                    ) : null;
                                  }
                                )
                              : ""}
                          </td>
                        );
                      })
                    : ""}
                </tr>
              );
            })
          ) : (
            <></>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default MenuExcelTemplate5;
