import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import CurrencyFormat from "react-currency-format";
import Select from "react-select";
import { Col, Modal, Row, Table, Form, Button } from "react-bootstrap";
import FoodAdd from "./FoodAdd";
import UnitAdd from "../../unit/UnitAdd";
import IngredentModal from "../../ingredient/IngredentModal";
import Check from "../../other/Check";
import api from "../../../../helper/axiosInstance";
import moment from "moment/moment";

const errorTextStyle = {
  fontSize: 13,
  color: "red",
  marginTop: 6,
};

const FoodEdit = ({ show, onHide, selectedFood }) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [ingredientOptions, setIngredientOptions] = useState([]);
  const [classGroups, setClassGroups] = useState([]);

  const [foodDetail, setFoodDetail] = useState({
    food_name: "",
    id_category: "",
    id_unit: "",
    state_unit: "",
    state_value: "",
  });
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [errorMessage, setErrorMessage] = useState({
    food_name: "",
    id_category: "",
    id_unit: "",
    state_unit: "",
    state_value: "",
  });

  const [addFoodModalShow, setAddFoodModalShow] = useState(false);
  const [addUnitModalShow, setAddUnitModalShow] = useState(false);
  const [addIngredientModalShow, setAddIngredientModalShow] = useState(false);

  useEffect(() => {
    getFoodDetail(selectedFood);
    getListUnits();
    getListCategories();
    getListIngredients();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // API
  const getListUnits = async () => {
    await api
      .get(`/unit-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setUnitOptions(res.data.units);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const getListCategories = async () => {
    await api
      .get(`/category-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setCategoryOptions(res.data.categories);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const getListClassGroups = async (foodIngredients) => {
    await api
      .get(`/group-show`)
      .then((res) => {
        let groups = res.data?.groups;

        if (groups.length > 0) {
          let ingredients = [];

          ingredients = foodIngredients.map((ingredientItem) => {
            return {
              id: ingredientItem.id,
              name: ingredientItem.ingredent_name,
              id_unit_cook: ingredientItem.id_unit_cook,
              unit_cook_name: ingredientItem.unit_cook_name,
              volume_cook: ingredientItem.volume_cook,
              ing_price_expect: ingredientItem.ing_price_expect,
              groups: groups.map((groupItem) => {
                if (
                  ingredientItem.groups.some(
                    (group) => group.id === groupItem.id
                  )
                ) {
                  return ingredientItem.groups.find(
                    (group) => group.id === groupItem.id
                  );
                }

                return {
                  id: groupItem.id,
                  quantity: 0,
                };
              }),
            };
          });

          ingredients.push({
            id: 0,
            name: "",
            id_unit_cook: 0,
            unit_cook_name: "",
            volume_cook: 0,
            ing_price_expect: 0,
            groups: groups.map((groupItem) => {
              return {
                id: groupItem.id,
                quantity: 0,
              };
            }),
          });

          setSelectedIngredients(ingredients);

          setClassGroups(groups.map((group) => ({ ...group, totalMoney: 0 })));
        }
      })
      .catch((err) => {});
  };

  const getListIngredients = async () => {
    await api
      .get(`/ingredent-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          const ingredents = res.data.ingredents
            .filter((item) => item.ingredent_type !== 3)
            .map((ingredient) => {
              return {
                id: ingredient.id,
                name: ingredient.ingredent_name,
                id_unit_cook: ingredient.id_unit_cook,
                unit_cook_name: ingredient.unit_cook_name,
                ing_price_expect: Number(ingredient.ing_price_expect) || 0,
                volume_cook: Number(ingredient.volume_cook) || 0,
              };
            });

          setIngredientOptions(ingredents);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const getFoodDetail = async (foodId) => {
    await api
      .get(`/food-show`, {
        params: {
          id: foodId,
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          setFoodDetail({
            food_name: res.data.food.food_name,
            id_category: res.data.food.id_category,
            id_unit: res.data.food.id_unit,
            state_unit: res.data.food.state_unit,
            state_value: res.data.food.state_value,
            volume_cook: res.data.food.volume_cook,
            ing_price_expect: res.data.food.ing_price_expect,
            food_date: res.data.food.food_date,
            updated_at: res.data.food.updated_at,
            updater_name: res.data.food.updater_name,
          });

          getListClassGroups(res.data.food.ingredents);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const updateNewFood = async () => {
    setErrorMessage({
      food_name: "",
      id_category: "",
      id_unit: "",
      state_unit: "",
      state_value: "",
    });

    if (!foodDetail.state_unit) {
      toast("Vui lòng nhập Khối lượng/Thể tích", {
        type: "error",
        autoClose: 2000,
      });

      return;
    }

    if (!foodDetail.state_value) {
      toast("Vui lòng nhập giá trị Khối lượng/Thể tích.", {
        type: "error",
        autoClose: 2000,
      });

      return;
    }

    if (
      selectedIngredients.filter((ingredient) => ingredient.id !== 0).length ===
      0
    ) {
      toast("Vui lòng chọn nguyên liệu món ăn.", {
        type: "error",
        autoClose: 1000,
      });

      return;
    }

    if (
      selectedIngredients.every((item) =>
        item.groups.every((group) => parseFloat(group.quantity) === 0)
      )
    ) {
      toast("Vui lòng nhập định lượng.", {
        type: "error",
        autoClose: 1000,
      });
      return;
    }

    var bodyFormData = new FormData();
    bodyFormData.append("id", selectedFood);
    bodyFormData.append("food_name", foodDetail.food_name);
    bodyFormData.append("id_category", foodDetail.id_category);
    bodyFormData.append("id_unit", foodDetail.id_unit);
    bodyFormData.append("state_unit", foodDetail.state_unit);
    bodyFormData.append("state_value", foodDetail.state_value);
    bodyFormData.append(
      "ingredents",
      JSON.stringify(
        selectedIngredients
          .filter((ingredientItem) => ingredientItem.id !== 0)
          .map((ingredientItem) => {
            delete ingredientItem.name;
            delete ingredientItem.id_unit_cook;
            delete ingredientItem.unit_cook_name;

            return {
              ...ingredientItem,
              groups: ingredientItem.groups.map((groupItem) => {
                return {
                  id: Number(groupItem.id),
                  quantity: parseFloat(groupItem.quantity),
                };
              }),
            };
          })
      )
    );

    await api({
      method: "put",
      url: "food-update",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      if (res.data.errCode === 0) {
        toast(res.data.message, {
          type: "success",
          autoClose: 1000,
        });

        onHide(true);
      } else {
        setErrorMessage(res.data.message);

        toast("Thêm thất bại", {
          type: "error",
          autoClose: 1000,
        });
      }
    });
  };
  // API

  // Logic
  const removeSelectedIngredient = (ingredentId) => {
    swal({
      title: "Bạn muốn xóa nguyên liệu?",
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        setSelectedIngredients(
          selectedIngredients.filter(
            (ingredentItem) => ingredentItem.id !== ingredentId
          )
        );

        toast("Đã xóa nguyên liệu.", {
          type: "success",
          autoClose: 1000,
        });
      }
    });
  };

  const handleShowAddFoodModal = () => {
    setAddFoodModalShow(true);
  };
  const handleCloseAddFoodModal = (isReload) => {
    setAddFoodModalShow(false);

    if (isReload) {
      onHide(true);
    }
  };

  const handleShowAddUnitModal = () => {
    setAddUnitModalShow(true);
  };
  const handleCloseAddUnitModal = (isReload) => {
    setAddUnitModalShow(false);

    if (isReload) {
      getListUnits();
    }
  };

  const handleShowAddIngredientModal = () => {
    setAddIngredientModalShow(true);
  };
  const handleCloseAddIngredientModal = (isReload) => {
    setAddIngredientModalShow(false);
    getListIngredients();
  };
  // Logic

  // Render
  const RenderIngredientTable = () => {
    return (
      <div style={{ overflowX: "scroll" }}>
        <Table size="lg" bordered hover>
          <thead>
            <tr className="align-middle text-center">
              <th rowSpan={2}>STT</th>
              <th rowSpan={2} style={{ minWidth: "15rem" }}>
                Nguyên liệu <i className="text-danger">*</i>
              </th>
              <th rowSpan={2}>ĐVT</th>
              <th colSpan={classGroups.length}>
                Định lượng<i className="text-danger">*</i>
              </th>
              {new Check().permission(["30"]) ? (
                <th rowSpan={2}>Phương thức</th>
              ) : (
                <></>
              )}
            </tr>
            <tr className="align-middle text-center">
              {classGroups.map((classGroup) => {
                return (
                  <th key={`${classGroup.id}`} style={{ minWidth: "5rem" }}>
                    {classGroup.group_name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>{RenderSelectedIngredients()}</tbody>
        </Table>
      </div>
    );
  };

  const RenderSelectedIngredients = () => {
    let totalMoneys = {};

    return (
      <>
        {selectedIngredients?.map((ingredientItem, index) => {
          const volume_cook = parseFloat(ingredientItem.volume_cook);
          const ing_price_expect = parseFloat(ingredientItem.ing_price_expect);
          return (
            <tr
              key={`ingredientKey_${index}`}
              className="align-middle text-center"
            >
              <td>{ingredientItem.id === 0 ? "" : index + 1}</td>
              <td>
                <Select
                  options={ingredientOptions}
                  className="text-start"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder={<>Chọn nguyên liệu</>}
                  value={
                    ingredientOptions.find(
                      (ingredient) => ingredient.id === ingredientItem.id
                    ) || "Chọn nguyên liệu"
                  }
                  onChange={(choice) => {
                    if (
                      selectedIngredients.some(
                        (item) => Number(item.id) === Number(choice.id)
                      )
                    ) {
                      toast("Bạn đã chọn nguyên liệu này rồi", {
                        type: "error",
                        autoClose: 1000,
                      });
                      return;
                    }

                    let newIngredent = selectedIngredients.map((item) => {
                      if (Number(ingredientItem.id) === Number(item.id)) {
                        return {
                          id: choice.id,
                          name: choice.name,
                          id_unit_cook: choice.id_unit_cook,
                          unit_cook_name: choice.unit_cook_name,
                          ing_price_expect: choice.ing_price_expect,
                          volume_cook: choice.volume_cook,
                          groups: item.groups,
                        };
                      }
                      return item;
                    });

                    if (ingredientItem.id === 0) {
                      newIngredent.push({
                        id: 0,
                        name: "",
                        id_unit_cook: 0,
                        unit_cook_name: "",
                        ing_price_expect: 0,
                        volume_cook: 0,
                        groups: classGroups.map((groupItem) => {
                          return {
                            id: groupItem.id,
                            quantity: 0,
                          };
                        }),
                      });
                    }

                    setSelectedIngredients(newIngredent);
                  }}
                  menuPortalTarget={document.querySelector("body")}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                />
              </td>
              <td>{ingredientItem.unit_cook_name}</td>

              {ingredientItem.groups.map((groupItem, groupIndex) => {
                const quantity = parseFloat(groupItem.quantity);

                const money =
                  Math.round((quantity * ing_price_expect) / volume_cook) || 0;

                if (!totalMoneys[groupItem.id]) {
                  totalMoneys[groupItem.id] = 0;
                }

                totalMoneys[groupItem.id] += money;

                return (
                  <td key={groupItem.id}>
                    <CurrencyFormat
                      className="form-control text-end"
                      thousandSeparator={true}
                      required
                      value={groupItem.quantity}
                      onValueChange={(values) =>
                        setSelectedIngredients(
                          selectedIngredients.map((ingredient) => {
                            if (ingredientItem.id === ingredient.id) {
                              return {
                                ...ingredient,
                                groups: ingredient.groups.map((group) => {
                                  if (group.id === groupItem.id) {
                                    return {
                                      id: groupItem.id,
                                      quantity: values.value,
                                    };
                                  }

                                  return group;
                                }),
                              };
                            }

                            return ingredient;
                          })
                        )
                      }
                      decimalScale={2}
                      disabled={ingredientItem.id === 0}
                    />
                  </td>
                );
              })}

              {new Check().permission(["30"]) ? (
                <td>
                  <Button
                    variant={`outline-danger ${
                      ingredientItem.id === 0 ? "d-none" : ""
                    }`}
                    size="sm"
                    type="button"
                    onClick={() => removeSelectedIngredient(ingredientItem.id)}
                    disabled={ingredientItem.id === 0}
                  >
                    <i className="fa-solid fa-times"></i>
                  </Button>
                </td>
              ) : (
                <></>
              )}
            </tr>
          );
        })}

        <tr>
          <th colSpan={3} className="text-end">
            Giá món (đ):
          </th>

          {totalMoneys ? (
            Object.entries(totalMoneys).map(([groupId, totalMoney]) => (
              <td key={`total-key-${groupId}`} className="text-end fw-bold">
                <CurrencyFormat
                  value={totalMoney} // Sử dụng totalMoney trực tiếp
                  thousandSeparator={true}
                  displayType={"text"}
                  decimalScale={0}
                />
              </td>
            ))
          ) : (
            <></>
          )}
          <td></td>
        </tr>
      </>
    );
  };

  return (
    <Modal
      size="xl"
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa-solid fa-bowl-rice me-2"></i>
          Chi tiết món ăn
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={4}>
            <Form.Floating className="mb-1">
              <Form.Control
                type="text"
                value={foodDetail.food_name}
                onChange={(e) => {
                  setFoodDetail({
                    ...foodDetail,
                    food_name: e.target.value,
                  });
                }}
                placeholder="Tên món ăn"
                required
              />
              <Form.Label>
                Tên món ăn
                <i className="text-danger">*</i>
              </Form.Label>

              {errorMessage.food_name && (
                <p style={errorTextStyle}>{errorMessage.food_name}</p>
              )}
            </Form.Floating>
            <Row>
              <Col className="align-content-end">
                <Form.Floating className="mb-3">
                  <Form.Select
                    value={foodDetail.id_category}
                    onChange={(e) => {
                      setFoodDetail({
                        ...foodDetail,
                        id_category: e.target.value,
                      });
                    }}
                    required
                  >
                    <option value="">--Chọn--</option>
                    {categoryOptions.length > 0
                      ? categoryOptions.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.category_name}
                            </option>
                          );
                        })
                      : "Không có lựa chọn"}
                  </Form.Select>
                  <Form.Label>
                    Danh mục<i className="text-danger">*</i>
                  </Form.Label>

                  {errorMessage.id_category && (
                    <p style={errorTextStyle}>{errorMessage.id_category}</p>
                  )}
                </Form.Floating>
              </Col>
              <Col>
                {new Check().permission(["68"]) ? (
                  <Form.Group className="text-end">
                    <Button
                      size="sm"
                      variant="link"
                      type="button"
                      className="fst-italic"
                      onClick={() => handleShowAddUnitModal()}
                    >
                      +Thêm đơn vị tính
                    </Button>
                  </Form.Group>
                ) : (
                  <></>
                )}
                <Form.Floating className="mb-3">
                  <Form.Select
                    value={foodDetail.id_unit}
                    onChange={(e) => {
                      setFoodDetail({
                        ...foodDetail,
                        id_unit: e.target.value,
                      });
                    }}
                    required
                  >
                    <option value="">--Chọn--</option>
                    {unitOptions.length > 0
                      ? unitOptions.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.unit_name}
                            </option>
                          );
                        })
                      : "Không có lựa chọn"}
                  </Form.Select>
                  <Form.Label>
                    Đơn vị tính
                    <i className="text-danger">*</i>
                  </Form.Label>

                  {errorMessage.id_unit && (
                    <p style={errorTextStyle}>{errorMessage.id_unit}</p>
                  )}
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Floating className="mb-3">
                  <Form.Select
                    value={foodDetail.state_unit}
                    onChange={(e) => {
                      setFoodDetail({
                        ...foodDetail,
                        state_unit: e.target.value,
                      });
                    }}
                    required
                  >
                    <option value="">--Chọn--</option>
                    <option value="gam">gam</option>
                    <option value="ml">ml</option>
                  </Form.Select>
                  <Form.Label>
                    Khối lượng/Thể tích<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
              </Col>
              <Col xs={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    value={foodDetail.state_value}
                    onChange={(e) => {
                      setFoodDetail({
                        ...foodDetail,
                        state_value: e.target.value,
                      });
                    }}
                    placeholder="Giá trị KL/TT"
                    required
                  />
                  <Form.Label>
                    Giá trị KL/TT<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
              </Col>

              <Col xs={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    value={moment(new Date(foodDetail.food_date * 1000)).format(
                      "DD/MM/YYYY"
                    )}
                    disabled
                    placeholder="Ngày định lượng"
                  />
                  <Form.Label>Ngày định lượng</Form.Label>
                </Form.Floating>
              </Col>
              <Col xs={6}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    value={moment(
                      new Date(foodDetail.updated_at * 1000)
                    ).format("DD/MM/YYYY HH:mm:ss")}
                    placeholder="Cập nhật"
                    disabled
                  />
                  <Form.Label>Cập nhật</Form.Label>
                </Form.Floating>
              </Col>
              <Col xs={12}>
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    value={foodDetail.updater_name}
                    placeholder="Người cập nhật"
                    disabled
                  />
                  <Form.Label>Người cập nhật</Form.Label>
                </Form.Floating>
              </Col>
            </Row>

            {new Check().permission(["30"]) ? (
              <Button
                size="sm"
                variant="outline-secondary mb-3"
                type="button"
                onClick={() => handleShowAddFoodModal()}
              >
                <i className="fa-solid fa-copy"></i> Sao chép
              </Button>
            ) : (
              <></>
            )}
          </Col>

          <Col md={8}>
            {RenderIngredientTable()}

            {new Check().permission(["41"]) ? (
              <Button
                size="sm"
                variant="link fst-italic float-end"
                onClick={() => handleShowAddIngredientModal()}
              >
                +Thêm nguyên liệu
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onHide()}>
          <i className="fa-solid fa-times"></i> Đóng
        </Button>

        {new Check().permission(["30"]) ? (
          <Button
            size="sm"
            type="submit"
            variant="success"
            onClick={() => updateNewFood()}
          >
            <i className="fa-solid fa-check"></i> Cập nhật
          </Button>
        ) : (
          <></>
        )}
      </Modal.Footer>

      {addFoodModalShow ? (
        <FoodAdd
          show={addFoodModalShow}
          onHide={handleCloseAddFoodModal}
          copiedFoodDetail={foodDetail}
          copiedIngredients={selectedIngredients}
        />
      ) : null}

      {addUnitModalShow ? (
        <UnitAdd show={addUnitModalShow} onHide={handleCloseAddUnitModal} />
      ) : (
        ""
      )}

      {addIngredientModalShow ? (
        <IngredentModal
          show={addIngredientModalShow}
          onHide={handleCloseAddIngredientModal}
          typeModal={`TM`}
        />
      ) : (
        ""
      )}
    </Modal>
  );
};

export default FoodEdit;
