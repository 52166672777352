import React, { useState } from "react";
import { toast } from "react-toastify";

import { Modal, Button, Form } from "react-bootstrap";
import api from "../../../../helper/axiosInstance";

const CategoryAdd = ({ show, onHide }) => {
  const [category, setCategory] = useState({
    category_name: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    category_name: "",
  });

  // API
  const createNewCategory = async () => {
    setErrorMessage({
      category_name: "",
    });
    let data = {
      category_name: category.category_name,
      categoryable: "post",
      is_status: 1,
      description: category.description || "",
    };

    api({
      method: "post",
      url: "new-category-create",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        // if (res.data.errCode == 0) {
        toast(res.data.messages || "Thành công", {
          type: "success",
          autoClose: 1000,
        });

        onHide(true);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.messages);
      });
  };
  // API

  return (
    <Modal
      // size="sm"
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa-solid fa-tag"></i> Thêm chủ đề
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          createNewCategory();
        }}
      >
        <Modal.Body>
          <Form.Floating className="mb-3">
            <Form.Control
              type="text"
              placeholder="Tên chủ đề"
              required
              value={category.category_name}
              onChange={(e) => {
                setCategory({
                  ...category,
                  category_name: e.target.value,
                });
                delete errorMessage["category_name"];
              }}
            />
            <Form.Label>
              Tên chủ đề<i className="text-danger">*</i>
            </Form.Label>

            {errorMessage.category_name && (
              <p className="text-form-err">{errorMessage.category_name}</p>
            )}
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Control
              type="text"
              placeholder="mô tả"
              value={category.description}
              onChange={(e) => {
                setCategory({
                  ...category,
                  description: e.target.value,
                });
                delete errorMessage["description"];
              }}
            />
            <Form.Label>
              Mô tả<i className="text-danger"></i>
            </Form.Label>

            {errorMessage.description && (
              <p className="text-form-err">{errorMessage.description}</p>
            )}
          </Form.Floating>
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={() => onHide()}>
            <i className="fa-solid fa-times" /> Đóng
          </Button>

          <Button size="sm" variant="success" type="submit">
            <i className="fa-solid fa-check" /> Lưu
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CategoryAdd;
