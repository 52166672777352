import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Check from "../../admin/other/Check";
import FormatData from "../../admin/FormatData";
import swal from "sweetalert";
import {
  Breadcrumb,
  Button,
  Container,
  Card,
  Nav,
  Spinner,
  Col,
  Form,
  Row,
} from "react-bootstrap";
import DataTables from "../../admin/datatable/DataTables";
import CategoryAdd from "./category/CategoryAdd";
import CategoryEdit from "./category/CategoryEdit";
import FoodAdd from "./food/FoodAdd";
import FoodEdit from "./food/FoodEdit";
import api from "../../../helper/axiosInstance";
import axios from "axios";

const toSlug = (str) => {
  str = str.toLowerCase(); // Chuyển hết sang chữ thường

  // xóa dấu
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

  str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ

  str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt

  return str;
};

const FoodIndex = () => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [addCategoryModalShow, setAddCategoryModalShow] = useState(false);
  const [editCategoryModalShow, setEditCategoryModalShow] = useState(false);

  const [selectedFood, setSelectedFood] = useState(0);
  const [rootFoods, setRootFoods] = useState([]);
  const [foods, setFoods] = useState([]);
  const [addFoodModalShow, setAddFoodModalShow] = useState(false);
  const [editFoodModalShow, setEditFoodModalShow] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [isFoodLoading, setIsFoodLoading] = useState(false);

  const fetchData = () => {
    const { data } = axios.get(
      "https://apikakid.kafood.vn:5006/test-reject-authorie"
    );
    console.log(data);
  };

  useEffect(() => {
    fetchData();
    document.title = "Danh mục món";

    getListCategories();
    getListFoods(selectedCategory);
    setKeyword("");
  }, [selectedCategory]);

  useEffect(() => {
    setFoods(
      rootFoods.filter((foodItem) =>
        toSlug(foodItem.food_name.trim()).includes(toSlug(keyword.trim()))
      )
    );
  }, [keyword]); // eslint-disable-line react-hooks/exhaustive-deps

  // API
  const getListCategories = async () => {
    await api
      .get(`/category-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setCategories(res.data.categories);
          // .filter((item) => item.is_main === 1));
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const hanldDeleteCategory = () => {
    swal({
      title: "Bạn muốn xóa danh mục?",
      text: `"${
        categories.find((category) => category.id === selectedCategory)
          .category_name
      }"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .delete(`/category-destroy?id=${selectedCategory}`)
            .then((res) => {
              if (res.data.errCode === 0) {
                toast(res.data.message, {
                  type: "success",
                  autoClose: 1000,
                });

                getListCategories();
                setSelectedCategory(0);
              } else {
                toast(res.data.message, {
                  type: "error",
                  autoClose: 1000,
                });
              }
            })
            .catch((err) => {});
        }
      })
      .catch((err) => {});
  };

  const getListFoods = async (categoryId) => {
    setIsFoodLoading(true);
    await api
      .get(`/food-show`, {
        params: {
          id_category: categoryId === 0 ? undefined : categoryId,
        },
      })
      .then((res) => {
        setIsFoodLoading(false);

        if (res.data.errCode === 0) {
          setFoods(res.data.foods);
          setRootFoods(res.data.foods);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const handleDeleteFood = (food) => {
    swal({
      title: "Bạn muốn xóa món ăn?",
      text: `"${food.food_name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    })
      .then(async (ok) => {
        if (ok) {
          await api
            .delete(`/food-destroy`, {
              params: {
                id: food.id,
              },
            })
            .then((res) => {
              if (res.data.errCode === 0) {
                toast(res.data.message, {
                  type: "success",
                  autoClose: 1000,
                });
                getListFoods(selectedCategory);
              } else {
                toast(res.data.message?.id, {
                  type: "error",
                  autoClose: 1000,
                });
              }
            })
            .catch((err) => {
              toast("Hệ thống xảy ra lỗi", {
                type: "error",
                autoClose: 1000,
              });
            });
        }
      })
      .catch((err) => {});
  };
  // API

  // Logic
  const handleShowAddCategoryModal = () => {
    setAddCategoryModalShow(true);
  };
  const handleCloseAddCategoryModal = (isReload) => {
    setAddCategoryModalShow(false);

    if (isReload) {
      getListCategories();
    }
  };
  const handleShowEditCategoryModal = () => {
    setEditCategoryModalShow(true);
  };
  const handleCloseEditCategoryModal = (isReload) => {
    setEditCategoryModalShow(false);

    if (isReload) {
      getListCategories();
    }
  };

  const handleShowAddFoodModal = () => {
    setAddFoodModalShow(true);
  };
  const handleCloseAddFoodModal = (isReload) => {
    setAddFoodModalShow(false);

    if (isReload) {
      getListFoods(selectedCategory);
    }
  };
  const handleShowEditFoodModal = (foodId) => {
    setEditFoodModalShow(true);
    setSelectedFood(foodId);
  };
  const handleCloseEditFoodModal = (isReload) => {
    setEditFoodModalShow(false);

    if (isReload) {
      getListFoods(selectedCategory);
    }
  };
  // Logic

  const columns = [
    {
      name: "Tên món ăn",
      selector: (row) => row.food_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Danh mục",
      selector: (row) => row.category_name,
      sortable: true,
    },
    {
      name: "Đơn vị tính",
      selector: (row) => row.unit_name,
      sortable: true,
      center: true,
    },
    {
      id: "updated_at",
      name: "Cập nhật",
      selector: (row) => parseInt(row.updated_at),
      sortable: true,
      right: true,
      cell: (row) => new FormatData().date(row.updated_at),
    },
    {
      name: "Phương thức",
      center: true,
      cell: (row) => (
        <>
          {new Check().permission(["30", "28"], "or") ? (
            <Button
              size="sm"
              variant="warning me-2"
              type="button"
              onClick={() => handleShowEditFoodModal(row.id)}
            >
              <i className="fa-solid fa-pencil"></i>
            </Button>
          ) : (
            ""
          )}

          {new Check().permission(["31"]) ? (
            <Button
              size="sm"
              variant="danger"
              type="button"
              onClick={() => handleDeleteFood(row)}
            >
              <i className="fas fa-trash"></i>
            </Button>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  return (
    <Container fluid className="px-3">
      <Breadcrumb className="mb-2 mt-2">
        <Breadcrumb.Item active>Món ăn</Breadcrumb.Item>
        <Breadcrumb.Item active>Danh mục món</Breadcrumb.Item>
      </Breadcrumb>

      <Card className="mb-4">
        <Card.Header>
          <Card.Title>
            <i className="fas fa-list me-1" /> Danh mục món
            {new Check().permission(["36"], "or") ? (
              <Button
                variant="success"
                size="sm"
                className="float-end ms-2"
                onClick={() => handleShowAddCategoryModal()}
              >
                <i className="fa-solid fa-plus" /> Thêm danh mục
              </Button>
            ) : null}
            {new Check().permission(["37"], "or") ? (
              <Button
                variant="warning"
                size="sm"
                className="float-end ms-2"
                onClick={() => handleShowEditCategoryModal()}
                hidden={selectedCategory === 0}
              >
                <i className="fa-solid fa-edit" /> Sửa danh mục
              </Button>
            ) : null}
            {new Check().permission(["38"], "or") ? (
              <Button
                variant="danger"
                size="sm"
                className="float-end ms-2"
                onClick={() => hanldDeleteCategory()}
                hidden={selectedCategory === 0}
              >
                <i className="fa-solid fa-trash" /> Xóa danh mục
              </Button>
            ) : null}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={3} md={4} xs={12} className="mb-3">
              <Form.Control
                type="text"
                placeholder="Tìm món ăn"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </Col>

            <Col>
              {new Check().permission([29]) ? (
                <Button
                  variant="success"
                  size="sm"
                  className="float-end mb-3"
                  onClick={() => handleShowAddFoodModal()}
                >
                  <i className="fa-solid fa-plus"></i> Thêm món
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Nav variant="tabs mb-3" defaultActiveKey={0}>
            <Nav.Item>
              <Nav.Link eventKey={0} onClick={() => setSelectedCategory(0)}>
                Tất cả
              </Nav.Link>
            </Nav.Item>

            {categories?.map((category, indexCategory) => {
              return (
                <Nav.Item key={`category-${indexCategory}`}>
                  <Nav.Link
                    eventKey={category.id}
                    onClick={() => setSelectedCategory(category.id)}
                  >
                    {category.category_name}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>

          <DataTables
            data={foods}
            columns={columns}
            progressPending={isFoodLoading}
            progressComponent={
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                style={{ width: "3rem", height: "3rem" }}
                className="mt-3"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            }
            defaultSortFieldId="updated_at"
            defaultSortAsc={false}
          />
        </Card.Body>
      </Card>

      {addCategoryModalShow ? (
        <CategoryAdd
          show={addCategoryModalShow}
          onHide={handleCloseAddCategoryModal}
        />
      ) : null}

      {editCategoryModalShow ? (
        <CategoryEdit
          show={editCategoryModalShow}
          onHide={handleCloseEditCategoryModal}
          selectedCategory={categories.find(
            (category) => category.id === selectedCategory
          )}
        />
      ) : null}

      {addFoodModalShow ? (
        <FoodAdd show={addFoodModalShow} onHide={handleCloseAddFoodModal} />
      ) : null}

      {editFoodModalShow ? (
        <FoodEdit
          show={editFoodModalShow}
          onHide={handleCloseEditFoodModal}
          selectedFood={selectedFood}
        />
      ) : null}
    </Container>
  );
};

export default FoodIndex;
