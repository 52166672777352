import * as XLSX from "xlsx-js-style";
import { SHOW_INVENTORY } from "../../../../constants";
import ExcelJS from "exceljs";
const fontStyle = {
  name: "Times New Roman",
};

const borderStyle = {
  top: { style: "thin" },
  bottom: { style: "thin" },
  left: { style: "thin" },
  right: { style: "thin" },
};
const exportMultipleTablesToExcel = (
  groupIngredientArray,
  tableIds,
  fileName
) => {
  const workbook = new ExcelJS.Workbook();
  const marketGroupIngredient = groupIngredientArray?.filter(
    (groupIngredientItem) => groupIngredientItem[0].ingredent_type === 1
  );

  const storeGroupIngredient = groupIngredientArray?.filter(
    (groupIngredientItem) => groupIngredientItem[0].ingredent_type === 2
  );
  tableIds.forEach((tableId, index) => {
    const table = document.getElementById(tableId);
    if (!table) {
      console.error("Lỗi");
      return;
    }

    const worksheet = workbook.addWorksheet(`Sổ bước ${index + 1}`, {
      views: [{ rightToLeft: false }],
    });

    worksheet.pageSetup = {
      orientation: "landscape",
      paperSize: 9,
      fitToPage: true,
      fitToWidth: 1,
      fitToHeight: 0,
    };

    const rows = table.querySelectorAll("tr");

    rows.forEach((row, indexCell) => {
      const cells = row.querySelectorAll("td, th");
      const excelRow = [];

      cells.forEach((cell) => {
        let cellValue = cell.textContent.trim();

        if (cellValue === "empty") {
          cellValue = "";
        }

        excelRow.push(cellValue);
      });

      const newRow = worksheet.addRow(excelRow);
      newRow.eachCell({ includeEmpty: true }, (cell) => {
        cell.font = {
          name: fontStyle?.name,
        };
        cell.alignment = {
          wrapText: true,
        };
      });
      if (indexCell >= 3 && index === 0 && indexCell < rows?.length - 1)
        newRow.eachCell({ includeEmpty: true }, (cell) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

      if (
        indexCell >= 1 &&
        index >= 1 &&
        indexCell < rows?.length - 1 &&
        indexCell != 7 + marketGroupIngredient?.length
      )
        newRow.eachCell({ includeEmpty: true }, (cell) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
    });
    if (index === 0) {
      const colAlpha = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
      ];
      const columnWidths = [
        { wch: 3.5 },
        { wch: 12 },
        { wch: 7 },
        { wch: 9 },
        { wch: 12 },
        { wch: 11 },
        { wch: 12 },
        { wch: 9 },
        { wch: 9 },
        { wch: 10 },
        { wch: 8 },
        { wch: 7 },
        { wch: 7 },
        { wch: 7 },
        { wch: 8 },
      ];
      colAlpha.forEach((column, index) => {
        worksheet.getColumn(column).width = columnWidths[index].wch;
      });
      worksheet.getRow(1).height = 100 / 2;
      worksheet.getRow(1).eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF" },
        };

        cell.font = {
          name: fontStyle?.name,
          bold: true,
          size: 16,
          color: { argb: "000" },
        };

        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };

        worksheet.getCell("I2").fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF" },
        };

        worksheet.getCell("I2").font = {
          bold: true,
          name: fontStyle?.name,

          color: { argb: "0000" },
        };

        worksheet.getCell("I2").alignment = {
          vertical: "top",
        };
        worksheet.getRow(2).height = 140 / 2;
        worksheet.getCell("A2").fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF" },
        };

        worksheet.getCell("A2").font = {
          // bold: true,
          name: fontStyle?.name,

          color: { argb: "0000" },
        };

        worksheet.getCell("A2").alignment = {
          vertical: "middle",
          wrapText: true,
        };

        worksheet.getCell("A3").font = {
          bold: true,
          name: fontStyle?.name,

          color: { argb: "0000" },
        };
        worksheet.getCell(`A${7 + marketGroupIngredient?.length}`).font = {
          bold: true,
          name: fontStyle?.name,

          color: { argb: "0000" },
        };
      });
      worksheet.getRow(4).height = 150 / 2;
      worksheet.getRow(5).height = 75 / 2;

      for (let i = 4; i <= 6; i++) {
        worksheet.getRow(i).eachCell({ includeEmpty: true }, (cell) => {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
        });
      }
      for (let i = 7; i <= 6 + marketGroupIngredient?.length; i++) {
        worksheet.getRow(i).eachCell({ includeEmpty: true }, (cell) => {
          cell.alignment = {
            vertical: "middle",
            wrapText: true,
          };
        });
      }
      worksheet.mergeCells(`A1:O1`);
      worksheet.mergeCells(`A2:H2`);
      worksheet.mergeCells(`I2:O2`);
      worksheet.mergeCells(`A3:O3`);
      worksheet.mergeCells("A4:A5");
      worksheet.mergeCells("B4:B5");
      worksheet.mergeCells("C4:C5");
      worksheet.mergeCells("D4:D5");
      worksheet.mergeCells("H4:H5");
      worksheet.mergeCells("I4:I5");
      worksheet.mergeCells("J4:J5");
      worksheet.mergeCells("O4:O5");
      worksheet.mergeCells("E4:G4");
      worksheet.mergeCells("K4:L4");
      worksheet.mergeCells("M4:N4");
      worksheet.mergeCells(
        `A${7 + marketGroupIngredient?.length}:O${
          7 + marketGroupIngredient?.length
        }`
      );
      for (
        let i = 8 + marketGroupIngredient?.length;
        i <= 11 + marketGroupIngredient?.length;
        i++
      ) {
        worksheet.getRow(i).eachCell({ includeEmpty: true }, (cell) => {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
        });
      }
      for (
        let i = 11 + marketGroupIngredient?.length;
        i <= 10 + marketGroupIngredient?.length + storeGroupIngredient?.length;
        i++
      ) {
        worksheet.getRow(i).eachCell({ includeEmpty: true }, (cell) => {
          cell.alignment = {
            vertical: "middle",
            wrapText: true,
          };
        });
      }
      const startRow = 8 + marketGroupIngredient?.length;
      const endRow = 9 + marketGroupIngredient?.length;
      worksheet.getRow(startRow).height = 150 / 2;
      worksheet.getRow(endRow).height = 75 / 2;
      worksheet.mergeCells(`A${startRow}:A${endRow}`);
      worksheet.mergeCells(`B${startRow}:B${endRow}`);
      worksheet.mergeCells(`C${startRow}:C${endRow}`);
      worksheet.mergeCells(`D${startRow}:D${endRow}`);
      worksheet.mergeCells(`E${startRow}:E${endRow}`);
      worksheet.mergeCells(`F${startRow}:F${endRow}`);
      worksheet.mergeCells(`J${startRow}:J${endRow}`);
      worksheet.mergeCells(`O${startRow}:O${endRow}`);
      worksheet.mergeCells(`G${startRow}:I${startRow}`);
      worksheet.mergeCells(`K${startRow}:K${endRow}`);
      worksheet.mergeCells(`L${startRow}:L${endRow}`);
      worksheet.mergeCells(`M${startRow}:N${startRow}`);
      const startRow_ =
        11 + marketGroupIngredient?.length + storeGroupIngredient?.length;
      const endRow_ = 9 + marketGroupIngredient?.length;
      worksheet.mergeCells(`A${startRow_}:E${startRow_}`);
      worksheet.mergeCells(`F${startRow_}:J${startRow_}`);
      worksheet.mergeCells(`K${startRow_}:O${startRow_}`);
      worksheet.getRow(startRow_).height = 80 / 2;
      worksheet.getRow(startRow_).eachCell({ includeEmpty: true }, (cell) => {
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
    }
    if (index === 1) {
      const colAlpha = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
      ];
      const columnWidths = [
        { wch: 3.5 },
        { wch: 8 },
        { wch: 15 },
        { wch: 28 },
        { wch: 5.5 },
        { wch: 5.5 },
        { wch: 6 },
        { wch: 15.5 },
        { wch: 9.5 },
        { wch: 8.5 },
        { wch: 7.5 },
        { wch: 7.5 },
        { wch: 10 },
      ];
      colAlpha.forEach((column, index) => {
        worksheet.getColumn(column).width = columnWidths[index].wch;
      });
      worksheet.getRow(1).height = 145 / 2;
      worksheet.getCell("A1").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFF" },
      };

      worksheet.getCell("A1").font = {
        // bold: true,
        name: fontStyle?.name,

        color: { argb: "0000" },
      };
      worksheet.mergeCells(`A1:F1`);
      worksheet.mergeCells(`G1:M1`);
      worksheet.getCell("A1").alignment = {
        vertical: "middle",
        wrapText: true,
      };
      worksheet.getCell("G1").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFF" },
      };

      worksheet.getCell("G1").font = {
        bold: true,
        name: fontStyle?.name,

        color: { argb: "0000" },
      };

      worksheet.getCell("G1").alignment = {
        vertical: "top",
      };

      for (let i = 2; i <= 4; i++) {
        worksheet.getRow(i).eachCell({ includeEmpty: true }, (cell) => {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
        });
      }
      const startRow = 2;
      const endRow = 3;
      worksheet.getRow(startRow).height = 150 / 2;
      worksheet.getRow(endRow).height = 200 / 2;
      worksheet.mergeCells(`A${startRow}:A${endRow}`);
      worksheet.mergeCells(`B${startRow}:B${endRow}`);
      worksheet.mergeCells(`C${startRow}:C${endRow}`);
      worksheet.mergeCells(`D${startRow}:D${endRow}`);
      worksheet.mergeCells(`E${startRow}:E${endRow}`);
      worksheet.mergeCells(`F${startRow}:F${endRow}`);
      worksheet.mergeCells(`G${startRow}:G${endRow}`);
      worksheet.mergeCells(`H${startRow}:J${startRow}`);
      worksheet.mergeCells(`K${startRow}:L${startRow}`);
      worksheet.mergeCells(`M${startRow}:M${endRow}`);
      worksheet.mergeCells(`A${rows.length}:D${rows.length}`);
      worksheet.mergeCells(`E${rows.length}:I${rows.length}`);
      worksheet.mergeCells(`J${rows.length}:M${rows.length}`);
      worksheet.getRow(rows.length).height = 80 / 2;

      worksheet.getRow(rows.length).eachCell({ includeEmpty: true }, (cell) => {
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
      for (let i = 5; i < rows.length; i++) {
        worksheet
          .getRow(i)
          .eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber === 4 || colNumber === 8 || colNumber === 3) {
              cell.alignment = {
                vertical: "middle",
                horizontal: "left",
                wrapText: true,
              };
            } else {
              cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
              };
            }
          });
      }
    }
    if (index === 2) {
      const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
      const columnWidths = [
        { wch: 3.5 },
        { wch: 13 },
        { wch: 18 },
        { wch: 11 },
        { wch: 11 },
        { wch: 11 },
        { wch: 25 },
        { wch: 13 },
        { wch: 13 },
        { wch: 13 },
      ];
      colAlpha.forEach((column, index) => {
        worksheet.getColumn(column).width = columnWidths[index].wch;
      });
      worksheet.getRow(1).height = 145 / 2;
      worksheet.getCell("A1").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFF" },
      };

      worksheet.getCell("A1").font = {
        // bold: true,
        name: fontStyle?.name,

        color: { argb: "0000" },
      };
      worksheet.mergeCells(`A1:F1`);
      worksheet.mergeCells(`G1:J1`);
      worksheet.getCell("A1").alignment = {
        vertical: "middle",
        wrapText: true,
      };
      worksheet.getCell("G1").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFF" },
      };

      worksheet.getCell("G1").font = {
        bold: true,
        name: fontStyle?.name,

        color: { argb: "0000" },
      };

      worksheet.getCell("G1").alignment = {
        vertical: "top",
      };

      for (let i = 2; i <= 4; i++) {
        worksheet.getRow(i).eachCell({ includeEmpty: true }, (cell) => {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
        });
      }
      const startRow = 2;
      const endRow = 3;
      worksheet.getRow(startRow).height = 150 / 2;
      // worksheet.getRow(endRow).height = 70;
      worksheet.mergeCells(`A${startRow}:A${endRow}`);
      worksheet.mergeCells(`B${startRow}:B${endRow}`);
      worksheet.mergeCells(`C${startRow}:C${endRow}`);
      worksheet.mergeCells(`D${startRow}:D${endRow}`);
      worksheet.mergeCells(`E${startRow}:E${endRow}`);
      worksheet.mergeCells(`F${startRow}:F${endRow}`);
      worksheet.mergeCells(`G${startRow}:G${endRow}`);
      worksheet.mergeCells(`H${startRow}:I${startRow}`);
      worksheet.mergeCells(`A${rows.length}:C${rows.length}`);
      worksheet.mergeCells(`D${rows.length}:G${rows.length}`);
      worksheet.mergeCells(`H${rows.length}:J${rows.length}`);
      worksheet.getRow(rows.length).height = 80 / 2;

      worksheet.getRow(rows.length).eachCell({ includeEmpty: true }, (cell) => {
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });
      for (let i = 5; i < rows.length; i++) {
        worksheet
          .getRow(i)
          .eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber === 4 || colNumber === 1) {
              cell.alignment = {
                vertical: "middle",
                horizontal: "center",

                wrapText: true,
              };
            } else {
              cell.alignment = {
                vertical: "middle",
                horizontal: "left",

                wrapText: true,
              };
            }
          });
      }
    }
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  });
};

const exportBook1 = (groupIngredientArray, step1_show_inventory = 1) => {
  const table = document.getElementById("book-step-1");
  var ws = XLSX.utils.table_to_sheet(table, { raw: true });

  //   ws["!pageSetup"] = {
  //     orientation: "landscape",
  //   };
  const marketGroupIngredient = groupIngredientArray?.filter(
    (groupIngredientItem) => groupIngredientItem[0].ingredent_type === 1
  );
  const storeGroupIngredient = groupIngredientArray?.filter(
    (groupIngredientItem) => groupIngredientItem[0].ingredent_type === 2
  );

  const lenMar =
    marketGroupIngredient.length === 0 ? 1 : marketGroupIngredient.length;
  const lenSto =
    storeGroupIngredient.length === 0 &&
    step1_show_inventory === SHOW_INVENTORY.TRUE
      ? 1
      : storeGroupIngredient.length;

  const COUNT_TITLE_MARKET = 6;
  const COUNT_TITLE_STORE =
    step1_show_inventory === SHOW_INVENTORY.TRUE ? 4 : 0;

  // Style cell
  const colAlpha = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
  ];

  const colAlphaBook1_Footer = ["A", "F", "K"];

  ws[`A1`].s = {
    font: {
      ...fontStyle,
      sz: 16,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[`A2`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
      wrapText: true,
    },
  };

  ws[`A3`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "left",
    },
  };

  ws[`I2`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "top",
      horizontal: "left",
    },
  };

  colAlpha.map((alpha) => {
    ws[`${alpha}4`].s = {
      font: {
        ...fontStyle,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: borderStyle,
    };

    return alpha;
  });

  colAlpha.map((alpha) => {
    ws[`${alpha}5`].s = {
      font: fontStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: borderStyle,
    };

    return alpha;
  });

  colAlpha.map((alpha) => {
    ws[`${alpha}6`].s = {
      font: fontStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };

    return alpha;
  });

  ws[`A${COUNT_TITLE_MARKET + lenMar + 1}`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "left",
    },
  };

  if (step1_show_inventory === SHOW_INVENTORY.TRUE) {
    for (let i = 1; i < COUNT_TITLE_STORE; i++) {
      colAlpha.map((alpha) => {
        ws[`${alpha}${6 + lenMar + i + 1}`].s = {
          font: fontStyle,
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          border: borderStyle,
        };
        return alpha;
      });
    }
  }

  ws["!cols"] = [
    { wch: 3 },
    { wch: 10 },
    { wch: 7 },
    { wch: 8 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 8 },
    { wch: 9 },
    { wch: 9 },
    { wch: 7 },
    { wch: 7 },
    { wch: 7 },
    { wch: 7 },
    { wch: 6 },
  ];

  const row = [{ hpt: 40 }, { hpt: 70 }, {}, { hpt: 60 }, {}, {}];

  lenMar === 1
    ? row.push({})
    : marketGroupIngredient.map((data) => {
        row.push({});
        return data;
      });

  row.push({});
  row.push({ hpt: 60 });
  row.push({ hpt: 45 });
  row.push({});

  lenSto === 1
    ? row.push({})
    : storeGroupIngredient.map((data) => {
        row.push({});
        return data;
      });

  row.push({ hpt: 60 });

  ws["!rows"] = row;

  colAlphaBook1_Footer.map((alpha) => {
    ws[
      `${alpha}${COUNT_TITLE_MARKET + lenMar + COUNT_TITLE_STORE + lenSto + 1}`
    ].s = {
      font: fontStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    return alpha;
  });

  const intLen = 7;

  for (let i = 0; i < lenMar; i++) {
    ws[`A${intLen + i}`].s = {
      font: fontStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };

    colAlpha.slice(1).map((alpha) => {
      ws[`${alpha}${intLen + i}`].s = {
        font: fontStyle,
        alignment: {
          vertical: "center",
          wrapText: true,
        },
        border: borderStyle,
      };

      return alpha;
    });
  }

  const sendLen = intLen + lenMar + COUNT_TITLE_STORE;

  for (let i = 0; i < lenSto; i++) {
    ws[`A${sendLen + i}`].s = {
      font: fontStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };

    colAlpha.slice(1).map((alpha) => {
      if (ws[`${alpha}${sendLen + i}`].v === "empty") {
        ws[`${alpha}${sendLen + i}`].v = " ";
      }

      ws[`${alpha}${sendLen + i}`].s = {
        font: fontStyle,
        alignment: {
          vertical: "center",
          wrapText: true,
        },
        border: borderStyle,
      };

      return alpha;
    });
  }

  let merge = [
    // Title
    { s: { r: 0, c: 0 }, e: { r: 0, c: 14 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
    { s: { r: 1, c: 8 }, e: { r: 1, c: 14 } },
    { s: { r: 2, c: 0 }, e: { r: 2, c: 14 } },
    // Title

    // Header 1
    { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
    { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
    { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },
    { s: { r: 3, c: 3 }, e: { r: 4, c: 3 } },

    { s: { r: 3, c: 4 }, e: { r: 3, c: 6 } },

    { s: { r: 3, c: 7 }, e: { r: 4, c: 7 } },
    { s: { r: 3, c: 8 }, e: { r: 4, c: 8 } },
    { s: { r: 3, c: 9 }, e: { r: 4, c: 9 } },

    { s: { r: 3, c: 10 }, e: { r: 3, c: 11 } },
    { s: { r: 3, c: 12 }, e: { r: 3, c: 13 } },

    { s: { r: 3, c: 14 }, e: { r: 4, c: 14 } },
    // Header 1

    // Footer
    {
      s: { r: 6 + lenMar + COUNT_TITLE_STORE + lenSto, c: 0 },
      e: { r: 6 + lenMar + COUNT_TITLE_STORE + lenSto, c: 4 },
    },
    {
      s: { r: 6 + lenMar + COUNT_TITLE_STORE + lenSto, c: 5 },
      e: { r: 6 + lenMar + COUNT_TITLE_STORE + lenSto, c: 9 },
    },
    {
      s: { r: 6 + lenMar + COUNT_TITLE_STORE + lenSto, c: 10 },
      e: { r: 6 + lenMar + COUNT_TITLE_STORE + lenSto, c: 14 },
    },
    // Footer
  ];

  if (step1_show_inventory === SHOW_INVENTORY.TRUE) {
    merge = merge.concat([
      // Header 2
      { s: { r: 6 + lenMar, c: 0 }, e: { r: 6 + lenMar, c: 14 } },
      { s: { r: 6 + lenMar + 1, c: 0 }, e: { r: 6 + lenMar + 2, c: 0 } },
      { s: { r: 6 + lenMar + 1, c: 1 }, e: { r: 6 + lenMar + 2, c: 1 } },
      { s: { r: 6 + lenMar + 1, c: 2 }, e: { r: 6 + lenMar + 2, c: 2 } },
      { s: { r: 6 + lenMar + 1, c: 3 }, e: { r: 6 + lenMar + 2, c: 3 } },
      { s: { r: 6 + lenMar + 1, c: 4 }, e: { r: 6 + lenMar + 2, c: 4 } },
      { s: { r: 6 + lenMar + 1, c: 5 }, e: { r: 6 + lenMar + 2, c: 5 } },

      { s: { r: 6 + lenMar + 1, c: 6 }, e: { r: 6 + lenMar + 1, c: 8 } },

      { s: { r: 6 + lenMar + 1, c: 9 }, e: { r: 6 + lenMar + 2, c: 9 } },
      { s: { r: 6 + lenMar + 1, c: 10 }, e: { r: 6 + lenMar + 2, c: 10 } },
      { s: { r: 6 + lenMar + 1, c: 11 }, e: { r: 6 + lenMar + 2, c: 11 } },

      { s: { r: 6 + lenMar + 1, c: 12 }, e: { r: 6 + lenMar + 1, c: 13 } },

      { s: { r: 6 + lenMar + 1, c: 14 }, e: { r: 6 + lenMar + 2, c: 14 } },
      // Header 2
    ]);
  }

  if (marketGroupIngredient.length === 0)
    merge.push({ s: { r: 6, c: 0 }, e: { r: 6, c: 14 } });

  if (storeGroupIngredient.length === 0)
    merge.push({
      s: { r: 6 + lenMar + COUNT_TITLE_STORE, c: 0 },
      e: { r: 6 + lenMar + COUNT_TITLE_STORE, c: 14 },
    });

  ws["!merges"] = merge;

  return ws;
};

const exportBook2 = (ingredientLen) => {
  const table = document.getElementById("book-step-2");

  var ws = XLSX.utils.table_to_sheet(table, { raw: true });

  // Style cell
  const colAlpha = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
  ];

  ws[`A1`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
      wrapText: true,
    },
  };

  ws[`G1`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "top",
      horizontal: "left",
    },
  };

  colAlpha.map((alpha) => {
    ws[`${alpha}2`].s = {
      font: fontStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: borderStyle,
    };

    return alpha;
  });

  colAlpha.map((alpha) => {
    ws[`${alpha}3`].s = {
      font: fontStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: borderStyle,
    };

    return alpha;
  });

  colAlpha.map((alpha) => {
    ws[`${alpha}4`].s = {
      font: fontStyle,
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: borderStyle,
    };

    return alpha;
  });

  ws["!cols"] = [
    { wch: 3 },
    { wch: 8 },
    { wch: 15 },
    { wch: 28 },
    { wch: 5.5 },
    { wch: 5.5 },
    { wch: 6 },
    { wch: 12.5 },
    { wch: 9.5 },
    { wch: 6.5 },
    { wch: 6.5 },
    { wch: 6.5 },
    { wch: 7 },
  ];

  const row = [{ hpt: 70 }, { hpt: 60 }, {}];

  const intLen = 5;
  for (let i = 0; i < ingredientLen; i++) {
    row.push({});

    colAlpha.map((alpha) => {
      if (ws[`${alpha}${intLen + i}`].v === "empty") {
        ws[`${alpha}${intLen + i}`].v = " ";
      }

      if (
        alpha === "E" ||
        alpha === "F" ||
        alpha === "G" ||
        alpha === "I" ||
        alpha === "J"
      ) {
        ws[`${alpha}${intLen + i}`].s = {
          font: fontStyle,
          border: borderStyle,
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
        };
      } else if (
        alpha === "B" ||
        alpha === "C" ||
        alpha === "D" ||
        alpha === "H"
      ) {
        ws[`${alpha}${intLen + i}`].s = {
          font: {
            name: "Times New Roman",
          },
          border: borderStyle,
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "left",
          },
        };
      } else {
        ws[`${alpha}${intLen + i}`].s = {
          font: fontStyle,
          border: borderStyle,
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
        };
      }

      return alpha;
    });

    if (intLen === intLen + i) {
      const str = ws[`H${intLen + i}`.toString()].v;
      ws[`H${intLen + i}`.toString()].v = str.split("delete").pop();
    }

    const str = ws[`G${intLen + i}`.toString()].v;
    ws[`G${intLen + i}`.toString()].v = str.replaceAll("Selected time:", "");

    const str1 = ws[`F${intLen + i}`.toString()].v;
    ws[`F${intLen + i}`.toString()].v = str1.replaceAll("Selected time:", "");
  }

  row.push({}, { hpt: 60 });

  ws["!rows"] = row;

  const merge = [
    // Title
    { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
    { s: { r: 0, c: 6 }, e: { r: 0, c: 12 } },
    // Title

    // Header
    { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
    { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
    { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
    { s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },
    { s: { r: 1, c: 4 }, e: { r: 2, c: 4 } },
    { s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },
    { s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },

    { s: { r: 1, c: 7 }, e: { r: 1, c: 9 } },
    { s: { r: 1, c: 10 }, e: { r: 1, c: 11 } },

    { s: { r: 1, c: 12 }, e: { r: 2, c: 12 } },
    // Header

    // Footer
    { s: { r: 4 + ingredientLen, c: 0 }, e: { r: 4 + ingredientLen, c: 3 } },
    { s: { r: 4 + ingredientLen, c: 4 }, e: { r: 4 + ingredientLen, c: 8 } },
    { s: { r: 4 + ingredientLen, c: 9 }, e: { r: 4 + ingredientLen, c: 12 } },
    // Footer
  ];
  ws["!merges"] = merge;

  colAlpha.map((alpha) => {
    ws[`${alpha}${4 + ingredientLen + 1}`].s = {
      font: fontStyle,
      alignment: {
        vertical: "top",
        horizontal: "center",
      },
    };

    return alpha;
  });

  return ws;
};

const exportBook3 = (ingredientLen) => {
  const table = document.getElementById("book-step-3");

  var ws = XLSX.utils.table_to_sheet(table, { raw: true });

  // Style cell
  const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

  const colAlphaBook3_Footer = ["A", "D", "H"];

  ws[`A1`].s = {
    font: fontStyle,
    alignment: {
      wrapText: true,
      vertical: "top",
      horizontal: "left",
    },
  };

  ws[`G1`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "top",
      horizontal: "left",
    },
  };

  colAlpha.map((alpha) => {
    ws[`${alpha}2`].s = {
      font: fontStyle,
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };

    return alpha;
  });

  colAlpha.map((alpha) => {
    ws[`${alpha}3`].s = {
      font: fontStyle,
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };

    return alpha;
  });

  colAlpha.map((alpha) => {
    ws[`${alpha}4`].s = {
      font: fontStyle,
      alignment: {
        wrapText: true,
        vertical: "center",
        horizontal: "center",
      },
      border: borderStyle,
    };

    return alpha;
  });

  ws["!cols"] = [
    { wch: 3.5 },
    { wch: 13 },
    { wch: 15 },
    { wch: 11 },
    { wch: 11 },
    { wch: 11 },
    { wch: 25 },
    { wch: 11 },
    { wch: 11 },
    { wch: 11 },
  ];

  const row = [{ hpt: 70 }, { hpt: 60 }, {}];

  const intLen = 5;

  for (let i = 0; i < ingredientLen; i++) {
    row.push({});

    colAlpha.map((alpha) => {
      if (ws[`${alpha}${intLen + i}`].v === "empty") {
        ws[`${alpha}${intLen + i}`].v = " ";
      }

      if (alpha === "D") {
        ws[`${alpha}${intLen + i}`].s = {
          font: fontStyle,
          border: borderStyle,
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
        };
      } else if (
        alpha === "B" ||
        alpha === "C" ||
        alpha === "E" ||
        alpha === "F" ||
        alpha === "G"
      ) {
        ws[`${alpha}${intLen + i}`].s = {
          font: fontStyle,
          border: borderStyle,
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "left",
          },
        };
      } else {
        ws[`${alpha}${intLen + i}`].s = {
          font: fontStyle,
          border: borderStyle,
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
        };
      }

      return alpha;
    });

    const str = ws[`B${intLen + i}`.toString()].v;
    ws[`B${intLen + i}`.toString()].v = str.replaceAll("Selected time:", "");

    const str1 = ws[`E${intLen + i}`.toString()].v;
    ws[`E${intLen + i}`.toString()].v = str1.replaceAll("Selected time:", "");

    const str2 = ws[`F${intLen + i}`.toString()].v;
    ws[`F${intLen + i}`.toString()].v = str2.replaceAll("Selected time:", "");
  }

  row.push({}, { hpt: 60 });

  ws["!rows"] = row;

  const merge = [
    // Title
    { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
    { s: { r: 0, c: 6 }, e: { r: 0, c: 9 } },
    // Title

    // Header
    { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
    { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
    { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
    { s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },
    { s: { r: 1, c: 4 }, e: { r: 2, c: 4 } },
    { s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },
    { s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },

    { s: { r: 1, c: 7 }, e: { r: 1, c: 8 } },

    { s: { r: 1, c: 9 }, e: { r: 2, c: 9 } },
    // Header

    // Footer
    { s: { r: 4 + ingredientLen, c: 0 }, e: { r: 4 + ingredientLen, c: 2 } },
    { s: { r: 4 + ingredientLen, c: 3 }, e: { r: 4 + ingredientLen, c: 6 } },
    { s: { r: 4 + ingredientLen, c: 7 }, e: { r: 4 + ingredientLen, c: 9 } },
    // Footer
  ];
  ws["!merges"] = merge;

  colAlphaBook3_Footer.map((alpha) => {
    ws[`${alpha}${4 + ingredientLen + 1}`].s = {
      font: fontStyle,
      alignment: {
        vertical: "top",
        horizontal: "center",
      },
    };

    return alpha;
  });

  return ws;
};

export { exportBook1, exportBook2, exportBook3, exportMultipleTablesToExcel };
